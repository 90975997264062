/**
 * @file Followers form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';

/**
 * Loads followers form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Most active followers</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table className="collapse-table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Select</TableCell>
                  <TableCell align="left">Account</TableCell>
                  <TableCell align="center">Verified</TableCell>
                  <TableCell align="center">No. of followers</TableCell>
                  <TableCell align="center">No. of friends</TableCell>
                  <TableCell align="center">No. of tweets</TableCell>
                  <TableCell align="center">Creation date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analysisDetail.accounts.map((follower: any, index: number) => (
                    <TableRow hover key={`follower-${index}`}>
                      <TableCell compact-title="Select" align="center">
                        <Checkbox checked={follower.keep} color="primary" disabled={true}/>
                      </TableCell>
                      <TableCell compact-title="Account"
                                 align="left">{follower.screen_name}</TableCell>
                      <TableCell compact-title="Verified"
                                 align="center">{follower.verified ? 'Yes' : 'No'}</TableCell>
                      <TableCell compact-title="No. of followers"
                                 align="center">{follower.followers_count}</TableCell>
                      <TableCell compact-title="No. of friends"
                                 align="center">{follower.friends_count}</TableCell>
                      <TableCell compact-title="No. of tweets"
                                 align="center">{follower.statuses_count}</TableCell>
                      <TableCell compact-title="Creation date"
                                 align="center">{moment(follower.created_at).format('YYYY-MM-DD')}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
);
