/**
 * @file Blank table for teams page when no teams are on record
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import analyticsImage from '../../../assets/images/analytics.png';
import pages from '../../../config/pages.json';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads blank table for teams page.
 * Prompts user to add the first team.
 */
export default () => (
    <Box mt={3} mb={2} px={2} py={10} border={1} borderColor="grey.300" borderRadius="borderRadius">
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item xs={12}>
          <img src={analyticsImage} alt="Analytics Graphic"/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>You don't have any teams. Try adding your first
            team.</Typography>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <LoadingButton variant="contained" color="secondary" href={pages['new-team-initial'].url}>Add
            team</LoadingButton>
        </Grid>
      </Grid>
    </Box>
);
