/**
 * @file Page footer
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import pages from '../../../config/pages.json';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[600],
    fontSize: '10pt',
    maxWidth: '100vw'
  },
  footerLink: {
    color: theme.palette.grey[900]
  }
}));

/**
 * Loads the common footer for most pages.
 */
export default () => {
  // Current year
  const year = new Date().getFullYear();

  const classes = useStyles();
  return (
      <Container component="footer" maxWidth="xl" className={classes.footer}>
        <Box display="flex" alignItems="center" justifyContent="flex-end" minHeight={56} px={3}
             borderTop={1} borderColor="grey.300">
          <Breadcrumbs separator="|">
            <Link component={RouterLink} to={pages['about'].url}>
              <Typography variant="body2"
                          className={classes.footerLink}>{pages['about'].title}</Typography>
            </Link>
            <Link component={RouterLink} to={pages['disclaimers'].url}>
              <Typography variant="body2"
                          className={classes.footerLink}>{pages['disclaimers'].title}</Typography>
            </Link>
            <Link component={RouterLink} to={pages['contact'].url}>
              <Typography variant="body2"
                          className={classes.footerLink}>{pages['contact'].title}</Typography>
            </Link>
            <Link href={pages['terms-and-conditions'].url} target="_blank"
                  rel="noopener noreferrer">
              <Typography variant="body2"
                          className={classes.footerLink}>{pages['terms-and-conditions'].title}</Typography>
            </Link>
            <Link href={pages['privacy'].url} target="_blank" rel="noopener noreferrer">
              <Typography variant="body2"
                          className={classes.footerLink}>{pages['privacy'].title}</Typography>
            </Link>
            <Typography variant="body2" className={classes.footerLink}>&copy; {year} University of
              Toronto</Typography>
          </Breadcrumbs>
        </Box>
      </Container>
  );
};
