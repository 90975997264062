/**
 * @file Layout for global dashboard
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../hooks/message-bar';
import Layout from '../common/layout';
import Dashboard from './dashboard';

/**
 * Sets up the page layout and loads global dashboard.
 */
export default () => (
    <Layout pageId={'main-dashboard'} title={null}>
      <MessageBarProvider>
        <Dashboard/>
      </MessageBarProvider>
    </Layout>
);
