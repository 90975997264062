/**
 * @file Hamburger button for header
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useContext } from 'react';
import { NavbarContext } from '../../../hooks/navbar';

/**
 * Loads hamburger button for header.
 */
export default () => {
  /**
   * Navbar state and dispatcher
   */
  const navbar = useContext(NavbarContext);

  /**
   * Toggles navbar when the user clicks the hamburger button.
   */
  const toggleNavbar = () => navbar.dispatch({ type: 'toggle' });

  return (
      <Box mr={1}>
        <IconButton edge="start" color="inherit" onClick={toggleNavbar}
                    aria-label="Toggle navigation bar">
          <MenuIcon/>
        </IconButton>
      </Box>
  );
};
