/**
 * @file Helper functions for the REST API calls
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { getLocalTokens } from './auth';

/**
 * Makes API call and includes all necessary headers and authentication data.
 * @param {AbortSignal} signal - abort signal to terminate the fetch request
 * @param {boolean} isAuthorized - indicated whether the request requires authentication
 * @param {string} url - URL of the endpoint
 * @param {string} method - request method
 * @param {any | undefined} payload - JSON based body of the request
 * @param {string | undefined} successMessage - message to be returned if the request is successful
 * @param {string} tokenName - overrides default token name (default: access_token)
 * @return {any} - JSON based response
 */
const call = async (signal: AbortSignal, isAuthorized: boolean, url: string, method: string, payload?: any, successMessage?: string, tokenName: string = 'access_token') => {
  let success = false;
  let message = undefined;
  let data = undefined;
  let next = undefined;
  let previous = undefined;
  let resourceCount = -1;
  let statusCode = 500;

  // Gets user authentication tokens.
  const tokens = getLocalTokens();

  // Proceeds if the request doesn't require an authentication or if we have a token loaded.
  if (!isAuthorized || (tokens && tokens[tokenName])) {
    try {
      // Sets up headers.
      const headers: any = {
        "Cache-Control": "no-cache",
        "Accept": "application/json"
      };
      if (method !== 'GET') {
        headers['Content-Type'] = 'application/json';
      }
      // Sets up authentication token if applicable.
      if (isAuthorized) {
        headers.Authorization = `Token ${tokens[tokenName]}`;
      }

      // Packages together the request settings.
      const settings: any = {
        "method": method,
        "headers": headers,
        "signal": signal
      };

      // Parses payload
      if (payload) {
        settings.body = JSON.stringify(payload);
      }

      // Makes the request asynchronously.
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, settings);

      // Not all endpoints support total-count
      resourceCount = parseInt(response.headers.get('total-count') || '-1');

      statusCode = response.status;

      // Responses from HEAD request do not have a body
      if (method !== 'HEAD') {
        // Gets the response from the request as a JSON object.
        const result = await response.json();

        if (result.status === 'success') {
          success = true;
          if (successMessage) {
            message = successMessage;
          }
          data = result.result;
          next = result.relative_next;
          previous = result.relative_previous;
        } else if (result.status === 'error') {

          data = { status_code: result.status_code };

          if (result.error) {
            // If the error message is a string, simply includes it in the return values.
            message = result.error;
          } else if (result.errors) {
            // If the error messages is an array, concatenates messages as a string.
            let errors: any[] = [];
            for (let key in result.errors) {
              errors = errors.concat(result.errors[key]);
            }
            message = errors.join(' ');

            (data as any)['errors'] = errors;
          }
        }
      }
    } catch {
      // If we don't intentionally abort the request, then it must be a server error.
      if (!signal.aborted) {
        message = 'Internal server error';
      }
    }
  } else if (isAuthorized) {
    statusCode = 401;
    message = 'Invalid authentication token';
  }

  return {
    success: success,
    message: message,
    data: data,
    next: next,
    previous: previous,
    resourceCount: resourceCount,
    statusCode: statusCode
  };
};

// Authentication

/**
 * Logs into a user account.
 * @param {string} username - username
 * @param {string} password - password
 * @param {AbortSignal} signal - abort signal
 */
export const login = async (username: string, password: string, signal: AbortSignal) =>
    call(signal, false, '/api/users/login/', 'POST', {
      "username": username.trim().toLowerCase(),
      "password": password
    });

/**
 * Logs out from the current user account.
 * @param {AbortSignal} signal - abort signal
 */
export const logout = async (signal: AbortSignal) =>
    call(signal, true, '/api/users/logout/', 'POST');

/**
 * Refreshes the remember token.
 * @param {AbortSignal} signal - abort signal
 */
export const remember = async (signal: AbortSignal) =>
    call(signal, true, '/api/users/remember/', 'POST', undefined, undefined, 'remember_token');

/**
 * Refreshes the refresh token.
 * @param {AbortSignal} signal - abort signal
 */
export const refresh = async (signal: AbortSignal) =>
    call(signal, true, '/api/users/refresh/', 'POST', undefined, undefined, 'refresh_token');

/**
 * Registers a user account with the basic user information.
 * @param {string} username - username
 * @param {string} password - password
 * @param {string} firstName - user's first name
 * @param {string} lastName - user's last name
 * @param {string} email - user's email
 * @param {AbortSignal} signal - abort signal
 */
export const register = async (username: string, password: string, firstName: string, lastName: string, email: string, signal: AbortSignal) =>
    call(signal, false, '/api/users/', 'POST', {
      "username": username.trim().toLowerCase(),
      "password": password,
      "first_name": firstName.trim(),
      "last_name": lastName.trim(),
      "email": email.trim()
    }, undefined);

/**
 * Updates the password of the current user.
 * @param {string} userId - user id
 * @param {string} currentPassword - current password
 * @param {string} newPassword - new password
 * @param {AbortSignal} signal - abort signal
 */
export const updatePassword = async (userId: string, currentPassword: string, newPassword: string, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/password/`, 'PUT', {
      "old_password": currentPassword,
      "password": newPassword
    }, 'The password has been updated successfully');

/**
 * Updates the email of the current user.
 * @param {string} userId - user id
 * @param {string} password - current password
 * @param {string} newEmail - new email
 * @param {AbortSignal} signal - abort signal
 */
export const updateEmail = async (userId: string, password: string, newEmail: string, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/email/`, 'PUT', {
      "password": password,
      "email": newEmail
    }, 'A confirmation email has been sent. You must confirm your new email for changes to apply.');

/**
 * Sends a verification email for a registered user.
 * @param {string} email - user's email
 * @param {AbortSignal} signal - abort signal
 */
export const sendVerificationEmail = async (email: string, signal: AbortSignal) =>
    call(signal, false, '/api/users/resend/', 'POST', { "email": email.trim() }, 'The email has been sent successfully');

/**
 * Verifies an email for a registered user.
 * @param {string} uidb64 - user id encoded in base 64
 * @param {string} token - email verification token
 * @param {AbortSignal} signal - abort signal
 */
export const verify = async (uidb64: string, token: string, signal: AbortSignal) =>
    call(signal, false, `/api/users/verify/${uidb64}/${token}/`, 'POST', undefined, 'The email has been verified successfully');

/**
 * Confirms a new email.
 * @param {string} uidb64 - user id encoded in base 64
 * @param {string} eidb64 - email id encoded in base 64
 * @param {string} token - email verification token
 * @param {AbortSignal} signal - abort signal
 */
export const confirm = async (uidb64: string, eidb64: string, token: string, signal: AbortSignal) =>
    call(signal, false, `/api/users/confirm/${uidb64}/${eidb64}/${token}/`, 'POST', undefined, 'The email has been confirmed successfully');

/**
 * Sends a password reset email to the user who forgot the password.
 * @param {string} email - email address to send password reset email to
 * @param {AbortSignal} signal - abort signal
 */
export const sendForgotPasswordEmail = async (email: string, signal: AbortSignal) =>
    call(signal, false, '/api/users/reset/', 'POST', { "email": email.trim() }, 'The email has been sent successfully');

/**
 * Resets the password associated with the encoded id and token.
 * @param {string} uidb64 - user id encoded in base 64
 * @param {string} token - password reset token
 * @param {string} password - new password
 * @param {AbortSignal} signal - abort signal
 */
export const resetPassword = async (uidb64: string, token: string, password: string, signal: AbortSignal) =>
    call(signal, false, `/api/users/reset/${uidb64}/${token}/`, 'POST', { "password": password }, 'The password has been reset successfully');

// User management

/**
 * Gets all users.
 * @param {AbortSignal} signal - abort signal
 */
export const listUsers = async (signal: AbortSignal) =>
    call(signal, true, '/api/users/?per_page=10', 'GET');

/**
 * Gets a specific user.
 * @param {string} userId - user id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getUser = async (userId: string, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/`, 'GET');

/**
 * Gets the current user.
 * @param {AbortSignal} signal - abort signal
 */
export const getCurrentUser = async (signal: AbortSignal) =>
    call(signal, true, `/api/users/current/`, 'GET');

/**
 * Gets a user's preferences
 * @param {string} userId - user id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getUserPreferences = async (userId: string, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/preferences/`, 'GET');

/**
 * Sets a user's preferences
 * @param {string} userId - user id to be retrieved
 * @param {any} preferences - preferences object
 * @param {AbortSignal} signal - abort signal
 */
export const setUserPreferences = async (userId: string, preferences: any, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/preferences/`, 'PUT', preferences);

/**
 * Updates a specific user profile.
 * @param {string} userId - user id to be updated
 * @param {string} username - new username
 * @param {string} firstName - new first name
 * @param {string} lastName - new last name
 * @param {AbortSignal} signal - abort signal
 */
export const updateUser = async (userId: string, username: string, firstName: string, lastName: string, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/`, 'PUT', {
      "username": username.trim().toLowerCase() || undefined,
      "first_name": firstName.trim() || undefined,
      "last_name": lastName.trim() || undefined
    }, 'The user profile has been updated successfully');

/**
 * Deletes a specific user.
 * @param {string} userId - user id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const deleteUser = async (userId: string, signal: AbortSignal) =>
    call(signal, true, `/api/users/${userId}/`, 'DELETE');

// Team management

/**
 * Gets all teams associated with the authenticated user.
 * @param {AbortSignal} signal - abort signal
 * @param {string | undefined} query - filter query
 */
export const listTeams = async (signal: AbortSignal, query?: string) =>
    call(signal, true, `/api/teams/${query !== undefined ? query : "?per_page=10"}`, 'GET');

/**
 * Gets a specific team.
 * @param {string} teamId - team id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getTeam = async (teamId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/`, 'GET');

/**
 * Creates a new team.
 * @param {string} name - new team name
 * @param {string} timezone - new team timezone
 * @param {string} description - new team description
 * @param {AbortSignal} signal - abort signal
 */
export const addTeam = async (name: string, timezone: string, description: string, signal: AbortSignal) =>
    call(signal, true, '/api/teams/', 'POST', {
      "name": name.trim(),
      "timezone": timezone.trim(),
      "description": description.trim()
    });

/**
 * Updates a specific team.
 * @param {string} teamId - team id to be updated
 * @param {string} name - new team name
 * @param {string} timezone - new team timezone
 * @param {string} description - new team description
 * @param {AbortSignal} signal - abort signal
 */
export const updateTeam = async (teamId: string, name: string, timezone: string, description: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/`, 'PUT', {
      "name": name.trim(),
      "timezone": timezone.trim(),
      "description": description.trim()
    }, 'The team profile has been updated successfully');

/**
 * Deletes a specific team.
 * @param {string} teamId - team id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const deleteTeam = async (teamId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/`, 'DELETE');

// Team member management

/**
 * Gets all team members associated with teamId.
 * @param {string} teamId - id of team whose members are to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const listTeamMembers = async (teamId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/members/?per_page=10`, 'GET');

/**
 * Gets a specific team member.
 * @param {string} teamId - id of team which the member belongs to
 * @param {string} userId - id of the member to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getTeamMember = async (teamId: string, userId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/members/${userId}/`, 'GET');

/**
 * Adds a new user to the current team.
 * @param {string} teamId - team id to be modified
 * @param {string} email - user's email
 * @param {string} role - user's role in the current team
 * @param {null | string} expires - new expiration of the invitation
 * @param {AbortSignal} signal - abort signal
 */
export const addTeamMember = async (teamId: string, email: string, role: string, expires: string | null, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/members/`, 'POST', {
      email: email,
      role: role,
      expires: expires
    });

/**
 * Adds a new user in bulk to the current team.
 * @param {string} teamId - team id to be modified
 * @param {{email: string, role: string}[]} membersArray - array of invites
 * @param {AbortSignal} signal - abort signal
 */
export const addTeamMemberBulk = async (teamId: string, membersArray: { email: string, role: string }[], signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/members/bulk/`, 'POST', membersArray);

/**
 * Updates a specific team member.
 * @param {string} teamId - team id to modify
 * @param {string} userId - member id to modify
 * @param {string} role - user's new role in the current team
 * @param {AbortSignal} signal - abort signal
 */
export const updateTeamMember = async (teamId: string, userId: string, role: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/members/${userId}/`, 'PUT', { role: role }, 'The user\'s role has been updated successfully');

/**
 * Removes a specific member from the current team.
 * @param {string} teamId - team id to modify
 * @param {string} userId - member id to remove
 * @param {AbortSignal} signal - abort signal
 */
export const deleteTeamMember = async (teamId: string, userId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/members/${userId}/`, 'DELETE');

// Invitation management

/**
 * Gets all invitations associated with teamId.
 * @param {string} teamId - team id to get invitations from
 * @param {AbortSignal} signal - abort signal
 * @param {string | undefined} query - filter query
 * @param {boolean | undefined} head - whether to use HEAD instead of GET method to omit response body
 */
export const listInvites = async (teamId: string, signal: AbortSignal, query?: string, head?: boolean) =>
    call(signal, true, `/api/teams/${teamId}/invites/${query !== undefined ? query : "?per_page=10"}`, head ? 'HEAD' : 'GET');

/**
 * Gets a specific invitation.
 * @param {string} teamId - team id associated with inviteId
 * @param {string} inviteId - invitation id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getInvite = async (teamId: string, inviteId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/invites/${inviteId}/`, 'GET');

/**
 * Updates a specific invitation.
 * @param {string} teamId - team id associated with inviteId
 * @param {string} inviteId - invitation id to be updated
 * @param {string} role - user's role in the current team
 * @param {null | string} expires - new expiration of the invitation
 * @param {AbortSignal} signal - abort signal
 */
export const updateInvite = async (teamId: string, inviteId: string, role: string, expires: string | null, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/invites/${inviteId}/`, 'PUT', {
      role: role,
      expires: expires
    }, 'The invitation has been updated successfully');

/**
 * Deletes a specific invitation.
 * @param {string} teamId - team id associated with the invitation
 * @param {string} inviteId - invitation id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const deleteInvite = async (teamId: string, inviteId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/invites/${inviteId}/`, 'DELETE');

// Twitter management

/**
 * Gets all available Twitter accounts.
 * @param {AbortSignal} signal - abort signal
 */
export const listTwitterAccounts = async (signal: AbortSignal) =>
    call(signal, true, '/api/analysis-twitter-accounts/?per_page=10', 'GET');

/**
 * Gets all Twitter credentials associated with teamId.
 * @param {string} teamId - team id to get credentials from
 * @param {AbortSignal} signal - abort signal
 * @param {string | undefined} query - filter query
 * @param {boolean | undefined} head - whether to use HEAD instead of GET method to omit response body
 */
export const listTwitterCredentials = async (teamId: string, signal: AbortSignal, query?: string, head?: boolean) =>
    call(signal, true, `/api/teams/${teamId}/credentials/${query !== undefined ? query : "?per_page=10"}`, head ? 'HEAD' : 'GET');

/**
 * Gets specific Twitter credentials.
 * @param {string} teamId - team id to get credential from
 * @param {string} credentialsId - credentials id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getTwitterCredentials = async (teamId: string, credentialsId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/credentials/${credentialsId}/`, 'GET');

/**
 * Adds Twitter credentials to the team associated with teamId.
 * @param {string} teamId - team id to add credentials to
 * @param {string} name - Twitter credentials name
 * @param {boolean} premium - indicates whether the credentials are for a premium account
 * @param {string} apiKey - API key for the credentials
 * @param {string} apiSecret - API secret for the credentials
 * @param {string} accessToken - access token for the credentials
 * @param {string} accessTokenSecret - access token secret for the credentials
 * @param {string} environmentLabel - developer environment label
 * @param {AbortSignal} signal - abort signal
 */
export const addTwitterCredentials = async (teamId: string, name: string, premium: boolean, apiKey: string, apiSecret: string, accessToken: string,
                                            accessTokenSecret: string, environmentLabel: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/credentials/`, 'POST', {
      "name": name,
      "premium": premium,
      "api_key": apiKey.trim(),
      "api_secret": apiSecret.trim(),
      "access_token": accessToken.trim(),
      "access_token_secret": accessTokenSecret.trim(),
      "env_label": premium ? environmentLabel.trim() : undefined
    });

/**
 * Adds Twitter credentials in bulk   to the team associated with teamId.
 * @param {string} teamId - team id to add credentials to
 * @param {{name: string, premium: boolean, apiKey: string, apiSecret: string, accessToken: string,
  accessTokenSecret: string, environmentLabel: string}[]} credentialsArray - Array of credential JSON objects
 * @param {AbortSignal} signal - abort signal
 */
export const addTwitterCredentialsBulk = async (teamId: string, credentialsArray: {
  name: string, premium: boolean, apiKey: string, apiSecret: string, accessToken: string,
  accessTokenSecret: string, environmentLabel: string
}[], signal: AbortSignal) => {

  let formattedCredentialsArray: any = [];

  credentialsArray.forEach((entry: any) => {
    formattedCredentialsArray.push({
      "name": entry['name'],
      "premium": entry['premium'],
      "api_key": entry['apiKey'].trim(),
      "api_secret": entry['apiKeySecret'].trim(),
      "access_token": entry['accessToken'].trim(),
      "access_token_secret": entry['accessTokenSecret'].trim(),
      "env_label": entry['environmentLabel'] ? entry['environmentLabel'].trim() : undefined
    })
  });

  return call(signal, true, `/api/teams/${teamId}/credentials/bulk/`, 'POST', formattedCredentialsArray)
};

/**
 * Updates a specific Twitter credentials associated with teamId.
 * @param {string} teamId - team id to add credentials to
 * @param {string} credentialsId - credentials id to be updated
 * @param {string} name - new name of credentials
 * @param {boolean} premium - indicates whether the credentials are for a premium account
 * @param {string} apiKey - new API key for the credentials
 * @param {string} apiSecret - new API secret for the credentials
 * @param {string} accessToken - new access token for the credentials
 * @param {string} accessTokenSecret - new access token secret for the credentials
 * @param {string} environmentLabel - new developer environment label
 * @param {AbortSignal} signal - abort signal
 */
export const updateTwitterCredentials = async (teamId: string, credentialsId: string, name: string, premium: boolean, apiKey: string, apiSecret: string,
                                               accessToken: string, accessTokenSecret: string, environmentLabel: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/credentials/${credentialsId}/`, 'PUT', {
      "name": name,
      "premium": premium,
      "api_key": apiKey.trim(),
      "api_secret": apiSecret.trim(),
      "access_token": accessToken.trim(),
      "access_token_secret": accessTokenSecret.trim(),
      "env_label": premium ? environmentLabel.trim() : undefined
    }, 'The Twitter credentials have been updated successfully');

/**
 * Deletes Twitter credentials associated with teamId.
 * @param {string} teamId - team id to delete credential from
 * @param {string} credentialsId - credentials id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const deleteTwitterCredentials = async (teamId: string, credentialsId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/credentials/${credentialsId}/`, 'DELETE');

// Lexicon management

/**
 * Gets all lexicon templates.
 * @param {AbortSignal} signal - abort signal
 */
export const listTemplates = async (signal: AbortSignal) =>
    call(signal, true, '/api/lexicon-templates/?per_page=10', 'GET');

/**
 * Gets a specific lexicon template.
 * @param {string} templateId - lexicon template id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getTemplate = async (templateId: string, signal: AbortSignal) =>
    call(signal, true, `/api/lexicon-templates/${templateId}/`, 'GET');

/**
 * Gets all lexicons associated with teamId.
 * @param {string} teamId - team id associated with the lexicons to be retrieved
 * @param {AbortSignal} signal - abort signal
 * @param {boolean | undefined} head - whether to use HEAD instead of GET method to omit response body
 */
export const listLexicons = async (teamId: string, signal: AbortSignal, head?: boolean) =>
    call(signal, true, `/api/teams/${teamId}/lexicons/?per_page=10`, head ? 'HEAD' : 'GET');

/**
 * Gets a specific lexicon associated with teamId.
 * @param {string} teamId - team id associated with lexicon to be retrieved
 * @param {string} lexiconId - lexicon id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getLexicon = async (teamId: string, lexiconId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/lexicons/${lexiconId}/`, 'GET');

/**
 * Creates a new lexicon associated with teamId.
 * @param {string} teamId - team id associated with new lexicon
 * @param {string} name - lexicon name
 * @param {string} description - lexicon description
 * @param {boolean} isPrivate - indicates whether the lexicon is private
 * @param {any} entries - dictionary of lexicon entries
 * @param {AbortSignal} signal - abort signal
 */
export const addLexicon = async (teamId: string, name: string, description: string, isPrivate: boolean, entries: any, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/lexicons/`, 'POST', {
      "name": name.trim(),
      "description": description.trim(),
      "public": !isPrivate,
      "entries": entries
    });

/**
 * Updates a specific lexicon associated with teamId.
 * @param {string} teamId - team id associated with lexicon
 * @param {string} lexiconId - lexicon id to be updated
 * @param {string} name - new name of lexicon
 * @param {string} description - new description of lexicon
 * @param {boolean} isPrivate - indicates whether the lexicon is private
 * @param {any} entries - new dictionary of lexicon entries
 * @param {AbortSignal} signal - abort signal
 */
export const updateLexicon = async (teamId: string, lexiconId: string, name: string, description: string, isPrivate: boolean, entries: any, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/lexicons/${lexiconId}/`, 'PUT', {
      "name": name.trim(),
      "description": description.trim(),
      "public": !isPrivate,
      "entries": entries
    }, 'The lexicon has been updated successfully');

/**
 * Deletes a lexicon associated with teamId.
 * @param {string} teamId - team id associated with lexicon
 * @param {string} lexiconId - lexicon id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const deleteLexicon = async (teamId: string, lexiconId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/lexicons/${lexiconId}/`, 'DELETE');

// Analysis management

/**
 * Gets all analyses associated with teamId.
 * @param {string} teamId - team id to get analyses from
 * @param {AbortSignal} signal - abort signal
 * @param {string | undefined} query - filter query
 * @param {boolean | undefined} head - whether to use HEAD instead of GET method to omit response body
 */
export const listAnalyses = async (teamId: string, signal: AbortSignal, query?: string, head?: boolean) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${query !== undefined ? query : "?per_page=10"}`, head ? 'HEAD' : 'GET');

/**
 * Gets a specific analysis associated with teamId.
 * @param {string} teamId - team id to get analyses from
 * @param {string} analysisId - analysis id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getAnalysis = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/`, 'GET');

/**
 * Gets detailed data of a specific analysis associated with teamId.
 * @param {string} teamId - team id to get analyses from
 * @param {string} analysisId - analysis id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getAnalysisDetail = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/configuration/`, 'GET');

/**
 * Creates a new analysis associated with teamId.
 * @param {string} teamId - team id which analysis belongs to
 * @param {string} name - analysis name
 * @param {string} description - analysis description
 * @param {boolean} isPrivate - indicates whether the analysis is private
 * @param {AbortSignal} signal - abort signal
 */
export const addAnalysis = async (teamId: string, name: string, description: string, isPrivate: boolean, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/`, 'POST', {
      "name": name.trim(),
      "description": description.trim(),
      "public": !isPrivate,
      "_mock": true
    });

/**
 * Updates a specific analysis associated with teamId.
 * @param {string} teamId - team id which analysis belongs to
 * @param {string} analysisId - analysis id to be updated
 * @param {string} name - new analysis name
 * @param {string} description - new analysis description
 * @param {boolean} isPrivate - indicates whether the analysis is private
 * @param {AbortSignal} signal - abort signal
 */
export const updateAnalysis = async (teamId: string, analysisId: string, name: string, description: string, isPrivate: boolean, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/`, 'PUT', {
      "name": name.trim(),
      "description": description.trim(),
      "public": !isPrivate
    }, 'The analysis has been updated successfully');

/**
 * Deletes a specific analysis associated with teamId.
 * @param {string} teamId - team id which analysis belongs to
 * @param {string} analysisId - analysis id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const deleteAnalysis = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/`, 'DELETE');

/**
 * Cancels a specific analysis associated with teamId.
 * @param {string} teamId - team id which analysis belongs to
 * @param {string} analysisId - analysis id to be deleted
 * @param {AbortSignal} signal - abort signal
 */
export const cancelAnalysis = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/cancel/`, 'POST');

/**
 * Creates the initial configuration for a new analysis.
 * @param {string} teamId - team id which analysis belongs to
 * @param {string} analysisId - analysis id to be deleted
 * @param {string} twitterAccount - Twitter account name
 * @param {string[]} hashtags - array containing hashtags
 * @param {string[]} twitterCredentials - array containing Twitter credentials ids
 * @param {string | string[]} times - id of time of day option or time range if free input
 * @param {string | string[]} days - id of day of week option or weekdays if free input
 * @param {string} startDate - start date
 * @param {string} endDate - end date
 * @param {boolean} specialDay - indicates whether special day insights is checked
 * @param {any} lexicon - lexicon entries
 * @param {boolean} insights - indicates whether semantic-sentiment insights is checked
 * @param {string} network - network id
 * @param {number} threshold - threshold of influential accounts
 * @param {string} community - communities id
 * @param {boolean} usePremiumSearch - whether to use premium search
 * @param {boolean} use30DaySearch - whether to use 30 day search
 * @param {boolean} networkEnabled - whether network analytics are enabled
 * @param {boolean} mock - whether to create mock analysis (For development purposes)
 * @param {AbortSignal} signal - abort signal
 */
export const createAnalysisConfiguration = (teamId: string, analysisId: string, twitterAccount: string, hashtags: string[], credentials: string[],
                                            times: string | string[], days: string | string[], startDate: string, endDate: string, specialDay: boolean, lexicon: any, insights: boolean,
                                            network: string, threshold: number, community: string, usePremiumSearch: boolean, use30DaySearch: boolean, networkEnabled: boolean, mock: boolean, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/configuration/`, 'POST', {
      "twitter_handle": twitterAccount,
      "hashtags": hashtags,
      "credentials": credentials,
      "times": times,
      "days": days,
      "start_date": startDate,
      "end_date": endDate,
      "special_day": specialDay,
      "lexicon": lexicon,
      "semantic_insights": insights,
      "network": network,
      "network_threshold": threshold,
      "community": community,
      "use_premium_search": usePremiumSearch,
      "use_30day_search": use30DaySearch,
      "_mock": mock,
      "network_enabled": networkEnabled
    });

/**
 * Gets all followers from an analysis associated with teamId.
 * @param {string} teamId - team id associated with analysis
 * @param {string} analysisId - analysis id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const listFollowers = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/collected/`, 'GET');

/**
 * Gets all terms from an analysis associated with teamId.
 * @param {string} teamId - team id associated with analysis
 * @param {string} analysisId - analysis id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const listTerms = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/collected/`, 'GET');

/**
 * Gets all tweets in an analysis associated with teamId.
 * @param {string} teamId - team id associated with analysis
 * @param {string} analysisId - analysis id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getTweetCount = async (teamId: string, analysisId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/collected/`, 'GET');

/**
 * Creates the final configuration for a new analysis.
 * @param {string} teamId - team id associated with analysis
 * @param {string} analysisId - analysis id to be retrieved
 * @param {any[]} accounts - array containing metadata of follower's accounts
 * @param {any[]} terms - array containing metadata of terms
 * @param {boolean} expandedCollection - indicates whether the expand method is selected
 * @param {AbortSignal} signal - abort signal
 */
export const updateAnalysisConfiguration = async (teamId: string, analysisId: string, accounts: any[], terms: any[], expandedCollection: boolean, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/analyses/${analysisId}/configuration/`, 'PUT', {
      "accounts": accounts,
      "terms": terms,
      "expanded_collection": expandedCollection
    });

/**
 * Gets all reports associated with teamId.
 * @param {string} teamId - team id to list reports
 * @param {AbortSignal} signal - abort signal
 */
export const listReports = async (teamId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/reports/?per_page=10`, 'GET');

/**
 * Gets a specific report associated with teamId.
 * @param {string} teamId - team id associated with report
 * @param {string} reportId - report id to be retrieved
 * @param {AbortSignal} signal - abort signal
 */
export const getReport = async (teamId: string, reportId: string, signal: AbortSignal) =>
    call(signal, true, `/api/teams/${teamId}/reports/${reportId}/`, 'GET');

/**
 * Gets the next page URL.
 * @param {string} url - cursor based next page URL
 * @param {AbortSignal} signal - abort signal
 */
export const getNextPage = async (url: string, signal: AbortSignal) =>
    call(signal, true, url, 'GET');

// Contact us

/**
 * Sends a message from the contact us form.
 * @param {string} name - user's name
 * @param {string} phone - user's phone number (optional)
 * @param {string} email - user's email
 * @param {string} message - message
 * @param {AbortSignal} signal - abort signal
 */
export const sendMessage = async (name: string, phone: string, email: string, message: string, signal: AbortSignal) =>
    call(signal, false, '/api/contacts/', 'POST', {
      name: name.trim(),
      phone: phone?.trim() || undefined,
      email: email.trim(),
      message: message.trim()
    }, 'The message has been submitted successfully');
