/**
 * @file Table for bunk action
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import grey from '@material-ui/core/colors/grey';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import MaterialTable, { MTableAction, MTableBodyRow, MTableCell } from 'material-table';
import React, { useContext } from 'react';
import { mobileBreakpointCompactTable, ViewportContext } from "../../../hooks/viewport";
import LoadingButton from "../loading/loading-button";

const useStyles = makeStyles(theme => ({
  tableRowError: {
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'rgb(97, 26, 21)',
    transition: 'all 300ms ease 0s'
  },
  tableRowErrorEditing: {
    backgroundColor: 'rgb(253, 236, 234)',
    color: 'rgb(97, 26, 21)',
    opacity: '0.2',
    transition: 'all 300ms ease 0s'
  },
  cellError: {
    borderBottom: 'none'
  },
  cell: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  errorMessage: {
    padding: '16px',
    borderBottom: '1px solid rgba(224, 194, 194, 1)'
  },
  errorMessageEditing: {
    padding: '16px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  errorIcon: {
    float: 'right'
  }
}));

/**
 * Loads lexicon table for lexicon detail page.
 * @param {any[]} data - array of table entries
 * @param {any[]} errors - array of validation errors
 * @param {any[]} columns - array of table column metadata
 * @param {any} editable - override for table actions
 * @param {string} tableTitle
 */
export default ({ data, errors, columns, editable, tableTitle }: { data: any[], errors: any, columns: any[], editable: any, tableTitle: string }) => {

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  const classes = useStyles();

  // Custom table components
  const components = {
    Action: (props: any) => props.action.tooltip === "Add" && viewport.state.width >= mobileBreakpointCompactTable
        ? <LoadingButton variant="contained" color="secondary" disableAutoLoad={true}
                         onClick={(event: any) => props.action.onClick(event, props.data)}>
          Add entry
        </LoadingButton>
        : <MTableAction {...props} />,
    Container: (props: any) => <Paper {...props} variant="outlined"/>,
    Pagination: (props: any) => <TablePagination {...props} />,
    Cell: (props: any) => {
      const isError = errors[props.rowData.tableData.id] && Object.keys(errors[props.rowData.tableData.id]).length !== 0;

      // Inject checkbox instead of default text for boolean values
      if (typeof props.value === "boolean") {
        return <td className={isError ? classes.cellError : classes.cell}>
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              {
                props.value ? <CheckBoxIcon color="primary"/> :
                    <CheckBoxOutlineBlankIcon color="primary"/>
              }
            </Grid>
          </Grid>
        </td>;
      } else {
        return <MTableCell {...props} className={isError ? classes.cellError : ''}/>;
      }
    },
    Row: (props: any) => {
      const isError = errors[props.index] && Object.keys(errors[props.index]).length !== 0;
      const editing = props.hasAnyEditingRow;

      // Inject error message
      return (
          <>
            <MTableBodyRow {...props} className={isError ? classes.tableRowError : ''}/>
            {
              isError &&
              <tr className={editing ? classes.tableRowErrorEditing : classes.tableRowError}>
                <td className={editing ? classes.errorMessageEditing : classes.errorMessage}>
                  <ErrorIcon className={classes.errorIcon}/>
                </td>
                <td className={editing ? classes.errorMessageEditing : classes.errorMessage}>
                  {
                    Object.keys(errors[props.index]).map((key: string) => {
                      return errors[props.index][key]?.map((error: string, key: number) => (
                          <Typography key={key}>
                            {
                              error
                            }
                          </Typography>));
                    })
                  }
                </td>
                {
                  [...Array(Math.max(columns.length - 1, 0))].map((_: string, key: number) => (
                          <td className={editing ? classes.errorMessageEditing : classes.errorMessage}
                              key={key}/>
                      )
                  )
                }
              </tr>
            }
          </>
      )
    }
  };

  // Dictionary of icons
  const icons = {
    Add: React.forwardRef((props: any, ref: any) => <AddCircleIcon {...props} color="secondary"
                                                                   ref={ref}/>),
    Check: CheckIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Clear: ClearIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Delete: DeleteOutlineIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    Edit: EditIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    FirstPage: FirstPageIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    LastPage: LastPageIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    NextPage: ChevronRightIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    PreviousPage: ChevronLeftIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
    SortArrow: ArrowDownwardIcon as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>
  };

  return (
      <MaterialTable title={tableTitle} components={components} columns={columns} data={data}
                     icons={icons}
                     options={{
                       search: false,
                       paging: false,
                       sorting: false,
                       draggable: false,
                       headerStyle: { backgroundColor: grey[100] }
                     }}
                     editable={editable} style={{ width: '100%' }}/>
  );
};
