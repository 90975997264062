/**
 * @file Context for user preferences
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import merge from 'lodash.merge';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { getUserPreferences, setUserPreferences } from "../utils/api";
import { AuthContext } from "./auth";
import { MountContext } from "./mount";

/**
 * Creates PreferenceContext
 * PreferenceContext stores user preferences in local storage and syncs them to the server for future sessions
 * if a setting is marked as persistent.
 *
 * State:
 *   preferences: preferences to be synced with the server across sessions
 */
export const PreferenceContext = createContext({} as {
  state: { latestLocalUpdate: any, preferences: any, synced: boolean },
  dispatch: React.Dispatch<{ preferences?: any, operationType?: 'update' | 'clear' | 'sync' }>
});

const defaultState: any = {
  preferences: {} as any,
  latestLocalUpdate: {} as any,
  synced: false
};

/**
 * PreferenceContext reducer
 *
 * Actions:
 *    operationType
 *        update: Updates local and remote preferences (default operation)
 *        clear: Clears local preferences
 *        sync: Same behaviour as update, but doesn't push change to backend as soon as we update the local preferences
 *
 *    preferences - Merges with existing preferences
 *
 * @param { preferences: any, synced: boolean } existingState
 * @param { preferences?: any, operationType?: 'update' | 'clear' | 'sync' } action - action parameters
 */
const reducer = (existingState: { latestLocalUpdate: any, preferences: any, synced: boolean }, action: { preferences?: any, operationType?: 'update' | 'clear' | 'sync' }) => {
  const mergedPreferences = merge(existingState.preferences, action.preferences || {});

  switch (action.operationType) {
    case "clear":
      return defaultState;
    case "sync":
      return {
        latestLocalUpdate: {}, // Empty last since there isn't any data to update the server with
        preferences: mergedPreferences,
        synced: true
      };
    default:
      return {
        latestLocalUpdate: action.preferences, // We separately store the data from the latest update so that we only send the server the difference
        preferences: mergedPreferences,
        synced: existingState.synced
      };
  }
};

/**
 * PreferenceContext provider
 * @param {any} children - child components
 */
export const PreferenceProvider = ({ children }: { children: any }) => {

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Gets the current state and dispatcher of PreferenceContext.
   * Initial state:
   *   preferences: {}
   */
  const [state, dispatch] = useReducer(reducer, defaultState);

  /**
   * Fetch remote preferences
   */
  useEffect(() => {
    (async () => {
      // Clears preferences as soon as a user logs out
      if (auth.state.isAuthenticated) {
        const userPreferences = await getUserPreferences(auth.state.userId, mount.state.signal);

        if (userPreferences.success) {
          dispatch({
            preferences: userPreferences.data,
            operationType: "sync"
          })
        }
      } else {
        dispatch({
          operationType: "clear"
        })
      }
    })();
  }, [auth.state.isAuthenticated, auth.state.userId, mount]);

  /**
   * Update remote preferences
   */
  useEffect(() => {
    (async () => {
      if (auth.state.isAuthenticated && Object.keys(state.latestLocalUpdate).length > 0) {
        await setUserPreferences(auth.state.userId, state.latestLocalUpdate, mount.state.signal);
      }
    })();
  }, [state.latestLocalUpdate, auth.state.userId, auth.state.isAuthenticated, mount]);

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the PreferenceContext consumers.
  return (
      <PreferenceContext.Provider value={{ state, dispatch }}>
        {children}
      </PreferenceContext.Provider>
  );
};
