export default () => ({
  "ui": {
    "label": "User Interface",
    "preferences": {
      "disclaimer_bar_hidden": {
        "label": "Permanently hide disclaimer bar",
        "type": "checkbox"
      },
      "report_disclaimers_hidden": {
        "label": "Permanently hide inline disclaimers in reports",
        "type": "checkbox"
      }
    }
  }
});
