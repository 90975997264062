/**
 * @file Failure page for user new email confirmation page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Link from "@material-ui/core/Link";
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import pages from "../../../config/pages.json";
import Layout from '../../common/layout';

/**
 * Loads failure page for user new email confirmation page if the provided token is invalid.
 */
export default () => (
    <Layout pageId="confirm" title="Email confirmation failed">
      <Typography variant="body1">
        The confirmation link is invalid or expired. Please try to update your email again.
        Click <Link component={RouterLink} to={pages['login'].url} variant="body1">here</Link> to
        continue.
      </Typography>
    </Layout>
);
