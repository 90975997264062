/**
 * @file Social form for new analysis social page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Alert from "@material-ui/lab/Alert/Alert";
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { BlockerContext } from '../../../hooks/blocker';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, setLocalAnalysis } from '../../../utils/analysis';
import { makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";
import { CheckboxValidator } from '../../common/validator';

const useStyles = makeStyles(_ => ({
  warning: {
    lineHeight: 1.75,
    fontWeight: 600
  }
}));

/**
 * Loads social form for new analysis social page.
 *
 * @param {(step: number) => void} updateMaxStep
 */
export default ({ updateMaxStep }: { updateMaxStep: (step: number) => void }) => {

  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {string} networkId - network id
   */
  const [networkId, setNetworkId] = useState('');

  /**
   * @type {{id: string, name: string}][]} networkList - array containing metadata of networks
   */
  const networkList = [
    { id: 'entire-network', name: 'Entire network' },
    { id: 'influential-accounts', name: 'Influential accounts only' }
  ];

  /**
   * @type {number | string} threshold - threshold of influential accounts, initially 100
   */
  const [threshold, setThreshold] = useState(100 as number | string);

  /**
   * @type {string} communitiesId - communities id
   */
  const [communitiesId, setCommunitiesId] = useState('');

  /**
   * @type {{id: string, name: string}][]} communitiesList - array containing metadata of communities
   */
  const communitiesList = [
    { id: 'none', name: 'None' },
    {
      id: 'modularity-maximization',
      name: 'Modularity maximization',
      tooltip: messages.tooltips.modularityMaximization
    },
    { id: 'information-flow', name: 'Information flow', tooltip: messages.tooltips.informationFlow }
  ];

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * @type {boolean} networkAnalytics - indicates whether network analytics are enabled
   */
  const [networkAnalytics, setNetworkAnalytics] = useState(false);

  /**
   * Updates the network from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateNetworkId = (e: React.ChangeEvent<HTMLSelectElement>) => setNetworkId(e.target.value);

  /**
   * Updates the threshold from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateThreshold = (e: React.ChangeEvent<HTMLInputElement>) => setThreshold(parseInt(e.target.value) || '');

  /**
   * Updates the communities from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateCommunitiesId = (e: React.ChangeEvent<HTMLSelectElement>) => setCommunitiesId(e.target.value);

  /**
   * Updates networkAnalytics from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateNetworkAnalytics = (e: React.ChangeEvent<HTMLInputElement>) => setNetworkAnalytics(e.target.checked);

  // Generates a URL to the new analysis semantic page for the back button.
  const moveBackUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 3 });

  // Generates a URL to the new analysis review page for the next button.
  const moveNextUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 5 });

  // Generates a URL to team dashboard page for cancel button
  const cancelUrl = makeUrl(pages['team'].url, params);

  /**
   * Sets up the new analysis social form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    if (analysis.networkId) {
      setNetworkId(analysis.networkId);
    }
    if (analysis.threshold) {
      setThreshold(analysis.threshold);
    }
    if (analysis.communitiesId) {
      setCommunitiesId(analysis.communitiesId);
    }

    if (analysis.networkEnabled) {
      setNetworkAnalytics(true);
    }

    setIsLoaded(true);
  }, [params.analysisId, history, mount.state.signal]);

  /**
   * Saves the user input for the form to the browser local storage.
   */
  const saveForm = () => {
    const analysis = getLocalAnalysis(params.analysisId);
    analysis.networkEnabled = networkAnalytics;

    if (networkAnalytics) {
      analysis.networkId = networkId;
      analysis.networkName = networkList.find(network => network.id === networkId)?.name;
      analysis.threshold = threshold;
      analysis.communitiesId = communitiesId;
      analysis.communitiesName = communitiesList.find(communities => communities.id === communitiesId)?.name;
    } else {
      delete analysis.networkId;
      delete analysis.networkName;
      delete analysis.threshold;
      delete analysis.communitiesId;
      delete analysis.communitiesName;
    }

    analysis.maxStep = 5;
    updateMaxStep(5);

    setLocalAnalysis(params.analysisId, analysis);
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    setIsSubmitting(true);
    saveForm();
    blocker.dispatch({ url: url });
  };

  const classes = useStyles();
  return isLoaded ? (
      <Container maxWidth="xs">
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={redirect(moveNextUrl)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box p={1} color="secondary.main" border={1} borderColor="secondary.main"
                   borderRadius="borderRadius">
                <Box display="flex" justifyItems="center">
                  <Typography component="span">
                    <WarningRoundedIcon/>
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.warning}>
                    Warning
                  </Typography>
                </Box>
                <Typography variant="body2" gutterBottom>
                  Enabling network analytics will result in long processing times.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CheckboxValidator name="network-analytics" value={networkAnalytics}
                                 onChange={updateNetworkAnalytics}
                                 label={
                                   <InlineTooltip text={messages.tooltips.networkAnalytics}>
                                     Network Analytics
                                   </InlineTooltip>
                                 }/>
            </Grid>
            {
              networkAnalytics && (
                  <>
                    <Grid item xs={12}>
                      <Alert severity="info">
                        {messages.pages.newAnalysisSocial.instructions}
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectValidator variant="outlined" fullWidth label="Accounts"
                                       inputProps={{ 'aria-label': 'Accounts' }}
                                       name="accounts" value={networkId} onChange={updateNetworkId}
                                       validators={['required']}
                                       errorMessages={['Network is required']}>
                        {networkList.map(network => <MenuItem value={network.id}
                                                              key={`network-${network.id}`}>{network.name}</MenuItem>)}
                      </SelectValidator>
                    </Grid>
                    {networkId === 'influential-accounts' && (
                        <>
                          <Grid item xs={12}>
                            <TextValidator variant="outlined" fullWidth
                                           label="Threshold (Minimum 100)"
                                           name="threshold" value={threshold}
                                           onChange={updateThreshold}
                                           validators={['required', 'minNumber: 100']}
                                           errorMessages={['Threshold is required', 'Threshold must be at least 100']}/>
                          </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                      <SelectValidator variant="outlined" fullWidth label="Communities"
                                       inputProps={{ 'aria-label': 'Communities' }}
                                       name="communities" value={communitiesId}
                                       onChange={updateCommunitiesId}
                                       validators={['required', 'matchRegexp:^none$']}
                                       errorMessages={['Communities is required', 'This feature is currently under testing, and will open to the public soon.']}>
                        {communitiesList.map(communities => <MenuItem value={communities.id}
                                                                      key={`communities-${communities.id}`}>
                          {
                            communities.tooltip
                                ? <InlineTooltip text={communities.tooltip}>
                                  {communities.name}
                                </InlineTooltip>
                                : communities.name
                          }
                        </MenuItem>)}
                      </SelectValidator>
                    </Grid>
                    <Grid item xs={12}/>
                  </>
              )
            }
            <Grid item xs={4}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Next</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
