/**
 * @file Analysis overview page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import grey from '@material-ui/core/colors/grey';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Step from "@material-ui/core/Step/Step";
import StepContent from "@material-ui/core/StepContent/StepContent";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";
import Stepper from "@material-ui/core/Stepper/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from '../../../hooks/team';
import { getAnalysis } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import { generateLabel, processState } from '../progress/progressUtils';
import AnalysisPanel from './analysis-panel';
import CancelPanel from './cancel-panel';
import ReportPanel from './report-panel';
import SetupPanel from './setup-panel';

const useStyles = makeStyles(theme => ({
  alert: {
    overflow: 'auto'
  },
  fullWidth: {
    width: '100%'
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  description: {
    color: grey[700],
    overflow: 'auto',
    maxWidth: '100%'
  },
  stepper: {
    padding: 0
  }
}));

export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * @type {string} analysisName - analysis name
   */
  const [analysisName, setAnalysisName] = useState('');

  /**
   * @type {string} description - analysis description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {boolean} isPrivate - indicates whether the analysis is private
   */
  const [isPrivate, setIsPrivate] = useState(false);

  /**
   * @type {string} authorId - user id of the analysis author
   */
  const [authorId, setAuthorId] = useState('0');

  /**
   * @type {number} activeStep
   */
  const [activeStep, setActiveStep] = useState(0);

  /**
   * @type {string} author - user's name of the analysis author
   */
  const [author, setAuthor] = useState('');

  /**
   * @type {string} status - analysis status
   */
  const [status, setStatus] = useState('');

  /**
   * @type {string} warning
   */
  const [warning, setWarning] = useState('');

  /**
   * @type {string[]} errors
   */
  const [errors, setErrors] = useState([] as string[]);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} hideContent - indicates whether to hide the stepper content
   */
  const [hideContent, setHideContent] = useState(false);

  /**
   * @type {string} lastModified - last modified date of the analysis
   */
  const [lastModified, setLastModified] = useState('');

  /**
   * @type {boolean} cancelAnalysisDialogOpen - indicates whether the cancel analysis dialog is open
   */
  const [cancelAnalysisDialogOpen, setCancelAnalysisDialogOpen] = useState(false);

  const teamDashboardUrl = makeUrl(pages['team'].url, params);

  // Generates a URL to the analysis profile page.
  const profileUrl = makeUrl(pages['analysis-profile'].url, params);

  // Warning message according to status
  const warningLookup = {
    'Cancelled': 'The analysis has been cancelled.',
    'Cancelling': 'The analysis is being cancelled.',
    'Not started': 'Setup the analysis to continue.',
    'In progress - configuration required': 'Configure the analysis to continue.'
  };

  /**
   * Sets up the analysis profile form.
   */
  useEffect(() => {
    (async () => {
      const result = await getAnalysis(params.teamId, params.analysisId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      processState(result.data, setActiveStep, setWarning, setErrors, setHideContent);

      setAnalysisName(result.data.name);
      setDescription(result.data.description);
      setIsPrivate(!result.data.public);
      setAuthorId(result.data.author?.id_str || '0');
      setLastModified(moment(result.data.updated).format('YYYY-MM-DD, h:mm:ss A'));
      setAuthor(result.data.author ? `${result.data.author.first_name} ${result.data.author.last_name}` : '');
      setStatus(result.data.status);
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  /**
   * Opens the cancel dialog
   */
  const cancelManagedAnalysis = () => setCancelAnalysisDialogOpen(true);

  /**
   * Some steps in the sequence have substeps (e.g. SetupPanel)
   * which means their content changes while keeping the same step number.
   *
   * In this case, the initial setup is grouped with Tweet collection, and
   * Analysis configuration is grouped with Analysing.
   */
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <SetupPanel status={status} cancelManagedAnalysis={cancelManagedAnalysis}
                           isUpdatable={isUpdatable}/>;
      case 1:
        return <AnalysisPanel status={status} cancelManagedAnalysis={cancelManagedAnalysis}
                              isUpdatable={isUpdatable}/>;
      case 2:
        return <ReportPanel/>;
      default:
        return 'Uh oh, something went wrong!';
    }
  };

  const steps = ['Collect Tweets', 'Analyse Tweets', 'View Report'];

  const isUpdatable = (team.state.role === 'Manager' || authorId === auth.state.userId || (!isPrivate && team.state.role === 'Analyst')) && team.state.role !== 'Viewer';

  const classes = useStyles();
  return isLoaded ? (
      <>
        <Container maxWidth="xs">
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Typography component="h1" variant="h4">
                {analysisName}
              </Typography>
              {
                description && (
                    <Typography className={classes.description}>
                      {description}
                    </Typography>
                )
              }
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={props => <Paper variant="outlined" {...props} />}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Author</TableCell>
                      <TableCell className={classes.borderLeft}>{author}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Last updated</TableCell>
                      <TableCell className={classes.borderLeft}>{lastModified}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {
              errors.length !== 0 && (<Grid item xs={12} className={classes.fullWidth}>
                <Alert severity="error" className={classes.alert}>
                  <b>Error - Analysis failed</b>
                  <ul>
                    {
                      errors.map((error: string, key: number) => (<li key={key}>
                        {
                          error
                        }
                      </li>))
                    }
                  </ul>
                </Alert>
              </Grid>)
            }
            {
              warning && (<Grid item xs={12} className={classes.fullWidth}>
                <Alert severity="warning" className={classes.alert}>
                  <b>
                    {
                      warning
                    }
                  </b>
                  {
                    (warningLookup as any)[warning] && (
                        <>
                          <br/>
                          {
                            (warningLookup as any)[warning]
                          }
                        </>
                    )
                  }
                </Alert>
              </Grid>)
            }
            <Grid item xs={12} className={classes.fullWidth}>
              <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                {steps.map((label, index) => {

                  const labelProps = generateLabel(errors, warning, activeStep, index, status, label);

                  return (<Step key={label}>
                    <StepLabel {...labelProps} />
                    <StepContent>
                      {!hideContent && getStepContent(index)}
                    </StepContent>
                  </Step>)
                })}
              </Stepper>
            </Grid>
            <Grid item xs={12}/>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <LoadingButton variant="outlined" color="primary" href={profileUrl}>More
                  options</LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item>
                    <LoadingButton variant="contained" color="primary"
                                   href={teamDashboardUrl}>Back</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <CancelPanel dialogOpen={cancelAnalysisDialogOpen}
                     setDialogOpen={setCancelAnalysisDialogOpen}/>
      </>
  ) : <LoadingContent/>;
};
