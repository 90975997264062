/**
 * @file Layout for password reset page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ForgotPasswordPage from './forgot-password-page';
import ResetPasswordPage from './reset-password-page';
import ResultPage from './result-page';

/**
 * Loads forgot password page if no reset token is provided.
 * Loads reset password page otherwise.
 * Loads results page if password reset was successful.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   uidb64: the user id encoded in base 64
   *   token: token to check that the password is valid
   */
  const params: any = useParams();

  /**
   * @type {boolean} isVerified - indicates whether password is successfully reset
   */
  const [isVerified, setIsVerified] = useState(false);

  return !(params.uidb64 && params.token) ? <ForgotPasswordPage/> : !isVerified ?
      <ResetPasswordPage setIsVerified={setIsVerified}/> : <ResultPage/>;
};
