/**
 * @file Semantic form for new analysis semantic page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { BlockerContext } from '../../../hooks/blocker';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, setLocalAnalysis } from '../../../utils/analysis';
import { getLexicon, getNextPage, listLexicons } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";
import SemanticTable from './semantic-table';

/**
 * Loads semantic form for new analysis semantic page.a
 *
 * @param {(step: number) => void} updateMaxStep
 */
export default ({ updateMaxStep }: { updateMaxStep: (step: number) => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {string} lexiconId - lexicon id
   */
  const [lexiconId, setLexiconId] = useState('');

  /**
   * @type {any[]} lexiconList - array containing metadata of lexicons
   */
  const [lexiconList, setLexiconList] = useState([] as any[]);

  /**
   * @type {{ [name: string]: string[] }} lexiconEntries - lexicon entries
   */
  const [lexiconEntries, setLexiconEntries] = useState({} as { [name: string]: string[] });

  /**
   * @type {any} selections - array containing selections of lexicon entries
   */
  const [selections, setSelections] = useState({} as any);

  /**
   * @type {boolean} semanticInsights - indicates whether the semantic insights is checked
   */
  const [semanticInsights, setSemanticInsights] = useState(true);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the lexicon from dropdown menu on change and loads lexicon table.
   * Redirects to add a lexicon if lexicon id is 0.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateLexiconId = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lexiconId = e.target.value;
    setLexiconId(lexiconId);
    if (lexiconId !== '0') {
      await loadLexiconTable(lexiconId);
    } else {
      redirect(newLexiconUrl)();
    }
  };

  /**
   * Updates the semantic insights from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateSemanticInsights = (e: React.ChangeEvent<HTMLInputElement>) => setSemanticInsights(e.target.checked);

  // Generates a URL to the new analysis temporal page for the back button.
  const moveBackUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 2 });

  // Generates a URL to the new analysis social page for the next button.
  const moveNextUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 4 });

  // Generates a URL to team dashboard page for cancel button
  const cancelUrl = makeUrl(pages['team'].url, params);

  // Generates a URL to the new lexicon page for the add button.
  const newLexiconUrl = makeUrl(pages['new-analysis-lexicon'].url, params);

  /**
   * Sets initial selections to false.
   * @param {any} entries - dictionary of lexicon entries
   */
  const initializeSelections = (entries: any) => {
    const initialSelections: any = {};
    Object.keys(entries).forEach(name => initialSelections[name] = true);
    setSelections(initialSelections);
  };

  /**
   * Loads the lexicon table with the selected lexicon id.
   * @param {string} lexiconId - lexicon id
   */
  const loadLexiconTable = async (lexiconId: string) => {
    const result = await getLexicon(params.teamId, lexiconId, mount.state.signal);
    if (result.success) {
      setLexiconEntries(result.data.entries);
      initializeSelections(result.data.entries);
    }
  };

  /**
   * Callback of the load lexicon table function
   */
  const loadLexiconTableCallback = useCallback(loadLexiconTable, []);

  /**
   * Sets up the new analysis semantic form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    if (analysis.insights) {
      setSemanticInsights(analysis.insights);
    }

    (async () => {
      let result = await listLexicons(params.teamId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      let data = result.data;
      while (result.next) {
        result = await getNextPage(result.next, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }
        data = data.concat(result.data);
      }
      setLexiconList(data);
      if (analysis.lexiconId) {
        setLexiconId(analysis.lexiconId);
        await loadLexiconTableCallback(analysis.lexiconId);
        if (analysis.lexiconSelections) {
          setSelections(analysis.lexiconSelections);
        }
      }
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, mount.state.signal, auth.dispatch, loadLexiconTableCallback]);

  /**
   * Saves the user input for the form to the browser local storage.
   */
  const saveForm = () => {
    const analysis = getLocalAnalysis(params.analysisId);
    analysis.lexiconId = lexiconId;
    analysis.lexiconName = lexiconList.find(lexicon => lexicon.id_str === lexiconId)?.name;
    analysis.lexiconEntries = lexiconEntries;
    analysis.lexiconSelections = selections;
    analysis.insights = semanticInsights;

    analysis.maxStep = 4;
    updateMaxStep(4);
    setLocalAnalysis(params.analysisId, analysis);
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    setIsSubmitting(true);
    saveForm();
    blocker.dispatch({ url: url });
  };

  return isLoaded ? (
    <>
      <Prompt when={!blocker.state.url} message={messages.navigationBlocker} />
      <ValidatorForm onSubmit={redirect(moveNextUrl)}>
        <Grid container direction="column" alignItems="center">
          <Container maxWidth='xs'>
            <Grid container spacing={2}>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Alert severity="info">
                  {messages.pages.newAnalysisSemantic.instructions}
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <SelectValidator variant="outlined" fullWidth label="Lexicon"
                  inputProps={{ 'aria-label': 'Lexicon' }}
                  name="lexicon" value={lexiconId} onChange={updateLexiconId}
                  validators={['required']}
                  errorMessages={['Lexicon is required']}>
                  {lexiconList.map((lexicon: any) => <MenuItem value={lexicon.id_str}
                    key={`lexicon-${lexicon.id_str}`}>{lexicon.name}</MenuItem>)}
                  <Divider />
                  <MenuItem value="0">New lexicon</MenuItem>
                </SelectValidator>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='xs'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel label={
                    <InlineTooltip text={messages.tooltips.semanticSentimentInsights}>
                      Semantic-sentiment insights
                      </InlineTooltip>
                  }
                    control={<Checkbox checked={semanticInsights} color="primary"
                      onChange={updateSemanticInsights} />} />
                </FormGroup>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={4}>
                <LoadingButton variant="outlined" color="primary"
                  href={cancelUrl}>Cancel</LoadingButton>
              </Grid>
              <Grid item xs={8}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item>
                    <LoadingButton variant="contained" color="primary"
                      onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton type="submit" variant="contained" color="secondary"
                      isSubmitting={isSubmitting}>Next</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
              {
                lexiconId && lexiconEntries && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {messages.pages.newAnalysisSemantic.tableInstruction}
                    </Alert>
                  </Grid>
                )
              }
            </Grid>
          </Container>
        </Grid>
        {lexiconId && lexiconEntries && (
          <Grid container spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <SemanticTable entries={lexiconEntries} selections={selections}
                setSelections={setSelections} />
            </Grid>
          </Grid>
        )}
      </ValidatorForm>
    </>
  ) : <LoadingPage minHeight="0" />;
};
