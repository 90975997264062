/**
 * @file Radio button validator
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";

const styles = theme => ({
  fieldset: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.grey[400],
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1.5, 2)
  },
  fieldsetInvalid: {
    borderColor: theme.palette.error.main
  },
  legend: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  radio: {
    alignItems: 'initial',
    paddingTop: 1,
    paddingBottom: 1
  },
  label: {
    alignItems: 'initial'
  }
});

/**
 * Class of custom radio button validator
 * @see {@link https://github.com/NewOldMax/react-material-ui-form-validator}
 */
class RadioButtonValidator extends ValidatorComponent {
  render() {
    const {
      classes,
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      label,
      options,
      ...rest
    } = this.props;

    // Indicates whether the radio button is currently in a valid state.
    const {isValid} = this.state;
    return (
        <>
          <FormControl component="fieldset" error={!isValid}
                       className={clsx(classes.fieldset,
                           {[classes.fieldsetInvalid]: !isValid})}>
            <FormLabel component="legend"
                       className={classes.legend}>{label}</FormLabel>
            <RadioGroup {...rest}>
              <Grid container spacing={1}>
                {options.map(option => {
                  const color = isValid ? 'primary' : 'error';
                  return (
                      <Grid item xs={12} key={`option-${option.id}`}>
                        <FormControlLabel label={<Typography
                            color={color}>{option.label}</Typography>}
                                          value={option.id}
                                          className={classes.label}
                                          control={<Radio
                                              icon={<RadioButtonUncheckedIcon
                                                  color={color}/>} checkedIcon={
                                            <RadioButtonCheckedIcon
                                                color={color}/>}
                                              className={classes.radio}/>}/>
                      </Grid>
                  );
                })}
                <Grid item xs={12}/>
              </Grid>
            </RadioGroup>
          </FormControl>
          <FormHelperText variant="outlined" error={!isValid}>
            {(!isValid && this.getErrorMessage()) || helperText}
          </FormHelperText>
        </>
    );
  }
}

RadioButtonValidator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonValidator);
