/**
 * @file Layout for terms and conditions page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

 import Container from '@material-ui/core/Container';
 import Grid from "@material-ui/core/Grid";
 import { makeStyles } from '@material-ui/core/styles';
 import Typography from '@material-ui/core/Typography';
 import React from 'react';
 import pages from '../../../config/pages.json';
 import Layout from '../../common/layout';
 import LoadingButton from "../../common/loading/loading-button";
 
 const useStyles = makeStyles(_ => ({
   root: {
     marginLeft: 'auto',
     marginRight: 'auto'
   }
 }));
 
 export default () => {
   const classes = useStyles();
   const styles = { root: classes.root };
   return (
       <Layout pageId="about" title={null}>
         <Container maxWidth="md" classes={styles}>
           <Typography component="h1" variant="h5">About Us</Typography>
           <Typography component="p" variant="body1">
            This software was developed by a team from the University of Toronto under the supervision of Prof. Tamer E. El-Diraby and Amer S Shalaby. The lead software architect was Theohar Konomi, with contributions from Omar Kabbani, Henry Tu, Roy Fang, Jiaxing Li, Rami Al-Sahar, and Julian de Rushe.
            The software platform represents the outcomes of ongoing research work on the use of social media analytics (and AI in general) in the smart city, particularly in transit systems.
            The project was jointly funded by NSERC (Natural Science and Engineering Council, Canada), CUTRIC (Canadian Urban Transit Research & Innovation Consortium), and Calgary Transit.
           </Typography>
 
           <br/>
           <Typography component="h1" variant="h5">Acknowledgements</Typography>
           <Typography component="p" variant="body1">
            The development team acknowledges Ms. Andrea Adams and Mr. Scott Hale of Calgary transit for their leadership and contributions.
           </Typography>

           <br/>
           <br/>
           <Grid container justify="flex-end" spacing={2}>
             <Grid item>
               <LoadingButton href={pages['home'].url} variant="contained" color="primary">Return
                 home</LoadingButton>
             </Grid>
           </Grid>
         </Container>
       </Layout>
   );
 };
 
