/**
 * @file Delete panel for user profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { deleteUser } from '../../../utils/api';
import { clearTokens } from '../../../utils/auth';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads delete panel for user profile page.
 */
export default () => {
  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {string} confirmDeletion - text in account deletion confirmation box
   */
  const [confirmDeletion, setConfirmDeletion] = useState('');

  /**
   * Updates the deletion confirmation from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateConfirmDeletion = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmDeletion(e.target.value);

  /**
   * Deletes user's account, and clears the auth tokens and logs the user out if successful.
   */
  const deleteAccount = async () => {
    const result = await deleteUser(auth.state.userId, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      clearTokens();
      auth.dispatch({ type: 'logout', userId: '0', userFirstName: '', userLastName: '' });
      history.push(pages['login'].url);
    }
  };

  // Only enables the delete button if the content of text field is "DELETE".
  return (
      <>
        <Box my={2}>
          <Typography component="h2" variant="h5">Delete account</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>Deleting an account cannot be undone. All
              settings will be lost.</Typography>
            <Typography variant="body1">If you want to continue, please type “DELETE”
              below.</Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField variant="outlined" fullWidth size="small"
                       inputProps={{
                         style: { textAlign: 'center' },
                         'aria-label': 'Confirm Deletion'
                       }}
                       name="confirm-deletion" value={confirmDeletion}
                       onChange={updateConfirmDeletion}/>
          </Grid>
          <Grid item container xs={7} justify="flex-end">
            <LoadingButton variant="contained" disabled={confirmDeletion !== 'DELETE'}
                           color="secondary" onClick={deleteAccount}>
              Permanently delete my account
            </LoadingButton>
          </Grid>
        </Grid>
      </>
  );
};
