/**
 * @file Layout for user email verification page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MountContext } from '../../../hooks/mount';
import { verify } from '../../../utils/api';
import LoadingContent from "../../common/loading/loading-content";
import FailurePage from './failure-page';
import SendEmailPage from './send-email-page';
import SuccessPage from './success-page';

/**
 * Loads send email page to resend email if no token is provided.
 * Otherwise, validates token, and displays the success or failure result.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   uidb64: the user id encoded in base 64
   *   token: token to check that the password is valid
   */
  const params: any = useParams();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {boolean} isVerified - indicates whether the provided token is valid
   */
  const [isVerified, setIsVerified] = useState(false);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Verifies token, and switches to the success or failure page.
   */
  useEffect(() => {
    (async () => {
      if (params.uidb64 && params.token) {
        const result = await verify(params.uidb64, params.token, mount.state.signal);
        setIsVerified(result.success);
        setIsLoaded(true);
      }
    })();
  }, [params.uidb64, params.token, mount.state.signal]);

  return !(params.uidb64 && params.token) ? <SendEmailPage/> : isLoaded ? (isVerified ?
      <SuccessPage/> : <FailurePage/>) : <LoadingContent/>;
};
