/**
 * @file Dashboard banner for analysis status
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert/Alert";
import { default as React } from "react";

// Messages for dashboard banners
const statusLookup: any = {
  'In progress - configuration required': {
    severity: 'warning',
    message: '%n analyses require configuration to continue analysis.',
    messageSingle: 'An analysis requires configuration to continue.'
  },
  'Not started': {
    severity: 'warning',
    message: '%n analyses have not completed initial setup.',
    messageSingle: 'An analysis has not completed initial setup.'
  }
};

/**
 * Generates analyses status banner for dashboard
 * @param {{} as { [id: string]: string[] }} statuses
 */
export default ({ statuses }: { statuses: { [id: string]: string[] } }) => (
    <>
      {
        Object.keys(statuses).sort().map((status: string, index: number) => (
            statusLookup[status] && (
                <Grid item xs={12} key={index}>
                  <Alert severity={statusLookup[status]?.severity || 'error'} key={index}>
                    {
                      statusLookup[status]
                          ? statuses[status].length > 1
                          ? statusLookup[status]['message'].replace('%n', statuses[status].length)
                          : statusLookup[status]['messageSingle']
                          : status
                    }
                  </Alert>
                </Grid>)
        ))
      }
    </>
);
