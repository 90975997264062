/**
 * @file Dialog box component
 * @copyright 2020 University of Toronto. All rights reserved.
 */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import LoadingButton from '../loading/loading-button';

/**
 * @param {any} children
 * @param {string} title
 * @param {boolean} open
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setOpen
 * @param {() => void} onConfirm
 * @param {string | undefined} confirmText - text to replace the confirm button
 * @param {boolean | undefined} disableConfirm - whether the confirm button is disabled
 * @param {boolean | undefined} disableAutoClose - whether to disable automatically closing dialog on confirm
 * @param {() => void} onDismiss
 */
export default ({ children, title, open, setOpen, onConfirm, confirmText, disableConfirm, disableAutoClose, onDismiss }:
                    {
                      children: any,
                      title: string,
                      open: boolean,
                      setOpen: React.Dispatch<React.SetStateAction<boolean>>,
                      onConfirm?: () => void,
                      confirmText?: string,
                      disableConfirm?: boolean,
                      disableAutoClose?: boolean,
                      onDismiss?: () => void,
                    }) => {

  const handleClose = () => {
    setOpen(false);

    if (onDismiss) {
      onDismiss();
    }
  };

  const confirm = () => {
    if (!disableAutoClose) {
      handleClose();
    }

    if (onConfirm) {
      onConfirm();
    }
  };

  return (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={title}
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {children}
    </DialogContent>
    <DialogActions>
      {
        onConfirm
            ? (<>
              <Button onClick={handleClose} color="secondary" autoFocus>
                Cancel
              </Button>
              <LoadingButton onClick={confirm} type="submit" color="primary" disabled={disableConfirm}>
                {confirmText || 'Confirm'}
              </LoadingButton>
            </>)
            : <Button onClick={handleClose} color="secondary" autoFocus>
              Dismiss
            </Button>
      }
    </DialogActions>
  </Dialog>);
};


