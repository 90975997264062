/**
 * @file Profile table for new analysis review page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from '../../../hooks/mount';
import { getAnalysis } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";

/**
 * Loads profile table for new analysis review page.
 * If user clicks edit, page redirects to the new analysis profile page.
 * @param {(url?: string) => () => void} redirect - callback of redirecting
 */
export default ({ redirect }: { redirect: (url?: string) => () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {string} analysisName - analysis name
   */
  const [analysisName, setAnalysisName] = useState('');

  /**
   * @type {string} description - analysis description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {boolean} isPrivate - indicates whether the analysis is private
   */
  const [isPrivate, setIsPrivate] = useState(false);

  /**
   * @type {string} author - user's name of the analysis author
   */
  const [author, setAuthor] = useState('');

  /**
   * @type {string} creationDate - creation date of the analysis
   */
  const [creationDate, setCreationDate] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  // Generates a URL to the new analysis profile page for the edit button.
  const profileUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 0 });

  /**
   * Sets up the analysis profile form.
   */
  useEffect(() => {
    (async () => {
      const result = await getAnalysis(params.teamId, params.analysisId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setAnalysisName(result.data.name);
      setDescription(result.data.description);
      setIsPrivate(!result.data.public);
      setAuthor(result.data.author ? `${result.data.author.first_name} ${result.data.author.last_name}` : '');
      setCreationDate(moment(result.data.created).format('YYYY-MM-DD, h:mm:ss A'));
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  return isLoaded ? (
      <Grid container spacing={2}>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Profile</Typography>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Analysis name</TableCell>
                  <TableCell>{analysisName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Private</TableCell>
                  <TableCell>{isPrivate ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>{description}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Author</TableCell>
                  <TableCell>{author}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Creation date</TableCell>
                  <TableCell>{creationDate}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item justify="flex-end" container xs={12}>
          <LoadingButton variant="contained" color="secondary"
                         onClick={redirect(profileUrl)}>Edit</LoadingButton>
        </Grid>
      </Grid>
  ) : <LoadingPage minHeight="0"/>;
};
