/**
 * @file Page header
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { SkipNavLink } from "@reach/skip-nav";
import React, { useContext } from 'react';
import { AuthContext } from '../../../hooks/auth';
import { mobileBreakpointSidebar } from "../../../hooks/viewport";
import Hamburger from './hamburger';
import LoginPanel from './login-panel';
import Masthead from './masthead';
import UserPanel from './user-panel';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.secondary.main
  },
  spacer: {
    minHeight: '56px',
    [`@media (min-width: ${mobileBreakpointSidebar}px)`]: {
      minHeight: '64px'
    }
  }
}));

/**
 * Loads the common header for most pages.
 * @param {boolean} isTeam - indicates whether the page is related to a team
 * @param {boolean} hasNav - indicates whether the page includes a navbar
 */
export default ({ isTeam, hasNav }: { isTeam: boolean, hasNav: boolean }) => {
  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  const classes = useStyles();
  return (
      <>
        <SkipNavLink/>
        <AppBar component="header" position="fixed" elevation={0} className={classes.header}>
          <Toolbar>
            {hasNav && <Hamburger/>}
            <Masthead/>
            <Box component="span" ml="auto">
              {auth.state.isAuthenticated ? <UserPanel isTeam={isTeam}/> : <LoginPanel/>}
            </Box>
          </Toolbar>
        </AppBar>
        <Grid className={classes.spacer}/>
      </>
  );
};
