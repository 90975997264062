/**
 * @file Team table for teams page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import LoadingButton from '../../common/loading/loading-button';
import LoadingLink from "../../common/loading/loading-link";

const useStyles = makeStyles(_ => ({
  tableRow: {
    cursor: 'pointer'
  }
}));

/**
 * Loads team table for teams page.
 * @param {any[]} teamList - array containing metadata of teams
 * @param {boolean} hasMore - indicates whether there are more teams available to load
 * @param {boolean} loadMore - callback to load the next page of teams
 */
export default ({ teamList, hasMore, loadMore }: { teamList: any[], hasMore: boolean, loadMore: () => void }) => {
  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Redirects to a specific team page.
   * @param {string} teamId - team id
   */
  const redirect = (teamId: string) => () => {
    const teamUrl = `${pages['teams'].url}/${teamId}`;
    history.push(teamUrl);
  };

  const classes = useStyles();
  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            {messages.pages.teams.description}
          </Alert>
        </Grid>
        <Grid item container xs={12} justify='flex-end'>
          <LoadingButton variant="contained" color="secondary" href={pages['new-team-initial'].url}>Add
            team</LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table className="collapse-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">No. of members</TableCell>
                  <TableCell align="center">Creation date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teamList.map((team: any) => (
                    <TableRow hover key={`team-${team.id_str}`} className={classes.tableRow}
                              onClick={redirect(team.id_str)}>
                      <TableCell compact-title="Name" align="left">
                        <LoadingButton variant="text" color="inherit"
                                       onClick={redirect(team.id_str)}>
                          {team.name}
                        </LoadingButton>
                      </TableCell>
                      <TableCell compact-title="Description"
                                 align="left">{team.description}</TableCell>
                      <TableCell compact-title="Role" align="center">{team.role}</TableCell>
                      <TableCell compact-title="No. of members"
                                 align="center">{team.member_count}</TableCell>
                      <TableCell compact-title="Creation date"
                                 align="center">{moment(team.created).format('YYYY-MM-DD, h:mm:ss A')}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
              {hasMore && (
                  <TableFooter>
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={8} align="center">
                        <LoadingLink onClick={loadMore} text="Load more..."/>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}/>
      </Grid>
  );
};
