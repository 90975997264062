/**
 * @file Layout for invitations page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import Layout from '../../common/layout';
import InviteForm from './invite-form';

/**
 * Loads invite form.
 */
export default () => (
    <Layout pageId="team-invites">
      <InviteForm/>
    </Layout>
);
