/**
 * @file Helper functions for analysis
 * @copyright 2020 University of Toronto. All rights reserved.
 */

/**
 * Gets cached new analysis data from the browser local storage, and
 * returns a JSON object, or an empty object if failed.
 * @param {string} id - analysis id
 * @returns {any} - the cached new analysis data
 */
export const getLocalAnalysis = (id: string) => {
  const analysisData = window.localStorage.getItem('analyses');
  if (analysisData) {
    try {
      const analyses = JSON.parse(analysisData);
      if (analyses[id]) {
        return analyses[id];
      }
    } catch {
    }
  }
  return {};
};

/**
 * Caches the new analysis data to the browser local storage
 * @param {string} id - analysis id
 * @param {any} newData - new analysis data
 */
export const setLocalAnalysis = (id: string, newData: any) => {
  let analyses: any = {};
  const analysisData = window.localStorage.getItem('analyses');
  if (analysisData) {
    try {
      analyses = JSON.parse(analysisData);
    } catch {
    }
  }

  analyses[id] = newData;
  const newAnalysisData = JSON.stringify(analyses);
  window.localStorage.setItem('analyses', newAnalysisData);
};

/**
 * Removes the analysis data from the browser local storage
 * @param {string} id - analysis id
 */
export const removeLocalAnalysis = (id: string) => {
  let analyses: any = {};
  const analysisData = window.localStorage.getItem('analyses');
  if (analysisData) {
    try {
      analyses = JSON.parse(analysisData);
    } catch {
    }
  }

  delete analyses[id];
  if (Object.keys(analyses).length > 0) {
    const newAnalysisData = JSON.stringify(analyses);
    window.localStorage.setItem('analyses', newAnalysisData);
  } else {
    window.localStorage.removeItem('analyses');
  }
};
