/**
 * @file Loading panel to span page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LoadingSpinner from './loading-spinner';

/**
 * Loads the loading animation and fills the page.
 *
 * @param {string | undefined} minHeight - min height of the page
 */
export default ({ minHeight }: { minHeight?: string }) => {
  const useStyles = makeStyles(theme => ({
    spinner: {
      minHeight: minHeight || 'calc(100vh - 121px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: minHeight || 'calc(100vh - 113px)'
      },
      margin: '0 !important',
      width: '100% !important',
      minWidth: '220px'
    }
  }));

  const classes = useStyles();
  return (
      <Grid container spacing={2} direction="column" justify="center" alignItems="center"
            className={classes.spinner}>
        <LoadingSpinner/>
      </Grid>
  );
};
