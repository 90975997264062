/**
 * @file Send email page for user email verification page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import SendEmailForm from './send-email-form';

/**
 * Loads send email page for user email verification page to request a new email verification.
 */
export default () => (
    <Layout pageId="verify" center={true}>
      <MessageBarProvider>
        <SendEmailForm/>
      </MessageBarProvider>
    </Layout>
);
