/**
 * @file Main dashboard page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import messages from "../../config/messages";
import pages from "../../config/pages.json";
import { requestQueryBuilder } from "../../utils/query";
import AnalysesForm from '../analysis/analyses/analysis-form';
import DashboardBanner from "../analysis/dashboard-banner";
import LoadingButton from "../common/loading/loading-button";
import LoadingContent from "../common/loading/loading-content";

export default () => {
  /**
   * @type {{ [id: string]: string[] }} statuses - dictionary of statuses
   */
  const [statuses, setStatuses] = useState({} as { [id: string]: string[] });

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {string} recentAnalysisFilter
   */
  const [recentAnalysisFilter, setRecentAnalysisFilter] = useState('');

  const userProfileUrl = pages['user'].url;

  const maxDaysNoUpdates = 14;

  /**
   * Runs only on initial page load
   */
  useEffect(() => {
    setRecentAnalysisFilter(requestQueryBuilder({
      per_page: 10,
      filters: [
        {
          '$or': [
            { updated__date__gte: moment().subtract(maxDaysNoUpdates, 'd').format('YYYY-MM-DD') },
            { status: 'In progress - collecting' },
            { status: 'In progress - analysing' },
            { status: 'In progress - configuration required' }
          ]
        }
      ]
    }));

    setIsLoaded(true);
  }, [setRecentAnalysisFilter]);

  return isLoaded
      ? (<Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4">
            {pages["main-dashboard"].title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            {messages.pages.dashboard.description}
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton variant="outlined" color="primary" href={userProfileUrl}>Account
            settings</LoadingButton>
        </Grid>
        <DashboardBanner statuses={statuses}/>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6">Recent Analyses</Typography>
          <Typography>(Includes active analyses and ones that have been updated in the
            last {maxDaysNoUpdates} days)</Typography>
          <AnalysesForm query={recentAnalysisFilter} dashboard={true} mainDashboard={true}
                        setStatuses={setStatuses}/>
        </Grid>
      </Grid>)
      : <LoadingContent/>;
};
