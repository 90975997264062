/**
 * @file User panel for header
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from "@material-ui/core/Button";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React, { useContext, useRef, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MountContext } from '../../../hooks/mount';
import { logout } from '../../../utils/api';
import { clearTokens } from '../../../utils/auth';
import { loadIcon } from '../../../utils/icon';
import { makeUrl } from '../../../utils/url';

const useStyles = makeStyles(theme => ({
  separator: {
    display: 'inline-block',
    width: 1,
    height: theme.spacing(3.5),
    margin: theme.spacing(0, 1, -1.25, 1),
    backgroundColor: theme.palette.common.white
  },
  name: {
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
    marginRight: theme.spacing(1)
  },
  popperButton: {
    padding: 0,
  }
}));

/**
 * Loads user panel for header.
 * @param {boolean} isTeam - indicates whether the page is related to a team
 */
export default ({ isTeam }: { isTeam: boolean }) => {
  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {boolean} open - indicates whether the user menu is open
   */
  const [open, setOpen] = useState(false);

  /**
   * Reference of user menu
   * @type {HTMLButtonElement | null}
   */
  const anchorRef = useRef(null as HTMLButtonElement | null);

  /**
   * Logs out the account when the user clicks the logout menu item.
   */
  const logoutAccount = async () => {
    await logout(mount.state.signal);
    clearTokens();
    auth.dispatch({ type: 'logout', userId: '0', userFirstName: '', userLastName: '' });
    history.push(pages['login'].url);
  };

  /**
   * Toggles user menu.
   */
  const toggleMenu = () => setOpen(prevOpen => !prevOpen);

  /**
   * Closes user menu when the user clicks on other parts of screen.
   * @param {React.MouseEvent<Document, MouseEvent>} e
   */
  const closeMenu = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (!(anchorRef.current && anchorRef.current.contains(e.target as HTMLElement))) {
      setOpen(false);
    }
  };

  // Loads the teams icon for menu item.
  const TeamsIcon = loadIcon(pages['teams'].icon);

  // Loads the analyses icon for menu item.
  const AnalysesIcon = loadIcon(pages['analyses'].icon);

  // Loads the reports icon for menu item.
  const ReportsIcon = loadIcon(pages['reports'].icon);

  // Loads the user icon for menu item.
  const UserProfileIcon = loadIcon(pages['user'].icon);

  // Loads the security icon for menu item.
  const UserSecurityIcon = loadIcon(pages['security'].icon);

  // Loads the logout icon for menu item.
  const LogoutIcon = loadIcon(pages['logout'].icon);

  // Generates a URL to the analyses page for analyses button.
  const analysesUrl = makeUrl(pages['analyses'].url, params);

  // Generates a URL to the reports page for reports button.
  const reportsUrl = makeUrl(pages['reports'].url, params);

  /**
   * Function to generate an anonymous function to redirect to a URL
   * @param url
   */
  const redirect = (url: string) => () => {
    history.push(url);
  };

  const classes = useStyles();
  return (
      <>
        <Link component={RouterLink} to={pages['teams'].url} color="inherit" underline="none">
          <Tooltip disableFocusListener title="Teams">
            <IconButton color="inherit">
              <TeamsIcon/>
            </IconButton>
          </Tooltip>
        </Link>
        {isTeam && (
            <>
              <Link component={RouterLink} to={analysesUrl} color="inherit" underline="none">
                <Tooltip disableFocusListener title="Analyses">
                  <IconButton color="inherit">
                    <AnalysesIcon/>
                  </IconButton>
                </Tooltip>
              </Link>
              <Link component={RouterLink} to={reportsUrl} color="inherit" underline="none">
                <Tooltip disableFocusListener title="Reports">
                  <IconButton color="inherit">
                    <ReportsIcon/>
                  </IconButton>
                </Tooltip>
              </Link>
            </>
        )}
        <Divider component="span" className={classes.separator}/>
        <Tooltip disableFocusListener title="Account">
          <Button color="inherit" ref={anchorRef} onClick={toggleMenu}>
            <Typography component="p" variant="body1" className={classes.name}>
              {auth.state.userFirstName}
            </Typography>
            <AccountCircleIcon/>
          </Button>
        </Tooltip>
        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition>
          {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={closeMenu}>
                    <MenuList autoFocusItem={open}>
                      <MenuItem onClick={redirect(pages['user'].url)}>
                        <ListItemIcon>
                          <UserProfileIcon/>
                        </ListItemIcon>
                        <ListItemText primary={pages['user'].title}/>
                      </MenuItem>
                      <MenuItem onClick={redirect(pages['security'].url)}>
                        <ListItemIcon>
                          <UserSecurityIcon/>
                        </ListItemIcon>
                        <ListItemText primary={pages['security'].title}/>
                      </MenuItem>
                      <Divider/>
                      <MenuItem onClick={logoutAccount}>
                        <ListItemIcon>
                          <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary={pages['logout'].title}/>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
          )}
        </Popper>
      </>
  );
};
