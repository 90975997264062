/**
 * Common timezone names matched with their correspoding tz database name
 *
 * Based on react-timezone-select by Nico Domino (https://github.com/ndom91/react-timezone-select)
 *
 */

export const timezones: { [key: string]: string } = {
  "Abu Dhabi, Muscat": "Asia/Dubai",
  "Adelaide": "Australia/Adelaide",
  "Alaska": "America/Juneau",
  "Almaty, Novosibirsk": "Asia/Almaty",
  "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna": "Europe/Amsterdam",
  "Arizona": "America/Phoenix",
  "Astana, Dhaka": "Asia/Dhaka",
  "Athens, Istanbul, Minsk": "Europe/Athens",
  "Atlantic Time (Canada)": "America/Glace_Bay",
  "Auckland, Wellington": "Pacific/Auckland",
  "Azores": "Atlantic/Azores",
  "Baghdad": "Asia/Baghdad",
  "Baku, Tbilisi, Yerevan": "Asia/Baku",
  "Bangkok, Hanoi, Jakarta": "Asia/Bangkok",
  "Beijing, Chongqing, Hong Kong SAR, Urumqi": "Asia/Shanghai",
  "Belgrade, Bratislava, Budapest, Ljubljana, Prague": "Europe/Belgrade",
  "Bogota, Lima, Quito": "America/Bogota",
  "Brasilia": "America/Sao_Paulo",
  "Brisbane": "Australia/Brisbane",
  "Brussels, Copenhagen, Madrid, Paris": "Europe/Brussels",
  "Bucharest": "Europe/Bucharest",
  "Buenos Aires, Georgetown": "America/Argentina/Buenos_Aires",
  "Cairo": "Africa/Cairo",
  "Canary Islands": "Atlantic/Canary",
  "Canberra, Melbourne, Sydney": "Australia/Sydney",
  "Cape Verde Islands": "Atlantic/Cape_Verde",
  "Caracas, La Paz": "America/Caracas",
  "Casablanca, Monrovia": "Africa/Casablanca",
  "Central America": "America/Belize",
  "Central Time (US and Canada)": "America/Chicago",
  "Chennai, Kolkata, Mumbai, New Delhi": "Asia/Kolkata",
  "Chihuahua, La Paz, Mazatlan": "America/Chihuahua",
  "Darwin": "Australia/Darwin",
  "Dublin, Edinburgh, Lisbon, London": "GMT",
  "Eastern Time (US and Canada)": "America/Detroit",
  "Ekaterinburg": "Asia/Yekaterinburg",
  "Fiji Islands, Kamchatka, Marshall Islands": "Pacific/Fiji",
  "Greenland": "America/Godthab",
  "Guadalajara, Mexico City, Monterrey": "America/Mexico_City",
  "Guam, Port Moresby": "Pacific/Guam",
  "Harare, Pretoria": "Africa/Harare",
  "Hawaii": "Pacific/Honolulu",
  "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius": "Europe/Helsinki",
  "Hobart": "Australia/Hobart",
  "Indiana (East)": "America/Indiana/Indianapolis",
  "Irkutsk, Ulaanbaatar": "Asia/Irkutsk",
  "Islamabad, Karachi, Tashkent": "Asia/Karachi",
  "Jerusalem": "Asia/Jerusalem",
  "Kabul": "Asia/Kabul",
  "Kathmandu": "Asia/Kathmandu",
  "Krasnoyarsk": "Asia/Krasnoyarsk",
  "Kuala Lumpur, Singapore": "Asia/Kuala_Lumpur",
  "Kuwait, Riyadh": "Asia/Kuwait",
  "Magadan, Solomon Islands, New Caledonia": "Asia/Magadan",
  "Mid-Atlantic": "America/Noronha",
  "Midway Island, Samoa": "Pacific/Midway",
  "Moscow, St. Petersburg, Volgograd": "Europe/Moscow",
  "Mountain Time (US and Canada)": "America/Boise",
  "Nairobi": "Africa/Nairobi",
  "Newfoundland and Labrador": "America/St_Johns",
  "Nuku'alofa": "Pacific/Tongatapu",
  "Osaka, Sapporo, Tokyo": "Asia/Tokyo",
  "Pacific Time (US and Canada); Tijuana": "America/Dawson",
  "Perth": "Australia/Perth",
  "Santiago": "America/Santiago",
  "Sarajevo, Skopje, Warsaw, Zagreb": "Europe/Sarajevo",
  "Saskatchewan": "America/Regina",
  "Seoul": "Asia/Seoul",
  "Sri Jayawardenepura": "Asia/Colombo",
  "Taipei": "Asia/Taipei",
  "Tehran": "Asia/Tehran",
  "UTC": "UTC",
  "Vladivostok": "Asia/Vladivostok",
  "West Central Africa": "Africa/Algiers",
  "Yakutsk": "Asia/Yakutsk",
  "Yangon Rangoon": "Asia/Rangoon"
};
