/**
 * @file Detail form for invitation detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from "../../../hooks/team";
import { addTeamMember, getInvite, updateInvite } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import { KeyboardDatePickerValidator } from '../../common/validator';
import DeletePanel from './delete-panel';
import InlineTooltip from "../../common/inline-tooltip";
import messages from "../../../config/messages";

/**
 * Loads detail form for invitation detail page.
 *
 * @param {boolean} setup - Whether this component is part of a setup sequence
 */
export default ({ setup }: { setup?: boolean }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   inviteId: invitation id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {string} email - user's email
   */
  const [email, setEmail] = useState('');

  /**
   * @type {string} memberRole - team member's role
   */
  const [memberRole, setMemberRole] = useState('');

  /**
   * @type {null} expires - expiry date of the invitation
   */
  const [expires, setExpires] = useState(null as MaterialUiPickersDate);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the email from the text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  /**
   * Updates the member role from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateMemberRole = (e: React.ChangeEvent<HTMLSelectElement>) => setMemberRole(e.target.value);

  /**
   * Updates the expiry date from date picker on change.
   * @param {MaterialUiPickersDate} date - new expiry date
   */
  const updateExpiryDate = (date: MaterialUiPickersDate) => setExpires(date?.startOf('day') || null);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the invitations page for the cancel button.
  const invitesUrl = makeUrl(pages['team-invites'].url, params);

  // Checks if the team member is an existing member.
  const isExistingInvite = !!params.inviteId;

  /**
   * Sets up the invitation detail form.
   */
  useEffect(() => {
    (async () => {
      if (isExistingInvite) {
        const result = await getInvite(params.teamId, params.inviteId, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        setEmail(result.data.email);
        setMemberRole(result.data.role);
        setExpires(result.data.expires && moment(result.data.expires));
      }

      setIsLoaded(true);
    })();
  }, [params.teamId, params.inviteId, history, isExistingInvite, auth.dispatch, mount.state.signal]);

  /**
   * Updates the invitation detail, and loads message about request status.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const date = expires ? moment(expires).format('YYYY-MM-DD') : null;

    if (isExistingInvite) {
      const result = await updateInvite(params.teamId, params.inviteId, memberRole, date, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });

      setIsSubmitting(false);
    } else {
      const result = await addTeamMember(params.teamId, email, memberRole, date, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });
      if (result.success) {
        if (setup) {
          handleNext();
        } else {
          history.push(invitesUrl);
        }
      } else {
        setIsSubmitting(false);
      }
    }
  };

  /**
   * Navigate to next step in setup sequence
   */
  const handleNext = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 2 }));
  };

  /**
   * Navigate to next step in setup sequence
   */
  const handleBack = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 0 }));
  };

  // Only manager can add or update a team member.
  const isAddable = !isExistingInvite && team.state.role === 'Manager';
  const isUpdatable = isExistingInvite && team.state.role === 'Manager';
  const isEditable = isAddable || isUpdatable;

  return isLoaded ? (
      <ValidatorForm onSubmit={submitForm}>
        <Container maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Email"
                             inputProps={{ 'aria-label': 'Email' }} autoFocus
                             name="email" value={email} onChange={updateEmail}
                             validators={['required']} errorMessages={['Email is required']}
                             disabled={!isEditable || isExistingInvite}/>
            </Grid>
            <Grid item xs={12}>
              <SelectValidator variant="outlined" fullWidth label="Role"
                               inputProps={{ 'aria-label': 'Role' }} disabled={!isEditable}
                               name="member-role" value={memberRole} onChange={updateMemberRole}
                               validators={['required']} errorMessages={['Role is required']}>
                <MenuItem value="Viewer">
                  <InlineTooltip text={messages.tooltips.viewerRole}>
                    Viewer
                  </InlineTooltip>
                </MenuItem>
                <MenuItem value="Analyst">
                  <InlineTooltip text={messages.tooltips.analystRole}>
                    Analyst
                  </InlineTooltip>
                </MenuItem>
                <MenuItem value="Manager">
                  <InlineTooltip text={messages.tooltips.managerRole}>
                    Manager
                  </InlineTooltip>
                </MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePickerValidator autoOk variant="inline" inputVariant="outlined" fullWidth
                                           label="Invitation Expires (Optional)" format="YYYY-MM-DD"
                                           disabled={!isEditable}
                                           name="expiry-date" value={expires}
                                           onChange={updateExpiryDate} minDate={moment()}/>
            </Grid>
            <Grid item xs={12}/>
            {
              setup
                  ? (<>
                    <Grid item xs={2}>
                      <LoadingButton variant="outlined" color="primary"
                                     href={pages['teams'].url}>Cancel</LoadingButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container justify='flex-end' spacing={2}>
                        < Grid item>
                          <Button onClick={handleBack}>
                            Back
                          </Button>
                        </Grid>
                        < Grid item>
                          <Button onClick={handleNext} variant="outlined">
                            Skip
                          </Button>
                        </Grid>
                        < Grid item>
                          <LoadingButton type="submit" variant="contained" color="secondary"
                                         isSubmitting={isSubmitting}>Next</LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>)
                  : isEditable && (
                  <>
                    {
                      isExistingInvite
                          ? <DeletePanel/>
                          : <Grid item xs={6}/>
                    }
                    <Grid item xs={6}>
                      <Grid container justify='flex-end' spacing={2}>
                        <Grid item>
                          <LoadingButton variant="contained" color="primary"
                                         href={invitesUrl}>{isExistingInvite ? 'Back' : 'Cancel'}</LoadingButton>
                        </Grid>
                        <Grid item>
                          <LoadingButton type="submit" variant="contained" color="secondary"
                                         isSubmitting={isSubmitting}>{isExistingInvite ? "Update" : "Add"}</LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>)
            }
          </Grid>
        </Container>
      </ValidatorForm>
  ) : <LoadingContent/>;
};
