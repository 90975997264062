/**
 * @file Twitter credentials table for Twitter credentials page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { TeamContext } from '../../../hooks/team';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingLink from "../../common/loading/loading-link";

const useStyles = makeStyles(_ => ({
  tableRow: {
    cursor: 'pointer'
  }
}));

/**
 * Loads Twitter credentials table for Twitter credentials page.
 * @param {any[]} credentialsList - array containing metadata of Twitter credentials
 * @param {boolean} hasMore - indicates whether there are more Twitter credentials available to load
 * @param {() => void} loadMore - callback to load the next page of Twitter credentials
 */
export default ({ credentialsList, hasMore, loadMore }: { credentialsList: any[], hasMore: boolean, loadMore: () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the new Twitter credentials page for the add button.
  const addCredentialsUrl = makeUrl(pages['new-team-credentials'].url, params);

  /**
   * Redirects to the specific Twitter credentials page.
   * @param {string} credentialsId - credentials id
   */
  const redirect = (credentialsId: string) => () => {
    // Generates a URL to a specific Twitter credentials page.
    const credentialsUrl = makeUrl(pages['team-credentials'].url, params) + `/${credentialsId}`;
    history.push(credentialsUrl);
  };

  // Only manager can add the Twitter credentials.
  const isAddable = team.state.role === 'Manager';

  const classes = useStyles();
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Alert severity="info">
        {messages.pages.twitterCredentials.description}
      </Alert>
    </Grid>
    {isAddable ? (
        <Grid item container xs={12} justify='flex-end'>
          <LoadingButton variant="contained" color="secondary" href={addCredentialsUrl}>Add Twitter
            credentials</LoadingButton>
        </Grid>
    ) : <Grid item xs={12}/>}
    <Grid item xs={12}>
      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Premium</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">API key</TableCell>
              <TableCell align="left">API secret</TableCell>
              <TableCell align="left">Access token</TableCell>
              <TableCell align="left">Access token secret</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {credentialsList.map((credentials: any) => (
                <TableRow hover key={`credentials-${credentials.id_str}`}
                          className={classes.tableRow} onClick={redirect(credentials.id_str)}>
                  <TableCell compact-title="Premium" align="center">{credentials.premium ?
                      <CheckBoxIcon color="primary"/> :
                      <CheckBoxOutlineBlankIcon color="primary"/>}</TableCell>
                  <TableCell compact-title="Name" align="left">
                    <LoadingButton variant="text" color="inherit"
                                   onClick={redirect(credentials.id_str)}>
                      {credentials.name}
                    </LoadingButton>
                  </TableCell>
                  <TableCell compact-title="API key" align="left">{credentials.api_key}</TableCell>
                  <TableCell compact-title="API secret"
                             align="left">{credentials.api_secret}</TableCell>
                  <TableCell compact-title="Access token"
                             align="left">{credentials.access_token}</TableCell>
                  <TableCell compact-title="Access token secret"
                             align="left">{credentials.access_token_secret}</TableCell>
                </TableRow>
            ))}
          </TableBody>
          {hasMore && (
              <TableFooter>
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={8} align="center">
                    <LoadingLink onClick={loadMore} text="Load more..."/>
                  </TableCell>
                </TableRow>
              </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
    <Grid item xs={12}/>
  </Grid>
};
