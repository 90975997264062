/**
 * @file Layout for disclaimers page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import pages from '../../../config/pages.json';
import Layout from '../../common/layout';
import LoadingButton from "../../common/loading/loading-button";

const useStyles = makeStyles(_ => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '@global': {
    li: {
      marginBottom: '15px'
    }
  }
}));

export default () => {
  const classes = useStyles();
  const styles = { root: classes.root };
  return (
      <Layout pageId="disclaimers" title={null}>
        <Container maxWidth="md" classes={styles}>
          <Box display="flex" mb={2}>
            <Typography component="h1" variant="h5">{pages['disclaimers'].title}</Typography>
          </Box>

          <ol>
            <li>
              This tool is a prototype that is the result of a research partnership between the
              University of Toronto and Calgary Transit. The tool is a work in progress research
              project, and your input as a user and research partner is needed to highlight any
              issues or shortcomings. Use the contact us tool to provide feedback which will help us
              to continuously improve the tool.
            </li>
            <li>
              TIPS is not a replacement for traditional tools such as customer
              satisfaction surveys to understand rider satisfaction. Insights from this tool should
              be used to augment the results of customer satisfaction surveys.
            </li>
            <li>
              The output of this tool should be evaluated while considering the limitations and
              biases of social media. Some of these limitations are listed below:
              <ol>
                <li>
                  Individuals that tweet about transit do not necessarily span the entire
                  demographic spectrum of transit users as Twitter users tend to be young adults
                </li>
                <li>
                  The socioeconomic and demographic characteristics of individuals are not
                  considered
                </li>
                <li>
                  The tool’s output is limited to a macro–level analysis of riders’ opinions rather
                  than a micro–level analysis of individual opinion dynamics
                </li>
                <li>
                  The availability of data is subject to constrains by the Twitter API on rate
                  limits and sample sizes
                </li>
                <li>
                  Twitter users tend to share more negative content about transit service when
                  compared to most other public services
                </li>
              </ol>
            </li>
            <li>
              There is no single approach to analyzing Twitter data to gauge customer satisfaction.
              This tool makes use of methodologies that are best suited for this use case.
            </li>
          </ol>


          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <LoadingButton href={pages['home'].url} variant="contained" color="primary">Return
                home</LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Layout>
  );
};
