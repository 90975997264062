/**
 * @file Leave panel for team profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from "../../../hooks/auth";
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { deleteTeamMember } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads leave panel for team profile page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {boolean} dialogOpen - indicates whether the confirmation dialog is opened
   */
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Open the confirmation dialog.
   */
  const confirmLeave = () => setDialogOpen(true);

  /**
   * Close the confirmation dialog.
   */
  const cancelLeave = () => {
    if (!isSubmitting) {
      setDialogOpen(false);
    }
  };

  /**
   * Makes API call to delete the current team, and redirects to the teams page if successful.
   */
  const leaveTeam = async () => {
    setIsSubmitting(true);

    const result = await deleteTeamMember(params.teamId, auth.state.userId, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      history.push(pages['teams'].url);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
      <>
        <Button variant="contained" color="secondary" onClick={confirmLeave}>Leave team</Button>
        <Dialog open={dialogOpen} onClose={cancelLeave}>
          <DialogTitle>Leave team</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to leave the team?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" autoFocus onClick={cancelLeave}
                    disabled={isSubmitting}>Cancel</Button>
            <LoadingButton color="secondary" onClick={leaveTeam} disableAutoLoad={true}
                           isSubmitting={isSubmitting}>Leave</LoadingButton>
          </DialogActions>
        </Dialog>
      </>
  );
};
