/**
 * @file Login form for login page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { login } from '../../../utils/api';
import { clearTokens, getName, saveTokens } from '../../../utils/auth';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads login form for login page.
 */
export default () => {

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {string} username - username
   */
  const [username, setUsername] = useState('');

  /**
   * @type {string} password - password
   */
  const [password, setPassword] = useState('');

  /**
   * @type {boolean} isRemembered - indicates whether to remember user for the next auto login
   */
  const [isRemembered, setIsRemembered] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the username from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateUsername = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);

  /**
   * Updates the password from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  /**
   * Updates the remembered flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateIsRemembered = (e: React.ChangeEvent<HTMLInputElement>) => setIsRemembered(e.target.checked);

  /**
   * Submits the login form.
   * Saves the auth tokens and whether and redirects to the teams page if successful.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const result = await login(username, password, mount.state.signal);
    if (result.success) {
      saveTokens(result.data, isRemembered);

      const name = await getName(mount.state.signal);

      auth.dispatch({
        type: 'login',
        userId: result.data.pk.toString(),
        userFirstName: name.firstName,
        userLastName: name.lastName
      });
    } else {
      setIsSubmitting(false);
      clearTokens();
    }
    messageBar.dispatch({
      open: !result.success,
      success: result.success,
      message: result.message
    });
  };

  return (
      <ValidatorForm onSubmit={submitForm} autoCorrect="off" autoCapitalize="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth label="Username or email"
                           inputProps={{ 'aria-label': 'Username or email' }}
                           autoComplete="login-username" autoFocus
                           name="login-username" value={username} onChange={updateUsername}
                           validators={['required']}
                           errorMessages={['Username or email is required']}/>
          </Grid>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth type="password" label="Password"
                           inputProps={{ 'aria-label': 'Password' }}
                           name="login-password" value={password} onChange={updatePassword}
                           validators={['required']} errorMessages={['Password is required']}/>
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel label="Remember me" aria-label="Remember me"
                              control={<Checkbox checked={isRemembered} value="remember"
                                                 color="primary" onChange={updateIsRemembered}/>}/>
          </Grid>
          <Grid item container xs={6} justify="flex-end" alignItems="center">
            <Link component={RouterLink} to={pages['reset'].url} variant="body1">Forgot
              password?</Link>
          </Grid>
          <Grid item container xs={12} alignItems="center">
            <Typography variant="body2">
              Didn't receive a confirmation email? <Link component={RouterLink}
                                                         to={pages['verify'].url} variant="body2">Request
              a new one.</Link>
            </Typography>
          </Grid>
          <Grid item xs={12}/>
          <Grid item container xs={12} justify="flex-end">
            <LoadingButton type="submit" variant="contained" color="secondary"
                           isSubmitting={isSubmitting}>Login</LoadingButton>
          </Grid>
        </Grid>
      </ValidatorForm>
  );
};
