/**
 * @file Customize bulk actions for new credentials
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import messages from "../../../config/messages";
import pages from "../../../config/pages.json";
import { DialogBoxContext } from "../../../hooks/dialog";
import { MountContext } from "../../../hooks/mount";
import { TeamContext } from "../../../hooks/team";
import { addTwitterCredentialsBulk } from "../../../utils/api";
import { makeUrl } from '../../../utils/url';
import BulkTabs from '../../common/bulk-actions/bulk-tabs';
import DetailForm from "../credentials-detail/detail-form";

const useStyles = makeStyles(_ => ({
  alert: {
    overflow: 'auto'
  },
  textField: {
    minWidth: '250px'
  }
}));

/**
 * @param {boolean} setup - Whether this component is part of a setup sequence
 */
export default ({ setup }: { setup?: boolean }) => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Dialog box state and dispatcher
   */
  const dialogBox = useContext(DialogBoxContext);

  /**
   * @type {any[]} entries
   */
  const [entries, setEntries] = useState([] as any[]);

  /**
   * @type {any[]} errors - array of errors for each table row in order
   */
  const [errors, setErrors] = useState([] as any[]);

  /**
   * Navigate to next step in setup sequence
   */
  const handleNext = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 3 }));
  };

  /**
   * Navigate to next step in setup sequence
   */
  const handleBack = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 1 }));
  };

  const classes = useStyles();

  const CSVHint = () => (
      <>
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={12}>
            <Alert severity="info" className={classes.alert}>
              <b>Tip: CSV Imports must include these header labels in the first row:</b><br/>
              <code>premium,
                <wbr/>
                name,
                <wbr/>
                apiKey,
                <wbr/>
                apiKeySecret,
                <wbr/>
                accessToken,
                <wbr/>
                accessTokenSecret,
                <wbr/>
                environmentLabel</code><br/>
              (Environment label is only required with premium credentials)
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info" className={classes.alert}>
              {messages.pages.twitterCredentials.envLabel}
            </Alert>
          </Grid>
        </Grid>
      </>
  );

  const cancelUrl = makeUrl(pages['team-credentials'].url, params);
  const setupCancelUrl = pages['teams'].url;

  const requiredFields = new Set([
    'name',
    'apiKey',
    'apiKeySecret',
    'accessToken',
    'accessTokenSecret'
  ]);

  /**
   * @param {string[]} errors
   */
  const rowSaveError = (errors: string[]) => {
    dialogBox.dispatch({
      open: true,
      success: false,
      body: (
          <>
            <Typography>
              Unable to save changes due to these validation errors:
            </Typography>
            <ul>
              {
                errors?.map((error) => (
                    <li key={error}>{error}</li>
                ))
              }
            </ul>
          </>
      )
    });
  };

  /**
   * @param {any} newData
   */
  const validateRow = (newData: any) => {

    let validationErrors: string[] = [];

    requiredFields.forEach((field: string) => {
      if (!newData[field]) {
        validationErrors.push(`${field} is required`)
      }
    });

    if ((newData['premium'] && !newData['environmentLabel'])) {
      validationErrors.push(`Environment label is required when premium is selected`)
    }

    if (validationErrors.length > 0) {
      return { success: false, errors: validationErrors };
    }

    return { success: true, errors: [] }
  };

  /**
   * Adds a row to the table.
   * @param {any} newData - JSON object with data about new row
   */
  const addRow = async (newData: any) => {
    const result = validateRow(newData);

    if (result.success) {
      setEntries((prevState: any) => {

        const formattedData = { ...newData };

        if (!formattedData.premium) {
          formattedData.premium = false;
        }

        return [...prevState, formattedData];
      });
    } else {
      rowSaveError(result.errors);
    }
  };

  /**
   * Updates an existing row of the table.
   * @param {any} newData - JSON object with data about new row
   * @param {any} oldData - JSON object with data about old row
   */
  const updateRow = async (newData: any, oldData: any) => {

    const newErrors = [...errors];

    // Clear error state after updating row
    const index = oldData.tableData.id;

    // Check if row has changed
    let changed = false;

    for (let k in newData) {
      if (newData[k] !== oldData[k]) {
        changed = true;
        break;
      }
    }

    if (changed) {
      const result = validateRow(newData);

      if (result.success) {
        setEntries((prevState: any) => {
          const state = [...prevState];

          state[index] = newData;

          // Clear outstanding errors
          if (newErrors[index]) {
            (newErrors as any)[index] = {};

            setErrors(newErrors);
          }

          return state;
        });
      } else {
        rowSaveError(result.errors);
      }
    }
  };

  /**
   * Deletes row from the table.
   * @param {any} oldData - JSON object with data about old row
   */
  const deleteRow = async (oldData: any) => {
    if (oldData?.tableData?.id !== undefined) {
      setEntries((prevState: any) => {

        // Clear error state after updating row
        const index = oldData?.tableData?.id;

        const newErrors = [...errors];

        if (newErrors[index]) {
          newErrors.splice(index, 1);
          setErrors(newErrors);
        }

        const state = [...prevState];
        state.splice(index, 1);

        return state;
      });
    } else {
      dialogBox.dispatch({
        open: true,
        success: false,
        message: 'An error occurred. Unable to delete row.'
      });
    }
  };

  // Layout of table columns
  const columns = [
    {
      title: 'Premium', width: '50px', field: 'premium', editComponent: (props: any) => (
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <Checkbox checked={props.value || false}
                        color="primary"
                        onChange={e => props.onChange((e.target as HTMLInputElement).checked)}/>
            </Grid>
          </Grid>
      )
    },
    {
      title: 'Name', field: 'name', width: '20%', editComponent: (props: any) => (
          <TextField variant='outlined'
                     fullWidth
                     autoFocus
                     name="name"
                     label="Name" inputProps={{ 'aria-label': 'Name' }}
                     className={classes.textField}
                     value={props.value || ''}
                     onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>
      )
    },
    {
      title: 'API key', field: 'apiKey', width: '20%', editComponent: (props: any) => (
          <TextField variant='outlined'
                     fullWidth
                     name="apiKey"
                     label="API key" inputProps={{ 'aria-label': 'API key' }}
                     className={classes.textField}
                     value={props.value || ''}
                     onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>
      )
    },
    {
      title: 'API key Secret', field: 'apiKeySecret', width: '20%', editComponent: (props: any) => (
          <TextField variant='outlined'
                     fullWidth
                     name="apiKeySecret"
                     label="API secret" inputProps={{ 'aria-label': 'API secret' }}
                     className={classes.textField}
                     value={props.value || ''}
                     onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>
      )
    },
    {
      title: 'Access token', field: 'accessToken', width: '20%', editComponent: (props: any) => (
          <TextField variant='outlined'
                     fullWidth
                     name="accessToken"
                     label="Access token" inputProps={{ 'aria-label': 'Access token' }}
                     className={classes.textField}
                     value={props.value || ''}
                     onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>
      )
    },
    {
      title: 'Access token secret',
      field: 'accessTokenSecret',
      width: '20%',
      editComponent: (props: any) => (
          <TextField variant='outlined'
                     fullWidth
                     name="accessTokenSecret"
                     label="Access secret" inputProps={{ 'aria-label': 'Access secret' }}
                     className={classes.textField}
                     value={props.value || ''}
                     onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>
      )
    },
    {
      title: 'Environment label (Premium)',
      field: 'environmentLabel',
      width: '20%',
      editComponent: (props: any) => (
          props.rowData.premium
              ? (<TextField variant='outlined'
                            fullWidth
                            name="environmentLabel"
                            label="Env label" inputProps={{ 'aria-label': 'Env label' }}
                            className={classes.textField}
                            value={props.value || ''}
                            onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>)
              : null
      )
    }
  ];

  const validateCSV = (data: any[]) => {
    let newEntries = [...entries];

    for (let i = 0; i < data.length; i++) {
      let row = data[i];

      for (let j = 0; j < Object.keys(row).length; j++) {
        let key = Object.keys(row)[j];

        // Ensures only premium is a boolean
        if (key === 'premium') {
          row[key] = row[key]?.toLowerCase() === 'true';
        } else {
          row[key] = row[key]?.toString().trim();
        }
      }

      const result = validateRow(row);

      if (!result.success) {
        return { success: false, errors: result.errors, row: i + 2 };
      }

      newEntries.push(row);
    }

    setEntries(newEntries);
    return { success: true };
  };

  const makeRequest = async () => {
    return await addTwitterCredentialsBulk(params.teamId, entries, mount.state.signal);
  };

  // Only manager can manage twitter credentials
  const isAddable = team.state.role === 'Manager';

  return <BulkTabs setup={setup || false}
                   SingleForm={DetailForm}
                   handleBack={handleBack}
                   handleNext={handleNext}
                   validateCSV={validateCSV}
                   makeRequest={makeRequest}
                   CSVHint={CSVHint}
                   isAddable={isAddable}
                   columns={columns}
                   addRow={addRow}
                   updateRow={updateRow}
                   deleteRow={deleteRow}
                   cancelUrl={cancelUrl}
                   setupCancelUrl={setupCancelUrl}
                   data={entries}
                   errors={errors}
                   setErrors={setErrors}
                   tableTitle="Group Credential Import"/>;
}
