/**
 * @file Delete panel for Twitter credentials detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { deleteTwitterCredentials } from '../../../utils/api';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads delete panel for Twitter credentials detail page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   credentialsId: credentials id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {boolean} dialogOpen - indicates whether the confirmation dialog is opened
   */
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Generates a URL to the Twitter credentials page for returning user after deleting the Twitter credentials.
  const credentialsUrl = makeUrl(pages['team-credentials'].url, params);

  /**
   * Opens the confirmation dialog.
   */
  const confirmDeletion = () => setDialogOpen(true);

  /**
   * Closes the confirmation dialog.
   */
  const cancelDeletion = () => {
    if (!isSubmitting) {
      setDialogOpen(false);
    }
  };

  /**
   * Makes API call to delete the current Twitter credentials, and redirects to the Twitter credentials page if successful.
   */
  const deleteManagedTwitterCredentials = async () => {
    setIsSubmitting(true);

    const result = await deleteTwitterCredentials(params.teamId, params.credentialsId, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      history.push(credentialsUrl);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
      <Grid item xs={6}>
        <Button variant="outlined" color="primary" onClick={confirmDeletion}>Delete</Button>
        <Dialog open={dialogOpen} onClose={cancelDeletion}>
          <DialogTitle>Delete Twitter credentials</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete the Twitter
              credentials?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" autoFocus onClick={cancelDeletion}
                    disabled={isSubmitting}>Cancel</Button>
            <LoadingButton color="secondary" onClick={deleteManagedTwitterCredentials}
                           disableAutoLoad={true} isSubmitting={isSubmitting}>Delete</LoadingButton>
          </DialogActions>
        </Dialog>
      </Grid>
  );
};
