/**
 * @file Followers form for new analysis followers page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { BlockerContext } from '../../../hooks/blocker';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, setLocalAnalysis } from '../../../utils/analysis';
import { listFollowers } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";
import FollowersTable from './followers-table';

/**
 * Loads followers form for new analysis followers page.
 *
 * @param {(step: number) => void} updateMaxStep
 */
export default ({ updateMaxStep }: { updateMaxStep: (step: number) => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {any[]} followerList - array containing metadata of followers
   */
  const [followerList, setFollowerList] = useState([] as any[]);

  /**
   * @type {boolean[]} selections - array containing selections of lexicon entries
   */
  const [selections, setSelections] = useState([] as boolean[]);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Generates a URL to the new analysis terms page for the next button.
  const moveNextUrl = makeUrl(pages['configure-analysis'].url, { ...params, stepNumber: 1 });

  // Generates a URL to the analysis overview page for the cancel button.
  const cancelUrl = makeUrl(pages['analysis'].url, params);

  /**
   * Sets up the new analysis followers form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);

    (async () => {
      const result = await listFollowers(params.teamId, params.analysisId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setFollowerList(result.data.accounts);
      if (analysis.followerSelections) {
        setSelections(analysis.followerSelections);
      } else {
        // Initializes the selections if not cached.
        const initializeSelections = [] as boolean[];
        result.data.accounts.forEach((_: any) => {
          initializeSelections.push(true);
        });
        setSelections(initializeSelections);
      }
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  /**
   * Saves the user input for the form to the browser local storage.
   */
  const saveForm = () => {
    const analysis = getLocalAnalysis(params.analysisId);
    analysis.followerList = followerList;
    analysis.followerSelections = selections;
    analysis.maxStepConfig = 1;
    updateMaxStep(1);
    setLocalAnalysis(params.analysisId, analysis);
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    setIsSubmitting(true);
    saveForm();
    blocker.dispatch({ url: url });
  };

  return isLoaded ? (
      <Container maxWidth='md'>
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={redirect(moveNextUrl)}>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.newAnalysisFollowers.instructions}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <FollowersTable followerList={followerList} selections={selections}
                              setSelections={setSelections}/>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={6}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item container xs={6} justify="flex-end">
              <LoadingButton type="submit" variant="contained" color="secondary"
                             isSubmitting={isSubmitting}>Next</LoadingButton>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
