/**
 * @file Layout for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import Layout from '../../common/layout';
import DetailForm from './detail-form';

/**
 * Loads detail form.
 */
export default () => (
    <Layout pageId="analysis-detail">
      <DetailForm/>
    </Layout>
);
