import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useState } from "react";
import preferencesMetadata from "../../../config/preferencesMetadata";
import { AuthContext } from "../../../hooks/auth";
import { MessageBarContext } from "../../../hooks/message-bar";
import { MountContext } from "../../../hooks/mount";
import { PreferenceContext } from "../../../hooks/preference";
import { setUserPreferences } from "../../../utils/api";
import LoadingButton from "../../common/loading/loading-button";
import LoadingContent from "../../common/loading/loading-content";

/**
 * @file Profile panel for user profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

export default () => {

  /**
   * Preference state and dispatcher
   *
   * We use a context to store this state so that it is persistent between pages
   */
  const preferenceContext = useContext(PreferenceContext);

  /**
   * @type {any} preferences - the local preferences, with any unsaved changes
   */
  const [localPreferences, setLocalPreferences] = useState({} as any);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  useEffect(() => {
    setLocalPreferences({ ...preferenceContext.state.preferences });
    setIsLoaded(true);
  }, [preferenceContext]);

  const updatePreferenceSelection = (preferenceCategoryID: string, preferenceID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPreferences = { ...localPreferences };

    newPreferences[preferenceCategoryID][preferenceID] = e.target.checked;

    setLocalPreferences(newPreferences);
  };

  const updatePreferences = async () => {
    setIsSubmitting(true);

    const result = await setUserPreferences(auth.state.userId, localPreferences, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });

    if (result.success) {
      // Update local changes if the operation was successful
      preferenceContext.dispatch({
        preferences: localPreferences,
        operationType: "sync" // we don't want the server to update with the changes twice
      });
    }

    setIsSubmitting(false);
  };

  return isLoaded
      ? (
          <>
            <Box my={2}>
              <Typography component="h2" variant="h5">Preferences</Typography>
            </Box>
            <Grid container spacing={2}>
              {
                Object.keys(preferencesMetadata()).map((preferenceCategoryID: string, index: number) => {

                  const preferenceCategory: any = (preferencesMetadata() as any)[preferenceCategoryID];

                  return <Grid item container xs={12} key={index}>
                    <Grid item xs={12}>
                      <Typography component="h3" variant="body1">
                        <b>
                          {preferenceCategory.label}
                        </b>
                      </Typography>
                    </Grid>
                    {
                      Object.keys(preferenceCategory.preferences).map((preferenceID: string, preferenceIndex: number) => {

                        const preference: any = preferenceCategory.preferences && preferenceCategory.preferences[preferenceID];

                        switch (preference.type) {
                          case "checkbox":
                            return <Grid item xs={12} key={preferenceIndex}>
                              <FormControlLabel label={preference.label}
                                                control={
                                                  <Checkbox
                                                      checked={localPreferences[preferenceCategoryID][preferenceID]}
                                                      color="primary"
                                                      onChange={updatePreferenceSelection(preferenceCategoryID, preferenceID)}
                                                  />
                                                }
                              />
                            </Grid>;
                          default:
                            return null;
                        }
                      })
                    }
                  </Grid>
                })
              }
              <Grid item xs={12}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item>
                    <LoadingButton type="submit"
                                   variant="contained"
                                   color="secondary"
                                   isSubmitting={isSubmitting}
                                   onClick={updatePreferences}
                    >
                      Update preferences
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      )
      : <LoadingContent height={200}/>;
}
