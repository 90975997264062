/**
 * @file Invite form for invitations page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../hooks/auth';
import { MountContext } from '../../../hooks/mount';
import { getNextPage, listInvites } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";
import BlankTable from './blank-table';
import InviteTable from './invite-table';

/**
 * Loads invite form for invitations page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {any[]} inviteList - array containing metadata of invitations
   */
  const [inviteList, setInviteList] = useState([]);

  /**
   * @type {string | null} nextPageUrl - cursor based next page URL of invitations
   */
  const [nextPageUrl, setNextPageUrl] = useState(null as string | null);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Sets up the invite form.
   */
  useEffect(() => {
    (async () => {
      const result = await listInvites(params.teamId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setInviteList(result.data);
      setNextPageUrl(result.next);
      setIsLoaded(true);
    })();
  }, [params.teamId, history, auth.dispatch, mount.state.signal]);

  /**
   * If the next page is available, adds a load more link to the bottom of the invitation table.
   * When user clicks the link, loads the next invitation items and add them to the current invitation table.
   */
  const loadMore = async () => {
    if (nextPageUrl) {
      const result = await getNextPage(nextPageUrl, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setInviteList(inviteList.concat(result.data));
      setNextPageUrl(result.next);
    }
  };

  // Loads the blank table if there is no invitation. Otherwise, loads the invitation table.
  return isLoaded
      ? inviteList.length > 0
          ? <InviteTable inviteList={inviteList} hasMore={!!nextPageUrl} loadMore={loadMore}/>
          : <BlankTable/>
      : <LoadingContent/>;
};
