/**
 * @file Profile form for analysis profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink, Prompt, useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { BlockerContext } from "../../../hooks/blocker";
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from '../../../hooks/team';
import { getAnalysis, updateAnalysis } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import LoadingPage from "../../common/loading/loading-page";
import DeletePanel from './delete-panel';

/**
 * Loads profile form for analysis profile page.
 *
 * @param {boolean} setup - Whether this component is being used as part of a stepper sequence
 */
export default ({ setup }: { setup?: boolean }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {string} analysisName - analysis name
   */
  const [analysisName, setAnalysisName] = useState('');

  /**
   * @type {string} description - analysis description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {boolean} isPrivate - indicates whether the analysis is private
   */
  const [isPrivate, setIsPrivate] = useState(false);

  /**
   * @type {string} authorId - user id of the analysis author
   */
  const [authorId, setAuthorId] = useState('0');

  /**
   * @type {string} author - user's name of the analysis author
   */
  const [author, setAuthor] = useState('');

  /**
   * @type {string} creationDate - creation date of the analysis
   */
  const [creationDate, setCreationDate] = useState('');

  /**
   * @type {string} lastModified - last modified date of the analysis
   */
  const [lastModified, setLastModified] = useState('');

  /**
   * @type {string} status - analysis status
   */
  const [status, setStatus] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the analysis name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateAnalysisName = (e: React.ChangeEvent<HTMLInputElement>) => setAnalysisName(e.target.value);

  /**
   * Updates the analysis description from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);

  /**
   * Updates the private flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateIsPrivate = (e: React.ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked);

  // Generates a URL to the analyses page for the cancel button.
  const analysisDashboardUrl = makeUrl(pages['analysis'].url, params);

  // Generates a URL to the analysis detail page.
  const detailUrl = makeUrl(pages['analysis-detail'].url, params);

  /**
   * Sets up the analysis profile form.
   */
  useEffect(() => {
    (async () => {
      const result = await getAnalysis(params.teamId, params.analysisId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setAnalysisName(result.data.name);
      setDescription(result.data.description);
      setIsPrivate(!result.data.public);
      setAuthorId(result.data.author?.id_str || '0');
      setAuthor(result.data.author ? `${result.data.author.first_name} ${result.data.author.last_name}` : '');
      setCreationDate(moment(result.data.created).format('YYYY-MM-DD, h:mm:ss A'));
      setLastModified(moment(result.data.updated).format('YYYY-MM-DD, h:mm:ss A'));
      setStatus(result.data.status);
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  /**
   * Updates the analysis, and loads message about request result.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const result = await updateAnalysis(params.teamId, params.analysisId, analysisName, description, isPrivate, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });

    if (setup) {
      const twitterUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 1 });
      redirect(twitterUrl)();
    } else {
      setIsSubmitting(false);
    }
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    blocker.dispatch({ url: url });
  };

  // Only author and manager can delete a analysis.
  // Manager can update a analysis.
  // Analyst can update a public analysis.
  // Author can update a public or private analysis.
  // Only the author and manager can change the private state of an analysis.
  const isDeletable = team.state.role !== 'Viewer' && (team.state.role === 'Manager' || authorId === auth.state.userId);
  const isUpdatable = team.state.role !== 'Viewer' && (team.state.role === 'Manager' || authorId === auth.state.userId || (!isPrivate && team.state.role === 'Analyst'));
  const isPrivatable = team.state.role !== 'Viewer' && (team.state.role === 'Manager' || authorId === auth.state.userId);

  return isLoaded ? (
      <Container maxWidth="xs">
        {
          setup &&
          <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        }
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            {
              setup && <Grid item xs={12}>
                <Alert severity="info">
                  {messages.pages.newAnalysisProfile.instructions}
                </Alert>
              </Grid>
            }
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Analysis name"
                             inputProps={{ 'aria-label': 'Analysis name' }}
                             name="analysis-name" value={analysisName} onChange={updateAnalysisName}
                             disabled={!isUpdatable}
                             validators={['required']}
                             errorMessages={['Analysis name is required']}/>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel label={
                <InlineTooltip
                    text={isPrivatable ? messages.tooltips.privateAnalysis : messages.tooltips.privateAnalysisDisabled}>
                  Private
                </InlineTooltip>
              } disabled={!isUpdatable || !isPrivatable}
                                control={<Checkbox checked={isPrivate} color="primary"
                                                   onChange={updateIsPrivate}/>}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Description"
                             inputProps={{ 'aria-label': 'Description' }}
                             name="description" value={description} onChange={updateDescription}
                             disabled={!isUpdatable}
                             multiline rows={5}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Author"
                             inputProps={{ 'aria-label': 'Author' }} name="author"
                             value={author} disabled/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Status"
                             inputProps={{ 'aria-label': 'Status' }} name="status"
                             value={status} disabled/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Creation date"
                             inputProps={{ 'aria-label': 'Creation date' }} name="creation-date"
                             value={creationDate} disabled/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Last modified"
                             inputProps={{ 'aria-label': 'Last modified' }} name="last-modified"
                             value={lastModified} disabled/>
            </Grid>
            <Grid item xs={12}/>
            {status !== 'Not started' && !setup && (
                <>
                  <Grid item container xs={12}>
                    <Grid item container xs={1} alignItems="center">
                      <OpenInNewIcon color="primary"/>
                    </Grid>
                    <Grid item container xs={11} alignItems="center">
                      <Link component={RouterLink} to={detailUrl}>Visit here for the analysis
                        detailed settings</Link>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}/>
                </>
            )}
            {
              setup
                  ? (<>
                    <Grid item xs={6}>
                      <LoadingButton variant="outlined" color="primary"
                                     href={analysisDashboardUrl}>Cancel</LoadingButton>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justify='flex-end' spacing={2}>
                        <Grid item>
                          <LoadingButton type="submit" variant="contained" color="secondary"
                                         isSubmitting={isSubmitting}>Next</LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>)
                  : (<>
                    <Grid item xs={12}>
                      <Grid container justify='flex-end' spacing={2}>
                        <Grid item>
                          <LoadingButton variant="contained" color="primary"
                                         href={analysisDashboardUrl}>Back</LoadingButton>
                        </Grid>
                        {
                          isUpdatable && (<Grid item>
                            <LoadingButton type="submit" variant="contained" color="secondary"
                                           isSubmitting={isSubmitting}>Update</LoadingButton>
                          </Grid>)
                        }
                      </Grid>
                    </Grid>
                  </>)
            }
          </Grid>
        </ValidatorForm>
        {isDeletable && !setup && (
            <>
              <Box my={4}>
                <Divider/>
              </Box>
              <DeletePanel/>
            </>
        )}
      </Container>
  ) : setup
      ? <LoadingPage minHeight="0"/>
      : <LoadingContent/>;
};
