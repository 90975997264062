/**
 * @file Layout for terms and conditions page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import pages from '../../../config/pages.json';
import Layout from '../../common/layout';
import LoadingButton from "../../common/loading/loading-button";

const useStyles = makeStyles(_ => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '@global': {
    li: {
      marginBottom: '15px'
    }
  }
}));

export default () => {
  const classes = useStyles();
  const styles = { root: classes.root };
  return (
      <Layout pageId="terms-and-conditions" title={null}>
        <Container maxWidth="md" classes={styles}>
          <Box display="flex" mb={2}>
            <Typography component="h1"
                        variant="h5">{pages['terms-and-conditions'].title}</Typography>
          </Box>

          <Typography component="h2" variant="h5">Introduction</Typography>
          <Typography component="p" variant="body1">
            Welcome to TIPS and Public Transit (referred to as “the
            Tool”)<br/><br/>
            These terms and conditions outline the rules and regulations for the using the Tool,
            located at <Link href={window.location.origin} target="_blank"
                             rel="noopener noreferrer">{window.location.origin}</Link>.<br/><br/>
            By accessing this website, we assume you accept these terms and conditions. Do not
            continue to use the
            tool if you do not agree to take all the terms and conditions stated on this
            page.<br/><br/>
            The following terminology applies to these Terms and Conditions, Privacy Statement and
            Disclaimer
            Notice and all
            Agreements: &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to you, the
            person log on this website and
            compliant to the Company’s terms and conditions. &quot;The
            Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;,
            refers to our Company. &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers
            to both the Client and ourselves. All terms refer
            to the offer, acceptance and consideration of payment necessary to undertake the process
            of our
            assistance to the Client in the most appropriate manner for the express purpose of
            meeting the Client’s
            needs in respect of provision of the Company’s stated services, in accordance with and
            subject to,
            prevailing law of Canada. Any use of the above terminology or other words in the
            singular, plural,
            capitalization and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">License</Typography>
          <Typography component="p" variant="body1">
            Except for tweet content, TIPS and Public Transit and/or its licensors
            own the
            intellectual property rights for all material on the Tool. All intellectual property
            rights are reserved.<br/><br/>
            You must not:
          </Typography>
          <ul>
            <li>
              Republish material from the Tool without copywrite notice
            </li>
            <li>
              Sell, rent or sub-license material from the Tool
            </li>
            <li>
              This Agreement shall begin on the date hereof. Our Terms and Conditions were created
              with the
              help of the Terms And Conditions Generator and the Privacy Policy Generator
            </li>
          </ul>
          <Typography component="p" variant="body1">
            Content on the Tool does not reflect the views and opinions of TIPS
            and Public Transit,
            its agents and/or affiliates. The content is extracted as–is from social media. To the
            extent permitted by
            applicable laws, We shall not be liable for the content or for any liability, damages or
            expenses caused
            and/or suffered as a result of any use of and/or posting of and/or appearance of the
            content used on
            this website.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">Hyperlinking to our Content</Typography>
          <Typography component="p" variant="body1">
            The following organizations may link to our Website without prior written approval:
          </Typography>
          <ul>
            <li>Government agencies</li>
            <li>Search engines</li>
            <li>News organizations</li>
          </ul>
          <Typography component="p" variant="body1">
            Online directory distributors may link to our Website in the same manner as they
            hyperlink to the
            Websites of other listed businesses; and<br/><br/>
            System wide Accredited Businesses except soliciting non-profit organizations, charity
            shopping malls,
            and charity fundraising groups which may not hyperlink to our Web site.<br/><br/>
            These organizations may link to our home page, to publications or to other Website
            information so long
            as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship,
            endorsement or
            approval of the linking party and its products and/or services; and (c) fits within the
            context of the
            linking party’s site.<br/><br/>
            We may consider and approve other link requests from the following types of
            organizations:
          </Typography>
          <ul>
            <li>Commonly-known consumer and/or business information sources</li>
            <li>dot.com community sites</li>
            <li>associations or other groups representing charities</li>
            <li>online directory distributors</li>
            <li>internet portals</li>
            <li>accounting, law and consulting firms</li>
            <li>educational institutions and trade associations</li>
          </ul>
          <Typography component="p" variant="body1">
            We will approve link requests from these organizations if we decide that: (a) the link
            would not make us
            look unfavorably to ourselves or to our accredited businesses; (b) the organization does
            not have any
            negative records with us; (c) the benefit to us from the visibility of the hyperlink
            compensates the
            absence of the Tool; and (d) the link is in the context of general resource information.<br/><br/>
            These organizations may link to our home page so long as the link: (a) is not in any way
            deceptive; (b)
            does not falsely imply sponsorship, endorsement or approval of the linking party and its
            products or
            services; and (c) fits within the context of the linking party’s site.<br/><br/>
            If you are one of the organizations listed in paragraph 2 above and are interested in
            linking to our
            website, you must inform us by using the “Contact Us” link within the Tool. Please
            include your name,
            your organization name, contact information as well as the URL of your site, a list of
            any URLs from
            which you intend to link to our Website, and a list of the URLs on our site to which you
            would like to link.
            Wait 2-3 weeks for a response.<br/><br/>
            Approved organizations may hyperlink to our Website as follows:
          </Typography>
          <ul>
            <li>By use of our corporate name</li>
            <li>By use of the uniform resource locator being linked to</li>
            <li>By use of any other description of our Website being linked to that makes sense
              within
              the
              context and format of content on the linking party’s site
            </li>
          </ul>
          <Typography component="p" variant="body1">
            No use of University of Toronto&#39;s logo or other artwork will be allowed for linking
            absent a
            trademark license agreement.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">IFrames</Typography>
          <Typography component="p" variant="body1">
            Without prior approval and written permission, you may not create frames around our
            Webpages that
            alter in any way the visual presentation or appearance of our Website.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">Content Liability</Typography>
          <Typography component="p" variant="body1">
            We shall not be hold responsible for any content that appears on your Website. You agree
            to protect
            and defend us against all claims that is rising on your Website. No link(s) should
            appear on any Website
            that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise
            violates, or
            advocates the infringement or other violation of, any third party rights.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">Your Privacy</Typography>
          <Typography component="p" variant="body1">
            Please read our <Link href={pages['privacy'].url} target="_blank"
                                  rel="noopener noreferrer">Privacy Policy</Link>
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">Reservation of Rights</Typography>
          <Typography component="p" variant="body1">
            We reserve the right to request that you remove all links or any particular link to our
            Website. You
            approve to immediately remove all links to our Website upon request. We also reserve the
            right to
            amend these terms and conditions and it’s linking policy at any time. By continuously
            linking to our
            Website, you agree to be bound to and follow these linking terms and conditions.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">Removal of links from our website</Typography>
          <Typography component="p" variant="body1">
            If you find any link on our Website that is offensive for any reason, you are free to
            contact and inform us
            any moment. We will consider requests to remove links, but we are not obligated to or so
            or to respond
            to you directly.<br/><br/>
            We do not ensure that the information on this website is correct, we do not warrant its
            completeness or
            accuracy; nor do we promise to ensure that the website remains available or that the
            material on the
            website is kept up to date.
          </Typography>

          <br/>
          <Typography component="h2" variant="h5">Disclaimer</Typography>
          <Typography component="p" variant="body1">
            To the maximum extent permitted by applicable law, we exclude all representations,
            warranties and
            conditions relating to our website and the use of this website. Nothing in this
            disclaimer will:
          </Typography>
          <ul>
            <li>Limit or exclude our or your liability for death or personal injury</li>
            <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation
            </li>
            <li>Limit any of our or your liabilities in any way that is not permitted under
              applicable law
            </li>
            <li>Exclude any of our or your liabilities that may not be excluded under applicable
              law.
            </li>
          </ul>
          <Typography component="p" variant="body1">
            The limitations and prohibitions of liability set in this Section and elsewhere in this
            disclaimer: (a) are
            subject to the preceding paragraph; and (b) govern all liabilities arising under the
            disclaimer, including
            liabilities arising in contract, in tort and for breach of statutory duty.
          </Typography>

          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <LoadingButton href={pages['home'].url} variant="contained" color="primary">Return
                home</LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Layout>
  );
};
