/**
 * @file Followers table for new analysis followers page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React from 'react';

/**
 * Loads followers table for new analysis followers page.
 * @param {any[]} followerList - array containing metadata of followers
 * @param {any} selections - dictionary of follower selections
 * @param {React.Dispatch<React.SetStateAction<any>>} setSelections - callback of setting dictionary of follower selections
 */
export default ({ followerList, selections, setSelections }: { followerList: any[], selections: any, setSelections: React.Dispatch<React.SetStateAction<any>> }) => {
  /**
   * Updates the follower selections from checkbox on change.
   * @param {number} index - index of the follower selections
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateSelections = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSelections = [...selections];
    newSelections[index] = e.target.checked;
    setSelections(newSelections);
  };

  return (
      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Select</TableCell>
              <TableCell align="left">Account</TableCell>
              <TableCell align="center">Verified</TableCell>
              <TableCell align="center">No. of followers</TableCell>
              <TableCell align="center">No. of friends</TableCell>
              <TableCell align="center">No. of tweets</TableCell>
              <TableCell align="center">Creation date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {followerList.map((follower, index) => (
                <TableRow hover key={`follower-${index}`}>
                  <TableCell compact-title="Select" align="center">
                    <Checkbox checked={selections[index]} color="primary"
                              inputProps={{ 'aria-label': `Select ${follower.screen_name}` }}
                              onChange={updateSelections(index)}/>
                  </TableCell>
                  <TableCell compact-title="Account" align="left">{follower.screen_name}</TableCell>
                  <TableCell compact-title="Verified"
                             align="center">{follower.verified ? 'Yes' : 'No'}</TableCell>
                  <TableCell compact-title="No. of followers"
                             align="center">{follower.followers_count}</TableCell>
                  <TableCell compact-title="No. of friends"
                             align="center">{follower.friends_count}</TableCell>
                  <TableCell compact-title="No. of tweets"
                             align="center">{follower.statuses_count}</TableCell>
                  <TableCell compact-title="Creation date"
                             align="center">{moment(follower.created_at).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};
