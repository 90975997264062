/**
 * @file Layout for configure analysis
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { BlockerProvider } from '../../../hooks/blocker';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import StepperForm from './stepper-form';

/**
 * Sets up the page layout and loads profile form.
 */
export default () => (
    <Layout pageId={'configure-analysis'}>
      <MessageBarProvider>
        <BlockerProvider>
          <StepperForm/>
        </BlockerProvider>
      </MessageBarProvider>
    </Layout>
);
