/**
 * @file Stepper form for new analysis
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { default as React, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from "../../../config/pages.json";
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from "../../../hooks/mount";
import { getLocalAnalysis } from "../../../utils/analysis";
import { getAnalysis } from "../../../utils/api";
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";
import StepperForm from '../../common/stepper-form';
import ProfileForm from '../new-analysis-profile/profile-form';
import ReviewForm from '../new-analysis-review/review-form';
import SemanticForm from '../new-analysis-semantic/semantic-form';
import SocialForm from '../new-analysis-social/social-form';
import TemporalForm from '../new-analysis-temporal/temporal-form';
import TwitterForm from '../new-analysis-twitter/twitter-form';
import ExistingProfileForm from '../profile/profile-form';

/**
 * Loads stepper form for new analysis.
 */
export default () => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {number} maxStep - Highest step number user can access
   */
  const [maxStep, setMaxStep] = useState(5);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @param {number} newMaxStep
   */
  const updateMaxStep = (newMaxStep: number) => {
    if (newMaxStep > maxStep) {
      setMaxStep(newMaxStep);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return params.analysisId
            ? <ExistingProfileForm setup={true}/>
            : <ProfileForm/>;
      case 1:
        return <TwitterForm updateMaxStep={updateMaxStep}/>;
      case 2:
        return <MuiPickersUtilsProvider utils={MomentUtils}>
          <TemporalForm updateMaxStep={updateMaxStep}/>
        </MuiPickersUtilsProvider>;
      case 3:
        return <SemanticForm updateMaxStep={updateMaxStep}/>;
      case 4:
        return <SocialForm updateMaxStep={updateMaxStep}/>;
      case 5:
        return <ReviewForm/>;
      default:
        return <p>If you're seeing this... something went wrong.</p>;
    }
  };

  const steps = ['Profile', 'Twitter', 'Temporal', 'Semantic', 'Social', 'Review'];

  /**
   * Update max step and verify analysis is ready for setup
   */
  useEffect(() => {
    (async () => {
      if (params.analysisId) {
        const analysis = getLocalAnalysis(params.analysisId);

        if (analysis.maxStep) {
          setMaxStep(analysis.maxStep)
        } else {
          setMaxStep(1);
        }

        // Make sure this analysis isn't already submitted
        const result = await getAnalysis(params.teamId, params.analysisId, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        if (result.data.status !== 'Not started') {
          const moveNextUrl = makeUrl(pages['analysis'].url, params);
          history.replace(moveNextUrl);
        } else {
          setIsLoaded(true);
        }
      } else {
        setMaxStep(1);
        setIsLoaded(true);
      }
    })();
  }, [params, history, auth.dispatch, mount.state.signal]);

  return isLoaded
      ? <StepperForm getStepContent={getStepContent} steps={steps} maxStep={maxStep}
                     pageId="new-analysis"/>
      : <LoadingContent/>;
};
