/**
 * @file Layout for team dashboard
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import Dashboard from './dashboard';

/**
 * Sets up the page layout and loads dashboard.
 */
export default () => (
    <Layout pageId={'team'} title={null}>
      <MessageBarProvider>
        <Dashboard/>
      </MessageBarProvider>
    </Layout>
);
