/**
 * @file Centralized message database
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import InlineTooltip from "../components/common/inline-tooltip";
import pages from "./pages.json";

const tooltips = {
  privateAnalysisDisabled: "You must be the author with edit permission or a Manager to change the visibility of this analysis.",
  privateLexiconDisabled: "You must be the author with edit permission or a Manager to change the visibility of this lexicon.",
  privateAnalysis: "The details and results of private analyses are only visible to you and Managers.",
  privateLexicon: "The details of private lexicons are only visible to you and Managers.",
  lexiconTerms: "Tweets are assigned to categorized based on the presence of terms in the tweet text. The terms are not case sensitive and are automatically lemmatized to facilitate matching",
  term: "The most common terms that appear in tweets at the agency during the analysis date range",
  associatedTerm: "The most common term that appears alongside the main term in tweets at the agency during the analysis date range",
  specialDayInsights: "Identify and analyze days with higher than usual tweet volumes by comparing hourly tweet volumes to other days in the same month",
  semanticSentimentInsights: "Label tweets using automatic sentiment analysis and break down the discussion topics by sentiments",
  networkAnalytics: "Analyze the 1.5–degree egocentric social network around the target account and determine the network attribute",
  modularityMaximization: "Apply the Louvain algorithm to the social network graph to identify communities. Only the largest community will be analyzed",
  informationFlow: "Apply the InfoMap algorithm to the social network graph to identify communities. The algorithm simulates random walks to identify communities where information persists. Communities are aggregated into small, medium, and large based on their node count",
  fullArchiveSearch: "Use the full archive Twitter Premium endpoint to search tweets. Please note that this search might use up all the monthly requests of your credentials.",
  thirtyDaySearch: "Use the 30 day archive Twitter Premium endpoint to search tweets. Please note that this search might use up all the monthly requests of your credentials.",
  density: "Indicates the interconnectedness between nodes, a value of 1 means that all nodes are connected to each other, and a value of 0 means there are no connections between any of the nodes. \n" +
      "A dense network indicates that an entire community likely shares the same interests where the opinions are possibly shaped by common experiences or by the propagation of ideas. This is because dense social networks are typically associated with good flow of information. On an online platform like Twitter, such connections can be interpreted as close personal or professional connections. On the other hand, a network with low density pertains to a sparse set of individuals who independently share the same interests. This is most likely due to similar independent experiences or due to opinions that are shaped due to information coming outside of the network.\n" +
      "The density of a network is best evaluated comparatively whether with respect to different networks, or the same network but at different points in time. Benchmarking social network densities can explain the maturity of a network, as new and small networks have high densities. However, the density decreases as the network grows over time with the introduction of new members with few connections to the existing nodes.",
  diameter: "Corresponds to the length of the shortest path that connects the two most distant nodes in the network. Small world networks are characterized by relatively small diameters.",
  edgeCount: "Corresponds to the number of relationships that exists within the network. For this social network, it corresponds to the followership count in the network. While the number of edges on its own is not significant, it can be used to compare the connectivity of different networks of similar sizes. However, and more importantly, the edge count affects other qualities of the network such as the density, shortest path, diameter, and average degrees, all of which are indicators of network connectivity.",
  nodeCount: "Corresponds to the number of accounts in the network. In other words, it is the number of accounts that tweet about the same transit discussion topics. The node count is the primary attribute to determine the popularity of certain discussion topics. This is because the number of tweets is not always the best indicator of popular topics as a small, yet vocal groups can generate the same number of tweets as a broader audience.",
  averagePathLength: "Defined as the average of the shortest paths between all possible node pairs in a network. The average path length gauges the ease of flow of information within a network, where highly connected networks have low average path lengths. Small world networks are characterized by relatively short average path lengths.",
  averageTotalDegree: "The degree of a node is the number of edges associated with it. The average total degree is the sum of the average incoming follower connection and the average outgoing follower connection. It serves as an indicator of the level of interaction in the network.",
  averageInOutDegree: "The average in–degree corresponds to the average number of followers an account has in the network and is calculated as the average of the number of edges directed towards a node for all nodes in the network. Similarly, the average out–degree is the average number of accounts that an account in the network follows. The average out–degree is calculated as the average of the number of edges directed away from a node for all nodes in the network.",
  viewerRole: "Can view analyses, reports, and basic information about the team",
  analystRole: "Can create, modify, view and delete analyses, lexicons, and can view basic information about the team",
  managerRole: "Can fully perform any action available for the team",
};

const disclaimers = {
  bar: <Typography component="p" variant="body1">The tool is a work in progress research project
    that analyzes Twitter content as is.
    For more information on the limitations of the work, <Link component={RouterLink}
                                                               to={pages['disclaimers'].url}
                                                               style={{
                                                                 textDecorationLine: 'underline',
                                                                 color: 'white'
                                                               }}>
      click here
    </Link>.
  </Typography>,
  automaticSentimentAnalysis: "The automatic sentiment analysis engine is contextualized to the domain of public transit; however, the algorithm’s performance does not match that of a human interpreter. This is attributed to factors such as lack of context, typing errors, slang, sarcasm, and irony.",
  topicDetection: "Topic detection is based on term occurrence, which is prone to misclassifications due to homographs. ",
  communityDetection: "Community detection is based on common network algorithms that are not specific to social network analysis or to public transit. ",
  specialDays: "Special days are identified based on higher than normal tweet volumes at certain times of the day. These peaks are commonly associated with disruptions or announcements; however, they can also occur coincidentally with no relation to transit issues. "
};

const pagesMessages = {
  security: {
    password: <>
      It's a good idea to choose a strong password that you don't use for other accounts.
    </>,
    email: <>
      Please enter your current password to update your email. The update won't be finalized until
      your new email is confirmed.
    </>
  },
  teams: {
    description: <>
      This table lists all the teams you are part of. Select a team to see more details and create
      new analyses.
    </>
  },
  dashboard: {
    description: <>
      This dashboard shows recent analyses from across all your teams. To view or modify an
      analysis, select from the list below.
    </>
  },
  teamDashboard: {
    description: <>
      This dashboard shows recent analysis from this team. To view or modify an analysis, select
      from the list below.
    </>
  },
  newAnalysisProfile: {
    instructions: <>
      Enter a name and description for your analysis
    </>
  },
  newAnalysisTwitter: {
    twitterAccount: <>
      Select the Twitter account of the transit agency to be analyzed
    </>,
    hashtags: <>
      Type in any relevant hashtags separated by commas<br/>
      For example: <code>#subway, #transit, #delay</code>
    </>,
    twitterCredentials: <>
      Select the credentials you wish to use to extract data from Twitter
    </>,
    timeLimit: <>
      Tweets captured by account mentions or hashtags are limited to a week back from the
      time of request for standard accounts and can be increased by upgrading to premium account.
      More information is provided
      at <a href="https://developer.twitter.com/en/products/twitter-api" target="_blank" rel="noopener noreferrer">
        Twitter API | Products | Twitter Developer
      </a>
    </>,
  },
  newAnalysisTemporal: {
    instructions: <>
      Enter a date range for the analysis as well as temporal filters. Only tweets that fall
      within the date range and satisfy the temporal filters will be included in the analysis.
    </>,
    specialDays: <>
      Only entire months that fall within the selected date range will be considered for special day
      insights.
    </>
  },
  newAnalysisSemantic: {
    instructions: <>
      Select a lexicon to view semantic categories
    </>,
    tableInstruction: <>
      Select the categories to be used as semantic filters. Only tweets that pertain to selected
      categories will be included in the analysis.
    </>
  },
  newAnalysisSocial: {
    instructions: <>
      Select the account and community filters. Only tweets by accounts that satisfy the social
      filters will be included in the analysis.
    </>
  },
  newAnalysisReview: {
    instructions: <>
      Before starting the analysis, please review the settings to confirm.
    </>
  },
  newAnalysisFollowers: {
    instructions: <>
      This table lists the accounts that have tweeted most at the agency during the analysis date
      range.<br/><br/>
      Unselect the accounts you wish to exclude from the analysis. Tweets by these accounts will
      also be are excluded from the analysis.
    </>
  },
  newAnalysisTerms: {
    instructions: <>
      This table lists the most common terms that appear in tweets at the agency during the
      analysis date range.<br/><br/>
      Unselect the terms you wish to exclude from the analysis. Tweets that contain an
      unselected <InlineTooltip text={tooltips.term}>
      term
    </InlineTooltip> will be excluded from the analysis.<br/><br/>
      Unselecting an associated term will only remove tweets that contain the <InlineTooltip
        text={tooltips.associatedTerm}>
      associated term
    </InlineTooltip> and
      the main term from the first column.
    </>
  },
  twitterCredentials: {
    description: <>
      This table lists all the Twitter credentials that are available to this team.<br/><br/>
      These credentials may be used to extract data from Twitter when a new analysis is run.
      Select a set of Twitter credentials to see more details or to make changes.
    </>,
    premium: <>
      Check this box if the credentials are associated with a premium Twitter API. Click <Link
        href="https://developer.twitter.com/en/products/twitter-api/premium-apis" target="_blank"
        rel="noopener noreferrer">here</Link> for more information.
    </>,
    envLabel: <>
      The environment label can be found on <Link
        href="https://developer.twitter.com/en/account/environments" target="_blank"
        rel="noopener noreferrer">this</Link> page.
    </>
  },
  newTwitterCredentials: {
    description: <>
      You can manually input your Twitter credentials or use the group upload feature to upload
      multiple credentials from a single file.
    </>
  },
  lexicons: {
    description: <>
      This table lists all the lexicons that are available to this team.<br/><br/>
      These lexicons contain categories that act as semantic filters in an analysis.
      Select a lexicon to see more details or to make changes.
    </>
  },
  lexiconDetails: {
    description: <>
      First, input the properties of the lexicon and then add or remove categories
      and <InlineTooltip text={tooltips.lexiconTerms}>
      terms
    </InlineTooltip> to modify the lexicon.
    </>
  },
  teamProfile: {
    description: <>
      Update your team settings by modifying these fields.
      For all analyses by this team, tweet timestamps will be localized to the selected time zone.
    </>
  },
  teamMembers: {
    description: <>
      This table lists all the members in your team.
      Select a member to see more details or to make changes.
    </>
  },
  userProfile: {
    instructions: <>
      Update your account by modifying these fields.
    </>
  }
};

export default {
  navigationBlocker: "You have uncommitted changes. Are you sure you want to leave?",
  tooltips: tooltips,
  disclaimers: disclaimers,
  pages: pagesMessages
}
