/**
 * @file Terms form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';

/**
 * Loads terms form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => {
  // Remember to update this if columns are added/removed to accomodate mobile
  const columnTitles = ['Category 1', 'Category 2.1', 'Category 2.2'];

  return (<Container maxWidth="md">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h6" gutterBottom>Terms</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={props => <Paper variant="outlined" {...props} />}>
          <Table className="collapse-table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Category 1</TableCell>
                <TableCell align="left">Category 2.1</TableCell>
                <TableCell align="left">Category 2.2</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {analysisDetail.terms.map((term: any, index: number) => (
                  <TableRow hover key={`term-${index}`}>
                    {Object.keys(term).map((name: string, category: number) => (
                        <TableCell compact-title={columnTitles[category]} align="left"
                                   key={`category-${category}`}>
                          <FormControlLabel label={term[name].term}
                                            control={<Checkbox checked={term[name].keep}
                                                               color="primary" disabled={true}/>}/>
                        </TableCell>
                    ))}
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </Container>);
};
