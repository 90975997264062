/**
 * @file Dynamically sets the page title
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { useEffect } from "react";

/**
 * @param {any} children - children components
 * @param {string} title - page title
 */
export default ({ children, title }: { children: any, title: string }) => {

  /**
   * Set the website title to the website name and page title for accessibility
   */
  useEffect(() => {
    const websiteTitle = process.env.REACT_APP_TITLE;
    let titleComponents: string[] = [];

    if (websiteTitle !== undefined) {
      titleComponents.push(websiteTitle);
    }

    if (title !== undefined) {
      titleComponents.push(title);
    }

    if (titleComponents.length > 0) {
      document.title = titleComponents.join(' - ');
    }
  }, [title]);

  return children;
}
