/**
 * @file Bulk action form
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import { VALID_MIME_TYPES } from '../../../config/csv';
import { DialogBoxContext } from "../../../hooks/dialog";
import LoadingButton from '../../common/loading/loading-button';
import BulkTable from './bulk-table';

const useStyles = makeStyles(_ => ({
  form: {
    width: '100%'
  },
  alert: {
    overflow: 'auto'
  }
}));

/**
 * @param {boolean} setup - whether this component is part of a setup sequence
 * @param {() => void} handleNext - callback to step forward in setup
 * @param {() => void} handleBack - callback to step backwards in setup
 * @param {(data: any) => { success: boolean, errors?: (string | undefined)[], row?: number }} validateCSV - validates csv data and returns formatted data
 * @param {() =>  Promise<{ success: boolean, message: string, data: any, next: string, previous: string }>} makeRequest - makes API call and returns data
 * @param {any} CSVHint - component to be displayed next to CSV upload button
 * @param {boolean} isAddable - whether table is read only
 * @param {any[]} columns - array of table column metadata
 * @param {(newData: any) => Promise<any>} addRow addRow - callback to handle adding new row
 * @param {(newData: any, oldData: any) => Promise<any>} updateRow - callback to handle updating row
 * @param {(oldData: any) => Promise<any>} deleteRow - callback to handle deleting row
 * @param {any[]} data
 * @param {any[]} errors
 * @param {React.Dispatch<React.SetStateAction<[]>>} setErrors
 * @param {string} tableTitle
 */
export default ({ setup, handleBack, handleNext, validateCSV, makeRequest, CSVHint, isAddable, columns, addRow, updateRow, deleteRow, cancelUrl, setupCancelUrl, data, errors, setErrors, tableTitle }:
                    {
                      setup: boolean,
                      handleBack: () => void,
                      handleNext: () => void,
                      validateCSV: (data: any) => { success: boolean, errors?: (string | undefined)[], row?: number },
                      makeRequest: () => Promise<{ success: boolean, message: string, data: any, next: string, previous: string }>,
                      CSVHint?: any,
                      isAddable: boolean,
                      columns: any[],
                      addRow: (newData: any) => Promise<any>,
                      updateRow: (newData: any, oldData: any) => Promise<any>,
                      deleteRow: (oldData: any) => Promise<any>,
                      cancelUrl: string,
                      setupCancelUrl: string,
                      data: any[],
                      errors: any[],
                      setErrors: React.Dispatch<React.SetStateAction<any>>,
                      tableTitle: string
                    }) => {

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Dialog box state and dispatcher
   */
  const dialogBox = useContext(DialogBoxContext);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * @type {boolean} lockSubmit - prevent form submission when there are outstanding validation issues
   */
  const [lockSubmit, setLockSubmit] = useState(false);

  /**
   * Triggers API call via callback
   */
  const submitForm = async () => {
    setIsSubmitting(true);

    const result = await makeRequest();

    if (result.success) {
      if (setup && handleNext) {
        handleNext();
      } else {
        history.push(cancelUrl);
      }
    } else {
      setErrors(result.data.errors);
      setIsSubmitting(false);
    }
  };

  /**
   * Options for CSV parser
   */
  const parseOptions = {
    dynamicTyping: false,
    skipEmptyLines: true,
    header: true,
    transformHeader: (header: string) => header.trim()
  };

  /**
   * Simulates a click on the input element to open file upload dialog
   */
  const triggerCSVImport = () => {
    const csvParserElement = document.getElementById('csv-parser') as any;
    if (csvParserElement) {
      csvParserElement.click();
    }
  };

  /**
   * Reads array of data from CSV parser
   * @param {any} data - dictionary of data
   * @param {IFileInfo} fileInfo
   */
  const importCSV = (data: any, fileInfo: IFileInfo) => {

    if (!VALID_MIME_TYPES.includes(fileInfo.type)) {

      dialogBox.dispatch({
        open: true,
        success: false,
        body: (
            <Typography>
              The file "<code>{fileInfo.name}</code>" is invalid. You must upload
              a <code>.csv</code> file.
            </Typography>
        )
      });

      return;
    }

    // Clear field first
    const csvParserElement = document.getElementById('csv-parser') as any;
    if (csvParserElement) {
      csvParserElement.value = "";
    }

    try {
      const result = validateCSV(data);

      if (!result.success) {
        dialogBox.dispatch({
          open: true,
          success: false,
          body: (
              <>
                <Typography>
                  Unable to import CSV due to {result.errors?.length === 1 && ' a '} validation
                  error{result.errors?.length !== 1 && 's'} on row {result.row}:
                </Typography>
                <ul>
                  {
                    result.errors?.map((error) => (
                        <li key={error}>{error}</li>
                    ))
                  }
                </ul>
              </>
          ),
          footer: <CSVHint/>
        });
      }
    } catch (e) {
      dialogBox.dispatch({
        open: true,
        success: false,
        message: `Unable to import CSV (${e.toString()})`
      });
    }
  };

  // Update form submission lock
  useEffect(() => {

    let locked = false;

    for (let k = 0; k < errors.length; k++) {
      if (errors[k] && Object.keys(errors[k]).length !== 0) {
        locked = true;
        break;
      }
    }

    setLockSubmit(locked);

  }, [errors, setLockSubmit]);

  const editable = { onRowAdd: addRow, onRowUpdate: updateRow, onRowDelete: deleteRow };

  const classes = useStyles();

  return <ValidatorForm onSubmit={submitForm} className={classes.form}>
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        {
          CSVHint && (<Grid item xs={12} md={8}>
            <CSVHint/>
          </Grid>)
        }
        <Grid item xs={12} md={4}>
          <Grid container justify='flex-end' spacing={2}>
            <Grid item>
              <CSVReader inputId="csv-parser" inputStyle={{ display: "none" }}
                         parserOptions={parseOptions} onFileLoaded={importCSV}/>
              <Button variant="contained" color="secondary" onClick={triggerCSVImport}>Import from
                CSV</Button>
            </Grid>
          </Grid>
        </Grid>

        <BulkTable data={data} errors={errors} columns={columns} editable={editable}
                   tableTitle={tableTitle}/>

        <Grid item xs={12}/>

        {
          lockSubmit && (<Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="error" className={classes.alert}>
                You must resolve all validation errors before submitting.
              </Alert>
            </Grid>
          </Grid>)
        }

        {
          data.length === 0 && (<Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="warning" className={classes.alert}>
                You must have at least one entry to submit.
              </Alert>
            </Grid>
          </Grid>)
        }

        <Grid item xs={12}/>
        {
          setup
              ? (<>
                <Grid item xs={3}>
                  <LoadingButton variant="outlined" color="primary"
                                 href={setupCancelUrl}>Cancel</LoadingButton>
                </Grid>
                <Grid item xs={9}>
                  <Grid container justify='flex-end' spacing={2}>
                    < Grid item>
                      <Button onClick={handleBack}>
                        Back
                      </Button>
                    </Grid>
                    < Grid item>
                      <Button onClick={handleNext} variant="outlined">
                        Skip
                      </Button>
                    </Grid>
                    < Grid item>
                      <LoadingButton type="submit" variant="contained" color="secondary"
                                     disabled={lockSubmit || data.length === 0}
                                     isSubmitting={isSubmitting}>Next</LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </>)
              : isAddable && (
              <Grid item xs={12}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item>
                    <LoadingButton variant="contained" color="primary"
                                   href={cancelUrl}>Cancel</LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton type="submit" variant="contained" color="secondary"
                                   isSubmitting={isSubmitting}
                                   disabled={lockSubmit || data.length === 0}>Add</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>)
        }
      </Grid>
    </Container>
  </ValidatorForm>
};
