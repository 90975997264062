/**
 * @file Twitter form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';

/**
 * Loads twitter form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Twitter</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField variant='outlined' fullWidth label="Twitter account"
                     inputProps={{ 'aria-label': 'Twitter account' }}
                     name="twitter-account" value={analysisDetail.twitter_handle || ''}
                     disabled={true}/>
        </Grid>
        <Grid item xs={12}>
          <TextField variant='outlined' fullWidth label="Hashtags"
                     inputProps={{ 'aria-label': 'Hashtags' }}
                     name="hashtags" value={analysisDetail.hashtags.join(', ') || ''}
                     disabled={true} multiline rows={3}/>
        </Grid>
        <Grid item xs={12}>
          <TextField variant='outlined' fullWidth label="Twitter credentials"
                     inputProps={{ 'aria-label': 'Twitter credentials' }}
                     name="twitter-credentials"
                     value={analysisDetail.credentials.map((credentials: any) => credentials.name).join(', ') || ''}
                     disabled={true} multiline rows={3}/>
        </Grid>
      </Grid>
    </Container>
);
