/**
 * @file Layout for video guides page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import pages from '../../../config/pages.json';
import Layout from '../../common/layout';
import LoadingButton from "../../common/loading/loading-button";

const useStyles = makeStyles(_ => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '@global': {
    li: {
      marginBottom: '15px'
    }
  },
  video: {
    width: '100%',
    maxHeight: '600px',
    height: '50vw'
  }
}));

export default () => {
  const classes = useStyles();
  const styles = { root: classes.root };
  return (
      <Layout pageId="guides" title={null}>
        <Container maxWidth="md" classes={styles}>
          <Box display="flex" mb={2}>
            <Typography component="h1" variant="h5">{pages['guides'].title}</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6">
                Team Creation and Management (<Link href="transcripts/TeamCreation.txt"
                                                    target="_blank" rel="noopener noreferrer">
                Transcript
              </Link>)
              </Typography>
              <iframe src="https://www.youtube.com/embed/D0gopBPdk8s"
                      title="Team Creation and Management"
                      className={classes.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6">
                Analysis Creation (<Link href="transcripts/AnalysisCreation.txt" target="_blank"
                                         rel="noopener noreferrer">
                Transcript
              </Link>)
              </Typography>
              <iframe src="https://www.youtube.com/embed/kDh1D4C8wDU"
                      title="Analysis Creation"
                      className={classes.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6">
                Reporting (<Link href="transcripts/Reporting.txt" target="_blank"
                                 rel="noopener noreferrer">
                Transcript
              </Link>)
              </Typography>
              <iframe src="https://www.youtube.com/embed/zsQGrcFvpE4"
                      title="Reporting"
                      className={classes.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </Grid>
          </Grid>


          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <LoadingButton href={pages['home'].url} variant="contained" color="primary">Return
                home</LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Layout>
  );
};
