/**
 * @file Social form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';

/**
 * Loads social form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => {
  const networkList: any = {
    "entire-network": "Entire network",
    "influential-accounts": "Influential accounts only"
  };

  const communitiesList: any = {
    "none": "None",
    "modularity-maximization": "Modularity maximization",
    "information-flow": "Information flow"
  };

  return (
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" gutterBottom>Social</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField variant='outlined' fullWidth label="Network"
                       inputProps={{ 'aria-label': 'Network' }}
                       name="network" value={networkList[analysisDetail.network] || ''}
                       disabled={true}/>
          </Grid>
          {analysisDetail.network_threshold !== undefined && (
              <Grid item xs={4}>
                <TextField variant='outlined' fullWidth label="Threshold"
                           inputProps={{ 'aria-label': 'Threshold' }}
                           name="threshold" value={analysisDetail.network_threshold || ''}
                           disabled={true}/>
              </Grid>
          )}
          <Grid item xs={12}>
            <TextField variant='outlined' fullWidth label="Comumunities"
                       inputProps={{ 'aria-label': 'Comumunities' }}
                       name="communities" value={communitiesList[analysisDetail.community] || ''}
                       disabled={true}/>
          </Grid>
        </Grid>
      </Container>
  );
};
