/**
 * @file Profile form for team member profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useContext, useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from '../../../hooks/team';
import { getTeamMember, updateTeamMember } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import DeletePanel from './delete-panel';

/**
 * Loads profile form for team member profile page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   userId: user id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * @type {string} username - team member's username
   */
  const [username, setUsername] = useState('');

  /**
   * @type {string} firstName - team member's first name
   */
  const [firstName, setFirstName] = useState('');

  /**
   * @type {string} lastName - team member's last name
   */
  const [lastName, setLastName] = useState('');

  /**
   * @type {string} email - team member's email
   */
  const [email, setEmail] = useState('');

  /**
   * @type {string} memberRole - team member's role
   */
  const [memberRole, setMemberRole] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Generates a URL to the members page for the cancel button.
  const membersUrl = makeUrl(pages['team-members'].url, params);

  /**
   * Updates the member role from the text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateMemberRole = (e: React.ChangeEvent<HTMLSelectElement>) => setMemberRole(e.target.value);

  /**
   * Sets up profile form.
   * Makes API call to get data about member and role of current user.
   * If unsuccessful, redirect to error page.
   * Otherwise, populate variables and set isLoaded to true to enable the page to load.
   */
  useEffect(() => {
    (async () => {
      const result = await getTeamMember(params.teamId, params.userId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setUsername(result.data.username);
      setFirstName(result.data.first_name);
      setLastName(result.data.last_name);
      setEmail(result.data.email);
      setMemberRole(result.data.role);

      setIsLoaded(true);
    })();
  }, [params.teamId, params.userId, history, auth.dispatch, mount.state.signal]);

  /**
   * Updates the team member profile if the member is existing, or
   * creates a new team member and redirect to the team members page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);

    const result = await updateTeamMember(params.teamId, params.userId, memberRole, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });

    setIsSubmitting(false);
  };

  // Only manager can update a team member.
  const isUpdatable = team.state.role === 'Manager';

  // If member data and role of current user has been retrieved, loads the form for editing.
  return isLoaded ? (
      <ValidatorForm onSubmit={submitForm}>
        <Container maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Username"
                             inputProps={{ 'aria-label': 'Username' }} name="username"
                             value={username} disabled/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant='outlined' fullWidth label="First name"
                             inputProps={{ 'aria-label': 'First name' }} name="first-name"
                             value={firstName} disabled/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant='outlined' fullWidth label="Last name"
                             inputProps={{ 'aria-label': 'Last name' }} name="last-name"
                             value={lastName} disabled/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Email"
                             inputProps={{ 'aria-label': 'Email' }} name="email" value={email}
                             disabled/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectValidator variant="outlined" fullWidth label="Role"
                               inputProps={{ 'aria-label': 'Role' }}
                               name="member-role" value={memberRole} onChange={updateMemberRole}
                               disabled={!isUpdatable}
                               validators={['required']} errorMessages={['Role is required']}>
                <MenuItem value="Viewer">Viewer</MenuItem>
                <MenuItem value="Analyst">Analyst</MenuItem>
                <MenuItem value="Manager">Manager</MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12}/>

            <Grid item xs={6}>
              {isUpdatable && <DeletePanel/>}
            </Grid>
            <Grid item xs={6}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 href={membersUrl}>Back</LoadingButton>
                </Grid>
                {
                  isUpdatable && (
                      <Grid item>
                        <LoadingButton type="submit" variant="contained" color="secondary"
                                       isSubmitting={isSubmitting}>Update</LoadingButton>
                      </Grid>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ValidatorForm>
  ) : <LoadingContent/>;
};
