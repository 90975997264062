/**
 * @file Contact form for contact page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import pages from "../../../config/pages.json";
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { sendMessage } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads contact form for contact page.
 */
export default () => {
  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {string} name - user's name
   */
  const [name, setName] = useState('');

  /**
   * @type {string} phone - user's phone number
   */
  const [phone, setPhone] = useState('');

  /**
   * @type {string} email - user's email
   */
  const [email, setEmail] = useState('');

  /**
   * @type {string} message - message
   */
  const [message, setMessage] = useState('');

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates user's name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);

  /**
   * Updates user's phone number from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updatePhone = (e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value);

  /**
   * Updates user's email from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  /**
   * Updates the message from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateMessage = (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value);

  /**
   * Clears the form.
   */
  const resetForm = () => {
    setName('');
    setPhone('');
    setEmail('');
    setMessage('');
  };

  /**
   * Submits the contact form, and displays the success or failure result.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const result = await sendMessage(name, phone, email, message, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      resetForm();
    }
    setIsSubmitting(false);
  };

  return (
      <ValidatorForm onSubmit={submitForm}>
        <Grid container spacing={2}>
          <Grid item container xs={12} justify="center">
            <Typography variant="body1">Have any questions? We are here to help!</Typography>
          </Grid>
          <Grid item xs={12}/>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth label="Name"
                           inputProps={{ 'aria-label': 'Name' }} autoComplete="name" autoFocus
                           name="name" value={name} onChange={updateName}
                           validators={['required']} errorMessages={['Name is required']}/>
          </Grid>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth label="Phone"
                           inputProps={{ 'aria-label': 'Phone' }} autoComplete="phone"
                           name="phone" value={phone} onChange={updatePhone}
                           validators={['matchRegexp:^-?[0-9]\\d*(\\d+)?$']}
                           errorMessages={['Phone number should be only in digits']}/>
          </Grid>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth label="Email"
                           inputProps={{ 'aria-label': 'Email' }} autoComplete="email"
                           name="email" value={email} onChange={updateEmail}
                           validators={['required']} errorMessages={['Email is required']}/>
          </Grid>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth multiline rows={5} label="Message"
                           inputProps={{ 'aria-label': 'Message' }}
                           name="message" value={message} onChange={updateMessage}
                           validators={['required']} errorMessages={['Message is required']}/>
          </Grid>
          <Grid item xs={12}/>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <LoadingButton href={pages['home'].url} variant="contained"
                             color="primary">Cancel</LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton type="submit" variant="contained" color="secondary"
                             isSubmitting={isSubmitting}>Send message</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
  );
};
