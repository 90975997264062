/**
 * @file Tweets form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';

/**
 * Loads tweets form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => {
  const processingMethodList = {
    "proceed": "Proceed with the analysis using the tweets extracted using mentions and hashtags only",
    "expand": "Expand the set of tweets for the analysis by evaluating all the tweets sent by the followers of the target account"
  };
  return (
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" gutterBottom>Tweets</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField variant='outlined' fullWidth label="# of tweets currently available"
                       inputProps={{ 'aria-label': '# of tweets currently available' }}
                       name="tweet-count" value={analysisDetail.total_tweets || ''}
                       disabled={true}/>
          </Grid>
          <Grid item xs={12}>
            <TextField variant='outlined' fullWidth label="Processing method"
                       inputProps={{ 'aria-label': 'Processing method' }}
                       name="processing-method"
                       value={processingMethodList[analysisDetail.expanded_collection ? 'expand' : 'proceed']}
                       disabled={true}/>
          </Grid>
        </Grid>
      </Container>
  );
};
