/**
 * @file Social table for new analysis review page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { getLocalAnalysis } from '../../../utils/analysis';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads social table for new analysis review page.
 * If user clicks edit, page redirects to the new analysis social page.
 * @param {(url?: string) => () => void} redirect
 */
export default ({ redirect }: { redirect: (url?: string) => () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  // Generates a URL to the new analysis social page for the edit button.
  const socialUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 4 });

  // Loads the cached user input for the form from the browser local storage.
  const analysis = getLocalAnalysis(params.analysisId);

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Social</Typography>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Network Enabled</TableCell>
                  <TableCell>{analysis.networkEnabled ? "Yes" : "No"}</TableCell>
                </TableRow>
                {
                  analysis.networkEnabled && (<>
                    <TableRow>
                      <TableCell>Network</TableCell>
                      <TableCell>{analysis.networkName}</TableCell>
                    </TableRow>
                    {analysis.networkId === 'influential-accounts' && (
                        <TableRow>
                          <TableCell>Threshold</TableCell>
                          <TableCell>{analysis.threshold || 100}</TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Communities</TableCell>
                      <TableCell>{analysis.communitiesName}</TableCell>
                    </TableRow>
                  </>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item justify="flex-end" container xs={12}>
          <LoadingButton variant="contained" color="secondary"
                         onClick={redirect(socialUrl)}>Edit</LoadingButton>
        </Grid>
      </Grid>
  );
};
