/**
 * @file Send email form for user email verification page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { sendVerificationEmail } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads send email form for user email verification page to request a new email verification.
 */
export default () => {
  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {string} email - user's email
   */
  const [email, setEmail] = useState('');

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates user's email from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  /**
   * Clears the form.
   */
  const resetForm = () => setEmail('');

  /**
   * Sends the verification email, displays the result of the request afterwards, and
   * clears the form if successful.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const result = await sendVerificationEmail(email, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      resetForm();
    }
    setIsSubmitting(false);
  };

  return (
      <Container maxWidth="xs">
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Please enter your email address.</Typography>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth label="Email"
                             inputProps={{ 'aria-label': 'Email' }} autoComplete="email"
                             autoFocus
                             name="email" value={email} onChange={updateEmail}
                             validators={['required']} errorMessages={['Email is required']}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item container xs={12} justify="flex-end">
              <LoadingButton type="submit" variant="contained" color="secondary"
                             isSubmitting={isSubmitting}>Verify email</LoadingButton>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  );
};
