/**
 * @file Detail form for Twitter credentials detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from '../../../hooks/team';
import {
  addTwitterCredentials,
  getTwitterCredentials,
  updateTwitterCredentials
} from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import DeletePanel from './delete-panel';

/**
 * Loads detail form for Twitter credentials detail page.
 *
 * @param {boolean} setup - Whether this component is part of a setup sequence
 */
export default ({ setup }: { setup?: boolean }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   credentialsId: Twitter credentials id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * @type {string} credentialsName - Twitter credentials name
   */
  const [credentialsName, setCredentialsName] = useState('');

  /**
   * @type {boolean} isPremium - indicates whether the Twitter credentials is a premium Twitter API key
   */
  const [isPremium, setIsPremium] = useState(false);

  /**
   * @type {string} apiKey - Twitter API key
   */
  const [apiKey, setApiKey] = useState('');

  /**
   * @type {string} apiSecret - Twitter API secret
   */
  const [apiSecret, setApiSecret] = useState('');

  /**
   * @type {string} accessToken - Twitter API access token
   */
  const [accessToken, setAccessToken] = useState('');

  /**
   * @type {string} accessTokenSecret - Twitter API access token secret
   */
  const [accessTokenSecret, setAccessTokenSecret] = useState('');

  /**
   * @type {string} environmentLabel - Twitter API developer environment label
   */
  const [environmentLabel, setEnvironmentLabel] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the Twitter credentials name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateCredentialsName = (e: React.ChangeEvent<HTMLInputElement>) => setCredentialsName(e.target.value);

  /**
   * Updates the premium flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateIsPremium = (e: React.ChangeEvent<HTMLInputElement>) => setIsPremium(e.target.checked);

  /**
   * Updates the Twitter API key from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateApiKey = (e: React.ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value);

  /**
   * Updates the Twitter API secret from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateApiSecret = (e: React.ChangeEvent<HTMLInputElement>) => setApiSecret(e.target.value);

  /**
   * Updates the Twitter API access token from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateAccessToken = (e: React.ChangeEvent<HTMLInputElement>) => setAccessToken(e.target.value);

  /**
   * Updates the Twitter API access token secret from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateAccessTokenSecret = (e: React.ChangeEvent<HTMLInputElement>) => setAccessTokenSecret(e.target.value);

  /**
   * Updates the Twitter API developer environment label from text field on change.
   * @param e {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateEnvironmentLabel = (e: React.ChangeEvent<HTMLInputElement>) => setEnvironmentLabel(e.target.value);

  // Generates a URL to the Twitter credentials page for the add and update button.
  const credentialsUrl = makeUrl(pages['team-credentials'].url, params);

  // Checks if the team is an existing Twitter credentials.
  const isExistingCredentials = !!params.credentialsId;

  /**
   * Sets up the Twitter credentials detail form.
   */
  useEffect(() => {
    (async () => {
      if (isExistingCredentials) {
        const result = await getTwitterCredentials(params.teamId, params.credentialsId, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        setCredentialsName(result.data.name);
        setIsPremium(result.data.premium);
        setApiKey(result.data.api_key);
        setApiSecret(result.data.api_secret);
        setAccessToken(result.data.access_token);
        setAccessTokenSecret(result.data.access_token_secret);
        setEnvironmentLabel(result.data.env_label);
      }

      setIsLoaded(true);
    })();
  }, [params.teamId, params.credentialsId, isExistingCredentials, history, auth.dispatch, mount.state.signal]);

  /**
   * Updates the Twitter credentials detail if the credentials are existing, or
   * creates new credentials and redirect to the Twitter credentials page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    if (isExistingCredentials) {
      const result = await updateTwitterCredentials(params.teamId, params.credentialsId, credentialsName, isPremium, apiKey, apiSecret,
          accessToken, accessTokenSecret, environmentLabel, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });

      setIsSubmitting(false);
    } else {
      const result = await addTwitterCredentials(params.teamId, credentialsName, isPremium, apiKey, apiSecret,
          accessToken, accessTokenSecret, environmentLabel, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });
      if (result.success) {
        if (setup) {
          handleNext();
        } else {
          history.push(credentialsUrl);
        }
      } else {
        setIsSubmitting(false);
      }
    }
  };

  // Only manager can add or update the Twitter credentials.
  const isAddable = !isExistingCredentials && team.state.role === 'Manager';
  const isUpdatable = isExistingCredentials && team.state.role === 'Manager';
  const isEditable = isAddable || isUpdatable;

  /**
   * Navigate to next step in setup sequence
   */
  const handleNext = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 3 }));
  };

  /**
   * Navigate to next step in setup sequence
   */
  const handleBack = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 1 }));
  };

  return isLoaded ? (
      <ValidatorForm onSubmit={submitForm}>
        <Container maxWidth="xs">
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Credentials name"
                             inputProps={{ 'aria-label': 'Credentials name' }}
                             autoFocus={!isExistingCredentials}
                             name="credentials-name" value={credentialsName}
                             onChange={updateCredentialsName} disabled={!isEditable}
                             validators={['required']}
                             errorMessages={['Credentials name is required']}/>
            </Grid>
            <Grid item xs={3}>
              <FormControlLabel label="Premium" aria-label="Premium"
                                control={<Checkbox checked={isPremium} color="primary"
                                                   onChange={updateIsPremium}/>}
                                disabled={!isEditable}/>
            </Grid>
            <Grid item xs={9}>
              {messages.pages.twitterCredentials.premium}
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="API key"
                             inputProps={{ 'aria-label': 'API key' }}
                             name="api-key" value={apiKey} onChange={updateApiKey}
                             disabled={!isEditable}
                             validators={['required']} errorMessages={['API key is required']}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="API secret"
                             inputProps={{ 'aria-label': 'API secret' }}
                             name="api-secret" value={apiSecret} onChange={updateApiSecret}
                             disabled={!isEditable}
                             validators={['required']} errorMessages={['API secret is required']}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Access token"
                             inputProps={{ 'aria-label': 'Access token' }}
                             name="access-token" value={accessToken} onChange={updateAccessToken}
                             disabled={!isEditable}
                             validators={['required']}
                             errorMessages={['Access token is required']}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Access token secret"
                             inputProps={{ 'aria-label': 'Access token secret' }}
                             name="access-token-secret" value={accessTokenSecret}
                             onChange={updateAccessTokenSecret} disabled={!isEditable}
                             validators={['required']}
                             errorMessages={['Access token secret is required']}/>
            </Grid>
            {isPremium && (
                <>
                  <Grid item xs={12}>
                    {messages.pages.twitterCredentials.envLabel}
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator variant='outlined' fullWidth label="Environment label"
                                   inputProps={{ 'aria-label': 'Environment label' }}
                                   name="environment-label" value={environmentLabel}
                                   onChange={updateEnvironmentLabel} disabled={!isEditable}
                                   validators={['required']}
                                   errorMessages={['Environment label is required']}/>
                  </Grid>
                </>
            )}
            <Grid item xs={12}/>
            {
              setup
                  ? (<>
                    <Grid item xs={2}>
                      <LoadingButton variant="outlined" color="primary"
                                     href={pages['teams'].url}>Cancel</LoadingButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Grid container justify='flex-end' spacing={2}>
                        < Grid item>
                          <Button onClick={handleBack}>
                            Back
                          </Button>
                        </Grid>
                        < Grid item>
                          <Button onClick={handleNext} variant="outlined">
                            Skip
                          </Button>
                        </Grid>
                        < Grid item>
                          <LoadingButton type="submit" variant="contained" color="secondary"
                                         isSubmitting={isSubmitting}>Next</LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>)
                  : (<>
                    <Grid item xs={6}>
                      {
                        isUpdatable && <DeletePanel/>
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justify='flex-end' spacing={2}>
                        <Grid item>
                          <LoadingButton variant="contained" color="primary"
                                         href={credentialsUrl}>{isAddable ? 'Cancel' : 'Back'}</LoadingButton>
                        </Grid>
                        {
                          isUpdatable && (
                              <Grid item>
                                <LoadingButton type="submit" variant="contained" color="secondary"
                                               isSubmitting={isSubmitting}>Update</LoadingButton>
                              </Grid>)
                        }
                        {
                          isAddable && (
                              <Grid item>
                                <LoadingButton type="submit" variant="contained" color="secondary"
                                               isSubmitting={isSubmitting}>Add</LoadingButton>
                              </Grid>)
                        }
                      </Grid>
                    </Grid>
                  </>)
            }
          </Grid>
        </Container>
      </ValidatorForm>
  ) : <LoadingContent/>;
};
