/**
 * @file Layout for analyses page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import Layout from '../../common/layout';
import AnalysisForm from './analysis-form';

/**
 * Loads analysis form.
 */
export default () => (
    <Layout pageId="analyses">
      <AnalysisForm/>
    </Layout>
);
