/**
 * @file Change email form for user security page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import messages from "../../../config/messages";
import { AuthContext } from "../../../hooks/auth";
import { MessageBarContext } from "../../../hooks/message-bar";
import { MountContext } from "../../../hooks/mount";
import { updateEmail } from "../../../utils/api";
import LoadingButton from "../../common/loading/loading-button";

export default () => {
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  /**
   * @type {string} newEmail - newEmail
   */
  const [newEmail, setNewEmail] = useState('');

  /**
   * @type {string} password - current password
   */
  const [password, setPassword] = useState('');

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitForm = async () => {
    setIsSubmitting(true);

    const result = await updateEmail(auth.state.userId, password, newEmail, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });

    if (result.success) {
      setPassword('');
      setNewEmail('');
    }

    setIsSubmitting(false);
  };

  /**
   * Updates the current password from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  /**
   * Updates the new email from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateNewEmail = (e: React.ChangeEvent<HTMLInputElement>) => setNewEmail(e.target.value);

  return <Container maxWidth="xs">
    <ValidatorForm onSubmit={submitForm}>
      <Box my={2}>
        <Typography component="h2" variant="h5">Change Email</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert severity="info">
            {messages.pages.security.email}
          </Alert>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <TextValidator variant="outlined" fullWidth type="password" label="Password"
                         inputProps={{ 'aria-label': 'Password' }}
                         autoFocus
                         name="current-password" value={password}
                         onChange={updateCurrentPassword}
                         validators={['required']}
                         errorMessages={['Current password is required']}/>
        </Grid>
        <Grid item xs={12}>
          <TextValidator variant="outlined" fullWidth label="New Email"
                         inputProps={{ 'aria-label': 'New Email' }} name="email"
                         value={newEmail} onChange={updateNewEmail}
                         validators={['required']}
                         errorMessages={['New email is required']}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={2}>
            <Grid item>
              <LoadingButton type="submit" variant="contained" color="secondary"
                             isSubmitting={isSubmitting}>Update email</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  </Container>;
}
