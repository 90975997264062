/**
 * @file Register form for login page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link as RouterLink } from "react-router-dom";
import pages from "../../../config/pages.json";
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { register } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';
import { CheckboxValidator } from "../../common/validator";

const useStyles = makeStyles(theme => ({
  hyperlink: {
    textDecorationLine: 'underline'
  }
}));

/**
 * Loads register form for login page.
 * @param {React.Dispatch<React.SetStateAction<string>>} setUserEmail - callback for setting the user email for the verification page
 */
export default ({ setUserEmail }: { setUserEmail: React.Dispatch<React.SetStateAction<string>> }) => {
  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {string} username - username
   */
  const [username, setUsername] = useState('');

  /**
   * @type {string} password - password
   */
  const [password, setPassword] = useState('');

  /**
   * @type {string} confirmPassword - password confirmation
   */
  const [confirmPassword, setConfirmPassword] = useState('');

  /**
   * @type {string} firstName - user's first name
   */
  const [firstName, setFirstName] = useState('');

  /**
   * @type {string} lastName - user's last name
   */
  const [lastName, setLastName] = useState('');

  /**
   * @type {string} email - user's email
   */
  const [email, setEmail] = useState('');

  /**
   * @type {boolean} acceptTermsAndConditions
   */
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);

  /**
   * @type {boolean} acceptDisclaimers
   */
  const [acceptDisclaimers, setAcceptDisclaimers] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the username from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateUsername = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);

  /**
   * Updates the password from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  /**
   * Updates the password confirmation from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);

  /**
   * Updates user's first name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);

  /**
   * Updates user's last name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);

  /**
   * Updates user's email from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  /**
   * Updates TAC box state
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateAcceptTermsAndConditions = (e: React.ChangeEvent<HTMLInputElement>) => setAcceptTermsAndConditions(e.target.checked);

  /**
   * Updates Disclaimers box state
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateAcceptDisclaimers = (e: React.ChangeEvent<HTMLInputElement>) => setAcceptDisclaimers(e.target.checked);

  /**
   * Submits the registration form, and displays the email verification page if successful.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const result = await register(username, password, firstName, lastName, email, mount.state.signal);
    messageBar.dispatch({
      open: !result.success,
      success: result.success,
      message: result.message
    });
    if (result.success) {
      setUserEmail(email);
    } else {
      setIsSubmitting(false);
    }
  };

  // Adds validation rule to ensure the password matches the password confirmation.
  ValidatorForm.addValidationRule('isPasswordMatched', value => value === password);
  ValidatorForm.addValidationRule('checked', value => !!value);

  const classes = useStyles();
  return (
      <ValidatorForm onSubmit={submitForm} autoCorrect="off" autoCapitalize="off">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth label="Username"
                           inputProps={{ 'aria-label': 'Username' }} autoFocus
                           name="register-username" value={username} onChange={updateUsername}
                           validators={['required']} errorMessages={['Username is required']}/>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={<span>• Must be at least 8 characters long
                <br/> • Cannot be entirely numeric
                <br/> • Cannot be a common password (e.g. 'password123')
                <br/> • Cannot be too similar to username, email, or first/last name</span>}
                     placement="bottom-start" arrow>
              <TextValidator variant="outlined" fullWidth type="password" label="Password"
                             inputProps={{ 'aria-label': 'Password' }}
                             name="register-password" value={password} onChange={updatePassword}
                             validators={['required']} errorMessages={['Password is required']}/>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth type="password" label="Confirm password"
                           inputProps={{ 'aria-label': 'Confirm password' }}
                           name="confirm-password" value={confirmPassword}
                           onChange={updateConfirmPassword}
                           validators={['required', 'isPasswordMatched']}
                           errorMessages={['Confirm password is required', 'Passwords do not match']}/>
          </Grid>
          <Grid item xs={6}>
            <TextValidator variant="outlined" fullWidth label="First name"
                           inputProps={{ 'aria-label': 'First name' }} autoComplete="first-name"
                           name="first-name" value={firstName} onChange={updateFirstName}
                           validators={['required']} errorMessages={['First name is required']}/>
          </Grid>
          <Grid item xs={6}>
            <TextValidator variant="outlined" fullWidth label="Last name"
                           inputProps={{ 'aria-label': 'Last name' }} autoComplete="last-name"
                           name="last-name" value={lastName} onChange={updateLastName}
                           validators={['required']} errorMessages={['Last name is required']}/>
          </Grid>
          <Grid item xs={12}>
            <TextValidator variant="outlined" fullWidth label="Email"
                           inputProps={{ 'aria-label': 'Email' }} autoComplete="email"
                           name="email" value={email} onChange={updateEmail}
                           validators={['required']} errorMessages={['Email is required']}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <CheckboxValidator value={acceptTermsAndConditions}
                                   aria-label="I accept the terms and conditions"
                                   onChange={updateAcceptTermsAndConditions} label={
                  <span>
                    I accept the <Link component={RouterLink}
                                       to={pages['terms-and-conditions'].url}
                                       target="_blank"
                                       className={classes.hyperlink}>
                    terms and conditions
                  </Link>
                  </span>
                } name="acceptTermsAndConditions" color="primary"
                                   validators={['checked']}
                                   errorMessages={['You must accept the terms and conditions']}/>
              </Grid>
              <Grid item xs={12}>
                <CheckboxValidator value={acceptDisclaimers} onChange={updateAcceptDisclaimers}
                                   aria-label="I have read the disclaimers and limitations"
                                   label={
                                     <span>
                    I read the <Link component={RouterLink}
                                     to={pages['disclaimers'].url}
                                     target="_blank"
                                     className={classes.hyperlink}>
                    disclaimers and limitations
                  </Link>
                  </span>
                                   } name="acceptDisclaimers" color="primary"
                                   validators={['checked']}
                                   errorMessages={['You must accept the disclaimers']}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}/>
          <Grid item container xs={12} justify="flex-end">
            <LoadingButton type="submit" variant="contained" color="secondary"
                           isSubmitting={isSubmitting}>Register</LoadingButton>
          </Grid>
        </Grid>
      </ValidatorForm>
  );
};
