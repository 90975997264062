/**
 * @file Layout for contact page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import pages from '../../../config/pages.json';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import ContactForm from './contact-form';

const useStyles = makeStyles(_ => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

/**
 * Loads contact form with a custom title.
 */
export default () => {
  const classes = useStyles();
  const styles = { root: classes.root };
  return (
      <Layout pageId="contact" title={null}>
        <Container maxWidth="xs" classes={styles}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <Typography component="h1" variant="h5">{pages['contact'].title}</Typography>
          </Box>
          <MessageBarProvider>
            <ContactForm/>
          </MessageBarProvider>
        </Container>
      </Layout>
  );
};
