/**
 * @file Analysis table for analyses page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { TeamContext } from '../../../hooks/team';
import { mobileBreakpointCompactTable, ViewportContext } from '../../../hooks/viewport';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingLink from "../../common/loading/loading-link";
import AnalysisProgress from "../progress";

const useStyles = makeStyles(_ => ({
  tableRow: {
    cursor: 'pointer'
  },
  paper: {
    overflow: 'auto'
  }
}));

/**
 * Loads analysis table for analysis page.
 * @param {any[]} analysisList - array containing metadata of analyses
 * @param {boolean} hasMore - indicates whether there are more analyses available to load
 * @param {boolean | undefined} dashboard - indicates whether this table is being used in the dashboard to hide non-essential columns
 * @param {() => void} loadMore - callback to load the next page of analyses
 * @param {boolean | undefined} mainDashboard - indicates whether this table is used in the main dashboard
 */
export default ({ analysisList, hasMore, dashboard, loadMore, mainDashboard }: { analysisList: any[], hasMore: boolean, dashboard?: boolean, loadMore: () => void, mainDashboard?: boolean }) => {

  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  // Generates a URL to the new analysis profile page for the add button.
  const addAnalysisUrl = makeUrl(pages['new-analysis-initial'].url, params);

  const teamsUrl = makeUrl(pages['teams'].url, params);
  const analysesUrl = makeUrl(pages['analyses'].url, params);

  /**
   * Redirects to the provided analysis page.
   * @param {string} analysisId - analysis id
   * @param {string | undefined} teamId - team id
   */
  const redirect = (analysisId: string, teamId?: string) => () => {
    let analysisUrl: string;
    if (mainDashboard) {
      // Override and inject teamId for mainDashboard
      analysisUrl = makeUrl(pages['analyses'].url, {
        ...params,
        teamId: teamId
      }) + `/${analysisId}`;
    } else {
      analysisUrl = makeUrl(pages['analyses'].url, params) + `/${analysisId}`;
    }
    history.push(analysisUrl);
  };

  // Only analyst and manager can add an analysis.
  const isAddable = team.state.role !== 'Viewer';

  const compactTableActive = viewport.state.width <= mobileBreakpointCompactTable;
  const mobileDashboardCellStyle = compactTableActive && dashboard ? {
    paddingTop: '5px',
    paddingBottom: '5px'
  } : {};
  const mobileDashboardRowStyle = compactTableActive && dashboard ? { paddingTop: '11px' } : {};

  const classes = useStyles();
  return (
      <Grid container spacing={2}>
        <Grid container item xs={12} justify='flex-end' spacing={2}>
          {
            mainDashboard && (
                <Grid item>
                  <LoadingButton variant="contained" color="secondary" href={teamsUrl}>View all
                    teams</LoadingButton>
                </Grid>
            )
          }
          {
            dashboard && !mainDashboard && (
                <Grid item>
                  <LoadingButton variant="contained" color="primary" href={analysesUrl}>View all
                    analyses</LoadingButton>
                </Grid>
            )
          }
          {
            isAddable && !mainDashboard && (
                <Grid item>
                  <LoadingButton variant="contained" color="secondary" href={addAnalysisUrl}>Add
                    analysis</LoadingButton>
                </Grid>
            )
          }
        </Grid>
        <Grid item xs={12}>
          { /* For some reason TableContainer cases the progress indicators to
            flicker when ViewportContext is active. Using Paper instead solves this issue. */}
          <Paper className={classes.paper}>
            <Table className="collapse-table">
              <TableHead>
                <TableRow>
                  {
                    mainDashboard && (<TableCell align="left">Team Name</TableCell>)
                  }
                  {
                    !dashboard && (<TableCell align="center">Private</TableCell>)
                  }
                  <TableCell align="left">Name</TableCell>
                  {
                    !dashboard && (<TableCell align="left">Description</TableCell>)
                  }
                  {
                    !compactTableActive && dashboard && (
                        <TableCell align="center">Progress</TableCell>)
                  }
                  <TableCell align="center">Status</TableCell>
                  {
                    !dashboard && (
                        <>
                          <TableCell align="center">Author</TableCell>
                          <TableCell align="center">Creation date</TableCell>
                        </>
                    )
                  }
                  <TableCell align="center">Last modified</TableCell>
                  {
                    /* Remember to move the column header when switching order too for accessibility reasons */
                    compactTableActive && dashboard && (
                        <TableCell align="center">Progress</TableCell>)
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {analysisList.map(analysis => (
                    <TableRow hover key={`analysis-${analysis.id_str}`} className={classes.tableRow}
                              onClick={redirect(analysis.id_str, analysis.team)}
                              style={mobileDashboardRowStyle}>
                      {
                        mainDashboard && (
                            <TableCell compact-title="Team name" align="left"
                                       style={mobileDashboardCellStyle}>{analysis.teamName}</TableCell>)
                      }
                      {
                        !dashboard && (
                            <TableCell compact-title="Private" align="center">{analysis.public ?
                                <CheckBoxOutlineBlankIcon color="primary"/> :
                                <CheckBoxIcon color="primary"/>}</TableCell>
                        )
                      }
                      <TableCell compact-title="Name" align="left"
                                 style={mobileDashboardCellStyle}>
                        <LoadingButton variant="text" color="inherit"
                                       onClick={redirect(analysis.id_str, analysis.team)}>
                          {analysis.name}
                        </LoadingButton>
                      </TableCell>
                      {
                        !dashboard && (<TableCell compact-title="Description"
                                                  align="left">{analysis.description}</TableCell>)
                      }
                      {
                        /* We switch the order of the Progress column on mobile */
                        !compactTableActive && dashboard && (
                            <TableCell no-label="true" align="left">
                              <AnalysisProgress analysisData={analysis}/>
                            </TableCell>)
                      }
                      <TableCell compact-title="Status" align="center"
                                 style={mobileDashboardCellStyle}>
                        {analysis.status}
                      </TableCell>
                      {
                        !dashboard && (
                            <>
                              <TableCell
                                  compact-title="Author"
                                  align="center">{analysis.author && `${analysis.author.first_name} ${analysis.author.last_name}`}</TableCell>
                              <TableCell
                                  compact-title="Creation date"
                                  align="center">{moment(analysis.created).format('YYYY-MM-DD, h:mm:ss A')}</TableCell>
                            </>
                        )
                      }
                      <TableCell
                          style={mobileDashboardCellStyle}
                          compact-title="Last modified"
                          align="center">{moment(analysis.updated).format('YYYY-MM-DD, h:mm:ss A')}</TableCell>
                      {
                        compactTableActive && dashboard && (<TableCell no-label="true" align="left">
                          <AnalysisProgress analysisData={analysis}/>
                        </TableCell>)
                      }
                    </TableRow>
                ))}
              </TableBody>
              {hasMore && (
                  <TableFooter>
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={8} align="center">
                        <LoadingLink onClick={loadMore} text="Load more..."/>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
              )}
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={12}/>
      </Grid>
  );
};
