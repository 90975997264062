/**
 * @file Entry point of the application
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import * as Sentry from "@sentry/react";
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import App from './components/app';
import * as serviceWorker from './service-worker';

Sentry.init({
  dsn: (process.env.REACT_APP_SENTRY_DSN || "")?.trim()
});

// Renders the React "App" element in the DOM "root" container.
// Note: this React app does NOT support the concurrent mode.
render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
