/**
 * @file Semantic table for new analysis review page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { getLocalAnalysis } from '../../../utils/analysis';
import { makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads semantic table for new analysis review page.
 * If user clicks edit, page redirects to the new analysis semantic page.
 * @param {(url?: string) => () => void} redirect - callback of redirecting
 */
export default ({ redirect }: { redirect: (url?: string) => () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  // Generates a URL to the new analysis semantic page for the edit button.
  const semanticUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 3 });

  // Loads the cached user input for the form from the browser local storage.
  const analysis = getLocalAnalysis(params.analysisId);

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Semantic</Typography>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Lexicon</TableCell>
                  <TableCell>{analysis.lexiconName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Selections</TableCell>
                  <TableCell>{analysis.lexiconSelections && Object.keys(analysis.lexiconSelections).filter(key => analysis.lexiconSelections[key]).join(', ')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <InlineTooltip text={messages.tooltips.semanticSentimentInsights}>
                      Semantic-sentiment insights
                    </InlineTooltip>
                  </TableCell>
                  <TableCell>{analysis.insights ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item justify="flex-end" container xs={12}>
          <LoadingButton variant="contained" color="secondary"
                         onClick={redirect(semanticUrl)}>Edit</LoadingButton>
        </Grid>
      </Grid>
  );
};
