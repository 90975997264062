/**
 * @file Report form for reports page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../hooks/auth'; //
import { MountContext } from '../../../hooks/mount';
import { getNextPage, listReports } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";
import BlankTable from './blank-table';
import ReportTable from './report-table';

/**
 * Loads report form for reports page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {any[]} reportList - array containing metadata of reports
   */
  const [reportList, setReportList] = useState([] as any[]);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Initializes the add report drop-down.
   * Makes request to retrieve the metadata of available reports.
   */
  useEffect(() => {
    (async () => {
      const result1 = await listReports(params.teamId, mount.state.signal);
      if (result1.statusCode >= 400 && result1.statusCode <= 599) {
        handleHttpError(history, result1.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      let result = result1;
      let data = result.data;
      while (result.next) {
        result = await getNextPage(result.next, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }
        data = data.concat(result.data);
      }
      setReportList(data);
      setIsLoaded(true);
    })();
  }, [params.teamId, history, auth.dispatch, mount.state.signal]);

  return isLoaded
      ? reportList.length > 0
          ? <ReportTable reportList={reportList}/>
          : <BlankTable/>
      : <LoadingContent/>;
};
