/**
 * @file Member table for team members page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { TeamContext } from '../../../hooks/team';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingLink from "../../common/loading/loading-link";

const useStyles = makeStyles(_ => ({
  tableRow: {
    cursor: 'pointer'
  }
}));

/**
 * Loads member table for team members page.
 * @param {any[]} memberList - array containing metadata of team members
 * @param {boolean} hasMore - indicates whether there are more team members available to load
 * @param {() => void} loadMore - callback to load the next page of team members
 */
export default ({ memberList, hasMore, loadMore }: { memberList: any[], hasMore: boolean, loadMore: () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the new team member page for the add button.
  const addMemberUrl = makeUrl(pages['new-team-member'].url, params);

  const invitationsUrl = makeUrl(pages['team-invites'].url, params);

  /**
   * Redirects to a specific team member page.
   * @param {string} userId - team member's user id
   */
  const redirect = (userId: string) => () => {
    // Generates a URL to a specific team member page.
    const memberUrl = makeUrl(pages['team-members'].url, params) + `/${userId}`;
    history.push(memberUrl);
  };

  // Only manager can add a team member.
  const isAddable = team.state.role === 'Manager';

  const classes = useStyles();
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Alert severity="info">
        {messages.pages.teamMembers.description}
      </Alert>
    </Grid>
    <Grid container item xs={12} justify='flex-end' spacing={2}>
      {isAddable && (
          <>
            <Grid item>
              <LoadingButton variant="contained" color="primary" href={invitationsUrl}>View
                Invitations</LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton variant="contained" color="secondary" href={addMemberUrl}>Add team
                member</LoadingButton>
            </Grid>
          </>
      )}
    </Grid>
    <Grid item xs={12}>
      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Username</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="center">Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {memberList.map((member: any) => (
                <TableRow hover key={`member-${member.id_str}`} className={classes.tableRow}
                          onClick={redirect(member.id_str)}>
                  <TableCell compact-title="Username" align="left">{member.username}</TableCell>
                  <TableCell compact-title="Name"
                             align="center">
                    <LoadingButton variant="text" color="inherit"
                                   onClick={redirect(member.id_str)}>
                      {member.first_name} {member.last_name}
                    </LoadingButton>
                  </TableCell>
                  <TableCell compact-title="Email" align="left">{member.email}</TableCell>
                  <TableCell compact-title="Role" align="center">{member.role}</TableCell>
                </TableRow>
            ))}
          </TableBody>
          {hasMore && (
              <TableFooter>
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={8} align="center">
                    <LoadingLink onClick={loadMore} text="Load more..."/>
                  </TableCell>
                </TableRow>
              </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
    <Grid item xs={12}/>
  </Grid>
};
