/**
 * @file Masthead for header
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logoImage from '../../../assets/images/logo.png';
import pages from '../../../config/pages.json';

const useStyles = makeStyles(theme => ({
  logo: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundImage: `url(${logoImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%'
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    marginLeft: theme.spacing(2),
    fontSize: '14pt'
  }
}));

/**
 * Loads masthead for header.
 */
export default () => {
  const classes = useStyles();
  return (
      <>
        <Link component={RouterLink} to={pages['home'].url} underline="none">
          <Box component="span" display="inline-block" className={classes.logo}/>
        </Link>
        <Link component={RouterLink} to={pages['home'].url} className={classes.title}
              color="inherit" underline="none">
          TIPS
        </Link>
      </>
  );
};
