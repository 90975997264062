/**
 * @file Layout for member invite page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import InviteTabs from './invite-tabs';

/**
 * Sets up the page layout and loads profile form.
 */
export default () => (
    <Layout pageId={'new-team-member'}>
      <MessageBarProvider>
        <InviteTabs/>
      </MessageBarProvider>
    </Layout>
);
