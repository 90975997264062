/**
 * @file Helper functions for user authentication
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { getCurrentUser, refresh, remember } from '../utils/api';

/**
 * Gets a JSON object with the authentication tokens from the browser local storage.
 * @return {any} - JSON based tokens object
 */
export const getLocalTokens = () => {
  const tokensData = window.localStorage.getItem('tokens');
  if (tokensData) {
    try {
      return JSON.parse(tokensData);
    } catch {
    }
  }
  return {};
};

/**
 * Saves the authentication tokens to the browser local storage.
 * @param {any} data - JSON object containing the authentication tokens
 * @param {boolean} isRemembered - indicates whether the user wants to retain tokens for the next session
 */
export const saveTokens = (data: any, isRemembered?: boolean) => {
  const currentTime = new Date().getTime();
  const tokens: any = {
    "userId": data.pk.toString(),
    "access_token": data.token,
    "access_expire": currentTime + data.expires_in * 1000,
    "refresh_token": data.refresh_token,
    "refresh_expire": currentTime + data.refresh_expires_in * 1000
  };

  // Saves the remember token if isRemembered is true. Otherwise, don't save it.
  let includesRememberToken = isRemembered;
  if (includesRememberToken === undefined) {
    // Saves the remember token if the current remember token exists.
    const currentTokens = getLocalTokens();
    includesRememberToken = currentTokens.remember_token && currentTokens.remember_expire;
  }
  if (includesRememberToken) {
    tokens.remember_token = data.remember_me_token;
    tokens.remember_expire = currentTime + data.remember_me_expires_in * 1000;
  }

  window.localStorage.setItem('tokens', JSON.stringify(tokens));
};

/**
 * Destroys anything stored in the browser local storage when the user logs out.
 */
export const clearTokens = () => window.localStorage.clear();

/**
 * Makes sure the authentication tokens are up to date, and makes an API call to refresh them as needed.
 * @param {boolean} initial - indicates whether this is the first time update tokens is called
 * @param {AbortSignal} signal - abort signal to terminate the fetch request
 * @return {any} - user authentication information
 */
export const updateTokens = async (initial: boolean, signal: AbortSignal) => {
  const tokens = getLocalTokens();
  if (tokens) {
    const startTime = 30000;
    const currentTime = new Date().getTime();

    if (initial && tokens.access_expire && tokens.access_expire >= currentTime) {
      // If the access token exists and this is the first load of the page and the token is not expired.
      const timeout = Math.max(tokens.access_expire - currentTime - startTime, 0);
      return { isAuthenticated: true, userId: tokens.userId, timeout: timeout };
    } else if (tokens.refresh_expire && tokens.refresh_expire >= currentTime) {
      // If the refresh token hasn't expired yet, make an API call to refresh it.
      const result = await refresh(signal);

      if (result.success) {
        saveTokens(result.data);
        // Calculates the delay for the next token update. Updates 30s early for grace.
        const timeout = Math.max(result.data.expires_in * 1000 - startTime, 0);
        return { isAuthenticated: true, userId: result.data.pk.toString(), timeout: timeout };
      } else {
        clearTokens();
      }
    } else if (tokens.remember_expire && tokens.remember_expire >= currentTime) {
      // If the remember token hasn't expired yet, make an API call to refresh it.
      const result = await remember(signal);

      if (result.success) {
        saveTokens(result.data);
        // Calculates the delay for the next token update. Updates 30s early for grace.
        const timeout = Math.max(result.data.expires_in * 1000 - startTime, 0);
        return { isAuthenticated: true, userId: result.data.pk.toString(), timeout: timeout };
      } else {
        clearTokens();
      }
    }
  }

  // In any other cases, retires user's authentication.
  return { isAuthenticated: false, userId: '0', timeout: 0 };
};

/**
 * Gets the user's full name. Fetches it from the server if it isn't cached.
 *
 * @param {AbortSignal} signal - abort signal to terminate the fetch request
 */
export const getName = async (signal: AbortSignal) => {
  let name: any = JSON.parse(window.localStorage.getItem('name') || "{}");

  // If the name is not cached, fetch it from the server and cache it
  if (Object.keys(name).length === 0) {
    // Fetch the user's name for the top bar
    const userDataResult = await getCurrentUser(signal);

    // Only cache the name if it's defined
    if (userDataResult?.data?.first_name !== undefined && userDataResult?.data?.last_name !== undefined) {
      saveName(userDataResult?.data?.first_name, userDataResult?.data?.last_name);
    }
  }

  return name;
};

/**
 * Saves the user's full name to local storage
 *
 * @param {string} firstName
 * @param {string} lastName
 */
export const saveName = (firstName: string, lastName: string) => window.localStorage.setItem('name', JSON.stringify({
  firstName: firstName,
  lastName: lastName,
}));
