/**
 * @file Context for message banner
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { AuthContext } from "./auth";

/**
 * Creates MessageBanner
 * MessageBanner contains a global state for message banner state
 *
 * We use a context to store this state so that it is persistent between pages
 *
 * State:
 *   open: indicates whether the message banner is visible
 */
export const MessageBannerContext = createContext({} as {
  state: { open: boolean },
  dispatch: React.Dispatch<{ open: boolean }>
});

const defaultState = { open: true };

/**
 * MessageBanner reducer
 * @param {{ open: boolean }} action - action parameters
 */
const reducer = (_: { open: boolean }, action: { open: boolean }) => action;

export const MessageBannerProvider = ({ children }: { children: any }) => {
  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Gets the current state and dispatcher of MessageBannerContext.
   */
  const [state, dispatch] = useReducer(reducer, defaultState);

  /**
   * Reset to default state if user logs out
   */
  useEffect(() => {
    if (!auth.state.isAuthenticated) {
      dispatch(defaultState)
    }
  }, [auth.state.isAuthenticated]);

  return (
      <MessageBannerContext.Provider value={{ state, dispatch }}>
        {children}
      </MessageBannerContext.Provider>
  );
};
