/**
 * @file Page layout
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import { SkipNavContent } from "@reach/skip-nav";
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MountContext } from '../../../hooks/mount';
import { NavbarProvider } from '../../../hooks/navbar';
import { TeamContext } from '../../../hooks/team';
import { getTeam } from "../../../utils/api";
import { getName, updateTokens } from '../../../utils/auth';
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import DynamicTitle from '../dynamic-title';
import Footer from '../footer';
import Header from '../header';
import LoadingPage from "../loading/loading-page";
import BreadcrumbsBar from './breadcrumbs-bar';
import MessageBanner from './message-banner';
import Navbar from './navbar';
import TitleBar from './title-bar';

const useStyles = makeStyles(theme => ({
  paper: {
    minHeight: 'calc(100vh - 121px)',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 113px)'
    }
  },
  box: {
    overflowY: 'auto'
  },
  container: {
    maxWidth: '100vw'
  }
}));

/**
 * Loads the common layout around child components.
 * @param {any} children - child components
 * @param {string} pageId - page id
 * @param {string | null | undefined} title - page title
 * @param {boolean | undefined} center - whether to center children
 */
export default ({ children, pageId, title, center }: { children: any, pageId: string, title?: string | null, center?: boolean }) => {
  const classes = useStyles();

  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  const { dispatch: authDispatch } = auth;
  const { dispatch: teamDispatch } = team;

  const page = (pages as any)[pageId];

  /**
   * Logs in with the access token, and sets up the refresh timer.
   *
   * Gets user's team role and updates TeamContext
   */
  useEffect(() => {
    let timer: any = null;  // refresh timer

    (async () => {
      /**
       * Updates the auth tokens and set a timer to repeat periodically.
       * @param {boolean} initial - indicates whether this is the first time auto login is called
       */
      const autoLogin = async (initial: boolean) => {
        // Makes the refresh auth tokens request.
        const { isAuthenticated, userId, timeout } = await updateTokens(initial, mount.state.signal);
        const name = await getName(mount.state.signal);

        // Updates AuthContext with the new authentication status.
        authDispatch({
          type: isAuthenticated ? 'login' : 'logout',
          userId: userId,
          userFirstName: name.firstName,
          userLastName: name.lastName
        });

        // If authentication is successful, starts timer to refresh the access token periodically.
        if (isAuthenticated) {
          timer = setTimeout(() => autoLogin(false), timeout);
        }

        return isAuthenticated;
      };

      // Auto logs in with the auth tokens.
      const isAuthenticated = await autoLogin(true);

      // If the page requires authentication and user isn't authenticated, redirect to the login page.
      // If the page doesn't require authentication (i.e. it's the login page, password reset, etc) and
      // the user is already authenticated, redirect to home page.
      if (page.needAuthentication === true && !isAuthenticated) {
        history.push(pages['login'].url);
      } else if (page.needAuthentication === false && isAuthenticated) {
        history.push(pages['home'].url);
      } else {
        // Be ready to render the page if the current page doesn't need authentication or the user is
        // authenticated successfully.
        setIsLoaded(true);
      }
    })();

    // Destroys the refresh timer on page unload.
    return () => clearTimeout(timer);
  }, [page, params.teamId, history, authDispatch, mount.state.signal]);

  /**
   * Fetches team data
   */
  useEffect(() => {
    (async () => {
      if (isLoaded) {
        // Updates TeamContext with current team data, teamId and role.
        if (params.teamId) {
          const result = await getTeam(params.teamId, mount.state.signal);
          if (isHTTPStatusCodeError(result)) {
            handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
            return;
          }

          // Note that we store result.data in the context so that we can access it later without
          // having to make another call using getTeam
          teamDispatch({ teamId: params.teamId, role: result.data.role, teamData: result.data });

          // Checks if the user has permission to view this page.
          if ((page?.permission?.indexOf(result.data.role) || 0) < 0) {
            handleHttpError(history, 403, auth.dispatch, mount.state.signal);
            return;
          }
        }
      }
    })();
  }, [isLoaded, page, params.teamId, history, teamDispatch, auth.dispatch, mount.state.signal]);

  // Show the inner page of the page if not a team page, or if permission has been updated
  const innerIsLoaded = !params.teamId || (team.state.teamId === params.teamId);

  const pageTitle = title ? title : page.title + (page.displayTeamName && team.state.teamData !== null ? ` (${team.state.teamData.name})` : "");

  // If the required data is loaded, loads header, navbar, breadcrumbs, title, child components and footer.
  return <DynamicTitle title={pageTitle}>
    {
      isLoaded ? (
          <NavbarProvider>
            <Header isTeam={!!params.teamId} hasNav={!!page.nav}/>
            <Container component="main" className={classes.container}>
              <Box display="flex" className={classes.paper}>
                {innerIsLoaded
                    ? page.nav && <Navbar nav={page.nav}/>
                    : <Navbar nav={[]}/>}
                {innerIsLoaded
                    ? (
                        <Box flex={1} p={3} className={classes.box}>
                          {page.breadcrumbs && <BreadcrumbsBar breadcrumbs={page.breadcrumbs}/>}
                          {(title || (title === undefined && page.title)) &&
                          <TitleBar
                              title={pageTitle}/>}
                          <SkipNavContent>
                            {
                              center
                                  ? (
                                      <Grid container direction="column" alignItems="center">
                                        {children}
                                      </Grid>
                                  )
                                  : children
                            }
                          </SkipNavContent>
                        </Box>
                    )
                    : <LoadingPage/>}
              </Box>
            </Container>
            <Footer/>
            {
              // Don't show the disclaimer banner on non-authenticated pages since there may not be a
              // user to save preferences to.
              page.needAuthentication &&
              <MessageBanner title="Disclaimer" preferenceKey="disclaimer_bar_hidden">
                {messages.disclaimers.bar}
              </MessageBanner>
            }
          </NavbarProvider>
      ) : <LoadingPage minHeight="100vh"/>
    }
  </DynamicTitle>;
};
