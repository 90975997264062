/**
 * @file Utilities for generating queries
 * @copyright 2020 University of Toronto. All rights reserved.
 */

const operatorLookup: any = {
  $and: '&',
  $or: '|'
};

/**
 * Helper function to recursively build the filter portion of the url query parameter
 *
 * @param {any} query - dictionary input (More details in header for requestQueryBuilder)
 * @param {string} parent - parent key
 */
const parseFilter = (query: any, parent: string) => {
  // Next level of queries, converted to string
  let childQueries: string[] = [];
  let operator = '&';

  if (operatorLookup[parent]) {
    operator = operatorLookup[parent];
  } else {
    throw Error(`Invalid operator! Got ${parent}`);
  }

  if (Array.isArray(query)) {
    for (let subQuery of query) {
      // subqueries are dictionaries (e.g. { foo : "bar" })

      let keys: any = Object.keys(subQuery);

      if (keys.length === 0) {
        throw Error("No key-value pair found!");
      }

      if (keys.length > 1) {
        throw Error(`More than one key found in a dictionary! Got ${keys.length}`)
      }

      let key = keys[0];
      let value = subQuery[key];

      let negation = key.length > 0 && key.charAt(0) === '~';
      let negationPrefix = negation ? '~' : '';

      if (negation) {
        key = key.substr(1);
      }

      if (Array.isArray(value)) {
        // It's not necessary to put brackets if the child and parent use the same operator.
        let includeBrackets = (operator !== operatorLookup[key] || negation) && value.length > 1 && query.length > 1;

        let prefix = includeBrackets ? '(' : '';
        let suffix = includeBrackets ? ')' : '';

        childQueries.push(`${negationPrefix}${prefix}${parseFilter(value, key)}${suffix}`);
      } else if (typeof value === 'string' || typeof value === 'number') {
        childQueries.push(`${negationPrefix}(${key.toString()}=${value.toString()})`);
      } else {
        throw Error(`Value in a dictionary must be an array, string, or number! Got ${value}`);
      }
    }
  } else {
    throw Error(`Top level of a filter must be an array! Got ${query}`);
  }

  return childQueries.join(operator);
};

/**
 * Parses the top level queries and ANDs them together.
 *
 * @param {any} query
 */
const parseTopLevelQueries = (query: any) => {
  let pairs: String[] = [];

  const keys = Object.keys(query);

  for (let key of keys) {
    let value = query[key];

    // The filters key is special as it can have a complex query structure
    if (key === 'filters') {
      pairs.push(`filters=${parseFilter(value, '$and')}`)
    } else {
      pairs.push(`${key.toString()}=${value.toString()}`);
    }
  }

  return pairs.join('&');
};

/**
 * Converts a human friendly dictionary of a query into a URL query param
 *
 * Only the filters query can take advantage of the complex features, such as ORing, and NOTing.
 *
 * As seen in the example below, the default operator at the top level is AND.
 * key-value pairs must each be in their own dictionary
 *
 * To negate a value, insert ~ at the beginning of the key.
 *
 * Preconditions:
 * - The elements of an array must be a dictionary
 * - The value in a key-value pair of a dictionary must either be an string, number
 * - The key to an array value must be a valid operator, with the exception of filters at the top level
 *
 * IMPORTANT: NESTED QUERIES ARE CURRENTLY NOT SUPPORTED BY THE BACKEND, SO ONLY USE ONE OPERATOR
 * i.e. Only use one of AND or OR exclusively
 *
 * Example input:
 * {
 *   per_page: 10,
 *   filters: [ // <- This is the "top level", which is implicitly "ANDed"
 *     {$or: [
 *        {def: '456'},
 *        {def: '789'},
 *        {~abc: '123'},
 *     ]},
 *     {water: 'melon'},
 *     {foo: 'bar'}
 *   ]
 * }
 *
 * Output:
 * ?per_page=10&filters=(foo=bar)&(water=melon)&(~(abc=123) | (def=456) | (def=789))
 *
 * @param {any} query - dict of query params
 * @return {string}
 */
export const requestQueryBuilder = (query: any) => (
    `?${parseTopLevelQueries(query)}`
);
