/**
 * @file Layout for Twitter credentials
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import DetailForm from './detail-form';

/**
 * Sets up the page layout and loads detail form.
 */
export default () => {
  return (
      <Layout pageId={'team-credentials-detail'} center={true}>
        <MessageBarProvider>
          <DetailForm/>
        </MessageBarProvider>
      </Layout>
  );
};
