/**
 * @file Lexicon table for lexicons page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { TeamContext } from '../../../hooks/team';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingLink from "../../common/loading/loading-link";

const useStyles = makeStyles(_ => ({
  tableRow: {
    cursor: 'pointer'
  }
}));

/**
 * Loads lexicon table for lexicons page.
 * @param {any[]} lexiconList - array containing metadata of lexicons
 * @param {boolean} hasMore - indicates whether there are more lexicons available to load
 * @param {() => void} loadMore - callback to load the next page of lexicons
 */
export default ({ lexiconList, hasMore, loadMore }: { lexiconList: any[], hasMore: boolean, loadMore: () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the new lexicon page for the add button.
  const addLexiconUrl = makeUrl(pages['new-lexicon'].url, params);

  /**
   * Redirects to a specific lexicon page.
   * @param {string} lexiconId - lexicon id
   */
  const redirect = (lexiconId: string) => () => {
    // Generates a URL to a specific lexicon page.
    const lexiconUrl = makeUrl(pages['lexicons'].url, params) + `/${lexiconId}`;
    history.push(lexiconUrl);
  };

  // Only analyst and manager can add a lexicon.
  const isAddable = team.state.role !== 'Viewer';

  // Loads the lexicon table when the role of the current user has been retrieved.
  const classes = useStyles();
  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Alert severity="info">
        {messages.pages.lexicons.description}
      </Alert>
    </Grid>
    {isAddable ? (
        <Grid item container xs={12} justify='flex-end'>
          <LoadingButton variant="contained" color="secondary" href={addLexiconUrl}>Add
            lexicon</LoadingButton>
        </Grid>
    ) : <Grid item xs={12}/>}
    <Grid item xs={12}>
      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Private</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="center">Author</TableCell>
              <TableCell align="center">Creation date</TableCell>
              <TableCell align="center">Last modified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lexiconList.map(lexicon => (
                <TableRow hover key={`lexicon-${lexicon.id_str}`} className={classes.tableRow}
                          onClick={redirect(lexicon.id_str)}>
                  <TableCell compact-title="Private" align="center">{lexicon.public ?
                      <CheckBoxOutlineBlankIcon color="primary"/> :
                      <CheckBoxIcon color="primary"/>}</TableCell>
                  <TableCell compact-title="Name" align="left">
                    <LoadingButton variant="text" color="inherit"
                                   onClick={redirect(lexicon.id_str)}>
                      {lexicon.name}
                    </LoadingButton>
                  </TableCell>
                  <TableCell compact-title="Description"
                             align="left">{lexicon.description}</TableCell>
                  <TableCell compact-title="Author"
                             align="center">{lexicon.author && `${lexicon.author.first_name} ${lexicon.author.last_name}`}</TableCell>
                  <TableCell compact-title="Creation date"
                             align="center">{moment(lexicon.created).format('YYYY-MM-DD, h:mm:ss A')}</TableCell>
                  <TableCell compact-title="Last modified"
                             align="center">{moment(lexicon.updated).format('YYYY-MM-DD, h:mm:ss A')}</TableCell>
                </TableRow>
            ))}
          </TableBody>
          {hasMore && (
              <TableFooter>
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={8} align="center">
                    <LoadingLink onClick={loadMore} text="Load more..."/>
                  </TableCell>
                </TableRow>
              </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
    <Grid item xs={12}/>
  </Grid>
};
