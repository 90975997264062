/**
 * @file Page navbar
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Backdrop from '@material-ui/core/Backdrop';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from "@material-ui/core/Grid";
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { NavbarContext } from '../../../hooks/navbar';
import { TeamContext } from '../../../hooks/team';
import { mobileBreakpointSidebar, ViewportContext } from "../../../hooks/viewport";
import { loadIcon } from '../../../utils/icon';
import { makeUrl } from '../../../utils/url';

const useStyles = makeStyles(theme => ({
  drawer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    left: '0px',
    zIndex: 16,
    top: '56px',
    [`@media (min-width: ${mobileBreakpointSidebar}px)`]: {
      top: '64px'
    }
  },
  drawerShift: {
    width: 0,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  spacer: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    right: '0px',
    bottom: '0px',
    left: '0px',
    top: '56px',
    width: '200px',
    [`@media (max-width: ${mobileBreakpointSidebar}px)`]: {
      width: 0
    }
  },
  spacerShift: {
    width: 0,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  backdrop: {
    position: 'fixed',
    zIndex: 15,
    display: 'none',
    [`@media (max-width: ${mobileBreakpointSidebar}px)`]: {
      display: 'flex'
    }
  }
}));

/**
 * Loads the common page navbar with icons, labels and links.
 * @param {string[]} nav - array of page ids
 */
export default ({ nav }: { nav: string[] }) => {
  /**
   * Parameters from the current URL
   */
  const params = useParams();

  /**
   * Navbar state and dispatcher
   */
  const navbar = useContext(NavbarContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  const classes = useStyles();

  // Filter out navbar buttons that the user doesn't have access to
  const filteredNav: string[] = [];
  for (let index in nav) {
    // Keep entry iff:
    //   1. Entry is not a page, or
    //   2. Entry is a page and does not have a permission requirement, or
    //   3. Entry has a permission requirement, and that requirement is satisfied
    const page = (pages as any)[nav[index]];
    if ((page?.permission?.indexOf(team.state.role) || 0) >= 0) {
      filteredNav.push(nav[index]);
    }
  }

  /**
   * Close navbar automatically on mobile
   */
  const closeNav = () => {
    if (viewport.state.width < mobileBreakpointSidebar) {
      navbar.dispatch({ type: 'close' });
    }
  };

  // Generates array of components containing buttons for the navbar.
  const items = filteredNav.map((key, index) => {
    // Inserts a divider when the page id is '-'.
    if (key === '-') {
      return <Divider key={`divider-${index}`}/>;
    }

    // Gets icon and URL associated with the page id.
    const page = (pages as any)[key];
    const ItemIcon = loadIcon(page.icon);
    const url = makeUrl(page.url, params);

    // Loads link with icon and text, which makes up a navbar entry.
    return (
        <Link component={RouterLink} to={url} color="inherit" underline="none" key={`nav-${key}`}>
          <ListItem onClick={closeNav} button>
            <ListItemIcon>
              <ItemIcon/>
            </ListItemIcon>
            <ListItemText primary={page.title}/>
          </ListItem>
        </Link>
    );
  });

  return (
      <>
        <Drawer variant="persistent" anchor="left" open={navbar.state.open}
                className={clsx(classes.drawer, { [classes.drawerShift]: !navbar.state.open })}>
          <List>
            {items}
          </List>
        </Drawer>

        <Grid className={clsx(classes.spacer, { [classes.spacerShift]: !navbar.state.open })}/>

        <Backdrop open={navbar.state.open} className={classes.backdrop} onClick={closeNav}/>
      </>
  );
};
