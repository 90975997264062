/**
 * @file Layout for login and registration forms
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import logoImage from '../../../assets/images/logo.png';
import skylineImage from '../../../assets/images/skyline.png';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarProvider } from '../../../hooks/message-bar';
import { loadIcon } from '../../../utils/icon';
import DynamicTitle from '../../common/dynamic-title';
import LoginForm from './login-form';
import RegisterForm from './register-form';
import VerificationForm from './verification-form';

// Breakpoints for a wide variety of screen widths
const breakpoints = createBreakpoints({});

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  paper: {
    height: '100vh'
  },
  header: {
    padding: theme.spacing(10, 0, 0, 12),
    backgroundColor: theme.palette.secondary.main,
    backgroundImage: `url(${skylineImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center bottom',
    color: theme.palette.common.white
  },
  formGrid: {
    [breakpoints.down('sm')]: {
      backgroundColor: theme.palette.secondary.main,
      backgroundImage: `url(${skylineImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: 'center bottom'
    }
  },
  formBox: {
    [breakpoints.down('sm')]: {
      marginTop: '32px',
      marginBottom: '128px',
      backgroundColor: theme.palette.common.white
    }
  },
  formBranding: {
    padding: theme.spacing(5, 0, 0, 0),
    color: theme.palette.common.white,
    display: 'none',
    [breakpoints.down('sm')]: {
      display: 'inline-block'
    }
  },
  logo: {
    display: 'inline-block',
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundImage: `url(${logoImage})`
  },
  formLogo: {
    display: 'inline-block',
    width: theme.spacing(5),
    height: theme.spacing(5),
    backgroundSize: '100%',
    backgroundImage: `url(${logoImage})`
  },
  container: {
    maxWidth: '100vw'
  }
}));

/**
 * Loads login and registration forms.
 * @param {boolean} isLogin - indicates whether this page is login (true) or registration (false)
 */
export default ({ isLogin }: { isLogin: boolean }) => {
  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Redirects to the home page if the user authenticated.
   */
  useEffect(() => {
    if (auth.state.isAuthenticated) {
      history.push(pages['home'].url);
    }
  }, [history, auth.state.isAuthenticated]);

  /**
   * @type {number} tabIndex - active tab (0 for login tab and 1 for registration tab)
   */
  const [tabIndex, setTabIndex] = useState(+!isLogin);

  /**
   * @type {string} userEmail - user's email stored for the verification page
   */
  const [userEmail, setUserEmail] = useState('');

  /**
   * Updates tab index when a new tab is pressed.
   * @param {number} newValue - new tab index (0 for login tab and 1 for registration tab)
   */
  const updateTabIndex = (_: any, newValue: number) => setTabIndex(newValue);

  // Redirects to the login page.
  const redirectToLogin = () => history.push(pages['login'].url);

  // Redirects to the registration page.
  const redirectToRegister = () => history.push(pages['register'].url);

  // Loads icons for login and registration tabs.
  const LoginIcon = loadIcon(pages['login'].icon);
  const RegisterIcon = loadIcon(pages['register'].icon);

  const classes = useStyles();
  const styles = { root: classes.root };

  const title: string = "TIPS";
  const subtitle: string = "Calgary Transit Portal";

  return (
      <DynamicTitle title={!tabIndex ? "Login" : "Registration"}>
        <Container component="main" className={classes.container}>
          <Grid container className={classes.paper}>
            <Hidden smDown>
              <Grid item md={6} className={classes.header}>
                <Typography component="h2" variant="h4" gutterBottom>
                  {title}
                  <Box ml={1} className={classes.logo}/>
                </Typography>
                <Typography component="h3" variant="h5">{subtitle}</Typography>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} className={classes.formGrid}>
              <Grid container direction="column" alignItems="center">
                <Grid item md={6} className={classes.formBranding}>
                  <Typography component="h3" variant="h5" gutterBottom>
                    {title}
                    <Box ml={1} className={classes.formLogo}/>
                  </Typography>
                  <Typography component="h4" variant="h6">{subtitle}</Typography>
                </Grid>
              </Grid>
              <Container maxWidth="xs" classes={styles}>
                <Box mt={{ xs: 8, md: 16 }} mb={4} border={1} borderColor="grey.300"
                     borderRadius="borderRadius" className={classes.formBox}>
                  <Tabs value={tabIndex} variant="fullWidth" indicatorColor="primary"
                        textColor="secondary" onChange={updateTabIndex}>
                    <Tab label={pages['login'].title} icon={<LoginIcon/>}
                         onClick={redirectToLogin}/>
                    <Tab label={pages['register'].title} icon={<RegisterIcon/>}
                         onClick={redirectToRegister}/>
                  </Tabs>
                  <Box mx={3} mt={4} mb={3}>
                    <MessageBarProvider>
                      {!tabIndex ? <LoginForm/> : !userEmail ?
                          <RegisterForm setUserEmail={setUserEmail}/> :
                          <VerificationForm userEmail={userEmail}/>}
                    </MessageBarProvider>
                  </Box>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </DynamicTitle>
  );
};
