/**
 * @file Message banner
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext } from "react";
import { MessageBannerContext } from "../../../hooks/message-banner";
import { PreferenceContext } from "../../../hooks/preference";
import LoadingButton from "../loading/loading-button";

const useStyles = makeStyles(theme => ({
  card: {
    position: 'fixed',
    zIndex: 16,
    bottom: 0,
    width: '100%',
    minHeight: theme.spacing(10),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    boxShadow: "0px -2px 33px -1px rgba(0,0,0,0.56)"
  },
  messageBannerContent: {
    [theme.breakpoints.down('xs')]: {
      order: 1
    }
  },
  checkbox: {
    color: `${theme.palette.common.white} !important`
  },
  verticalCenter: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  button: {
    color: `${theme.palette.common.white} !important`,
    borderColor: `${theme.palette.common.white} !important`,
  }
}));

/**
 * @param {any} children - body of the message
 * @param {string} title - title of the bar
 * @param {string} preferenceKey - used to read/write to the preference API for the "Don't show again" setting
 */
export default ({ children, title, preferenceKey }: { children: any, title: string, preferenceKey: string }) => {

  /**
   * Preference state and dispatcher
   *
   * We use a context to store this state so that it is persistent between pages
   */
  const preference = useContext(PreferenceContext);

  /**
   * Message banner state and dispatcher
   */
  const messageBanner = useContext(MessageBannerContext);

  /**
   * Closes the message bar
   */
  const closeMessageBanner = () => messageBanner.dispatch({ open: false });

  /**
   * Closes the message bar and saves don't show again state
   */
  const dontShowAgain = () => {
    closeMessageBanner();

    const newUIPreferences: any = {};

    newUIPreferences[preferenceKey] = true;

    preference.dispatch({
      preferences: {
        ui: newUIPreferences
      }
    })
  };

  const preferencesLoaded = preference.state.synced;
  const dontShowAgainEnabled = preference.state.preferences.ui && preference.state.preferences.ui[preferenceKey];

  const classes = useStyles();
  return messageBanner.state.open && preferencesLoaded && !dontShowAgainEnabled
      ? (
          <Card className={classes.card}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} sm={9} className={classes.messageBannerContent}>
                  <Typography component="h1" variant="h5">{title}</Typography>
                  {
                    children
                  }
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Grid container
                        spacing={2}
                        justify='flex-end'
                        className={classes.verticalCenter}>
                    <Grid item>
                      <LoadingButton type="submit" variant="outlined"
                                     onClick={closeMessageBanner} className={classes.button}>
                        Dismiss
                      </LoadingButton>
                    </Grid>
                    <Grid item>
                      <LoadingButton type="submit" variant="outlined"
                                     onClick={dontShowAgain} className={classes.button}>
                        Don't show again
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Card>
      )
      : null
}
