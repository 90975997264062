/**
 * @file Layout for reports page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import ReportForm from './report-form';

/**
 * Loads report form.
 */
export default () => (
    <Layout pageId="reports">
      <MessageBarProvider>
        <ReportForm/>
      </MessageBarProvider>
    </Layout>
);
