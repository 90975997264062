/**
 * @file Password form for user security page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from "@material-ui/core/Box";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import messages from "../../../config/messages";
import pages from "../../../config/pages.json";
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { updatePassword } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads password form for user security page.
 */
export default () => {

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {string} currentPassword - current password
   */
  const [currentPassword, setCurrentPassword] = useState('');

  /**
   * @type {string} newPassword - new password
   */
  const [newPassword, setNewPassword] = useState('');

  /**
   * @type {string} confirmPassword - password confirmation
   */
  const [confirmPassword, setConfirmPassword] = useState('');

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the current password from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateCurrentPassword = (e: React.ChangeEvent<HTMLInputElement>) => setCurrentPassword(e.target.value);

  /**
   * Updates the new password from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value);

  /**
   * Updates the password confirmation from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);

  /**
   * Clears the form.
   */
  const resetForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  /**
   * Updates user's password, loads message about request result and clears the form if successful.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const result = await updatePassword(auth.state.userId, currentPassword, newPassword, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      resetForm();
    }
    setIsSubmitting(false);
  };

  // Adds validation rule to ensure the password matches the password confirmation.
  ValidatorForm.addValidationRule('isPasswordMatched', value => value === newPassword);

  const mainDashboardUrl = pages['main-dashboard'].url;

  return (
      <Container maxWidth="xs">
        <ValidatorForm onSubmit={submitForm}>
          <Box my={2}>
            <Typography component="h2" variant="h5">Change Password</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.security.password}
              </Alert>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth type="password" label="Current password"
                             inputProps={{ 'aria-label': 'Current password' }}
                             autoFocus
                             name="current-password" value={currentPassword}
                             onChange={updateCurrentPassword}
                             validators={['required']}
                             errorMessages={['Current password is required']}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth type="password" label="New password"
                             inputProps={{ 'aria-label': 'New password' }}
                             name="new-password" value={newPassword} onChange={updateNewPassword}
                             validators={['required']}
                             errorMessages={['New password is required']}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth type="password" label="Confirm password"
                             inputProps={{ 'aria-label': 'Confirm password' }}
                             name="confirm-password" value={confirmPassword}
                             onChange={updateConfirmPassword}
                             validators={['required', 'isPasswordMatched']}
                             errorMessages={['Confirm password is required', 'Passwords do not match']}/>
            </Grid>
            <Grid item xs={12}/>

            <Grid item xs={12}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 href={mainDashboardUrl}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Update password</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  );
};
