/**
 * @file Temporal form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import messages from "../../../config/messages";
import InlineTooltip from "../../common/inline-tooltip";

/**
 * Loads temporal form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => {
  const timeOfDayList: any = {
    "entire-day": "Entire day (24 hours)",
    "am-rush-hour": "AM rush hour",
    "pm-rush-hour": "PM rush hour",
    "rush-hour": "Rush hour (AM and PM)",
    "off-peak-day": "Off-peak day",
    "off-peak-evening": "Off-peak evening",
    "night": "Night"
  };
  const dayOfWeekList: any = {
    "entire-week": "Entire week (all 7 days)",
    "weekdays": "Weekdays",
    "weekends": "Weekends"
  };

  return (
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h6" gutterBottom>Temporal</Typography>
          </Grid>
          {analysisDetail.times && !Array.isArray(analysisDetail.times) && (
              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth label="Time of day"
                           inputProps={{ 'aria-label': 'Time of day' }}
                           name="time-of-day" value={timeOfDayList[analysisDetail.times] || ''}
                           disabled={true}/>
              </Grid>
          )}
          {analysisDetail.times && Array.isArray(analysisDetail.times) && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label="Start time"
                             inputProps={{ 'aria-label': 'Start time' }}
                             name="start-time" value={analysisDetail.times[0] || ''}
                             disabled={true}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant='outlined' fullWidth label="End time"
                             inputProps={{ 'aria-label': 'End time' }}
                             name="end-time" value={analysisDetail.times[1] || ''} disabled={true}/>
                </Grid>
              </>
          )}
          {analysisDetail.days && !Array.isArray(analysisDetail.days) && (
              <Grid item xs={12}>
                <TextField variant='outlined' fullWidth label="Day of week"
                           inputProps={{ 'aria-label': 'Day of week' }}
                           name="day-of-week" value={dayOfWeekList[analysisDetail.days] || ''}
                           disabled={true}/>
              </Grid>
          )}
          {analysisDetail.days && Array.isArray(analysisDetail.days) && (
              <>
                <Grid item xs={3}>
                  <FormControlLabel label="Sunday" aria-label="Sunday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('sunday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Monday" aria-label="Monday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('monday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Tuesday" aria-label="Tuesday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('tuesday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Wednesday" aria-label="Wednesday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('wednesday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Thursday" aria-label="Thursday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('thursday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Friday" aria-label="Friday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('friday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel label="Saturday" aria-label="Saturday"
                                    control={<Checkbox
                                        checked={analysisDetail.days.indexOf('saturday') >= 0}
                                        color="primary" disabled={true}/>}/>
                </Grid>
              </>
          )}
          <Grid item xs={12} sm={6}>
            <TextField variant='outlined' fullWidth label="Start date"
                       inputProps={{ 'aria-label': 'Start date' }}
                       name="start-date" value={analysisDetail.start_date || ''} disabled={true}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField variant='outlined' fullWidth label="End date"
                       inputProps={{ 'aria-label': 'End date' }}
                       name="end-date" value={analysisDetail.end_date || ''} disabled={true}/>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel label={
              <InlineTooltip text={messages.tooltips.specialDayInsights}>
                Special day insights
              </InlineTooltip>
            }
                              control={<Checkbox checked={analysisDetail.special_day}
                                                 color="primary" disabled={true}/>}/>
          </Grid>
        </Grid>
      </Container>
  );
};
