/**
 * @file Customize bulk actions for member invites
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from 'moment';
import React, { useContext, useState } from "react";
import { SelectValidator } from "react-material-ui-form-validator";
import { useHistory, useParams } from "react-router";
import pages from "../../../config/pages.json";
import { DialogBoxContext } from "../../../hooks/dialog";
import { MountContext } from "../../../hooks/mount";
import { TeamContext } from "../../../hooks/team";
import { addTeamMemberBulk } from "../../../utils/api";
import { makeUrl } from '../../../utils/url';
import BulkTabs from '../../common/bulk-actions/bulk-tabs';
import { KeyboardDatePickerValidator } from '../../common/validator';
import DetailForm from "../invite-detail/detail-form";
import { validateEmail, validateExpires, validateRole } from "./validation";
import InlineTooltip from "../../common/inline-tooltip";
import messages from "../../../config/messages";

const useStyles = makeStyles(_ => ({
  alert: {
    overflow: 'auto'
  },
  textField: {
    minWidth: '200px'
  }
}));

/**
 * Loads member invite form
 *
 * @param {boolean} setup - Whether this component is part of a setup sequence
 */
export default ({ setup }: { setup?: boolean }) => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * Dialog box state and dispatcher
   */
  const dialogBox = useContext(DialogBoxContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {any} entries - dictionary of invitations
   */
  const [entries, setEntries] = useState({});

  /**
   * @type {any} errors - array of errors for each table row in order
   */
  const [errors, setErrors] = useState([] as any[]);

  /**
   * Navigate to next step in setup sequence
   */
  const handleNext = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 2 }));
  };

  /**
   * Navigate to next step in setup sequence
   */
  const handleBack = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 0 }));
  };

  const classes = useStyles();

  const CSVHint = () => (
      <Alert severity="info" className={classes.alert}>
        <b>Tip: CSV Imports must include these header labels in the first row:</b><br/>
        <code>email,
          <wbr/>
          role,
          <wbr/>
          expires</code><br/>
        (Expires is optional)
      </Alert>
  );

  const cancelUrl = makeUrl(pages['team-invites'].url, params);
  const setupCancelUrl = pages['teams'].url;

  /**
   * @param {string[]} errors
   */
  const rowSaveError = (errors: string[]) => {
    dialogBox.dispatch({
      open: true,
      success: false,
      body: (
          <>
            <Typography>
              Unable to save changes due to these validation errors:
            </Typography>
            <ul>
              {
                errors?.map((error) => (
                    <li key={error}>{error}</li>
                ))
              }
            </ul>
          </>
      )
    });
  };

  /**
   * @param {any} newData
   */
  const validateRow = (newData: any) => {

    let validationErrors = [];

    // Validate changes
    const validatorStatus = [
      validateRole(newData['role']),
      validateEmail(newData['email']),
      validateExpires(newData['expires'])
    ];

    for (let i = 0; i < validatorStatus.length; i++) {
      if (!validatorStatus[i].success) {
        validationErrors.push(validatorStatus[i].error);
      }
    }

    if (validationErrors.length > 0) {
      return { success: false, errors: validationErrors };
    }

    return { success: true, errors: [] }
  };

  /**
   * Adds a row to the table.
   * @param {any} newData - JSON object with data about new row
   */
  const addRow = async (newData: any) => {
    const result = validateRow(newData);

    if (result.success) {
      setEntries((prevState: any) => {
        const state = { ...prevState };

        state[newData.email] = {
          role: newData.role
        };

        // Expiry is optional
        if (newData.expires !== 'None' && newData.expires) {
          state[newData.email]['expires'] = newData.expires;
        }

        return state;
      });
    } else {
      rowSaveError(result.errors);
    }
  };

  /**
   * Updates an existing row of the table.
   * @param {any} newData - JSON object with data about new row
   * @param {any} oldData - JSON object with data about old row
   */
  const updateRow = async (newData: any, oldData: any) => {

    const newErrors = [...errors];

    // Clear error state after updating row
    const index = Object.keys(entries).indexOf(oldData.email);

    // Check if row has changed
    let changed = false;

    for (let k in newData) {
      if (newData[k] !== oldData[k]) {
        changed = true;
        break;
      }
    }

    if (changed) {
      const result = validateRow(newData);

      if (result.success) {
        setEntries((prevState: any) => {
          const state = { ...prevState };

          if (newData.email !== oldData.email) {
            delete state[oldData.email];
          }

          // Clear outstanding errors
          if (newErrors[index]) {

            // If the email has changed, the index has also changed
            if (newData.email !== oldData.email) {
              newErrors.splice(index, 1);
            } else {
              (newErrors as any)[index] = {};
            }

            setErrors(newErrors);
          }

          // Set new data
          state[newData.email] = {
            role: newData.role
          };

          // Expiry is optional
          if (newData.expires !== 'None' && newData.expires) {
            state[newData.email]['expires'] = newData.expires;
          }

          return state;
        });
      } else {
        rowSaveError(result.errors);
      }
    }
  };

  /**
   * Deletes row from the table.
   * @param {any} oldData - JSON object with data about old row
   */
  const deleteRow = async (oldData: any) => {
    setEntries((prevState: any) => {

      // Clear error state after updating row
      const index = Object.keys(entries).indexOf(oldData.email);

      const newErrors = [...errors];

      if (newErrors[index]) {
        newErrors.splice(index, 1);
        setErrors(newErrors);
      }

      const state = { ...prevState };
      delete state[oldData.email];
      return state;
    });
  };

  // Layout of table columns
  const columns = [
    {
      title: 'Email', field: 'email', width: '50%', editComponent: (props: any) => (
          <TextField variant='outlined' fullWidth label="Email"
                     inputProps={{ 'aria-label': 'Email' }} autoFocus name="email"
                     value={props.value || ''}
                     className={classes.textField}
                     onChange={e => props.onChange((e.target as HTMLInputElement).value)}/>
      )
    },
    {
      title: 'Role', field: 'role', editComponent: (props: any) => (
          <SelectValidator variant="outlined" fullWidth label="Role"
                           inputProps={{ 'aria-label': 'Role' }}
                           name="member-role" value={props.value || ''}
                           className={classes.textField}
                           onChange={e => props.onChange((e.target as HTMLInputElement).value)}
                           validators={['required']} errorMessages={['Role is required']}>
            <MenuItem value="Viewer">
              <InlineTooltip text={messages.tooltips.viewerRole}>
                Viewer
              </InlineTooltip>
            </MenuItem>
            <MenuItem value="Analyst">
              <InlineTooltip text={messages.tooltips.analystRole}>
                Analyst
              </InlineTooltip>
            </MenuItem>
            <MenuItem value="Manager">
              <InlineTooltip text={messages.tooltips.managerRole}>
                Manager
              </InlineTooltip>
            </MenuItem>
          </SelectValidator>
      )
    },
    {
      title: 'Invitation Expires (Optional)', field: 'expires', editComponent: (props: any) => (
          <KeyboardDatePickerValidator autoOk variant="inline" inputVariant="outlined" fullWidth
                                       label="Expires " inputProps={{ 'aria-label': 'Expires ' }}
                                       format="YYYY-MM-DD"
                                       name="expires-date" value={props.value || null}
                                       className={classes.textField}
                                       onChange={(date: MaterialUiPickersDate) => {
                                         const expiresDate = date?.startOf('day') || null;
                                         props.onChange(expiresDate ? moment(expiresDate).format('YYYY-MM-DD') : null);
                                       }} minDate={moment()}/>
      )
    }
  ];

  /**
   * @param {any[]} data
   */
  const validateCSV = (data: any[]) => {

    let newEntries = { ...entries };

    for (let i = 0; i < data.length; i++) {
      let row = data[i];

      // Ensures all entries are strings
      for (let j = 0; j < Object.keys(row).length; j++) {
        let key = Object.keys(row)[j];

        row[key] = row[key]?.toString().trim();
      }

      const result = validateRow(row);

      if (!result.success) {
        return { success: false, errors: result.errors, row: i + 2 };
      }

      // Format set set role
      row['role'] = row['role'].toUpperCase().charAt(0) + row['role'].toLowerCase().slice(1);

      // Format and set date
      // Expiry is optional
      if (row['expires']) {
        row['expires'] = moment(row['expires']).format('YYYY-MM-DD')
      } else {
        delete row['expires']
      }

      (newEntries as any)[row['email']] = row;
    }

    setEntries(newEntries);
    return { success: true };
  };

  const makeRequest = async () => {
    const data = Object.keys(entries).map(email => ({
      email: email,
      role: (entries as any)[email]['role'],
      expires: (entries as any)[email]['expires']
    }));

    return await addTeamMemberBulk(params.teamId, data, mount.state.signal);
  };

  const data = Object.keys(entries).map(email => ({
    email: email,
    role: (entries as any)[email]['role'],
    expires: (entries as any)[email]['expires'] || 'None'
  }));

  // Only manager can add or update a team member.
  const isAddable = team.state.role === 'Manager';

  return <BulkTabs setup={setup || false}
                   SingleForm={DetailForm}
                   handleBack={handleBack}
                   handleNext={handleNext}
                   validateCSV={validateCSV}
                   makeRequest={makeRequest}
                   CSVHint={CSVHint}
                   isAddable={isAddable}
                   columns={columns}
                   addRow={addRow}
                   updateRow={updateRow}
                   deleteRow={deleteRow}
                   cancelUrl={cancelUrl}
                   setupCancelUrl={setupCancelUrl}
                   data={data}
                   errors={errors}
                   setErrors={setErrors}
                   tableTitle="Group Invitations"/>;
}
