/**
 * @file Button that switches to a loading animation after being clicked
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import LoadingSpinner from './loading-spinner';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginLeft: theme.spacing(1)
  }
}));

/**
 * Button wrapper
 * @param {any} children - child components
 * @param {() => void | undefined} onClick - callback when Link is pressed
 * @param {any | undefined} type
 * @param {any | undefined}  variant
 * @param {any | undefined} color
 * @param {boolean | undefined} disabled
 * @param {string | undefined} href
 * @param {boolean | undefined} isSubmitting - external control of loading animation
 * @param {boolean | undefined} disableAutoLoad
 * @param {string | undefined} className
 */
export default ({ children, onClick, type, variant, color, disabled, href, isSubmitting, disableAutoLoad, className }:
                    { children: any, onClick?: any, type?: any, variant?: any, color?: any, disabled?: boolean, href?: string, isSubmitting?: boolean, disableAutoLoad?: boolean, className?: string }) => {
  /**
   * loading {boolean} - whether loading animation is active
   */
  const [loading, setLoading] = useState(false);

  /**
   * Reacts Router history object.
   */
  const history = useHistory();

  /**
   * Pushes new page to history instead of using default href to avoid having to reload the entire page.
   */
  if (href) {
    onClick = () => {
      history.push(href);
    };
  }

  const handleClick = async () => {
    setLoading(true);

    (async () => {
      if (onClick) {
        await onClick();

        // If this is a redirect, don't attempt to cancel the loading
        if (href === null) {
          setLoading(false);
        }
      }
    })();
  };

  const classes = useStyles();
  return (
      <Button type={type} variant={variant} color={color} onClick={handleClick} disabled={disabled}
              className={className}>
        {children}
        {loading && ((type !== "submit" && !disableAutoLoad) || isSubmitting) && (
            <LoadingSpinner color={variant === 'contained' ? 'inherit' : color} delay={100}
                            size="0.875rem" className={classes.spinner}/>
        )}
      </Button>
  );
};
