/**
 * @file Profile form for team profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Alert from "@material-ui/lab/Alert/Alert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { AuthContext } from "../../../hooks/auth";
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from "../../../hooks/team";
import { addTeam, updateTeam } from '../../../utils/api';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import LoadingPage from "../../common/loading/loading-page";
import DeletePanel from './delete-panel';
import LeavePanel from './leave-panel';
import { timezones } from "./timezones";

/**
 * Loads profile form for team profile page.
 *
 * @param {boolean} setup - Whether this component is part of a setup sequence
 */
export default ({ setup }: { setup?: boolean }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   userId: user id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * @type {string} teamName - team name
   */
  const [teamName, setTeamName] = useState('');

  /**
   * @type {string} description - team description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {string} role - user's role in the current team
   */
  const [role, setRole] = useState('');

  /**
   * @type {number} memberCount - number of team members
   */
  const [memberCount, setMemberCount] = useState(0);

  /**
   * @type {string} creationDate - creation date of the current team
   */
  const [creationDate, setCreationDate] = useState('');

  /**
   * @type {string} timezone - team timezone selection
   */
  const [timezone, setTimezone] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the team name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateTeamName = (e: React.ChangeEvent<HTMLInputElement>) => setTeamName(e.target.value);

  /**
   * Updates the team description from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);

  // Checks if the team is an existing team.
  const isExistingTeam = !!params.teamId;

  const dashboardUrl = makeUrl(pages['team'].url, params);
  const teamsUrl = pages['teams'].url;

  const { state: teamState } = team;

  // Get keys from timezones object to display timezone labels
  const timezoneLabels = Object.keys(timezones);

  /**
   * Helper function to retrieve timezone label for a given tz database name
   * @param {{ [key: string]: string }} timezones - timezone labels and tz database names
   * @param {string} name - tz database name
   * @return {string} - timezone label
   */
  const getTimezoneLabel = (timezones: { [key: string]: string }, name: string): string => {
    if(name === "") return "";

    const timezoneLabel = Object.keys(timezones).find(key => timezones[key] === name);

    if (timezoneLabel === undefined || timezoneLabel === null) {
      throw new TypeError("Timezone not found")
    }

    return timezoneLabel
  };

  /**
   * Sets up the team profile form.
   */
  useEffect(() => {
    (async () => {
      if (isExistingTeam) {
        setTeamName(teamState.teamData.name);
        setTimezone(teamState.teamData.timezone);
        setDescription(teamState.teamData.description);
        setRole(teamState.teamData.role);
        setMemberCount(teamState.teamData.member_count);
        setCreationDate(moment(teamState.teamData.created).format('YYYY-MM-DD, h:mm:ss A'));
      } else {
        setTimezone('');
        setRole('Manager');
        setMemberCount(1);
        setCreationDate(moment().format('YYYY-MM-DD, h:mm:ss A'));
      }

      setIsLoaded(true);
    })();
  }, [teamState, params.teamId, isExistingTeam, history, auth.dispatch, mount.state.signal]);

  /**
   * Updates the team profile if the team is existing, or
   * creates a new team and redirect to the teams page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    if (isExistingTeam) {
      const result = await updateTeam(params.teamId, teamName, timezone, description, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });

      setIsSubmitting(false);

      if (setup) {
        history.push(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 1 }));
      }
    } else {
      const result = await addTeam(teamName, timezone, description, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });
      if (result.success) {

        if (setup) {
          // This is a special case. We need to refresh the page so that the
          // page is associated with the new team id
          history.replace(makeUrl(pages['new-team'].url, {
            'teamId': result.data.id,
            'stepNumber': 1
          }));
        } else {
          history.push(pages['teams'].url);
        }
      } else {
        setIsSubmitting(false);
      }
    }
  };

  // Only manager can add or update a team.
  const isAddable = !isExistingTeam && role === 'Manager';
  const isUpdatable = isExistingTeam && role === 'Manager';
  const isEditable = isAddable || isUpdatable;

  return isLoaded ? (
      <Container maxWidth="xs">
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.teamProfile.description}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Team name"
                             inputProps={{ 'aria-label': 'Team name' }}
                             autoFocus={!isExistingTeam}
                             name="team-name" value={teamName} onChange={updateTeamName}
                             disabled={!isEditable}
                             validators={['required']} errorMessages={['Team name is required']}/>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                  fullWidth
                  disableClearable
                  disabled={!isEditable}
                  value={getTimezoneLabel(timezones, timezone)}
                  onChange={(event: any, newTimezone: string) => {
                    setTimezone(timezones[newTimezone]);
                  }}
                  options={timezoneLabels}
                  renderInput={(params) => <TextValidator {...params} fullWidth label="Timezone" 
                                                      name={(params.inputProps as any).name} value={(params.inputProps as any).value}
                                                      variant="outlined" validators={['required']} errorMessages={['Timezone is required']}/>}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Description"
                             inputProps={{ 'aria-label': 'Description' }}
                             name="description" value={description} onChange={updateDescription}
                             disabled={!isEditable}
                             multiline rows={5}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant='outlined' fullWidth label="Role"
                             inputProps={{ 'aria-label': 'Role' }} name="role" value={role}
                             disabled/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant='outlined' fullWidth label="No. of members"
                             inputProps={{ 'aria-label': 'No. of members' }} name="memberCount"
                             value={memberCount} disabled/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Creation date"
                             inputProps={{ 'aria-label': 'Creation date' }} name="creation-date"
                             value={creationDate} disabled/>
            </Grid>
            <Grid item xs={12}/>
            {
              setup
                  ? (<Grid item xs={12}>
                    <Grid container justify='flex-end' spacing={2}>
                      <Grid item xs={6}>
                        <Grid item>
                          <LoadingButton variant="outlined" color="primary"
                                         href={teamsUrl}>Cancel</LoadingButton>
                        </Grid>
                      </Grid>
                      < Grid item xs={6}>
                        <Grid container justify='flex-end'>
                          <Grid item>
                            <LoadingButton type="submit" variant="contained" color="secondary"
                                           isSubmitting={isSubmitting}>
                              {
                                isExistingTeam
                                    ? "Next"
                                    : "Create"
                              }
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>)
                  : (
                      <>
                        <Grid item xs={6}>
                          {isExistingTeam && <LeavePanel/>}
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container justify='flex-end' spacing={2}>
                            <Grid item>
                              <LoadingButton variant="contained" color="primary"
                                             href={dashboardUrl}>Back</LoadingButton>
                            </Grid>
                            {
                              isUpdatable && (
                                  <Grid item>
                                    <LoadingButton type="submit" variant="contained" color="secondary"
                                                   isSubmitting={isSubmitting}>Update</LoadingButton>
                                  </Grid>)
                            }
                            {
                              isAddable && (
                                  <Grid item>
                                    <LoadingButton type="submit" variant="contained" color="secondary"
                                                   isSubmitting={isSubmitting}>Add</LoadingButton>
                                  </Grid>
                              )
                            }
                          </Grid>
                        </Grid>
                      </>
                  )
            }
          </Grid>
        </ValidatorForm>
        {isUpdatable && !setup && (
            <>
              <Box my={4}>
                <Divider/>
                <DeletePanel/>
              </Box>
            </>)
        }
      </Container>
  ) : setup
      ? <LoadingPage minHeight="0"/>
      : <LoadingContent/>;
};
