/**
 * @file Detail form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from "../../../config/pages.json";
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from '../../../hooks/mount';
import { getAnalysisDetail } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from "../../common/loading/loading-button";
import LoadingContent from "../../common/loading/loading-content";
import FollowersForm from './followers-form';
import ProfileForm from './profile-form';
import SemanticForm from './semantic-form';
import SocialForm from './social-form';
import TemporalForm from './temporal-form';
import TermsForm from './terms-form';
import TweetsForm from './tweets-form';
import TwitterForm from './twitter-form';

/**
 * Loads detail form for analysis detail page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {any} analysisDetail - analysis detail data from the parent component
   */
  const [analysisDetail, setAnalysisDetail] = useState({} as any);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  // Generates a URL to the analysis profile page.
  const profileUrl = makeUrl(pages['analysis-profile'].url, params);

  /**
   * Finds analysis detail information.
   */
  useEffect(() => {
    (async () => {
      const result = await getAnalysisDetail(params.teamId, params.analysisId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setAnalysisDetail(result.data);
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  return isLoaded ? (
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column" alignItems="center">
            <ProfileForm analysisDetail={analysisDetail}/>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column" alignItems="center">
            <TwitterForm analysisDetail={analysisDetail}/>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column" alignItems="center">
            <TemporalForm analysisDetail={analysisDetail}/>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column" alignItems="center">
            <SemanticForm analysisDetail={analysisDetail}/>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container direction="column" alignItems="center">
            <SocialForm analysisDetail={analysisDetail}/>
          </Grid>
        </Grid>
        {(analysisDetail.analysis.status === 'In progress - analysing' || analysisDetail.analysis.status === 'Completed') && (
            <>
              <Grid item xs={12} xl={6}>
                <Grid container direction="column" alignItems="center">
                  <FollowersForm analysisDetail={analysisDetail}/>
                </Grid>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Grid container direction="column" alignItems="center">
                  <TermsForm analysisDetail={analysisDetail}/>
                </Grid>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Grid container direction="column" alignItems="center">
                  <TweetsForm analysisDetail={analysisDetail}/>
                </Grid>
              </Grid>
            </>
        )}
        <Grid item xs={12}>
          <Grid container justify='flex-end' spacing={2}>
            <Grid item>
              <LoadingButton variant="contained" color="primary"
                             href={profileUrl}>Back</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  ) : <LoadingContent/>;
};
