/**
 * @file Layout for team members page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import Layout from '../../common/layout';
import MemberForm from './member-form';

/**
 * Loads member form.
 */
export default () => (
    <Layout pageId="team-members">
      <MemberForm/>
    </Layout>
);
