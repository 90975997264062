/**
 * @file Page title bar
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

/**
 * Loads title bar.
 * @param {string} title - page title
 */
export default ({ title }: { title: string }) => (
    <Box mb={2}>
      <Typography component="h1" variant="h5">{title}</Typography>
    </Box>
);
