/**
 * @file Context for dialog box
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { createContext, useReducer } from 'react';
import LoadingButton from "../components/common/loading/loading-button";

/**
 * Creates DialogBox
 * DialogBox contains a global state for dialog box status and its content.
 * State:
 *   open: indicates whether the dialog box is visible
 *   success: true for a success message, and false for a failure message
 *   message: text to be shown on the dialog box
 *   body: component for the body of the dialog
 *   title: title for dialog box
 *   footer: component at bottom of dialog box
 */
export const DialogBoxContext = createContext({} as {
  state: { open: boolean, success?: boolean, body?: any, title?: string, footer: any },
  dispatch: React.Dispatch<{ open: boolean, success?: boolean, message?: string, body?: any, title?: string, footer?: any }>
});

/**
 * DialogBox reducer
 * @param {{ open: boolean, success?: boolean, message?: string, body?: any, title?: string, footer?: any }} action - action parameters
 */
const reducer = (_: { open: boolean, success?: boolean, body?: any, title?: string, footer?: any },
                 action: { open: boolean, success?: boolean, message?: string, body?: any, title?: string, footer?: any }) => (
    {
      open: action.open && (!!action.body || !!action.message),
      success: action.success,
      body: action.body || <Typography>{action.message}</Typography>,
      title: action.title || (action.success ? 'Alert' : 'Error'),
      footer: action.footer || null
    }
);

/**
 * DialogBox provider
 * @param {any} children - child components
 */
export const DialogBoxProvider = ({ children }: { children: any }) => {
  /**
   * Gets the current state and dispatcher of MountContext.
   * Initial state:
   *   open: false
   *   success: true
   *   message: undefined
   *   title: 'Alert'
   *   footer: null
   */
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    success: true,
    body: null,
    title: 'Alert',
    footer: null
  });

  /**
   * Closes the dialog box
   */
  const closeDialogBox = () => dispatch({
    open: false,
    success: state.success,
    body: state.body,
    title: state.title
  });

  return (
      <DialogBoxContext.Provider value={{ state, dispatch }}>
        <Dialog open={state.open} onClose={closeDialogBox}>
          <DialogTitle>{state.title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item>
                <Box ml={1}>
                  {state.body}
                </Box>
              </Grid>
            </Grid>
            {state.footer && <br/>}
            {state.footer}
          </DialogContent>
          <DialogActions>
            <LoadingButton color="secondary" onClick={closeDialogBox}
                           disableAutoLoad={true}>Dismiss</LoadingButton>
          </DialogActions>
        </Dialog>
        {children}
      </DialogBoxContext.Provider>
  )
};
