/**
 * @file Validation for table entries
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import moment from 'moment';

/**
 * @param {string} email
 */
export const validateEmail = (email: string) => {
  // Makes sure there is at least one @
  if (!email || (email.match(/@/g) || []).length === 0) {
    return { success: false, error: 'Invalid Email' };
  } else {
    return { success: true, error: '' };
  }
};

// Normalize to lowercase
const validRoles = new Set([
  'viewer',
  'analyst',
  'manager'
]);

/**
 * @param {role} role
 */
export const validateRole = (role: string) => {
  if (validRoles.has(role?.toLowerCase())) {
    return { success: true, error: '' };
  } else {
    return { success: false, error: 'Invalid role' };
  }
};

/**
 * @param {string} expires - expiry date as a string
 */
export const validateExpires = (expires: string) => {
  try {
    if (expires && expires !== 'None') {
      let date = moment(expires);

      if (!date.isValid()) {
        return { success: false, error: `Invalid expiry date "${expires}"` };
      }

      let today = moment();
      today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      if (date < today) {
        return { success: false, error: 'Expiry must be in the future' };
      }
    }

    return { success: true, error: '' };
  } catch (e) {
    return { success: false, error: `Invalid expiry date "${expires}"` };
  }
};
