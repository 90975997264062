/**
 * @file Tab page for bulk and single actions
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import MomentUtils from "@date-io/moment";
import Box from "@material-ui/core/Box";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import BulkForm from "./bulk-form";

/**
 * @param {boolean} setup - Whether this component is part of a setup sequence
 * @param {any} SingleForm - Form for non-bulk action
 * @param {() => void} handleNext - callback to step forward in setup
 * @param {() => void} handleBack - callback to step backwards in setup
 * @param {(data: any) => { success: boolean, errors?: (string | undefined)[], row?: number }} validateCSV - validates csv data and returns formatted data
 * @param {() =>  Promise<{ success: boolean, message: string, data: any, next: string, previous: string }>} makeRequest - makes API call and returns data
 * @param {any} CSVHint - component to be displayed next to CSV upload button
 * @param {boolean} isAddable - whether table is read only
 * @param {any[]} columns - array of table column metadata
 * @param {(newData: any) => Promise<any>} addRow addRow - callback to handle adding new row
 * @param {(newData: any, oldData: any) => Promise<any>} updateRow - callback to handle updating row
 * @param {(oldData: any) => Promise<any>} deleteRow - callback to handle deleting row
 * @param {any[]} data
 * @param {any[]} errors
 * @param {React.Dispatch<React.SetStateAction<[]>>} setErrors
 * @param {string} tableTitle
 */
export default ({ setup, SingleForm, handleBack, handleNext, validateCSV, makeRequest, CSVHint, isAddable, columns, addRow, updateRow, deleteRow, cancelUrl, setupCancelUrl, data, errors, setErrors, tableTitle }:
                    {
                      setup: boolean,
                      SingleForm: any,
                      handleBack: () => void,
                      handleNext: () => void,
                      validateCSV: (data: any) => { success: boolean, errors?: (string | undefined)[], row?: number },
                      makeRequest: () => Promise<{ success: boolean, message: string, data: any, next: string, previous: string }>,
                      CSVHint?: any,
                      isAddable: boolean,
                      columns: any[],
                      addRow: (newData: any) => Promise<any>,
                      updateRow: (newData: any, oldData: any) => Promise<any>,
                      deleteRow: (oldData: any) => Promise<any>,
                      cancelUrl: string,
                      setupCancelUrl: string,
                      data: any[],
                      errors: any[],
                      setErrors: React.Dispatch<React.SetStateAction<any>>
                      tableTitle: string
                    }) => {

  /**
   * @type {number} tabIndex - active tab
   */
  const [tabIndex, setTabIndex] = useState(0);

  /**
   * Updates tab index when a new tab is pressed.
   * @param {number} newValue - new tab index
   */
  const updateTabIndex = (_: any, newValue: number) => setTabIndex(newValue);

  const getTab = (index: number) => {
    switch (index) {
      case 0:
        return <SingleForm setup={setup}/>;
      case 1:
        return <BulkForm setup={setup}
                         handleBack={handleBack}
                         handleNext={handleNext}
                         validateCSV={validateCSV}
                         makeRequest={makeRequest}
                         CSVHint={CSVHint}
                         isAddable={isAddable}
                         columns={columns}
                         addRow={addRow}
                         updateRow={updateRow}
                         deleteRow={deleteRow}
                         cancelUrl={cancelUrl}
                         setupCancelUrl={setupCancelUrl}
                         data={data}
                         errors={errors}
                         setErrors={setErrors}
                         tableTitle={tableTitle}/>;
      default:
        return <p>If you're seeing this... something went wrong.</p>;
    }
  };

  return (
      <>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Container maxWidth="lg">
            <Box mt={3} mb={2} border={1} borderColor="grey.300" borderRadius="borderRadius">
              <Tabs value={tabIndex} variant="fullWidth" indicatorColor="primary"
                    textColor="secondary" onChange={updateTabIndex}>
                <Tab label={"Individual"}/>
                <Tab label={"Group"}/>
              </Tabs>
              <Box mx={3} mt={4} mb={4}>
                <Grid container spacing={2} direction="column" alignItems="center">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    {
                      getTab(tabIndex)
                    }
                  </MuiPickersUtilsProvider>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Grid>
      </>
  )
}
