/**
 * @file Disclaimer box for report sections
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useState } from "react";
import { PreferenceContext } from "../../../hooks/preference";
import LoadingButton from "../../common/loading/loading-button";

const useStyles = makeStyles(theme => ({
  buttonsWide: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  seeMore: {
    padding: 0,
    fontWeight: 'bold'
  },
  noPrint:{
    "@media print": {
      display: "none"
    }
  }
}));

/**
 * @param {string} text - text for the disclaimer
 * @param {string} preferenceKey - used to read/write to the preference API for the "Don't show again" setting
 */
export default ({ text, preferenceKey }: { text: string, preferenceKey: string }) => {

  // Characters after this point will be truncated
  const charLimit = 60;
  const truncatedText = text.substring(0, charLimit).trim();
  const isTruncated = text.length > charLimit;

  /**
   * Preference state and dispatcher
   *
   * We use a context to store this state so that it is persistent between pages
   */
  const preference = useContext(PreferenceContext);

  /**
   * @type {boolean} open
   */
  const [open, setOpen] = useState(true);

  /**
   * @type {boolean} expanded
   */
  const [expanded, setExpanded] = useState(false);

  const expand = () => setExpanded(true);
  const closeDisclaimer = () => setOpen(false);
  const dontShowAgain = () => {
    setOpen(false);

    const newUIPreferences: any = {};

    newUIPreferences[preferenceKey] = true;

    preference.dispatch({
      preferences: {
        ui: newUIPreferences
      }
    })
  };

  const preferencesLoaded = preference.state.synced;
  const dontShowAgainEnabled = preference.state.preferences.ui && preference.state.preferences.ui[preferenceKey];

  const classes = useStyles();
  return open && preferencesLoaded && !dontShowAgainEnabled
      ? <Box mb={1} className={classes.noPrint}>
        <Alert severity="warning">
          <Typography>
            {
              expanded || !isTruncated
                  ? text
                  : <>
                    {truncatedText}... <LoadingButton onClick={expand} disableAutoLoad={true}
                                                      className={classes.seeMore}>
                    See More
                  </LoadingButton>
                  </>
            }
          </Typography>
          <Grid item container
                spacing={2}
                xs={12}>
            <Grid item xs={12}/>
            <Grid item className={classes.buttonsWide}>
              <LoadingButton variant="outlined" onClick={closeDisclaimer} disableAutoLoad={true}
                             className={classes.buttonsWide}>
                Dismiss
              </LoadingButton>
            </Grid>
            <Grid item className={classes.buttonsWide}>
              <LoadingButton variant="outlined" onClick={dontShowAgain} disableAutoLoad={true}
                             className={classes.buttonsWide}>
                Don't show disclaimers again
              </LoadingButton>
            </Grid>
          </Grid>
        </Alert>
      </Box>
      : null
}
