/**
 * @file Reset Password form for reset password page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { resetPassword } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads reset password form for reset password page.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsVerified - callback for setting whether password is successfully reset
 */
export default ({ setIsVerified }: { setIsVerified: React.Dispatch<React.SetStateAction<boolean>> }) => {
  /**
   * Parameters from the current URL:
   *   uidb64: the user id encoded in base 64
   *   token: token to check that the password is valid
   */
  const params: any = useParams();
  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {string} password - password
   */
  const [password, setPassword] = useState('');

  /**
   * @type {string} confirmPassword - password confirmation
   */
  const [confirmPassword, setConfirmPassword] = useState('');

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the password from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  /**
   * Updates the password confirmation from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value);

  /**
   * Clears the form.
   */
  const resetForm = () => {
    setPassword('');
    setConfirmPassword('');
  };

  /**
   * Resets the password, loads message about request result and clears the form if successful.
   * Sets verified to true to switch to the results page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    const uidb64 = params.uidb64 || '';
    const token = params.token || '';
    const result = await resetPassword(uidb64, token, password, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      setIsVerified(true);
      resetForm();
    }
    setIsSubmitting(false);
  };

  // Adds validation rule to ensure the password matches the password confirmation.
  ValidatorForm.addValidationRule('isPasswordMatched', value => value === password);

  return (
      <Container maxWidth="xs">
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Please enter your new password.</Typography>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth type="password" label="New password"
                             inputProps={{ 'aria-label': 'New password' }}
                             autoFocus
                             name="password" value={password} onChange={updatePassword}
                             validators={['required']} errorMessages={['Password is required']}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth type="password" label="Confirm password"
                             inputProps={{ 'aria-label': 'Confirm password' }}
                             name="confirm-password" value={confirmPassword}
                             onChange={updateConfirmPassword}
                             validators={['required', 'isPasswordMatched']}
                             errorMessages={['Confirm password is required', 'Passwords do not match']}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item container xs={12} justify="flex-end">
              <LoadingButton type="submit" variant="contained" color="secondary"
                             isSubmitting={isSubmitting}>Reset Password</LoadingButton>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  );
};
