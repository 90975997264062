/**
 * @file Layout for invitation detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import DetailForm from './detail-form';

/**
 * Loads detail form.
 */
export default () => (
    <Layout pageId="team-invite-detail" center={true}>
      <MessageBarProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DetailForm/>
        </MuiPickersUtilsProvider>
      </MessageBarProvider>
    </Layout>
);
