/**
 * @file Terms table for new analysis terms page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

/**
 * Loads terms table for new analysis terms page.
 * @param {string[][]} termList - array containing metadata of terms
 * @param {boolean[][]} selections - dictionary of term selections
 * @param {React.Dispatch<React.SetStateAction<boolean[][]>>} setSelections - callback of setting dictionary of term selections
 */
export default ({ termList, selections, setSelections }: { termList: string[][], selections: boolean[][], setSelections: React.Dispatch<React.SetStateAction<boolean[][]>> }) => {
  /**
   * Updates term selections from checkbox on change.
   * @param {number} index - index of the term selections
   * @param {number} category - category name
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateSelections = (index: number, category: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSelections = [...selections];
    newSelections[index][category] = e.target.checked;
    setSelections(newSelections);
  };

  const columnTitles = ['Term', 'Associated Term 1', 'Associated Term 2'];

  return (
      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{columnTitles[0]}</TableCell>
              <TableCell align="left">{columnTitles[1]}</TableCell>
              <TableCell align="left">{columnTitles[2]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {termList.map((term, index) => (
                <TableRow hover key={`term-${index}`}>
                  {term.map((name: string, category: number) => (
                      <TableCell compact-title={columnTitles[category]} align="left"
                                 key={`category-${category}`}>
                        <FormControlLabel label={name}
                                          control={<Checkbox checked={selections[index][category]}
                                                             color="primary"
                                                             inputProps={{ 'aria-label': `Select ${name}` }}
                                                             onChange={updateSelections(index, category)}/>}/>
                      </TableCell>
                  ))}
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};
