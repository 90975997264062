/**
 * @file Profile form for new analysis profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { BlockerContext } from '../../../hooks/blocker';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { addAnalysis, getUser } from '../../../utils/api';
import { makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";

/**
 * Loads profile form for new analysis profile page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {string} analysisName - analysis name
   */
  const [analysisName, setAnalysisName] = useState('');

  /**
   * @type {string} description - analysis description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {boolean} isPrivate - indicates whether the analysis is private
   */
  const [isPrivate, setIsPrivate] = useState(false);

  /**
   * @type {string} authorName - user's name of the analysis author
   */
  const [authorName, setAuthorName] = useState('');

  /**
   * @type {string} creationDate - creation date of the analysis
   */
  const [creationDate, setCreationDate] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the analysis name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateAnalysisName = (e: React.ChangeEvent<HTMLInputElement>) => setAnalysisName(e.target.value);

  /**
   * Updates the analysis description from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);

  /**
   * Updates the private flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateIsPrivate = (e: React.ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked);

  // Generates a URL to team dashboard page for cancel button
  const cancelUrl = makeUrl(pages['team'].url, params);

  /**
   * Sets up the new analysis profile form.
   */
  useEffect(() => {
    // Sets the current user name as author name.
    (async () => {
      const result = await getUser(auth.state.userId, mount.state.signal);
      if (result.success) {
        setAuthorName(`${result.data.first_name} ${result.data.last_name}`);
      }
      setCreationDate(moment().format('YYYY-MM-DD, h:mm:ss A'));
      setIsLoaded(true);
    })();
  }, [params.teamId, auth.state.userId, history, mount.state.signal]);

  /**
   * Creates a new analysis and redirect to the new analysis twitter page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);

    const result = await addAnalysis(params.teamId, analysisName, description, isPrivate, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      // Generates a URL to the new analysis twitter page for the next button.
      const moveNextUrl = makeUrl(pages['new-analysis'].url, {
        ...params,
        analysisId: result.data.id_str,
        stepNumber: 1
      });
      redirect(moveNextUrl)();
    } else {
      setIsSubmitting(false);
    }
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    blocker.dispatch({ url: url, replace: true });
  };

  return isLoaded ? (
      <Container maxWidth="xs">
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.newAnalysisProfile.instructions}
              </Alert>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Analysis name"
                             inputProps={{ 'aria-label': 'Analysis name' }}
                             autoFocus={!analysisName}
                             name="analysis-name" value={analysisName} onChange={updateAnalysisName}
                             validators={['required']}
                             errorMessages={['Analysis name is required']}/>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel label={
                <InlineTooltip text={messages.tooltips.privateAnalysis}>
                  Private
                </InlineTooltip>
              } control={<Checkbox checked={isPrivate} color="primary"
                                   onChange={updateIsPrivate}/>}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant='outlined' fullWidth label="Description"
                             inputProps={{ 'aria-label': 'Description' }}
                             name="description" value={description} onChange={updateDescription}
                             multiline rows={5}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant='outlined' fullWidth label="Author"
                             inputProps={{ 'aria-label': 'Author' }} name="author"
                             value={authorName} disabled/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant='outlined' fullWidth label="Creation date"
                             inputProps={{ 'aria-label': 'Creation date' }} name="creation-date"
                             value={creationDate} disabled/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={6}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item container xs={6} justify="flex-end">
              <LoadingButton type="submit" variant="contained" color="secondary"
                             isSubmitting={isSubmitting}>Next</LoadingButton>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
