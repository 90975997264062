/**
 * @file Page breadcrumbs
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { makeUrl } from '../../../utils/url';

/**
 * Loads the common breadcrumbs with appropriate links and separators.
 * @param {string[]} breadcrumbs - array of ids of pages in order of hierarchy
 */
export default ({ breadcrumbs }: { breadcrumbs: string[] }) => {
  /**
   * Parameters from the current URL
   */
  const params = useParams();

  // Arrow to separate page names on bar.
  const separator = <NavigateNextIcon fontSize="small"/>;

  // Array of components that have been generated by page id.
  // Titles and URLs are generated from the page id using data from pages.json.
  // If the breadcrumb is not the last one (current page), make it a link that will return to that page.
  const links = breadcrumbs.map(key => {
    const page = (pages as any)[key];
    const title = <Typography variant="body2">{page.title}</Typography>;
    let link;
    if (key !== breadcrumbs[breadcrumbs.length - 1]) {
      let url = makeUrl(page.url, params);
      link = <Link component={RouterLink} to={url} key={`breadcrumb-${key}`}>{title}</Link>;
    } else {
      link = <Box component="span" key={`breadcrumb-${key}`}>{title}</Box>;
    }
    return link;
  });

  return (
      <Box mb={3}>
        <Box mb={1}>
          <Breadcrumbs separator={separator} maxItems={10}>
            {links}
          </Breadcrumbs>
        </Box>
        <Divider/>
      </Box>
  );
};
