/**
 * @file Context for updating viewport metadata
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useEffect, useReducer } from 'react';

/**
 * Creates ViewportContext
 * ViewportContext contains a global state for viewport metadata.
 * State:
 *   width
 *   height
 */
export const ViewportContext = createContext({} as {
  state: { width: number, height: number },
  dispatch: React.Dispatch<null>
});

/**
 * ViewportContext reducer
 */
const reducer = () => (
    { width: window.innerWidth, height: window.innerHeight }
);

/**
 * ViewportContext provider
 * @param {any} children - child components
 */
export const ViewportProvider = ({ children }: { children: any }) => {
  /**
   * Gets the current state and dispatcher of ViewportContext.
   */
  const [state, dispatch] = useReducer(reducer, {
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    window.addEventListener("resize", dispatch);

    return () => window.removeEventListener("resize", dispatch);
  }, []);

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the ViewportContext consumers.
  return (
      <ViewportContext.Provider value={{ state, dispatch }}>
        {children}
      </ViewportContext.Provider>
  );
};

export const mobileBreakpointSidebar = 820;
export const mobileBreakpointStepper = 670;
export const mobileBreakpointCompactTable = 600;
