/**
 * @file Report panel for analysis overview
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useParams } from "react-router";
import pages from '../../../config/pages.json';
import { makeUrl } from '../../../utils/url';
import LoadingButton from "../../common/loading/loading-button";

export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   stepNumber: step number
   */
  const params: any = useParams();

  // Generates a URL to the reports page.
  const reportsUrl = makeUrl(pages['reports'].url, params) + `?analysis=${params.analysisId}`;

  return <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom>The analysis is complete and is ready to be
        reviewed.</Typography>
    </Grid>
    <Grid item>
      <LoadingButton variant="contained" color="secondary" href={reportsUrl}>View
        report</LoadingButton>
    </Grid>
  </Grid>
}
