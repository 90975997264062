/**
 * @file Loading spinner after a delay
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";

/**
 * Loads the loading animation after a delay.
 * @param {number | undefined} delay - delay before loading animation appears in seconds
 * @param {size | undefined} size - size of CircularProgress
 * @param {'primary' | 'secondary' | 'inherit' | undefined} color - color of CircularProgress
 * @param {any | undefined} alternativeContent - component loaded before timeout is complete
 * @param {any | undefined} className - style for spinner
 */
export default ({ delay, size, color, alternativeContent, className }:
                    { delay?: number, size?: number | string, color?: 'primary' | 'secondary' | 'inherit', alternativeContent?: any, className?: any }) => {
  /**
   * @type {boolean} timeoutComplete - indicates whether delay has elapsed
   */
  const [timeoutComplete, setTimeoutComplete] = useState(false);

  useEffect(() => {
    // Adds a delay to prevent loading screen from flashing on fast connections.
    const timeout = setTimeout(() => setTimeoutComplete(true), delay || 100);

    // Clears timeout when component is unloaded to avoid memory leaks.
    return () => clearTimeout(timeout);
  }, [delay]);

  return timeoutComplete
      ? <CircularProgress size={size} color={color} className={className}/>
      : alternativeContent || null;
};
