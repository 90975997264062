/**
 * @file Helper functions for URL
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { History } from 'history';
import React from "react";
import pages from '../config/pages.json';
import { logout } from "./api";
import { clearTokens } from "./auth";

/**
 * Replaces placeholders in the format of ":<key>" with values from params.
 * @param {string} url - URL to be operated on
 * @param {any} params - collection of key-value pairs to be substituted into the URL
 * @return {string} - actual URL
 */
export const makeUrl = (url: string, params: any): string => {
  if (params) {
    for (let key of Object.keys(params)) {
      const pattern = new RegExp(`:${key}[^/]*`, 'g');
      url = url.replace(pattern, params[key]);
    }
  }
  return url;
};

/**
 * Redirects to an appropriate error page by statusCode.
 * @param {History} history - React Router history object
 * @param {number} statusCode - HTTP status code
 * @param {React.Dispatch<{ type: string, userId: string, userFirstName: string, userLastName: string }>} authDispatch - auth dispatch
 * @param {AbortSignal} signal
 */
export const handleHttpError = (history: History,
                                statusCode: number,
                                authDispatch: React.Dispatch<{ type: string, userId: string, userFirstName: string, userLastName: string }>,
                                signal: AbortSignal) => {
  if (statusCode === 401) {
    (async () => {
      await logout(signal);
    })();
    clearTokens();
    authDispatch({ type: 'logout', userId: '0', userFirstName: "", userLastName: "" });
    history.replace(pages['login'].url);
  } else if (statusCode === 403) {
    history.replace(pages['error-403'].url);
  } else if (statusCode >= 400 && statusCode <= 499) {
    history.replace(pages['error-404'].url);
  } else if (statusCode >= 500 && statusCode <= 599) {
    history.replace(pages['error-500'].url);
  }
};

/**
 * Checks if an HTTP status code should result in a redirect to an error page
 *
 * @param result
 */
export const isHTTPStatusCodeError = (result: any) => result.statusCode >= 400 && result.statusCode <= 599;
