/**
 * @file Analysis progress bar
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Step from "@material-ui/core/Step/Step";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";
import Stepper from "@material-ui/core/Stepper/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import { default as React, useEffect, useState } from "react";
import { generateLabel, processState } from './progressUtils';

const useStyles = makeStyles(_ => ({
  stepper: {
    padding: 0,
    background: "none"
  }
}));

/**
 * @param {any} analysisData
 */
export default ({ analysisData }: { analysisData: any }) => {

  /**
   * @type {number} activeStep
   */
  const [activeStep, setActiveStep] = useState(0);

  /**
   * @type {string} warning
   */
  const [warning, setWarning] = useState('');

  /**
   * @type {string[]} errors
   */
  const [errors, setErrors] = useState([] as string[]);

  const steps = ['Collect Tweets', 'Analyse Tweets', 'View Report'];

  useEffect(() => {
    processState(analysisData, setActiveStep, setWarning, setErrors);
  }, [analysisData]);

  const classes = useStyles();
  return <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
    {steps.map((label, index) => {

      const labelProps = generateLabel(errors,
          warning,
          activeStep,
          index,
          analysisData.status,
          label,
          true);

      return (<Step key={label}>
        <StepLabel {...labelProps} />
      </Step>)
    })}
  </Stepper>;
}
