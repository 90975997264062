/**
 * @file Login panel for header
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import pages from '../../../config/pages.json';

/**
 * Loads login panel for header.
 */
export default () => (
    <Link component={RouterLink} to={pages['login'].url} color="inherit" underline="none">
      <Button variant="outlined" color="inherit">Login</Button>
    </Link>
);
