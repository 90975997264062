/**
 * @file Layout for new team page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import StepperForm from './stepper-form';

/**
 * Sets up the page layout and loads profile form.
 */
export default () => (
    <Layout pageId={'new-team-initial'}>
      <MessageBarProvider>
        <StepperForm/>
      </MessageBarProvider>
    </Layout>
);
