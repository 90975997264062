/**
 * @file Temporal table for new analysis review page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { getLocalAnalysis } from '../../../utils/analysis';
import { makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads temporal table for new analysis review page.
 * If user clicks edit, page redirects to the new analysis temporal page.
 * @param {(url?: string) => () => void} redirect
 */
export default ({ redirect }: { redirect: (url?: string) => () => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  // Generates a URL to the new analysis temporal page for the edit button.
  const temporalUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 2 });

  // Weekday names
  const weekdayNames: any = {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday'
  };

  // Loads the cached user input for the form from the browser local storage.
  const analysis = getLocalAnalysis(params.analysisId);

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Temporal</Typography>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Time of day</TableCell>
                  <TableCell>{analysis.timeOfDayName}</TableCell>
                </TableRow>
                {analysis.timeOfDayId === 'free-input' && (
                    <>
                      <TableRow>
                        <TableCell>Start time</TableCell>
                        <TableCell>{analysis.startTime && moment(analysis.startTime).format('hh:mm A')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>End time</TableCell>
                        <TableCell>{analysis.endTime && moment(analysis.endTime).format('hh:mm A')}</TableCell>
                      </TableRow>
                    </>
                )}
                <TableRow>
                  <TableCell>Day of week</TableCell>
                  <TableCell>{analysis.dayOfWeekName}</TableCell>
                </TableRow>
                {analysis.dayOfWeekId === 'free-input' && (
                    <TableRow>
                      <TableCell>Weekdays</TableCell>
                      <TableCell>{analysis.weekdays && Object.keys(analysis.weekdays).filter(key => analysis.weekdays[key]).map(key => weekdayNames[key]).join(', ')}</TableCell>
                    </TableRow>
                )}
                <TableRow>
                  <TableCell>Start date</TableCell>
                  <TableCell>{analysis.startDate && moment(analysis.startDate).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>End date</TableCell>
                  <TableCell>{analysis.endDate && moment(analysis.endDate).format('YYYY-MM-DD')}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <InlineTooltip text={messages.tooltips.specialDayInsights}>
                      Special day insights
                    </InlineTooltip>
                  </TableCell>
                  <TableCell>{analysis.specialDay ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item justify="flex-end" container xs={12}>
          <LoadingButton variant="contained" color="secondary"
                         onClick={redirect(temporalUrl)}>Edit</LoadingButton>
        </Grid>
      </Grid>
  );
};
