/**
 * @file Stepper form for new team page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Alert from "@material-ui/lab/Alert/Alert";
import { default as React } from 'react';
import StepperForm from '../../common/stepper-form';
import TeamCredentialsTabs from "../new-credentials/credentials-tabs";
import InviteTabs from "../new-invite/invite-tabs";
import TeamProfileForm from "../profile/profile-form";
import ReviewPanel from "./review-panel";

/**
 * Loads stepper form for team profile page.
 */
export default () => {
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <TeamProfileForm setup={true}/>;
      case 1:
        return <>
          <Alert severity="info">
            You can manage your invitations after creating your team
          </Alert>
          <InviteTabs setup={true}/>
        </>;
      case 2:
        return <TeamCredentialsTabs setup={true}/>;
      case 3:
        return <ReviewPanel setup={true}/>;
      default:
        return 'Uh oh, something went wrong!';
    }
  };

  const steps = ['Configure Team', 'Invite Members', 'Add Credentials', 'Done'];

  return <StepperForm getStepContent={getStepContent} steps={steps} pageId='new-team'/>
};
