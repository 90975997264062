/**
 * @file Layout for reset password page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import ResetPasswordForm from './reset-password-form';

/**
 * Loads reset password form.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsVerified - callback for setting whether password is successfully reset
 */
export default ({ setIsVerified }: { setIsVerified: React.Dispatch<React.SetStateAction<boolean>> }) => (
    <Layout pageId="reset" center={true}>
      <MessageBarProvider>
        <ResetPasswordForm setIsVerified={setIsVerified}/>
      </MessageBarProvider>
    </Layout>
);
