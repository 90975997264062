/**
 * @file Helper functions for icon
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ChatIcon from '@material-ui/icons/Chat';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockIcon from '@material-ui/icons/Lock';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PublicIcon from '@material-ui/icons/Public';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TranslateIcon from '@material-ui/icons/Translate';
import TwitterIcon from '@material-ui/icons/Twitter';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import YouTubeIcon from '@material-ui/icons/YouTube';

/**
 * Gets the icon object by name.
 * @param {string} name - icon name
 * @return {any} - icon object
 *
 * Note: dynamically importing icons will significantly increase the size of production build.
 */
export const loadIcon = (name: string) => {
  switch (name) {
    case 'AccessTime':
      return AccessTimeIcon;
    case 'AccountBox':
      return AccountBoxIcon;
    case 'AccountTree':
      return AccountTreeIcon;
    case 'Assessment':
      return AssessmentIcon;
    case 'Assignment':
      return AssignmentIcon;
    case 'AssignmentTurnedIn':
      return AssignmentTurnedInIcon;
    case 'Chat':
      return ChatIcon;
    case 'ContactMail':
      return ContactMailIcon;
    case 'Dashboard':
      return DashboardIcon;
    case 'Description':
      return DescriptionIcon;
    case 'ExitToApp':
      return ExitToAppIcon;
    case 'FontDownload':
      return FontDownloadIcon;
    case 'GroupAdd':
      return GroupAddIcon;
    case 'LibraryBooks':
      return LibraryBooksIcon;
    case 'ListAlt':
      return ListAltIcon;
    case 'Lock':
      return LockIcon;
    case 'MailOutline':
      return MailOutlineIcon;
    case 'Person':
      return PersonIcon;
    case 'PersonAdd':
      return PersonAddIcon;
    case 'PostAdd':
      return PostAddIcon;
    case 'PublicIcon':
      return PublicIcon;
    case 'SupervisedUserCircle':
      return SupervisedUserCircleIcon;
    case 'SupervisorAccount':
      return SupervisorAccountIcon;
    case 'Translate':
      return TranslateIcon;
    case 'Twitter':
      return TwitterIcon;
    case 'ViewComfy':
      return ViewComfyIcon;
    case 'ViewCompact':
      return ViewCompactIcon;
    case 'VpnKey':
      return VpnKeyIcon;
    case 'YouTube':
      return YouTubeIcon;
    default:
      return WallpaperIcon;
  }
};
