/**
 * @file Layout for new Twitter credentials
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import messages from "../../../config/messages";
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import TeamCredentialsTabs from './credentials-tabs';

/**
 * Sets up the page layout and loads detail form.
 */
export default () => (
    <Layout pageId={'new-team-credentials'}>
      <MessageBarProvider>
        {messages.pages.newTwitterCredentials.description}
        <TeamCredentialsTabs/>
      </MessageBarProvider>
    </Layout>
);
