/**
 * @file Twitter credentials form for Twitter credentials page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../../hooks/auth';
import { MountContext } from '../../../hooks/mount';
import { getNextPage, listTwitterCredentials } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import LoadingContent from '../../common/loading/loading-content';
import BlankTable from './blank-table';
import CredentialsTable from './credentials-table';

/**
 * Loads Twitter credentials form for Twitter credentials page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {any[]} credentialsList - array containing metadata of Twitter credentials
   */
  const [credentialsList, setCredentialsList] = useState([]);

  /**
   * @type {string | null} nextPageUrl - cursor based next page URL of Twitter credentials
   */
  const [nextPageUrl, setNextPageUrl] = useState(null as string | null);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Sets up the Twitter credentials form.
   */
  useEffect(() => {
    (async () => {
      const result = await listTwitterCredentials(params.teamId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setCredentialsList(result.data);
      setNextPageUrl(result.next);
      setIsLoaded(true);
    })();
  }, [params.teamId, history, auth.dispatch, mount.state.signal]);

  /**
   * If the next page is available, adds a load more link to the bottom of the Twitter credentials table.
   * When user clicks the link, loads the next Twitter credentials items and add them to the current Twitter credentials table.
   */
  const loadMore = async () => {
    if (nextPageUrl) {
      const result = await getNextPage(nextPageUrl, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setCredentialsList(credentialsList.concat(result.data));
      setNextPageUrl(result.next);
    }
  };

  // Loads the blank table if there is no Twitter credentials. Otherwise, loads the credentials table.
  return isLoaded
      ? credentialsList.length > 0
          ? <CredentialsTable credentialsList={credentialsList} hasMore={!!nextPageUrl}
                              loadMore={loadMore}/>
          : <BlankTable/>
      : <LoadingContent/>;
};
