/**
 * @file Loading panel to span content section
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LoadingSpinner from './loading-spinner';

/**
 * Loads the loading animation and fills the page.
 *
 * @param {number | undefined} height - height of the component *
 */
export default ({ height }: { height?: number }) => {

  const useStyles = makeStyles(theme => ({
    spinner: {
      minHeight: height ? height : 'calc(100vh - 370px)',
      [theme.breakpoints.down('xs')]: {
        minHeight: height ? height : 'calc(100vh - 365px)'
      },
      margin: '0 !important',
      width: '100% !important'
    }
  }));

  const classes = useStyles();
  return (
      <Grid container spacing={0} direction="column" justify="center" alignItems="center"
            className={classes.spinner}>
        <LoadingSpinner/>
      </Grid>
  );
};
