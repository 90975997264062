/**
 * @file Analysis form for analyses page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from '../../../hooks/mount';
import { getNextPage, listAnalyses, listTeams } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";
import AnalysisTable from './analysis-table';
import BlankTable from './blank-table';

/**
 * Loads analysis form for analysis page.
 *
 * @param {string | undefined} query - filter query for analyses
 * @param {boolean | undefined} dashboard - whether this form is part of a dashboard
 * @param {boolean | undefined} mainDashboard - whether this form is part of the main dashboard (dashboard must be true if mainDashboard is true)
 * @param {React.Dispatch<React.SetStateAction<{ [id: string]: string[] }>> | undefined} setStatuses - set statuses dictionary on dashboard page
 */
export default ({ query, dashboard, mainDashboard, setStatuses }: { query?: string, dashboard?: boolean, mainDashboard?: boolean, setStatuses?: React.Dispatch<React.SetStateAction<{ [id: string]: string[] }>> }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {any[]} analysisList - array containing metadata of analyses
   */
  const [analysisList, setAnalysisList] = useState([]);

  /**
   * @type {string | null} nextPageUrl - cursor based next page URL of analyses
   */
  const [nextPageUrl, setNextPageUrl] = useState(null as string | null);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} moreResultsOutsideQuery - whether there are more analyses that can be displayed, but don't match the search criteria
   */
  const [moreResultsOutsideQuery, setMoreOutsideQuery] = useState(false);

  /**
   * Sets up the analyses table.
   */
  useEffect(() => {
    (async () => {

      /**
       * Helper function to update dashboard status banners
       *
       * @param {any[]} analyses
       */
      const updateStatuses = (analyses: any[]) => {
        let data: any;

        for (let a = 0; a < analyses.length; a++) {
          data = analyses[a];

          if (!currentStatuses[data.status]) {
            currentStatuses[data.status] = [] as string[];
          }

          currentStatuses[data.status].push(data.id_str);
        }
      };

      /**
       * Helper function to check if there are any analyses outside of the search query.
       * Used to decide whether to tell user to revise query/look elsewhere.
       *
       * Returns whether the operation was successful.
       *
       * @param {string} teamId
       * @return {boolean}
       */
      const checkAnalysesOutsideQuery = async (teamId: string) => {
        const result2 = await listAnalyses(teamId, mount.state.signal, undefined, true);
        if (result2.statusCode >= 400 && result2.statusCode <= 599) {
          handleHttpError(history, result2.statusCode, auth.dispatch, mount.state.signal);
          return false;
        }

        if (result2.resourceCount > 0) {
          setMoreOutsideQuery(true);
        }

        return true;
      };

      /**
       * Helper function to get analyses from a team
       *
       * Returns a dictionary when the operation is successful, null otherwise.
       *
       * @param {number} teamId
       * @return {any | null}
       */
      const getAnalyses = async (teamId: string) => {
        const result = await listAnalyses(teamId, mount.state.signal, query);

        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return null;
        }

        // If nothing is returned, we check if there are other analyses available
        if (query && result.data.length === 0) {
          // Check if there was an error with this operation
          if (!await checkAnalysesOutsideQuery(teamId)) {
            return null;
          }
        }

        updateStatuses(result.data);

        return result;
      };

      /**
       * Helper function to get an array of analyses from across the top 10 most
       * recently updated teams the user is part of.
       *
       * Returns an array when the operation is successful, null otherwise.
       *
       * @return {any[] | null}
       */
      const getAnalysesFromTeams = async () => {
        let formattedAnalyses: any = [];

        const teamsResult = await listTeams(mount.state.signal, "?per_page=10&_full=false");
        if (teamsResult.statusCode >= 400 && teamsResult.statusCode <= 599) {
          handleHttpError(history, teamsResult.statusCode, auth.dispatch, mount.state.signal);
          return null;
        }

        const teams = teamsResult.data;

        // Asynchronously fetch the analyses from all the teams
        let team: any;
        let teamPromises: Promise<any>[] = [];
        let analyses: any;

        for (let t = 0; t < teams.length; t++) {
          teamPromises.push(getAnalyses(teams[t].id_str));
        }

        const teamsResults = await Promise.all(teamPromises);

        // Add reference to the parent team for each analysis
        for (let t = 0; t < teamsResults.length; t++) {
          // Check if the data is valid
          if (teamsResults[t]) {
            analyses = teamsResults[t]?.data;
            team = teams[t];

            for (let a = 0; a < analyses.length; a++) {
              formattedAnalyses.push({ ...analyses[a], teamName: team.name });
            }
          } else {
            // We'll need to return here to avoid rendering on an unloaded page
            // while we're getting redirected to the error page
            return null;
          }
        }

        return formattedAnalyses;
      };

      /**
       * Sort function for analyses (based on updated date)
       * @param {any} a
       * @param {any} b
       */
      const sortAnalyses = (a: any, b: any) => {
        if (a.updated < b.updated) {
          return 1;
        }

        if (a.updated > b.updated) {
          return -1;
        }

        return 0;
      };

      const currentStatuses: { [id: string]: string[] } = {};
      let unsortedAnalyses: any = [];

      // If it's the main dashboard, we'll try to get the analyses from the 10 most recent teams we're in
      if (mainDashboard) {
        unsortedAnalyses = await getAnalysesFromTeams();

        // We'll need to return here to avoid rendering on an unloaded page
        // while we're getting redirected to the error page
        if (!unsortedAnalyses) {
          return;
        }
      } else {
        const analysisData = await getAnalyses(params.teamId);
        // Check if the data is valid
        if (analysisData) {
          unsortedAnalyses = analysisData?.data;
          setNextPageUrl(analysisData?.next);
        } else {
          // We'll need to return here to avoid rendering on an unloaded page
          // while we're getting redirected to the error page
          return;
        }
      }

      // Sort by last modified for dashboards
      if (dashboard) {
        unsortedAnalyses.sort(sortAnalyses);
      }

      if (setStatuses) {
        setStatuses(currentStatuses);
      }

      setAnalysisList(unsortedAnalyses);
      setIsLoaded(true);
    })();
  }, [dashboard, mainDashboard, setStatuses, query, params.teamId, auth.dispatch, history, mount.state.signal]);

  /**
   * If the next page is available, adds a load more link to the bottom of the analysis table.
   * When user clicks the link, loads the next analysis items and add them to the current analysis table.
   */
  const loadMore = async () => {
    if (nextPageUrl) {
      const result = await getNextPage(nextPageUrl, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setAnalysisList(analysisList.concat(result.data));
      setNextPageUrl(result.next);
    }
  };

  // Loads the blank table if there is no analysis. Otherwise, loads the analysis table.
  return isLoaded
      ? analysisList.length > 0
          ? <AnalysisTable analysisList={analysisList} hasMore={!!nextPageUrl}
                           mainDashboard={mainDashboard} dashboard={dashboard} loadMore={loadMore}/>
          : <BlankTable dashboard={dashboard} mainDashboard={mainDashboard}
                        moreResultsOutsideQuery={moreResultsOutsideQuery}/>
      : <LoadingContent/>;
};
