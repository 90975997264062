/**
 * @file Layout for teams page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import Layout from '../../common/layout';
import TeamForm from './team-form';

/**
 * Loads team form.
 */
export default () => (
    <Layout pageId="teams">
      <TeamForm/>
    </Layout>
);
