/**
 * @file Tweets form for new analysis tweets page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { AuthContext } from "../../../hooks/auth";
import { BlockerContext } from '../../../hooks/blocker';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, removeLocalAnalysis } from '../../../utils/analysis';
import { getTweetCount, updateAnalysisConfiguration } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";
import { RadioButtonValidator } from '../../common/validator';

const useStyles = makeStyles(_ => ({
  warning: {
    lineHeight: 1.75,
    fontWeight: 600
  }
}));

/**
 * Loads tweets form for new analysis tweets page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {number} tweetCount - count of tweets
   */
  const [tweetCount, setTweetCount] = useState(0);

  /**
   * @type {string} processingMethod - processing method id
   */
  const [processingMethod, setProcessingMethod] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the processing method from radio button on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateProcessingMethod = (e: React.ChangeEvent<HTMLInputElement>) => setProcessingMethod(e.target.value);

  // Generates a URL to the new analysis terms page for the back button.
  const moveBackUrl = makeUrl(pages['configure-analysis'].url, { ...params, stepNumber: 1 });

  // Generates a URL to the analyses page for the cancel button.
  const cancelUrl = makeUrl(pages['analysis'].url, params);

  /**
   * Sets up the new analysis tweets form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    if (analysis.tweetCount) {
      setTweetCount(analysis.tweetCount);
    }
    if (analysis.processingMethod) {
      setProcessingMethod(analysis.processingMethod);
    }

    (async () => {
      if (!analysis.tweetCount) {
        const result = await getTweetCount(params.teamId, params.analysisId, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        setTweetCount(result.data.total_tweets);
      }
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  /**
   * Submits the second stage new analysis forms, and redirects to the analysis profile page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);

    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    const accounts = analysis.followerList.map((follower: any, index: number) => ({
      "id": follower.id,
      "screen_name": follower.screen_name,
      "keep": analysis.followerSelections[index]
    }));
    const terms = analysis.termList.map((term: any, index: number) => ({
      "main": {
        "term": term[0],
        "keep": analysis.termSelections[index][0]
      },
      "sub1": {
        "term": term[1],
        "keep": analysis.termSelections[index][1]
      },
      "sub2": {
        "term": term[2],
        "keep": analysis.termSelections[index][2]
      },
    }));
    const isExpandedCollection = processingMethod === 'expand';

    const result = await updateAnalysisConfiguration(params.teamId, params.analysisId, accounts, terms, isExpandedCollection, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      removeLocalAnalysis(params.analysisId);
      // Generates a URL to the analysis page for the next button.
      const moveNextUrl = makeUrl(pages['analysis'].url, params);
      redirect(moveNextUrl)();
    } else {
      setIsSubmitting(false);
    }
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    blocker.dispatch({ url: url });
  };

  const processingMethodOptions = [
    {
      "id": "proceed",
      "label": "Proceed with the analysis using the tweets extracted using mentions and hashtags only"
    },
    {
      "id": "expand",
      "label": "Expand the set of tweets for the analysis by evaluating all the tweets sent by the followers of the target account"
    }
  ];

  const classes = useStyles();
  return isLoaded ? (
      <Container maxWidth="xs">
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Box p={1} color="secondary.main" border={1} borderColor="secondary.main"
                   borderRadius="borderRadius">
                <Box display="flex" justifyItems="center">
                  <Typography component="span">
                    <WarningRoundedIcon/>
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.warning}>
                    Warning
                  </Typography>
                </Box>
                <Typography variant="body2" gutterBottom>
                  Expanding the corpus requires extracting and processing all tweets sent out by the
                  followers of the target account.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  This typically exceeds the monthly caps set out by Twitter’s free and paid plans.
                  Proceeding will most likely consume the entire month’s requests.
                </Typography>
                <Typography variant="body2">
                  Due to the large size of the dataset, extraction and processing will take a
                  substantial amount of time.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={6}>
              <TextValidator variant="outlined" fullWidth label="# of tweets currently available"
                             inputProps={{ 'aria-label': '# of tweets currently available' }}
                             name="tweet-count" value={tweetCount} disabled/>
            </Grid>
            <Grid item xs={12}>
              <RadioButtonValidator name="processing-method" value={processingMethod}
                                    onChange={updateProcessingMethod}
                                    label="Processing method"
                                    inputProps={{ 'aria-label': 'Processing method' }}
                                    options={processingMethodOptions}
                                    validators={['required', 'matchRegexp:^proceed$']}
                                    errorMessages={['Processing method is required', 'This feature is currently under testing, and will open to the public soon.']}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={4}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Next</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
