/**
 * @file Semantic form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import messages from "../../../config/messages";
import InlineTooltip from "../../common/inline-tooltip";

/**
 * Loads semantic form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => {
  return <Container maxWidth="md">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h6" gutterBottom>Semantic</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={props => <Paper variant="outlined" {...props} />}>
          <Table className="collapse-table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Categories</TableCell>
                <TableCell align="left">Terms</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(analysisDetail.lexicon).map((name, index) => (
                  <TableRow hover key={`entry-${index}`}>
                    <TableCell compact-title="Category" align="left">{name}</TableCell>
                    <TableCell compact-title="Terms"
                               align="left">{analysisDetail.lexicon[name].join(', ')}</TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel label={
          <InlineTooltip text={messages.tooltips.semanticSentimentInsights}>
            Semantic-sentiment insights
          </InlineTooltip>
        }
                          control={<Checkbox checked={analysisDetail.semantic_insights}
                                             color="primary" disabled={true}/>}/>
      </Grid>
    </Grid>
  </Container>
}
