/**
 * @file Time picker validator
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { KeyboardTimePicker } from '@material-ui/pickers';
import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";

/**
 * Class of custom time picker validator
 * @see {@link https://github.com/NewOldMax/react-material-ui-form-validator}
 */
export default class KeyboardTimePickerValidator extends ValidatorComponent {
  render() {
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      ...rest
    } = this.props;

    const {isValid} = this.state;
    return (
        <KeyboardTimePicker
            {...rest}
            error={!isValid}
            helperText={(!isValid && this.getErrorMessage()) || helperText}
        />
    );
  }
}
