/**
 * @file Setup panel for analysis overview
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useParams } from "react-router";
import pages from '../../../config/pages.json';
import { makeUrl } from '../../../utils/url';
import LoadingButton from "../../common/loading/loading-button";

/**
 * @param {String} status
 * @param {() => void} cancelManagedAnalysis
 * @param {boolean} isUpdatable
 */
export default ({ status, cancelManagedAnalysis, isUpdatable }: { status: String, cancelManagedAnalysis: () => void, isUpdatable: boolean }) => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  // Generates a URL to the new analysis twitter page.
  const newAnalysisURL = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 6 });

  return <Grid container spacing={2}>
    {
      status === "Not started"
          ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>You haven't finished setting up your
                    analysis.</Typography>
                </Grid>
                {
                  isUpdatable && (<Grid item>
                    <LoadingButton variant="contained" color="secondary" href={newAnalysisURL}>
                      Continue setup
                    </LoadingButton>
                  </Grid>)
                }
              </>
          )
          : (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    {
                      status === "Configured"
                          ? "The analysis has been configured. Tweet collection will begin soon."
                          : "Tweets are currently being collected. You'll receive an email when this process is complete."
                    }
                  </Typography>
                </Grid>
                {
                  isUpdatable && (<Grid item>
                    <LoadingButton variant="contained" color="secondary"
                                   onClick={cancelManagedAnalysis}
                                   disableAutoLoad={true}>Cancel
                      analysis</LoadingButton>
                  </Grid>)
                }
              </>
          )
    }
  </Grid>
}
