/**
 * @file Detail form for lexicon detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from '../../../hooks/team';
import { ViewportContext } from "../../../hooks/viewport";
import { getLocalAnalysis, setLocalAnalysis } from "../../../utils/analysis";
import {
  addLexicon,
  getLexicon,
  getNextPage,
  getTemplate,
  getUser,
  listTemplates,
  updateLexicon
} from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import DeletePanel from './delete-panel';
import LexiconTable from './lexicon-table';

// noinspection JSUnusedLocalSymbols
const useStyles = makeStyles(_ => ({
  padding: {
    padding: '5px'
  },
  table: {
    marginTop: '15px'
  }
}));

/**
 * Loads detail form for lexicon detail page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   lexiconId: lexicon id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  /**
   * @type {string} lexiconName - lexicon name
   */
  const [lexiconName, setLexiconName] = useState('');

  /**
   * @type {string} description - lexicon description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {boolean} isPrivate - indicates whether the lexicon is private
   */
  const [isPrivate, setIsPrivate] = useState(false);

  /**
   * @type {string} authorId - user id of the lexicon author
   */
  const [authorId, setAuthorId] = useState('0');

  /**
   * @type {string} authorName - user's name of the lexicon author
   */
  const [authorName, setAuthorName] = useState('');

  /**
   * @type {string} creationDate - creation date of the lexicon
   */
  const [creationDate, setCreationDate] = useState('');

  /**
   * @type {string} lastModified - last modified date of the lexicon
   */
  const [lastModified, setLastModified] = useState('');

  /**
   * @type {string} templateId - lexicon template id
   */
  const [templateId, setTemplateId] = useState('0');

  /**
   * @type {any[]} templateList - array containing metadata of lexicon templates
   */
  const [templateList, setTemplateList] = useState([] as any[]);

  /**
   * @type {string} entries - dictionary of lexicon entries
   */
  const [entries, setEntries] = useState({});

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the lexicon name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateLexiconName = (e: React.ChangeEvent<HTMLInputElement>) => setLexiconName(e.target.value);

  /**
   * Updates the lexicon description from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateDescription = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);

  /**
   * Updates the private flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateIsPrivate = (e: React.ChangeEvent<HTMLInputElement>) => setIsPrivate(e.target.checked);

  /**
   * Updates the template from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateTemplateId = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTemplateId(e.target.value);
    await loadLexiconTable(e.target.value);
  };

  // Determines the redirect URL based on the current URL.
  const redirectUrl = params.analysisId
      ? makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 3 })
      : makeUrl(pages['lexicons'].url, params);

  /**
   * Loads the lexicon table from a template.
   * @param {string} templateId - template id
   */
  const loadLexiconTable = async (templateId: string) => {
    if (templateId !== '0') {
      const result = await getTemplate(templateId, mount.state.signal);
      if (result.success) {
        setEntries(result.data.entries);
      }
    } else {
      setEntries([]);
    }
  };

  // Checks if the team is an existing lexicon.
  const isExistingLexicon = !!params.lexiconId;

  /**
   * Sets up the lexicon detail form.
   */
  useEffect(() => {
    (async () => {
      if (isExistingLexicon) {
        // Retrieves data for existing lexicon.
        const result = await getLexicon(params.teamId, params.lexiconId, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        setLexiconName(result.data.name);
        setDescription(result.data.description);
        setIsPrivate(!result.data.public);
        setAuthorId(result.data.author?.id_str || '0');
        setAuthorName(result.data.author ? `${result.data.author.first_name} ${result.data.author.last_name}` : '');
        setCreationDate(moment(result.data.created).format('YYYY-MM-DD, h:mm:ss A'));
        setLastModified(moment(result.data.updated).format('YYYY-MM-DD, h:mm:ss A'));
        setEntries(result.data.entries);
      } else {
        // Sets author creation date, and last modified date for new lexicon.
        setAuthorId(auth.state.userId);
        const result = await getUser(auth.state.userId, mount.state.signal);
        if (result.success) {
          setAuthorName(`${result.data.first_name} ${result.data.last_name}`);
        }
        setCreationDate(moment().format('YYYY-MM-DD, h:mm:ss A'));
        setLastModified(moment().format('YYYY-MM-DD, h:mm:ss A'));
      }

      let result = await listTemplates(mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      let data = result.data;
      while (result.next) {
        result = await getNextPage(result.next, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }
        data = data.concat(result.data);
      }
      setTemplateList(data);
      setIsLoaded(true);
    })();
  }, [params.teamId, params.lexiconId, auth.state.userId, isExistingLexicon, history, auth.dispatch, mount.state.signal]);

  /**
   * Updates the lexicon detail if the lexicon is existing, or
   * creates a new lexicon and redirect to the lexicons page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);
    if (isExistingLexicon) {
      const result = await updateLexicon(params.teamId, params.lexiconId, lexiconName, description, isPrivate, entries, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });

      setIsSubmitting(false);
    } else {
      const result = await addLexicon(params.teamId, lexiconName, description, isPrivate, entries, mount.state.signal);
      messageBar.dispatch({ open: true, success: result.success, message: result.message });

      // If this lexicon is created for a new analysis
      // set the selected lexicon to this one
      if (params.analysisId) {
        const analysis = getLocalAnalysis(params.analysisId);
        analysis.lexiconId = result.data.id_str;
        analysis.lexiconName = lexiconName;
        analysis.lexiconEntries = entries;

        const initialSelections: any = {};
        Object.keys(entries).forEach(name => initialSelections[name] = true);

        analysis.lexiconSelections = initialSelections;

        setLocalAnalysis(params.analysisId, analysis);
      }

      // Checks if the user is coming from the new analysis semantic page.
      // If yes, redirects to the semantic page. Otherwise, redirects to the lexicons page.
      if (result.success) {
        history.push(redirectUrl);
      } else {
        setIsSubmitting(false)
      }
    }
  };

  // Only analyst and manager can add a lexicon.
  // Only author and manager can delete a lexicon.
  // Manager can update a lexicon.
  // Analyst can update a public lexicon.
  // Author can update a public or private lexicon.
  // Only author and manager can change the private state of a lexicon
  const isAddable = !isExistingLexicon && team.state.role !== 'Viewer';
  const isDeletable = isExistingLexicon && team.state.role !== 'Viewer' && (team.state.role === 'Manager' || authorId === auth.state.userId);
  const isUpdatable = isExistingLexicon && team.state.role !== 'Viewer' && (team.state.role === 'Manager' || authorId === auth.state.userId || (!isPrivate && team.state.role === 'Analyst'));
  const isEditable = isAddable || isDeletable || isUpdatable;
  const isPrivatable = team.state.role === 'Manager' || authorId === auth.state.userId;

  // Move delete panel after table on smaller screens
  const tableBreakpoint = 1280;

  const classes = useStyles();
  return isLoaded ? (
      <>
        <ValidatorForm onSubmit={submitForm}>
          <Grid container>
            <Grid item xs={12} lg={4} className={classes.padding}>
              <Grid container direction="column" alignItems="center">
                <Container maxWidth="md">
                  <Grid container spacing={2}>
                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                      <Alert severity="info">
                        {messages.pages.lexiconDetails.description}
                      </Alert>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator variant='outlined' fullWidth label="Lexicon name"
                                     inputProps={{ 'aria-label': 'Lexicon name' }}
                                     autoFocus={!isExistingLexicon}
                                     name="lexicon-name" value={lexiconName}
                                     onChange={updateLexiconName}
                                     disabled={!isEditable}
                                     validators={['required']}
                                     errorMessages={['Lexicon name is required']}/>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel label={
                        <InlineTooltip
                            text={isPrivatable ? messages.tooltips.privateLexicon : messages.tooltips.privateLexiconDisabled}>
                          Private
                        </InlineTooltip>
                      }
                                        control={<Checkbox checked={isPrivate} color="primary"
                                                           onChange={updateIsPrivate}/>}
                                        disabled={!isEditable || !isPrivatable}/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator variant='outlined' fullWidth label="Description"
                                     inputProps={{ 'aria-label': 'Description' }}
                                     name="description" value={description}
                                     onChange={updateDescription}
                                     disabled={!isEditable}
                                     multiline rows={5}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectValidator variant="outlined" fullWidth label="Template"
                                       inputProps={{ 'aria-label': 'Template' }}
                                       name="template" value={templateId}
                                       onChange={updateTemplateId} disabled={!isEditable}>
                        <MenuItem value="0">None</MenuItem>
                        {templateList.map((template: any) => <MenuItem value={template.id_str}
                                                                       key={`template-${template.id_str}`}>{template.name}</MenuItem>)}
                      </SelectValidator>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextValidator variant='outlined' fullWidth label="Author"
                                     inputProps={{ 'aria-label': 'Author' }} name="author"
                                     value={authorName}
                                     disabled/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator variant='outlined' fullWidth label="Creation date"
                                     inputProps={{ 'aria-label': 'Creation date' }}
                                     name="creation-date"
                                     value={creationDate} disabled/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator variant='outlined' fullWidth label="Last modified"
                                     inputProps={{ 'aria-label': 'Last modified' }}
                                     name="last-modified"
                                     value={lastModified} disabled/>
                    </Grid>
                    <Grid item xs={12}/>
                  </Grid>
                </Container>
                <Grid container spacing={2}>
                  <Grid item xs={12}/>
                </Grid>

                <Container maxWidth='md'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                      <Grid container justify='flex-end' spacing={2}>
                        <Grid item>
                          <LoadingButton variant="contained" color="primary"
                                         href={redirectUrl}>{isAddable ? 'Cancel' : 'Back'}</LoadingButton>
                        </Grid>

                        {(isAddable || isUpdatable) && (
                            <Grid item>
                              <LoadingButton type="submit" variant="contained"
                                             isSubmitting={isSubmitting}
                                             color="secondary">{isAddable ? 'Add' : 'Update'}</LoadingButton>
                            </Grid>)
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
                {
                  viewport.state.width >= tableBreakpoint && isDeletable && (
                      <Container maxWidth='md'>
                        <Box my={4}>
                          <Divider/>
                        </Box>
                        <DeletePanel/>
                      </Container>
                  )
                }
              </Grid>
            </Grid>
            <Grid item xs={12} lg={8} className={classes.padding}>
              <Grid direction="column" alignItems="center" className={classes.table} container>
                <LexiconTable entries={entries} setEntries={setEntries} isEditable={isEditable}/>
              </Grid>
            </Grid>
            {
              viewport.state.width < tableBreakpoint && isDeletable && (
                  <Grid direction="column" alignItems="center" container>
                    <Container maxWidth='md'>
                      <Box my={4}>
                        <Divider/>
                      </Box>
                      <DeletePanel/>
                    </Container>
                  </Grid>
              )
            }
          </Grid>
        </ValidatorForm>
      </>
  ) : <LoadingContent/>;
};
