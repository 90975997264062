/**
 * @file Profile form for analysis detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import React from 'react';

/**
 * Loads profile form for analysis detail page.
 * @param {any} analysisDetail - analysis detail data from the parent component
 */
export default ({ analysisDetail }: { analysisDetail: any }) => (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h6" gutterBottom>Profile</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField variant='outlined' fullWidth label="Analysis name"
                     inputProps={{ 'aria-label': 'Analysis name' }}
                     name="analysis-name" value={analysisDetail.analysis.name || ''}
                     disabled={true}/>
        </Grid>
        <Grid item xs={12}>
          <TextField variant='outlined' fullWidth label="Status"
                     inputProps={{ 'aria-label': 'Status' }}
                     name="status" value={analysisDetail.analysis.status || ''} disabled={true}/>
        </Grid>
      </Grid>
    </Container>
);
