/**
 * @file Verification form for login page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { sendVerificationEmail } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads verification form for login page.
 * @param {string} userEmail - user's email where the confirmation email has been sent to
 */
export default ({ userEmail }: { userEmail: string }) => {
  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {boolean} buttonDisabled - indicates whether the resend email button is disabled
   */
  const [buttonDisabled, setButtonDisabled] = useState(false);

  /**
   * Sends the verification email, and prevents the user to resubmit it immediately.
   */
  const submitForm = async () => {
    const result = await sendVerificationEmail(userEmail, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 10000);
    }
  };

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h2" variant="h5" gutterBottom>Please verify your email
            address</Typography>
          <Typography variant="body1" gutterBottom>
            For added security, we need to verify your email address. We’ve sent an email
            to {userEmail}.
            Please click the link in that email to continue the registration process.
          </Typography>
        </Grid>
        <Grid item xs={12}/>
        <Grid item container xs={12} justify="flex-end">
          <LoadingButton variant="contained" color="secondary" disabled={buttonDisabled}
                         onClick={submitForm}>Resend</LoadingButton>
        </Grid>
      </Grid>
  );
};
