/**
 * @file Layout for user security page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import EmailForm from './email-form';
import PasswordForm from './password-form';

/**
 * Loads password form.
 */
export default () => (
    <Layout pageId="security" center={true}>
      <MessageBarProvider>
        <Container maxWidth="xs">
          <PasswordForm/>
          <Box my={4}>
            <Divider/>
          </Box>
          <EmailForm/>
        </Container>
      </MessageBarProvider>
    </Layout>
);
