/**
 * @file Custom styles and main router for components
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import grey from '@material-ui/core/colors/grey';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import pages from '../../config/pages.json';
import { AuthProvider } from '../../hooks/auth';
import { DialogBoxProvider } from "../../hooks/dialog";
import { MessageBannerProvider } from "../../hooks/message-banner";
import { MountProvider } from '../../hooks/mount';
import { PreferenceProvider } from "../../hooks/preference";
import { TeamProvider } from "../../hooks/team";
import {
  mobileBreakpointCompactTable,
  mobileBreakpointSidebar,
  ViewportProvider
} from '../../hooks/viewport';
import Analyses from '../analysis/analyses';
import ConfigureAnalysis from '../analysis/configure-analysis';
import AnalysisDetail from '../analysis/detail';
import NewAnalysis from '../analysis/new-analysis';
import AnalysisOverview from '../analysis/overview';
import AnalysisProfile from '../analysis/profile';
import MainDashboard from '../dashboard';
import LexiconDetail from '../lexicon/detail';
import Lexicons from '../lexicon/lexicons';
import Reports from '../report/reports';
import TeamCredentials from '../team/credentials';
import TeamCredentialsDetail from '../team/credentials-detail';
import TeamDashboard from '../team/dashboard';
import TeamInviteDetail from '../team/invite-detail';
import TeamInvites from '../team/invites';
import TeamMemberProfile from '../team/member-profile';
import TeamMembers from '../team/members';
import NewTeamCredentials from '../team/new-credentials';
import Invite from '../team/new-invite';
import NewTeam from '../team/new-team';
import TeamProfile from '../team/profile';
import Teams from '../team/teams';
import Confirm from '../user/confirm';
import Login from '../user/login';
import UserProfile from '../user/profile';
import Reset from '../user/reset';
import UserSecurity from '../user/security';
import Verify from '../user/verify';
import Contact from './contact';
import Disclaimers from './disclaimers';
import Error403 from './error-403';
import Error404 from './error-404';
import Error500 from './error-500';
import Guides from './guides';
import TermsAndConditions from './terms-and-conditions';
import About from './about';

// Breakpoints for a wide variety of screen widths
const breakpoints = createBreakpoints({});

// Custom styles for Materia UI components
const theme = createMuiTheme({
  typography: {
    fontFamily: 'inherit'
  },
  palette: {
    primary: {
      main: grey[600]
    },
    secondary: {
      main: '#b83a3f'
    },
    error: {
      main: '#b83a3f'
    },
    text: {
      disabled: grey[600]
    }
  },
  overrides: {
    MuiContainer: {
      root: {
        [breakpoints.up('xs')]: {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      }
    },
    MuiTabs: {
      root: {
        backgroundColor: grey[100]
      }
    },
    MuiDrawer: {
      root: {
        width: '201px'
      },
      paper: {
        position: 'initial',
        width: '200px',
        zIndex: 'initial',
        backgroundColor: grey[100]
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '36px'
      }
    },
    MuiListItemText: {
      root: {
        whiteSpace: 'nowrap'
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: grey[100]
      }
    },
    // https://github.com/mui-org/material-ui/issues/14073
    MuiTableRow: {
      root: {
        '&:last-child th, &:last-child td': {
          borderBottom: 0
        }
      }
    },
    MuiToolbar: {
      regular: {
        [`@media (max-width: ${mobileBreakpointSidebar}px) and (orientation: landscape)`]: {
          minHeight: '56px !important'
        }
      }
    }
  }
});

// Global styles
const useStyles = makeStyles({
  '@global': {
    body: {
      margin: 0,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"'
      ].join(','),
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale'
    },
    img: {
      maxWidth: '100%'
    },
    '.collapse-table': {
      width: '100%'
    },
    [`@media (max-width: ${mobileBreakpointCompactTable}px)`]: {
      // Courtesy of Chris Coyier from https://css-tricks.com/responsive-data-tables/
      // Remember to update compact-title attribute on td when adding/removing columns
      '.collapse-table table': {
        display: 'block !important',
        width: '100% !important'
      },
      '.collapse-table thead': {
        display: 'block !important',
      },
      '.collapse-table thead tr': {
        position: 'absolute !important',
        top: '-9999px !important',
        left: '-9999px !important',
      },
      '.collapse-table th': {
        display: 'block !important'
      },
      '.collapse-table tbody': {
        display: 'block !important',
        maxWidth: 'calc(100vw - 48px) !important'
      },
      '.collapse-table tbody tr': {
        display: 'block !important',
        border: `1px solid ${grey[300]} !important`,
        overflow: 'auto !important'
      },
      '.collapse-table tbody td:before': {
        position: 'absolute !important',
        top: 'calc(50% - 10px) !important',
        left: '15px !important',
        paddingRight: '10px !important',
        whiteSpace: 'nowrap !important',
        fontWeight: 'bold',
        content: 'attr(compact-title)'
      },
      '.collapse-table tbody td': {
        display: 'block !important',
        border: 'none !important',
        position: 'relative !important',
        textAlign: 'left !important'
      },
      '.collapse-table tbody td:not([no-label="true"])': {
        paddingLeft: '170px !important',
      },
      '.collapse-table tbody td[no-label="true"]': {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        width: '100% !important'
      },
      '.collapse-table tfoot': {
        display: 'block !important',
        width: '100% !important'
      },
      '.collapse-table tfoot tr': {
        display: 'block !important'
      },
      '.collapse-table tfoot td': {
        display: 'block !important'
      },
    }
  }
});

/**
 * Creates the routing for all available URLs.
 */
export default () => {
  useStyles();
  return (
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <TeamProvider>
            <ViewportProvider>
              <MountProvider>
                <PreferenceProvider>
                  <MessageBannerProvider>
                    <DialogBoxProvider>
                      <BrowserRouter>
                        <Switch>
                          <Route path={pages['main-dashboard'].url} exact
                                 component={MainDashboard}/>
                          <Route path={pages['teams'].url} exact component={Teams}/>
                          <Route path={pages['team'].url} exact component={TeamDashboard}/>
                          <Route path={pages['team-profile'].url} exact component={TeamProfile}/>
                          <Route path={pages['new-team-initial'].url} exact component={NewTeam}/>
                          <Route path={pages['new-team'].url} exact component={NewTeam}/>
                          <Route path={pages['team-members'].url} exact component={TeamMembers}/>
                          <Route path={pages['team-member-profile'].url} exact
                                 component={TeamMemberProfile}/>
                          <Route path={pages['new-team-member'].url} exact component={Invite}/>
                          <Route path={pages['team-invites'].url} exact component={TeamInvites}/>
                          <Route path={pages['team-invite-detail'].url} exact
                                 component={TeamInviteDetail}/>
                          <Route path={pages['team-credentials'].url} exact
                                 component={TeamCredentials}/>
                          <Route path={pages['team-credentials-detail'].url} exact
                                 component={TeamCredentialsDetail}/>
                          <Route path={pages['new-team-credentials'].url} exact
                                 component={NewTeamCredentials}/>
                          <Route path={pages['lexicons'].url} exact component={Lexicons}/>
                          <Route path={pages['lexicon'].url} exact component={LexiconDetail}/>
                          <Route path={pages['new-lexicon'].url} exact component={LexiconDetail}/>
                          <Route path={pages['new-analysis-lexicon'].url} exact
                                 component={LexiconDetail}/>
                          <Route path={pages['analyses'].url} exact component={Analyses}/>
                          <Route path={pages['analysis'].url} exact component={AnalysisOverview}/>
                          <Route path={pages['analysis-profile'].url} exact
                                 component={AnalysisProfile}/>
                          <Route path={pages['analysis-detail'].url} exact
                                 component={AnalysisDetail}/>
                          <Route path={pages['new-analysis-initial'].url} exact
                                 component={NewAnalysis}/>
                          <Route path={pages['new-analysis'].url} exact component={NewAnalysis}/>
                          <Route path={pages['configure-analysis'].url} exact
                                 component={ConfigureAnalysis}/>
                          <Route path={pages['reports'].url} exact component={Reports}/>
                          <Route path={pages['login'].url}
                                 render={props => <Login {...props} isLogin={true}/>}/>
                          <Route path={pages['register'].url}
                                 render={props => <Login {...props} isLogin={false}/>}/>
                          <Route path={pages['verify'].url} exact component={Verify}/>
                          <Route path={`${pages['verify'].url}/:uidb64/:token`} exact
                                 component={Verify}/>
                          <Route path={`${pages['confirm'].url}/:uidb64/:eidb64/:token`} exact
                                 component={Confirm}/>
                          <Route path={pages['reset'].url} exact component={Reset}/>
                          <Route path={`${pages['reset'].url}/:uidb64/:token`} exact
                                 component={Reset}/>
                          <Route path={pages['user'].url} exact component={UserProfile}/>
                          <Route path={pages['security'].url} exact component={UserSecurity}/>
                          <Route path={pages['contact'].url} exact component={Contact}/>
                          <Route path={pages['disclaimers'].url} exact component={Disclaimers}/>
                          <Route path={pages['terms-and-conditions'].url} exact
                                 component={TermsAndConditions}/>
                          <Route path={pages['about'].url} exact component={About}/>
                          <Route path={pages['guides'].url} exact component={Guides}/>
                          <Route path={pages['error-403'].url} exact component={Error403}/>
                          <Route path={pages['error-500'].url} exact component={Error500}/>
                          <Route path="*" component={Error404}/>
                        </Switch>
                      </BrowserRouter>
                    </DialogBoxProvider>
                  </MessageBannerProvider>
                </PreferenceProvider>
              </MountProvider>
            </ViewportProvider>
          </TeamProvider>
        </AuthProvider>
      </ThemeProvider>
  );
};
