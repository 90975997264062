/**
 * @file HTTP 404 page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import errorImage from '../../../assets/images/404.png';
import pages from '../../../config/pages.json';
import Layout from '../../common/layout';
import LoadingButton from '../../common/loading/loading-button';

const useStyles = makeStyles(_ => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

/**
 * Loads HTTP 404 page.
 */
export default () => {
  const classes = useStyles();
  const styles = { root: classes.root };
  return (
      <Layout pageId="error-404" title={null}>
        <Container classes={styles}>
          <Box display="flex" flexDirection="column" alignItems="center" py={3}>
            <img src={errorImage} alt="HTTP 404"/>
            <Box>
              <Typography component="h2" variant="h5">Oops! Page not found</Typography>
            </Box>
            <Box mt={3} mb={6}>
              <Typography variant="body1">The page you are looking for doesn't exist or may have
                been removed.</Typography>
            </Box>
            <LoadingButton href={pages['home'].url} variant="contained" color="primary">Go back
              home</LoadingButton>
          </Box>
        </Container>
      </Layout>
  );
};
