/**
 * @file Analysis panel for analysis overview
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useParams } from "react-router";
import pages from '../../../config/pages.json';
import { makeUrl } from '../../../utils/url';
import LoadingButton from "../../common/loading/loading-button";

/**
 * @param {string} status
 * @param {() => void} cancelManagedAnalysis
 * @param {boolean} isUpdatable
 */
export default ({ status, cancelManagedAnalysis, isUpdatable }: { status: string, cancelManagedAnalysis: () => void, isUpdatable: boolean }) => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  // Generates a URL to the new analysis followers page.
  const configureAnalysisURL = makeUrl(pages['configure-analysis'].url, {
    ...params,
    stepNumber: 2
  });

  return <Grid container spacing={2}>
    {
      status === 'In progress - configuration required'
          ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    The data is ready to be analysed. The next step is to configure the analysis.
                  </Typography>
                </Grid>
                {
                  isUpdatable && (<Grid item>
                    <LoadingButton variant="contained" color="secondary" href={configureAnalysisURL}>
                      Continue configuration
                    </LoadingButton>
                  </Grid>)
                }
              </>
          )
          : <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              The data is currently being analysed. You'll receive an email when this process is
              complete.
            </Typography>
            {
              isUpdatable && (<Grid item>
                <LoadingButton variant="contained" color="secondary"
                               onClick={cancelManagedAnalysis}
                               disableAutoLoad={true}>Cancel
                  analysis</LoadingButton>
              </Grid>)
            }
          </Grid>
    }
  </Grid>
}
