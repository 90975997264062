/**
 * @file Processes analysis state for analysis progress stepper
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Assessment from '@material-ui/icons/Assessment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Description from '@material-ui/icons/Description'
import ErrorIcon from '@material-ui/icons/Error';
import { default as React } from "react";

/**
 * @param {any} analysisData
 * @param {React.Dispatch<React.SetStateAction<number>>} setActiveStep
 * @param {React.Dispatch<React.SetStateAction<string>>} setWarning
 * @param {React.Dispatch<React.SetStateAction<string[]>>} setErrors
 * @param {React.Dispatch<React.SetStateAction<boolean>> | undefined} setHideContent
 */
export const processState = (analysisData: any,
                             setActiveStep: React.Dispatch<React.SetStateAction<number>>,
                             setWarning: React.Dispatch<React.SetStateAction<string>>,
                             setErrors: React.Dispatch<React.SetStateAction<string[]>>,
                             setHideContent?: React.Dispatch<React.SetStateAction<boolean>>) => {

  // result.data.collected is used here instead of result.data.status
  // to get stage in the case of an error
  if (!analysisData.collected) {
    setActiveStep(0);
  } else if (analysisData.collected && analysisData.status !== 'Completed') {
    setActiveStep(1);
  } else if (analysisData.status === 'Completed') {
    setActiveStep(2);
  }

  // States that should not trigger a warning/error banner
  const normalStates = new Set([
    'In progress - collecting',
    'In progress - analysing',
    'Configured',
    'Completed'
  ]);

  // States where user must intervene
  // Show a warning but don't hide content
  const actionStates = new Set([
    'In progress - configuration required',
    'Not started'
  ]);

  if (!normalStates.has(analysisData.status)) {
    if (analysisData.status !== 'Failed') {
      setWarning(analysisData.status);
    } else {
      setErrors(analysisData.errors?.length > 0 ? analysisData.errors : ['Unknown error']);
    }

    if (setHideContent) {
      setHideContent(!actionStates.has(analysisData.status));
    }
  }
};

/**
 * Generates label props for progress stepper
 *
 * @param {string[]} errors
 * @param {string} warning
 * @param {number} activeStep
 * @param {number} index
 * @param {string} status
 * @param {string} label
 * @param {boolean | undefined} centerStatus
 */
export const generateLabel = (errors: string[], warning: string, activeStep: number, index: number, status: string, label: string, centerStatus?: boolean) => {
  let labelProps: any = {};
  let labelColor: string = '';
  let subLabelColor: string = '';

  const errorColor = '#b83a3f';
  const warningColor = orange[500];
  const successColor = green[500];
  const incompleteColor = grey[500];

  const errorTextColor = 'rgb(73, 23, 25)';
  const warningTextColor = 'rgb(102, 60, 0)';
  const successTextColor = 'rgb(30, 70, 32)';

  let iconColor: string = incompleteColor;

  if (errors.length !== 0 && activeStep === index) {
    iconColor = errorColor;
    subLabelColor = errorTextColor;
    labelColor = errorTextColor;
  } else if (warning && activeStep === index) {
    iconColor = warningColor;
    subLabelColor = warningTextColor;
    labelColor = warningTextColor;
  } else if (index <= activeStep) {
    iconColor = successColor;
    labelColor = successTextColor;
  }

  if (errors.length !== 0 && activeStep === index) {
    labelProps.StepIconComponent = () => <ErrorIcon style={{ color: errorColor }}/>;
  } else {
    switch (index) {
      case 0:
        labelProps.StepIconComponent = () => <CloudDownloadIcon style={{ color: iconColor }}/>;
        break;
      case 1:
        labelProps.StepIconComponent = () => <Assessment style={{ color: iconColor }}/>;
        break;
      case 2:
        labelProps.StepIconComponent = () => <Description style={{ color: iconColor }}/>;
        break;
      default:
        labelProps.StepIconComponent = () => <CheckCircleIcon style={{ color: iconColor }}/>;
    }
  }

  if (subLabelColor) {
    labelProps.optional = (
        <Grid container direction="column" style={{ textAlign: centerStatus ? 'center' : 'left' }}>
          <Grid item xs={12}>
            <Typography variant="caption" style={{ color: subLabelColor }}>
              {status}
            </Typography>
          </Grid>
        </Grid>
    );
  }

  labelProps.children = <span style={labelColor ? { color: labelColor } : {}}>
      {label}
    </span>;

  return labelProps;
};
