/**
 * @file Layout for user profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import ProfileForm from './profile-form';

/**
 * Loads profile form.
 */
export default () => (
    <Layout pageId="user" center={true}>
      <MessageBarProvider>
        <ProfileForm/>
      </MessageBarProvider>
    </Layout>
);
