/**
 * @file Team form for teams page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from '../../../hooks/mount';
import { getNextPage, listTeams } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";
import BlankTable from './blank-table';
import TeamTable from './team-table';

/**
 * Loads team form for teams page.
 */
export default () => {
  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {any[]} teamList - array containing metadata of teams
   */
  const [teamList, setTeamList] = useState([]);

  /**
   * @type {string | null} nextPageUrl - cursor based next page URL of teams
   */
  const [nextPageUrl, setNextPageUrl] = useState(null as string | null);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Sets up the team form.
   */
  useEffect(() => {
    (async () => {
      const result = await listTeams(mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setTeamList(result.data);
      setNextPageUrl(result.next);
      setIsLoaded(true);
    })();
  }, [history, auth.dispatch, mount.state.signal]);

  /**
   * If the next page is available, adds a load more link to the bottom of the team table.
   * When user clicks the link, loads the next team items and add them to the current team table.
   */
  const loadMore = async () => {
    if (nextPageUrl) {
      const result = await getNextPage(nextPageUrl, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setTeamList(teamList.concat(result.data));
      setNextPageUrl(result.next);
    }
  };

  // Loads the blank table if there is no team. Otherwise, loads the team table.
  return isLoaded
      ? teamList.length > 0
          ? <TeamTable teamList={teamList} hasMore={!!nextPageUrl} loadMore={loadMore}/>
          : <BlankTable/>
      : <LoadingContent/>;
};
