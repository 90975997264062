/**
 * @file Review panel for new team page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useHistory, useParams } from "react-router";
import analyticsImage from "../../../assets/images/analytics.png";
import pages from '../../../config/pages.json';
import { makeUrl } from '../../../utils/url';
import LoadingButton from "../../common/loading/loading-button";

const useStyles = makeStyles(theme => ({
  box: {
    textAlign: 'center'
  },
  analyticsImage: {
    width: '250px',
    maxWidth: '100%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

/**
 * Loads review panel for new team page.
 *
 * @param {boolean} setup - Whether this component is part of a setup sequence
 */
export default ({ setup }: { setup?: boolean }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   stepNumber: step number
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  const teamUrl = makeUrl(pages['team'].url, params);

  const newAnalysisUrl = makeUrl(pages['new-analysis-initial'].url, params);

  /**
   * Navigate to next step in setup sequence
   */
  const handleBack = () => {
    history.replace(makeUrl(pages['new-team'].url, { ...params, 'stepNumber': 2 }));
  };

  const classes = useStyles();

  return <Container maxWidth="xs">
    <Box mt={3} mb={2} px={2} py={2} border={1} borderColor="grey.300" borderRadius="borderRadius"
         className={classes.box}>
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <img src={analyticsImage} alt="Analytics Graphic" className={classes.analyticsImage}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>Your new team has successfully been created! Get
            started on your first analysis.</Typography>
        </Grid>
        <Grid item>
          <LoadingButton variant="contained" color="secondary" href={newAnalysisUrl}>Add
            Analysis</LoadingButton>
        </Grid>
        <Grid item xs={12}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify='flex-end' spacing={2}>
          < Grid item>
            <Button onClick={handleBack}>
              Back
            </Button>
          </Grid>
          < Grid item>
            <LoadingButton variant="contained" color="secondary"
                           href={teamUrl}>Finish</LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Container>
}
