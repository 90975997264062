/**
 * @file Report tables and charts for report page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { Select } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useHistory, useParams } from 'react-router-dom';
import analyticsImage from "../../../assets/images/analytics.png";
import messages from "../../../config/messages";
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { ViewportContext } from "../../../hooks/viewport";
import { getReport } from '../../../utils/api';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingSpinner from "../../common/loading/loading-spinner";
import Disclaimer from "./disclaimer";
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginLeft: theme.spacing(1)
  },
  popperPaper: {
    overflow: "auto"
  },
  printOnly: {
    position: "absolute",
    top: -99999,
    "@media print": {
      position: "relative",
      top: "auto"
    }
  },
  screenOnly: {
    display: "block",
    "@media print": {
      display: "none"
    }
  },
  pageBreakAvoid: {
    "@media print": {
      "page-break-inside": "avoid"
    }
  },
  pageBreakBefore: {
    "@media print": {
      "page-break-before": "always"
    }
  }
}));

/**
 * Loads report tables and charts for report page.
 * @param {any[]} reportList - array containing metadata of reports
 */
export default ({ reportList }: { reportList: any[] }) => {
  /**
   * @type {HTMLDivElement | null} printingReportRef - reference to the report that is currently being printed
   */
  const printingReportRef = useRef<HTMLDivElement | null>(null);

  /**
   * @type {HTMLDivElement[]} reportRefs - array containing references to report containers
   */
  const reportRefs = useRef<HTMLDivElement[]>([]);

  /**
   * React printing object
   */
  const handlePrint = useReactToPrint({
    content: () => printingReportRef.current ?? null,
    pageStyle: "@page { size: letter; }"
  });

  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  /**
   * @type {number[]} tabList - array containing tab indexes of reports
   */
  const [tabList, setTabList] = useState([] as number[]);

  /**
   * @type {{ name: string, results: any[] }[]} selectedReportList - array containing the selected reports
   */
  const [selectedReportList, setSelectedReportList] = useState([] as { name: string, data: any }[]);

  /**
   * @type {boolean} open - indicates whether the add report drop-down is open
   */
  const [open, setOpen] = useState(false);

  /**
   * @type {boolean} loading - indicates whether the a report is being loaded
   */
  const [loading, setLoading] = useState(false);

  /**
   * Reference of add report drop-down item
   * @type {HTMLButtonElement | null}
   */
  const anchorRef = useRef(null as HTMLButtonElement | null);

  /**
   * Retrieves details about a report and adds it to the list of reports being displayed.
   * @param {string} reportId - report id to be retrieved in detail
   */
  const addReport = async (reportId: string) => {

    setLoading(true);

    const result = await getReport(params.teamId, reportId, mount.state.signal);

    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {

      // Sets the default tab of the new report to 0.
      setTabList([...tabList, 0]);

      // Adds the new report to the selected report list.
      const data = {
        name: result.data.name,
        data: {
          "General": result.data.data.general
        },
        loading: false
      };
      if (result.data.data['special-days']) {
        data.data = { ...data.data, ...result.data.data['special-days'] };
      }

      setSelectedReportList([...selectedReportList, data]);
    }

    setLoading(false);
  };

  /**
   * Removes a report from the tab list and the selected report list.
   * @param {number} index - Index of report to be removed
   */
  const removeReport = (index: number) => () => {
    const newTabList = [...tabList];
    newTabList.splice(index, 1);
    setTabList(newTabList);

    const newReportList = [...selectedReportList];
    newReportList.splice(index, 1);
    setSelectedReportList(newReportList);
  };

  /**
   * Updates the tab index of the current report.
   * @param {number} index - new tab index of the current report
   */
  const updateTabIndex = (index: number) => (e: React.ChangeEvent<{ value: unknown }>) => {
    const newTabList = [...tabList];
    newTabList[index] = parseInt(e.target.value as string);
    setTabList(newTabList);
  };

  /**
   * Adds a particular report.
   * @param {string} reportId - report id to be added
   */
  const selectReport = (reportId: string) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    addReport(reportId);
    setOpen(false);
  };

  /**
   * Toggles the add report drop-down.
   */
  const toggleMenu = () => {
    if (!loading) {
      setOpen(prevOpen => !prevOpen);
    }
  };

  /**
   * Closes the add report drop-down.
   * @param {React.MouseEvent<Document, MouseEvent>} e - mouse event
   */
  const closeMenu = (e: React.MouseEvent<Document, MouseEvent>) => {
    if (!(anchorRef.current && anchorRef.current.contains(e.target as HTMLElement))) {
      setOpen(false);
    }
  };

  const reportTypeList: any = {
    "semantic": "Semantic",
    "sentiment": "Sentiment",
    "social-network": "Social network",
    "semantic-sentiment": "Semantic-sentiment"
  };

  const popperModifiers = {
    flip: {
      enabled: false,
    },
    preventOverflow: {
      enabled: true,
      boundariesElement: 'scrollParent'
    }
  };

  // Automatically loads report from analysis in query params on first render
  useEffect(() => {
    // Since this will only run on the first render, we can just create new arrays
    // for tabList and selectedReportList instead of adding onto existing ones
    const addInitialReport = async (reportId: string) => {
      setLoading(true);

      const result = await getReport(params.teamId, reportId, mount.state.signal);

      if (result.success) {
        // Sets the default tab of the new report to 0.
        setTabList([0]);

        // Adds the new report to the selected report list.
        const data = {
          name: result.data.name,
          data: {
            "General": result.data.data.general
          },
          loading: false
        };
        if (result.data.data['special-days']) {
          data.data = { ...data.data, ...result.data.data['special-days'] };
        }

        setSelectedReportList([data]);
      }

      setLoading(false);
    };

    // Automatically load report corresponding to analysisId provided in query params
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const analysisId = queryParams.get('analysis');

      if (analysisId) {
        for (let i = 0; i < reportList.length; i++) {
          if (reportList[i]?.analysis?.id_str === analysisId) {
            await addInitialReport(reportList[i]?.id_str);
            break;
          }
        }

        // Clear the search query so that the report isn't automatically added on refresh
        history.replace(window.location.pathname);
      }
    })();
  }, [history, reportList, params, mount.state.signal]);

  const navbarHeight = 56;
  const popperPadding = 8;
  const popperItemHeight = 48;
  const maxRows = Math.min((viewport.state.height - popperItemHeight - navbarHeight) / popperItemHeight);
  const minPopperHeight = 224;

  // We alter the max height to ensure:
  // 1. There is at least half an entry that is obstructed to hint scrolling
  // 2. The entire menu is accessible
  const popperMaxHeight = Math.max(popperPadding + popperItemHeight * (maxRows - 0.5), minPopperHeight);

  // These fields are for counts and don't require decimals
  const integerValuedSentimentFields = new Set([
    'Neutral',
    'Negative',
    'Positive'
  ]);

  // These fields will explicitly have a polarity (either + or -) for non-zero values
  const explicitPolaritySentimentFields = new Set([
    'Average sentiment'
  ]);

  /**
   * Function to format the sentiment values according to the value type (e.g. percentage, count, etc.)
   *
   * @param {number} value
   * @param {string} attribute
   */
  const formatSentimentValues = (value: number, attribute: string) => {

    // Rounding
    let newValue = value.toFixed(integerValuedSentimentFields.has(attribute) ? 0 : 3);

    // Add explicit polarity
    if (explicitPolaritySentimentFields.has(attribute) && value > 0) {
      newValue = `+${newValue}`;
    }

    // Add explicit % sign
    if (attribute.includes('%')) {
      newValue = `${newValue}%`;
    }

    return newValue;
  };

  // These fields are for counts and don't require decimals
  const integerValuedSocialNetworkFields = new Set([
    'Edge count',
    'Node count',
    'Diameter'
  ]);

  const socialNetworkTooltipMap: any = {
    "Density": "density",
    "Diameter": "diameter",
    "Edge count": "edgeCount",
    "Node count": "nodeCount",
    "Average path length": "averagePathLength",
    "Average total degree": "averageTotalDegree",
    "Average in-degree/out-degree": "averageInOutDegree"
  };

  /**
   * Function to format the social network values according to the value type (e.g. percentage, count, etc.)
   *
   * @param {number} value
   * @param {string} attribute
   */
  const formatSocialNetworkValues = (value: number, attribute: string) => {

    // Rounding
    let newValue = value.toFixed(integerValuedSocialNetworkFields.has(attribute) ? 0 : 3);

    return newValue;
  };

  /**
   * Message displayed when a report section has no data
   */
  const NoDataMessage = () => <Grid item xs={12}>
    <Alert severity="error">
      No data available
    </Alert>
  </Grid>;

  const POSITIVE_SEMANTIC_SENTIMENT_INDEX = 0;
  const NEGATIVE_SEMANTIC_SENTIMENT_INDEX = 1;
  const NEUTRAL_SEMANTIC_SENTIMENT_INDEX = 2;

  const POSITIVE_SEMANTIC_SENTIMENT_COLOR = 'green';
  const NEGATIVE_SEMANTIC_SENTIMENT_COLOR = 'red';
  const NEUTRAL_SEMANTIC_SENTIMENT_COLOR = 'grey';

  let charts: any;
  const classes = useStyles();
  return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}/>
          <Grid item container xs={12} justify="flex-end">
            <Button variant="contained" color="secondary" ref={anchorRef} onClick={toggleMenu}>
              Add report
              {loading
                  ? <LoadingSpinner color={'inherit'} delay={100} size="0.875rem"
                                    className={classes.spinner}
                                    alternativeContent={<ArrowDropDownIcon/>}/>
                  : <ArrowDropDownIcon/>}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} modifiers={popperModifiers}
                    placement="bottom-end" transition>
              {({ TransitionProps }) => (
                  <Grow {...TransitionProps}>
                    <Paper className={classes.popperPaper}
                           style={{ maxHeight: `${popperMaxHeight}px` }}>
                      <ClickAwayListener onClickAway={closeMenu}>
                        <MenuList autoFocusItem={open}>
                          {reportList.map(report => (
                              <MenuItem value={report.id_str} key={`report-name-${report.id_str}`}
                                        onClick={selectReport(report.id_str)}>
                                <ListItemIcon>
                                  <DescriptionIcon/>
                                </ListItemIcon>
                                <ListItemText primary={report.name}/>
                              </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
              )}
            </Popper>
          </Grid>

          {selectedReportList.map((report, index) => (
            <Grid item container xs={12} md={6} alignItems="center" alignContent="flex-start"
                  key={`report-${index}`}>
              <Grid item xs={6}>
                <Typography variant="h6">{report.name}</Typography>
              </Grid>
              <Grid item container xs={6} justify="flex-end" direction="row">
                <Button variant="contained" color="secondary" onClick={() => { 
                  printingReportRef.current = reportRefs.current[index]; 
                  if(handlePrint) handlePrint(); 
                }}>
                  Print report
                </Button>
                <IconButton edge="end" color="inherit" onClick={removeReport(index)}
                            disabled={loading}>
                  <CloseIcon/>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Box mt={1} border={1} borderColor="grey.300" borderRadius="borderRadius">
                  <Box m={2} key="special-days-selector">
                    <Grid container spacing={1}>
                      {
                        Object.keys(report.data).length > 1
                            ? (<Grid item xs={12}>
                              <Disclaimer text={messages.disclaimers.specialDays}
                                          preferenceKey="report_disclaimers_hidden"/>
                            </Grid>)
                            : null
                      }
                      <Grid item xs={12}>
                        <InputLabel>
                          Select a special day insight
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                            variant="outlined"
                            fullWidth
                            inputProps={{ 'aria-label': 'Select a special day insight' }}
                            value={tabList[index]}
                            onChange={updateTabIndex(index)}
                        >
                          {
                            Object.keys(report.data).map((name, i) => (
                                <MenuItem value={i} key={`tab-${i}`}>
                                  {name}
                                </MenuItem>
                            ))
                          }
                          {
                            Object.keys(report.data).length <= 1 && <MenuItem value="" disabled>
                              No special days available
                            </MenuItem>
                          }
                        </Select>
                      </Grid>
                    </Grid>
                  </Box>
                  <div ref={el => { if(el) reportRefs.current[index] = el; }}>
                    <Box m={2} className={classes.printOnly}>
                      <Typography variant="h4">{report.name}</Typography>
                    </Box>
                    {Object.keys(report.data).map((tab, j) => (
                      (j === tabList[index] ? [false, true] : [true]).map((isPrint) => (
                        <div className={`${isPrint ? classes.printOnly : classes.screenOnly} ${j > 0 ? classes.pageBreakBefore : ""}`} key={tab + isPrint}>
                          <Box m={2} className={classes.printOnly}>
                            <Typography variant="h6">{tab}</Typography>
                          </Box>
                          {Object.keys(charts = report.data[tab]).map((type, i) => (
                              <Box m={2} key={`chart-${i}`} className={classes.pageBreakAvoid}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography variant="subtitle1"
                                                gutterBottom>{reportTypeList[type]}</Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {type === 'semantic' && (
                                        <>
                                          <Disclaimer text={messages.disclaimers.topicDetection}
                                                      preferenceKey="report_disclaimers_hidden"/>
                                          {
                                            Object.keys(charts[type] || {}).length > 0
                                                ? <Chart width={isPrint ? "8.5in" : "100%"} height={isPrint ? 500 : 300} chartType="ColumnChart"
                                                        data={Object.keys(charts[type]).reduce((result: any[], name: string) =>
                                                            [...result, [name, charts[type][name] / 100]], [['Topic', 'Tweet']])}
                                                        options={{
                                                          vAxis: {
                                                            title: '% of labelled tweets',
                                                            format: '# %'
                                                          },
                                                          legend: { position: 'none' },
                                                          tooltip: { ignoreBounds: true },
                                                          chartArea: { // These settings are to provide more padding for the bar labels
                                                            top: 50,
                                                            height: '60%'
                                                          }
                                                        }}/>
                                                : <NoDataMessage/>
                                          }
                                        </>
                                    )}
                                    {type === 'sentiment' && (
                                        <>
                                          <Disclaimer
                                              text={messages.disclaimers.automaticSentimentAnalysis}
                                              preferenceKey="report_disclaimers_hidden"/>
                                          {
                                            Object.keys(charts[type] || {}).length > 0
                                                ? <TableContainer
                                                    component={props => <Paper
                                                        variant="outlined" {...props} />}>
                                                  <Table size="small">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell align="left">Attribute</TableCell>
                                                        <TableCell align="right">Value</TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {Object.keys(charts[type]).map((attribute, j) => (
                                                          <TableRow key={`sentiment-${i}-${j}`}>
                                                            <TableCell align="left">{attribute}</TableCell>
                                                            <TableCell
                                                                align="right">{formatSentimentValues(charts[type][attribute], attribute)}</TableCell>
                                                          </TableRow>
                                                      ))}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                                : <NoDataMessage/>
                                          }
                                        </>
                                    )}
                                    {type === 'social-network' && (
                                        Object.keys(charts[type]?.graph?.data?.x || {}).length > 0
                                            ? <Grid container spacing={2}>
                                              <Grid item xs={12}>
                                                <Chart width={isPrint ? "8.5in" : "100%"} height={isPrint ? 500 : 300} chartType="ScatterChart"
                                                      data={charts[type].graph.data.x.reduce((result: any[], xValue: number, i: number) =>
                                                          [...result, [xValue, charts[type].graph.data.y[i]]], [['x', 'Degree']])}
                                                      options={{
                                                        title: charts[type].graph.title,
                                                        hAxis: { title: charts[type].graph.x_axis_title },
                                                        vAxis: { title: charts[type].graph.y_axis_title },
                                                        legend: { position: 'none' },
                                                        pointSize: 3
                                                      }}/>
                                              </Grid>
                                              <Grid item xs={12}>
                                                <TableContainer component={props => <Paper
                                                    variant="outlined" {...props} />}>
                                                  <Table size="small">
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell align="left">Name</TableCell>
                                                        <TableCell align="right">Value</TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {Object.keys(charts[type].properties).map((name, j) => (
                                                          <TableRow key={`social-network-${i}-${j}`}>
                                                            <TableCell align="left">
                                                              {
                                                                (socialNetworkTooltipMap[name] !== undefined && !isPrint)
                                                                    ? <InlineTooltip
                                                                        text={(messages.tooltips as any)[socialNetworkTooltipMap[name]]}>
                                                                      {name}
                                                                    </InlineTooltip>
                                                                    : name
                                                              }
                                                            </TableCell>
                                                            <TableCell
                                                                align="right">{formatSocialNetworkValues(charts[type].properties[name], name)}</TableCell>
                                                          </TableRow>
                                                      ))}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>
                                              </Grid>
                                            </Grid>
                                            : <NoDataMessage/>
                                    )}
                                    {type === 'semantic-sentiment' && (
                                        <>
                                          <Disclaimer text={messages.disclaimers.topicDetection}
                                                      preferenceKey="report_disclaimers_hidden"/>
                                          <Disclaimer
                                              text={messages.disclaimers.automaticSentimentAnalysis}
                                              preferenceKey="report_disclaimers_hidden"/>
                                          {
                                            Object.keys(charts[type] || {}).length > 0
                                                ? <Chart width={isPrint ? "8.5in" : "100%"} height={isPrint ? 500 : 300} chartType="ColumnChart"
                                                        data={Object.keys(charts[type]).reduce((result: any[][], name: string) =>
                                                                [...result, [
                                                                  name,
                                                                  charts[type][name][NEGATIVE_SEMANTIC_SENTIMENT_INDEX],
                                                                  charts[type][name][NEUTRAL_SEMANTIC_SENTIMENT_INDEX],
                                                                  charts[type][name][POSITIVE_SEMANTIC_SENTIMENT_INDEX]
                                                                ]],
                                                            [['Topic', 'Negative', 'Neutral', 'Positive']])}
                                                        options={{
                                                          isStacked: 'percent',
                                                          vAxis: {
                                                            title: '% of labelled tweets',
                                                            format: '# %'
                                                          },
                                                          legend: { position: 'bottom' },
                                                          tooltip: { ignoreBounds: true },
                                                          chartArea: { // These settings are to provide more padding for the bar labels
                                                            top: 50,
                                                            height: '50%'
                                                          },
                                                          colors: [ // Override colors for the series
                                                            NEGATIVE_SEMANTIC_SENTIMENT_COLOR,
                                                            NEUTRAL_SEMANTIC_SENTIMENT_COLOR,
                                                            POSITIVE_SEMANTIC_SENTIMENT_COLOR
                                                          ]
                                                        }}/>
                                                : <NoDataMessage/>
                                          }
                                        </>
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                          ))}
                        </div>
                      ))
                    ))}
                  </div>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {selectedReportList.length === 0 && (
            <>
              <Box mt={3} mb={2} px={2} py={10} border={1} borderColor="grey.300"
                   borderRadius="borderRadius">
                <Grid container spacing={2} direction="column" alignItems="center">
                  <Grid item xs={12}>
                    <img src={analyticsImage} alt="Analytics Graphic"/>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>There are reports ready to be reviewed.
                      Try adding your first report to see details.</Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
        )}
      </>
  );
};
