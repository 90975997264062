/**
 * @file Cancel panel for analysis profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { cancelAnalysis } from '../../../utils/api';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads cancel panel for analysis profile page.
 *
 * @param {boolean} dialogOpen
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setDialogOpen
 */
export default ({ dialogOpen, setDialogOpen }: { dialogOpen: boolean, setDialogOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Closes the confirmation dialog.
   */
  const closeDialog = () => setDialogOpen(false);

  /**
   * Makes API call to delete the current analysis, and redirects to the analyses page if successful.
   */
  const cancelManagedAnalysis = async () => {

    const result = await cancelAnalysis(params.teamId, params.analysisId, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });

    if (result.success) {
      closeDialog();
      history.go(0);
    }
  };

  return (
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Cancel analysis</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to cancel the analysis? All progress will be
            lost.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" autoFocus onClick={closeDialog}>Cancel</Button>
          <LoadingButton color="secondary" onClick={cancelManagedAnalysis}>Confirm</LoadingButton>
        </DialogActions>
      </Dialog>
  );
};
