/**
 * @file Context for page navigation blocker
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import messages from '../config/messages';

/**
 * Creates BlockerContext
 * BlockerContext contains a global state for navigation blocker.
 * State:
 *   url: URL for redirect if it's not undefined, otherwise used for preventing the page navigation
 *   replace: whether the new location should replace the existing one
 */
export const BlockerContext = createContext({} as {
  state: { url?: string, replace?: boolean },
  dispatch: React.Dispatch<{ url?: string, replace?: boolean }>
});

/**
 * BlockerContext reducer
 * @param {{ url: string | undefined, replace: boolean | undefined }} action - action parameters
 */
const reducer = (_: { url?: string, replace?: boolean }, action: { url?: string, replace?: boolean }) => ({
  url: action.url,
  replace: action.replace || false
});

/**
 * BlockerContext provider
 * @param {any} children - child components
 */
export const BlockerProvider = ({ children }: { children: any }) => {
  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Gets the current state and dispatcher of BlockerContext.
   * Initial state:
   *   url: undefined
   *   replace: false
   */
  const [state, dispatch] = useReducer(reducer, { url: undefined, replace: false });

  /**
   * Enforces navigation restrictions.
   * Prevents premature navigation to prevent data loss.
   */
  const block = () => {
    window.onbeforeunload = () => messages.navigationBlocker;
  };

  /**
   * Removes navigation restrictions.
   * @param {string | undefined} url - URL for redirect
   */
  const unblock = (url?: string) => {
    dispatch({ url: url });
    window.onbeforeunload = null;
  };

  /**
   * Sets a blocker when page is first loaded.
   */
  useEffect(() => {
    // When the user tries to navigate away from the page, display the navigation blocker message.
    // Note: the block function should only be called once.
    block();

    // Removes the blocker during the page unloading.
    return unblock;
  }, []);

  /**
   * Redirects to state.url when it's changed
   */
  useEffect(() => {
    if (state.url) {
      if (state.replace) {
        history.replace(state.url);
      } else {
        history.push(state.url);
      }
    }
  }, [state.url, state.replace, history]);

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the BlockerContext consumers.
  return (
      <BlockerContext.Provider value={{ state, dispatch }}>
        {children}
      </BlockerContext.Provider>
  );
};
