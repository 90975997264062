/**
 * @file Layout for team profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import ProfileForm from './profile-form';

/**
 * Sets up the page layout and loads profile form.
 */
export default () => {
  return (
      <Layout pageId={'team-profile'} center={true}>
        <MessageBarProvider>
          <ProfileForm/>
        </MessageBarProvider>
      </Layout>
  );
};
