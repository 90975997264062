/**
 * @file Layout for lexicon detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React from 'react';
import { useParams } from 'react-router-dom';
import { MessageBarProvider } from '../../../hooks/message-bar';
import Layout from '../../common/layout';
import DetailForm from './detail-form';

/**
 * Sets up the page layout and loads detail form.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   lexiconId: lexicon id
   */
  const params: any = useParams();

  return (
      <Layout pageId={params.lexiconId ? 'lexicon' : 'new-lexicon'}>
        <MessageBarProvider>
          <DetailForm/>
        </MessageBarProvider>
      </Layout>
  );
};
