/**
 * @file Context for team data
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useReducer } from 'react';

/**
 * Creates TeamContext
 * TeamContext contains a global state for team data.
 * State:
 *   teamId: id of team associated with role
 *   role: indicates role of user
 *   teamData: object with data about the current team (Stored to avoid redundant calls)
 */
export const TeamContext = createContext({} as {
  state: { teamId?: string, role: string, teamData: any },
  dispatch: React.Dispatch<{ teamId?: string, role: string, teamData: any }>
});

/**
 * TeamContext reducer
 * @param {{ teamId: string | undefined, role: string, teamData: any }} action - action parameters
 */
const reducer = (state: { teamId?: string, role: string, teamData: any },
                 action: { teamId?: string, role: string, teamData: any }) => (
    {
      teamId: action.teamId ? action.teamId : state.teamId,
      role: action.role ? action.role : state.role,
      teamData: action.teamData || {}
    }
);

/**
 * TeamContext provider
 * @param {any} children - child components
 */
export const TeamProvider = ({ children }: { children: any }) => {
  /**
   * Gets the current state and dispatcher of TeamContext.
   * Initial state:
   *   teamId: undefined
   *   role: 'Viewer'
   *   teamData: {}
   */
  const [state, dispatch] = useReducer(reducer, {
    teamId: undefined,
    role: 'Viewer',
    teamData: {}
  });

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the TeamContext consumers.
  return (
      <TeamContext.Provider value={{ state, dispatch }}>
        {children}
      </TeamContext.Provider>
  );
};
