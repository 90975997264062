/**
 * @file Failure page for user email verification page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import pages from '../../../config/pages.json';
import Layout from '../../common/layout';

/**
 * Loads failure page for user email verification page if the provided token is invalid.
 */
export default () => (
    <Layout pageId="verify" title="Email verification failed">
      <Typography variant="body1">
        Click <Link component={RouterLink} to={pages['verify'].url} variant="body1">here</Link> to
        verify your email again.
      </Typography>
    </Layout>
);
