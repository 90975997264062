/**
 * @file Team dashboard page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import grey from "@material-ui/core/colors/grey";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from "../../../config/pages.json";
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from '../../../hooks/mount';
import { TeamContext } from "../../../hooks/team";
import {
  listAnalyses,
  listInvites,
  listLexicons,
  listTwitterCredentials
} from '../../../utils/api';
import { requestQueryBuilder } from "../../../utils/query";
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import AnalysesForm from '../../analysis/analyses/analysis-form';
import DashboardBanner from "../../analysis/dashboard-banner";
import LoadingButton from "../../common/loading/loading-button";
import LoadingContent from "../../common/loading/loading-content";

const useStyles = makeStyles(theme => ({
  alert: {
    overflow: 'auto'
  },
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  borderLeft: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  description: {
    color: grey[700],
    overflow: 'auto',
    maxWidth: '100%'
  }
}));

export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   userId: user id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  /**
   * @type {string} teamName - team name
   */
  const [teamName, setTeamName] = useState('');

  /**
   * @type {string} description - team description
   */
  const [description, setDescription] = useState('');

  /**
   * @type {number} memberCount - number of team members
   */
  const [memberCount, setMemberCount] = useState(0);

  /**
   * @type {number} credentialCount - number of pending invitations
   */
  const [credentialCount, setCredentialCount] = useState(0);

  /**
   * @type {number} lexiconCount - number of lexicons
   */
  const [lexiconCount, setLexiconCount] = useState(0);

  /**
   * @type {number} completedAnalysesCount - number of pending invitations
   */
  const [completedAnalysesCount, setCompletedAnalysesCount] = useState(0);


  /**
   * @type {number} activeAnalysesCount - number of pending invitations
   */
  const [activeAnalysesCount, setActiveAnalysesCount] = useState(0);

  /**
   * @type {number} inviteCount - number of pending invitations
   */
  const [inviteCount, setInviteCount] = useState(0);

  /**
   * @type {string} creationDate - creation date of the current team
   */
  const [creationDate, setCreationDate] = useState('');

  /**
   * @type {{ [id: string]: string[] }} statuses - dictionary of statuses
   */
  const [statuses, setStatuses] = useState({} as { [id: string]: string[] });

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {string} recentAnalysisFilter
   */
  const [recentAnalysisFilter, setRecentAnalysisFilter] = useState('');

  // Generates a URL to the team profile page.
  const profileUrl = makeUrl(pages['team-profile'].url, params);

  const isManager = team.state.role === 'Manager';
  const isAnalyst = team.state.role === 'Analyst';
  const maxDaysNoUpdates = 14;

  const { state: teamState } = team;

  /**
   * Sets up the team profile form.
   */
  useEffect(() => {
    (async () => {
      setRecentAnalysisFilter(requestQueryBuilder({
        per_page: 10,
        filters: [
          {
            '$or': [
              { updated__date__gte: moment().subtract(maxDaysNoUpdates, 'd').format('YYYY-MM-DD') },
              { status: 'In progress - collecting' },
              { status: 'In progress - analysing' },
              { status: 'In progress - configuration required' }
            ]
          }
        ]
      }));

      const activeAnalysesQuery = requestQueryBuilder({
        filters: [
          {
            '$or': [
              { status: 'In progress - collecting' },
              { status: 'In progress - analysing' },
              { status: 'In progress - configuration required' }
            ]
          }
        ]
      });

      const analysesCompletedPromise = listAnalyses(params.teamId, mount.state.signal, "?status=Completed", true);
      const analysesActivePromise = listAnalyses(params.teamId, mount.state.signal, activeAnalysesQuery, true);

      // We assign a variable first so we have an iterable object to make error checking more efficient
      const analysesResult = await Promise.all([analysesCompletedPromise, analysesActivePromise]);
      const [analysesCompletedResult, analysesActiveResult] = analysesResult;

      // Check results for errors
      let result: any;
      for (let i = 0; i < analysesResult.length; i++) {
        result = analysesResult[i];

        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }
      }

      if (isAnalyst || isManager) {
        const credentialsPromise = listTwitterCredentials(params.teamId, mount.state.signal, "", true);
        const lexiconPromise = listLexicons(params.teamId, mount.state.signal, true);

        const credentialsAndLexiconResults = await Promise.all([credentialsPromise, lexiconPromise]);
        const [credentialsResult, lexiconResult] = credentialsAndLexiconResults;

        // Check results for errors
        let result: any;
        for (let i = 0; i < credentialsAndLexiconResults.length; i++) {
          result = credentialsAndLexiconResults[i];

          if (isHTTPStatusCodeError(result)) {
            handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
            return;
          }
        }

        setCredentialCount(credentialsResult.resourceCount);
        setLexiconCount(lexiconResult.resourceCount);
      }

      if (isManager) {
        const invitesResult = await listInvites(params.teamId, mount.state.signal, "", true);

        if (invitesResult.statusCode >= 400 && invitesResult.statusCode <= 599) {
          handleHttpError(history, invitesResult.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        setInviteCount(invitesResult.resourceCount);
      }

      setActiveAnalysesCount(analysesActiveResult.resourceCount);
      setCompletedAnalysesCount(analysesCompletedResult.resourceCount);

      // Team data is already stored in the team context, so we can avoid making a redundant call
      setTeamName(teamState.teamData.name);
      setDescription(teamState.teamData.description);
      setMemberCount(teamState.teamData.member_count);
      setCreationDate(moment(teamState.teamData.created).format('YYYY-MM-DD, h:mm:ss A'));

      setIsLoaded(true);
    })();
  }, [teamState, isAnalyst, isManager, params.teamId, auth.dispatch, history, mount.state.signal]);

  /**
   * Gets the standard width of columns with lg screen size for the grid system.
   *
   * Managers have 4 columns, Analysts have 3, Viewers have 2
   */
  const getLgWidth = () => {
    if (isManager) {
      return 3;
    } else if (isAnalyst) {
      return 4;
    } else {
      return 6;
    }
  };

  /**
   * Gets the width of the first box for the grid system. This is a special case because
   * for analysts, there are an odd number of cols, so one of them needs to be wider.
   */
  const getTopMdWidth = () => {
    if (isManager) {
      return 6;
    } else {
      return 12;
    }
  };

  /**
   * Gets the standard width of columns with md screen size for the grid system.
   *
   * Managers and Analysts will have 2 columns, but viewers have 1
   */
  const getMdWidth = () => {
    if (isAnalyst || isManager) {
      return 6;
    } else {
      return 12;
    }
  };

  const classes = useStyles();
  return isLoaded ? (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4">
            {teamName}
          </Typography>
          {
            description && (
                <Typography className={classes.description}>
                  {description}
                </Typography>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            {
              messages.pages.teamDashboard.description
            }
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton variant="outlined" color="primary" href={profileUrl}>More
            options</LoadingButton>
        </Grid>
        <DashboardBanner statuses={statuses}/>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6">Overview</Typography>
        </Grid>
        <Grid item xs={12} md={getTopMdWidth()} lg={getLgWidth()}>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell className={classes.borderLeft}>{creationDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Members</TableCell>
                  <TableCell className={classes.borderLeft}>{memberCount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {
          (isAnalyst || isManager) && (
              <Grid item xs={12} md={getMdWidth()} lg={getLgWidth()}>
                <TableContainer component={props => <Paper variant="outlined" {...props} />}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Credentials</TableCell>
                        <TableCell className={classes.borderLeft}>{credentialCount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Lexicons</TableCell>
                        <TableCell className={classes.borderLeft}>{lexiconCount}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
          )
        }
        <Grid item xs={12} md={getMdWidth()} lg={getLgWidth()}>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Completed Analyses</TableCell>
                  <TableCell className={classes.borderLeft}>{completedAnalysesCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Active Analyses</TableCell>
                  <TableCell className={classes.borderLeft}>{activeAnalysesCount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {
          isManager && <Grid item xs={12} md={getMdWidth()} lg={getLgWidth()}>
            <TableContainer component={props => <Paper variant="outlined" {...props} />}
                            className={classes.fullHeight}>
              <Table size="medium" className={classes.fullHeight}>
                <TableBody>
                  <TableRow>
                    <TableCell>Invitations</TableCell>
                    <TableCell className={classes.borderLeft}>{inviteCount}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        }
        <Grid item xs={12}>
          <Typography component="h1" variant="h6">Recent Analyses</Typography>
          <Typography>(Includes active analyses and ones that have been updated in the
            last {maxDaysNoUpdates} days)</Typography>
          <AnalysesForm query={recentAnalysisFilter} dashboard={true} setStatuses={setStatuses}/>
        </Grid>
      </Grid>
  ) : <LoadingContent/>;
};
