/**
 * @file Terms form for new analysis terms page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { BlockerContext } from '../../../hooks/blocker';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, setLocalAnalysis } from '../../../utils/analysis';
import { listTerms } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";
import TermsTable from './terms-table';

/**
 * Loads terms form for new analysis terms page.
 *
 * @param {(step: number) => void} updateMaxStep
 */
export default ({ updateMaxStep }: { updateMaxStep: (step: number) => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {string[][]} termList - array containing metadata of terms
   */
  const [termList, setTermList] = useState([] as string[][]);

  /**
   * @type {boolean[][]} selections - array containing metadata of term selections
   */
  const [selections, setSelections] = useState([] as boolean[][]);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Generates a URL to the new analysis followers page for the back button.
  const moveBackUrl = makeUrl(pages['configure-analysis'].url, { ...params, stepNumber: 0 });

  // Generates a URL to the new analysis tweets page for the next button.
  const moveNextUrl = makeUrl(pages['configure-analysis'].url, { ...params, stepNumber: 2 });

  // Generates a URL to the analyses page for the cancel button.
  const cancelUrl = makeUrl(pages['analysis'].url, params);

  /**
   * Sets up the new analysis terms form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);

    (async () => {
      const result = await listTerms(params.teamId, params.analysisId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setTermList(result.data.terms);
      if (analysis.termSelections) {
        setSelections(analysis.termSelections);
      } else {
        const initializeSelections: boolean[][] = [];
        result.data.terms.forEach((_: any) => {
          initializeSelections.push([true, true, true]);
        });
        setSelections(initializeSelections);
      }
      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  /**
   * Saves the user input for the form to the browser local storage.
   */
  const saveForm = () => {
    const analysis = getLocalAnalysis(params.analysisId);
    analysis.termList = termList;
    analysis.termSelections = selections;
    analysis.maxStepConfig = 2;
    updateMaxStep(2);
    setLocalAnalysis(params.analysisId, analysis);
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    setIsSubmitting(true);
    saveForm();
    blocker.dispatch({ url: url });
  };

  return isLoaded ? (
      <Container maxWidth='md'>
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={redirect(moveNextUrl)}>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.newAnalysisTerms.instructions}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <TermsTable termList={termList} selections={selections}
                          setSelections={setSelections}/>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={4}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Next</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
