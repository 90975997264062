/**
 * @file Semantic table for new analysis semantic page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';

/**
 * Loads semantic table for new analysis semantic page.
 * @param {any} entries - dictionary of lexicon entries
 * @param {any} selections - dictionary of lexicon selections
 * @param {React.Dispatch<React.SetStateAction<any>>} setSelections - callback of setting dictionary of lexicon selections
 */
export default ({ entries, selections, setSelections }: { entries: any, selections: any, setSelections: React.Dispatch<React.SetStateAction<any>> }) => {
  /**
   * Updates the lexicon selections from checkbox on change.
   * @param {string} name - category name
   */
  const updateSelections = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => setSelections({
    ...selections,
    [name]: e.target.checked
  });

  return (
      <TableContainer component={props => <Paper variant="outlined" {...props} />}>
        <Table className="collapse-table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Select</TableCell>
              <TableCell align="left">Categories</TableCell>
              <TableCell align="left">Terms</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(entries).map((name, index) => (
                <TableRow hover key={`entry-${index}`}>
                  <TableCell compact-title="Select" align="center">
                    <Checkbox checked={selections[name]} color="primary"
                              inputProps={{ 'aria-label': `Select ${name} category` }}
                              onChange={updateSelections(name)}/>
                  </TableCell>
                  <TableCell compact-title="Category" align="left">{name}</TableCell>
                  <TableCell compact-title="Terms"
                             align="left">{entries[name].join(', ')}</TableCell>
                </TableRow>
            ))}
            {
              Object.keys(entries).length === 0 && (
                  <TableRow hover key={`entry-empty`}>
                    <TableCell/>
                    <TableCell align="center">
                      No records to display
                    </TableCell>
                    <TableCell/>
                  </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
  );
};
