/**
 * @file Inline tooltip
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import React from "react";

const useStyles = makeStyles(_ => ({
  dottedUnderline: {
    borderBottom: '2px dotted'
  }
}));

/**
 * @param {any} children - text which will trigger the tooltip when hovered over
 * @param {string} text - text that appears on hover
 */
export default ({ children, text }: { children: any, text: string }) => {
  const classes = useStyles();

  return <Tooltip
      disableFocusListener title={text} arrow>
       <span className={classes.dottedUnderline}>
         {children}
       </span>
  </Tooltip>
}
