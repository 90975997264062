/**
 * @file Link that switches to a loading animation after being clicked
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import LoadingSpinner from './loading-spinner';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginLeft: theme.spacing(1)
  }
}));

/**
 * Link wrapper
 * @param {any} children - child components
 * @param {() => void} onClick - callback when Link is pressed
 * @param {text | undefined} text - text of typography (Puts loader inline with text)
 */
export default ({ children, onClick, text }: { children?: any, onClick: any, text?: string }) => {
  /**
   * loading {boolean} - Whether loading animation is active
   */
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    (async () => {
      if (onClick) {
        onClick();
      }
    })();
  };

  const classes = useStyles();
  return (
      <Link color="inherit" underline="none" onClick={handleClick}>
        {text
            ? (
                <Typography variant="body1">
                  {text}
                  {loading && (
                      <LoadingSpinner delay={0} size={"1rem"} className={classes.spinner}/>
                  )}
                </Typography>
            ) : loading
                ? <LoadingSpinner delay={0}/>
                : children
        }
      </Link>
  );
};
