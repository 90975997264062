/**
 * @file Context for floating message bar (toast)
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import React, { createContext, useReducer } from 'react';

/**
 * Creates MessageBar
 * MessageBar contains a global state for message bar status and its content.
 * State:
 *   open: indicates whether the message bar is visible
 *   success: true for a success message, and false for a failure message
 *   message: text to be shown on the message bar
 */
export const MessageBarContext = createContext({} as {
  state: { open: boolean, success?: boolean, message?: string },
  dispatch: React.Dispatch<{ open: boolean, success?: boolean, message?: string }>
});

/**
 * MessageBar reducer
 * @param {{ open: boolean, success?: boolean, message?: string }} action - action parameters
 */
const reducer = (_: { open: boolean, success?: boolean, message?: string },
                 action: { open: boolean, success?: boolean, message?: string }) => (
    { open: action.open && !!action.message, success: action.success, message: action.message }
);

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.main
  }
}));

/**
 * MessageBar provider
 * @param {any} children - child components
 */
export const MessageBarProvider = ({ children }: { children: any }) => {
  /**
   * Gets the current state and dispatcher of MountContext.
   * Initial state:
   *   open: false
   *   success: true
   *   message: undefined
   */
  const [state, dispatch] = useReducer(reducer, { open: false, success: true, message: undefined });

  /**
   * Closes the message bar
   */
  const closeMessageBar = () => dispatch({
    open: false,
    success: state.success,
    message: state.message
  });

  /**
   * Loads the appropriate icon depending on the message bar status.
   * Loads the message bar text content.
   */
  const messageContent = (
      <Box display="flex" alignItems="center">
        {state.success ? <CheckCircleIcon/> : <ErrorIcon/>}
        <Box ml={1}>
          <Typography variant="body2">{state.message}</Typography>
        </Box>
      </Box>
  );

  /**
   * Adds button to dismiss message.
   */
  const messageAction = (
      <IconButton key="close" color="inherit" onClick={closeMessageBar}>
        <CloseIcon/>
      </IconButton>
  );

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the MessageBarContext consumers.
  const classes = useStyles();
  return (
      <MessageBarContext.Provider value={{ state, dispatch }}>
        <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} open={state.open}
                  autoHideDuration={5000} onClose={closeMessageBar}>
          <SnackbarContent message={messageContent} action={[messageAction]}
                           className={state.success ? classes.success : classes.error}/>
        </Snackbar>
        {children}
      </MessageBarContext.Provider>
  );
};
