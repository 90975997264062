/**
 * @file Delete panel for analysis profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { deleteAnalysis } from '../../../utils/api';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads delete panel for analysis profile page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   *   analysisId: analysis id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * @type {boolean} dialogOpen - indicates whether the confirmation dialog is opened
   */
  const [dialogOpen, setDialogOpen] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  const teamDashboardUrl = makeUrl(pages['team'].url, params);

  /**
   * Opens the confirmation dialog.
   */
  const confirmDeletion = () => setDialogOpen(true);

  /**
   * Closes the confirmation dialog.
   */
  const cancelDeletion = () => {
    if (!isSubmitting) {
      setDialogOpen(false);
    }
  };

  /**
   * Makes API call to delete the current analysis, and redirects to the analyses page if successful.
   */
  const deleteManagedAnalysis = async () => {
    setIsSubmitting(true);

    const result = await deleteAnalysis(params.teamId, params.analysisId, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      history.push(teamDashboardUrl);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
      <>
        <Box my={2}>
          <Typography component="h2" variant="h5">Delete analysis</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>Deleting an analysis cannot be undone. All
              settings and report will be lost.</Typography>
          </Grid>
          <Grid item container xs={12} justify="flex-end">
            <Button variant="contained" color="secondary" onClick={confirmDeletion}>Delete
              analysis</Button>
            <Dialog open={dialogOpen} onClose={cancelDeletion}>
              <DialogTitle>Delete analysis</DialogTitle>
              <DialogContent>
                <DialogContentText>Are you sure you want to delete the analysis?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus onClick={cancelDeletion}
                        disabled={isSubmitting}>Cancel</Button>
                <LoadingButton color="secondary" onClick={deleteManagedAnalysis}
                               disableAutoLoad={true}
                               isSubmitting={isSubmitting}>Delete</LoadingButton>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </>
  );
};
