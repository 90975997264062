/**
 * @file Temporal form for new analysis temporal page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Alert from "@material-ui/lab/Alert/Alert";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { BlockerContext } from '../../../hooks/blocker';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, setLocalAnalysis } from '../../../utils/analysis';
import { makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";
import {
  CheckboxValidator,
  KeyboardDatePickerValidator,
  KeyboardTimePickerValidator
} from '../../common/validator';

/**
 * Loads temporal form for new analysis temporal page.
 *
 * @param {(step: number) => void} updateMaxStep
 */
export default ({ updateMaxStep }: { updateMaxStep: (step: number) => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * @type {string} timeOfDayId - time of day option id
   */
  const [timeOfDayId, setTimeOfDayId] = useState('');

  /**
   * @type {{id: string, name: string}][]} timeOfDayList - array containing metadata of time of day options
   */
  const timeOfDayList = [
    { id: 'entire-day', name: 'Entire day (24 hours)' },
    { id: 'am-rush-hour', name: 'AM rush hour' },
    { id: 'pm-rush-hour', name: 'PM rush hour' },
    { id: 'rush-hour', name: 'Rush hour (AM and PM)' },
    { id: 'off-peak-day', name: 'Off-peak day' },
    { id: 'off-peak-evening', name: 'Off-peak evening' },
    { id: 'night', name: 'Night' },
    { id: 'free-input', name: 'Free input' }
  ];

  /**
   * @type {MaterialUiPickersDate} startTime - start time
   */
  const [startTime, setStartTime] = useState(null as MaterialUiPickersDate);

  /**
   * @type {MaterialUiPickersDate} endTime - end time
   */
  const [endTime, setEndTime] = useState(null as MaterialUiPickersDate);

  /**
   * @type {string} dayOfWeekId - day of week option id
   */
  const [dayOfWeekId, setDayOfWeekId] = useState('');

  /**
   * @type {{id: string, name: string}][]} dayOfWeekList - array containing metadata of day of week options
   */
  const dayOfWeekList = [
    { id: 'entire-week', name: 'Entire week (all 7 days)' },
    { id: 'weekdays', name: 'Weekdays' },
    { id: 'weekends', name: 'Weekends' },
    { id: 'free-input', name: 'Free input' }
  ];

  /**
   * @type {any} weekdays - dictionary of weekdays
   */
  const [weekdays, setWeekdays] = useState({
    "sunday": false,
    "monday": false,
    "tuesday": false,
    "wednesday": false,
    "thursday": false,
    "friday": false,
    "saturday": false
  });

  /**
   * @type {MaterialUiPickersDate} startDate - start date
   */
  const [startDate, setStartDate] = useState(null as MaterialUiPickersDate);

  /**
   * @type {MaterialUiPickersDate} endDate - end date
   */
  const [endDate, setEndDate] = useState(null as MaterialUiPickersDate);

  /**
   * @type {boolean} specialDay - indicates whether special day is checked
   */
  const [specialDay, setSpecialDay] = useState(false);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isRushHour = (id: string) => id === 'am-rush-hour' || id === 'pm-rush-hour' || id === 'rush-hour' || id === 'off-peak-day' || id === 'off-peak-evening';

  /**
   * Updates the time of day from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateTimeOfDayId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeOfDayId(e.target.value);
    if (isRushHour(e.target.value)) {
      if (dayOfWeekId === 'weekends') {
        setDayOfWeekId('');
      }
      if (weekdays['sunday'] || weekdays['saturday']) {
        setWeekdays({ ...weekdays, "sunday": false, "saturday": false });
      }
    }
  };

  /**
   * Updates the start time from time picker on change.
   * @param {MaterialUiPickersDate} date - new start time
   */
  const updateStartTime = (date: MaterialUiPickersDate) => setStartTime(date ? moment([2000, 0, 1, date.hour(), date.minute()]) : null);

  /**
   * Updates the end time from time picker on change.
   * @param {MaterialUiPickersDate} date - new end time
   */
  const updateEndTime = (date: MaterialUiPickersDate) => setEndTime(date ? moment([2000, 0, 1, date.hour(), date.minute()]) : null);

  /**
   * Updates the day of week from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateDayOfWeekId = (e: React.ChangeEvent<HTMLSelectElement>) => setDayOfWeekId(e.target.value);

  /**
   * Updates the weekdays from dropdown menu on change.
   * @param {string} name - id of weekday
   */
  const updateWeekdays = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => setWeekdays({
    ...weekdays,
    [name]: e.target.checked
  });

  /**
   * Updates the start date from date picker on change.
   * @param {MaterialUiPickersDate} date - new start date
   */
  const updateStartDate = (date: MaterialUiPickersDate) => setStartDate(date?.startOf('day') || null);

  /**
   * Updates the end date from date picker on change.
   * @param {MaterialUiPickersDate} date - new end date
   */
  const updateEndDate = (date: MaterialUiPickersDate) => setEndDate(date?.startOf('day') || null);

  /**
   * Updates the special day from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateSpecialDay = (e: React.ChangeEvent<HTMLInputElement>) => setSpecialDay(e.target.checked);

  // Generates a URL to the new analysis twitter page for the back button.
  const moveBackUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 1 });

  // Generates a URL to the new analysis semantic page for the next button.
  const moveNextUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 3 });

  // Generates a URL to team dashboard page for cancel button
  const cancelUrl = makeUrl(pages['team'].url, params);

  /**
   * Sets up the new analysis temporal form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    if (analysis.timeOfDayId) {
      setTimeOfDayId(analysis.timeOfDayId);
    }
    if (analysis.startTime) {
      setStartTime(moment(analysis.startTime));
    }
    if (analysis.endTime) {
      setEndTime(moment(analysis.endTime));
    }
    if (analysis.dayOfWeekId) {
      setDayOfWeekId(analysis.dayOfWeekId);
    }
    if (analysis.weekdays) {
      setWeekdays(analysis.weekdays);
    }
    if (analysis.startDate) {
      setStartDate(moment(analysis.startDate));
    }
    if (analysis.endDate) {
      setEndDate(moment(analysis.endDate));
    }
    if (analysis.specialDay) {
      setSpecialDay(analysis.specialDay);
    }

    setIsLoaded(true);
  }, [params.teamId, params.analysisId, history, mount.state.signal]);

  /**
   * Saves the user input for the form to the browser local storage.
   */
  const saveForm = () => {
    const analysis = getLocalAnalysis(params.analysisId);
    analysis.timeOfDayId = timeOfDayId;
    analysis.timeOfDayName = timeOfDayList.find(time => time.id === timeOfDayId)?.name;
    analysis.startTime = startTime;
    analysis.endTime = endTime;
    analysis.dayOfWeekId = dayOfWeekId;
    analysis.dayOfWeekName = dayOfWeekList.find(day => day.id === dayOfWeekId)?.name;
    analysis.weekdays = weekdays;
    analysis.startDate = startDate;
    analysis.endDate = endDate;
    analysis.specialDay = specialDay;

    analysis.maxStep = 3;
    updateMaxStep(3);
    setLocalAnalysis(params.analysisId, analysis);
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    setIsSubmitting(true);
    saveForm();
    blocker.dispatch({ url: url });
  };

  // Adds custom validation rules for date and time.
  ValidatorForm.addValidationRule('isValidDateRange', value => startDate && value ? value.isSameOrAfter(startDate) : true);
  ValidatorForm.addValidationRule('isInSixMonths', value => startDate && value ? value.diff(startDate, 'month') < 6 : true);
  ValidatorForm.addValidationRule('isFalse', value => !value);

  const isNonRushHour = !isRushHour(timeOfDayId);
  return isLoaded ? (
      <Container maxWidth="xs">
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={redirect(moveNextUrl)}>
          <Grid container spacing={2}>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.newAnalysisTemporal.instructions}
              </Alert>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm>
                  <KeyboardDatePickerValidator autoOk variant="inline" inputVariant="outlined"
                                               fullWidth label="Start date"
                                               inputProps={{ 'aria-label': 'Start date' }}
                                               format="YYYY-MM-DD"
                                               name="start-date" value={startDate}
                                               onChange={updateStartDate}
                                               validators={['required']}
                                               errorMessages={['Start date is required']}/>
                </Grid>
                <Grid item container xs={12} sm={1} alignItems="center" justify="center">
                  <Typography variant="body1">to</Typography>
                </Grid>
                <Grid item xs={12} sm>
                  <KeyboardDatePickerValidator autoOk variant="inline" inputVariant="outlined"
                                               fullWidth label="End date"
                                               inputProps={{ 'aria-label': 'End date' }}
                                               format="YYYY-MM-DD"
                                               name="end-date" value={endDate}
                                               onChange={updateEndDate}
                                               validators={['required', 'isValidDateRange', 'isInSixMonths']}
                                               errorMessages={['End date is required', 'End date should be later than start date', 'The date range should be less than 6 months']}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SelectValidator variant="outlined" fullWidth label="Day of week"
                               inputProps={{ 'aria-label': 'Day of week' }}
                               name="day-of-week" value={dayOfWeekId} onChange={updateDayOfWeekId}
                               validators={['required']}
                               errorMessages={['Day of week is required']}>
                {dayOfWeekList.map(day => (isNonRushHour || (day.id !== 'entire-week' && day.id !== 'weekends')) && (
                    <MenuItem value={day.id} key={`day-${day.id}`}>{day.name}</MenuItem>
                ))}
              </SelectValidator>
            </Grid>
            {dayOfWeekId === 'free-input' && (
                <Grid item xs={12}>
                  <FormGroup row>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Sunday" aria-label="Sunday"
                                        control={<Checkbox checked={weekdays.sunday} color="primary"
                                                           disabled={!isNonRushHour}
                                                           onChange={updateWeekdays('sunday')}/>}/>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Monday" aria-label="Monday"
                                        control={<Checkbox checked={weekdays.monday} color="primary"
                                                           onChange={updateWeekdays('monday')}/>}/>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Tuesday" aria-label="Tuesday"
                                        control={<Checkbox checked={weekdays.tuesday}
                                                           color="primary"
                                                           onChange={updateWeekdays('tuesday')}/>}/>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Wednesday" aria-label="Wednesday"
                                        control={<Checkbox checked={weekdays.wednesday}
                                                           color="primary"
                                                           onChange={updateWeekdays('wednesday')}/>}/>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Thursday" aria-label="Thursday"
                                        control={<Checkbox checked={weekdays.thursday}
                                                           color="primary"
                                                           onChange={updateWeekdays('thursday')}/>}/>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Friday" aria-label="Friday"
                                        control={<Checkbox checked={weekdays.friday} color="primary"
                                                           onChange={updateWeekdays('friday')}/>}/>
                    </Grid>
                    <Grid item xs={6} sm={4} md={3}>
                      <FormControlLabel label="Saturday" aria-label="Saturday"
                                        control={<Checkbox checked={weekdays.saturday}
                                                           color="primary" disabled={!isNonRushHour}
                                                           onChange={updateWeekdays('saturday')}/>}/>
                    </Grid>
                  </FormGroup>
                </Grid>
            )}
            <Grid item xs={12}>
              <SelectValidator variant="outlined" fullWidth label="Time of day"
                               inputProps={{ 'aria-label': 'Time of day' }}
                               name="time-of-day" value={timeOfDayId} onChange={updateTimeOfDayId}
                               validators={['required']}
                               errorMessages={['Time of day is required']}>
                {timeOfDayList.map(time => <MenuItem value={time.id}
                                                     key={`time-${time.id}`}>{time.name}</MenuItem>)}
              </SelectValidator>
            </Grid>
            {timeOfDayId === 'free-input' && (
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm>
                      <KeyboardTimePickerValidator variant="inline" inputVariant="outlined"
                                                   fullWidth label="Start time"
                                                   inputProps={{ 'aria-label': 'Start time' }}
                                                   mask="__:__ _M"
                                                   name="start-time" value={startTime}
                                                   onChange={updateStartTime}
                                                   validators={['required']}
                                                   errorMessages={['Start time is required']}/>
                    </Grid>
                    <Grid item container xs={12} sm={1} alignItems="center" justify="center">
                      <Typography variant="body1">to</Typography>
                    </Grid>
                    <Grid item xs={12} sm>
                      <KeyboardTimePickerValidator variant="inline" inputVariant="outlined"
                                                   fullWidth label="End time"
                                                   inputProps={{ 'aria-label': 'End time' }}
                                                   mask="__:__ _M"
                                                   name="end-time" value={endTime}
                                                   onChange={updateEndTime}
                                                   validators={['required']}
                                                   errorMessages={['End time is required']}/>
                    </Grid>
                  </Grid>
                </Grid>
            )}
            <Grid item xs={12}>
              <CheckboxValidator name="special-day-insights" value={specialDay}
                                 onChange={updateSpecialDay}
                                 label={
                                   <InlineTooltip text={messages.tooltips.specialDayInsights}>
                                     Special day insights
                                   </InlineTooltip>
                                 } checked={specialDay}/>

            </Grid>
            {
              specialDay && <Grid item xs={12}>
                <Alert severity="warning">
                  {
                    messages.pages.newAnalysisTemporal.specialDays
                  }
                </Alert>
              </Grid>
            }
            <Grid item xs={12}/>
            <Grid item xs={4}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Next</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
