/**
 * @file Context for navigation bar open status
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { mobileBreakpointSidebar, ViewportContext } from "./viewport";

/**
 * Creates NavbarContext
 * NavbarContext contains a global state for navbar status.
 * State:
 *   open: indicates whether the navbar is visible
 */
export const NavbarContext = createContext({} as {
  state: { open: boolean },
  dispatch: React.Dispatch<{ type: string }>
});

/**
 * NavbarContext reducer
 * Actions:
 *   open: sets open to true
 *   close: sets open to false
 *   toggle: sets open to !open
 * @param {boolean} state - current state
 * @param {string} action - action type
 */
const reducer = (state: { open: boolean }, action: { type: string }) => {
  switch (action.type) {
    case 'open':
      return { open: true };
    case 'close':
      return { open: false };
    case 'toggle':
      return { open: !state.open };
    default:
      return state;
  }
};

/**
 * NavbarContext provider
 * @param {any} children - child components
 */
export const NavbarProvider = ({ children }: { children: any }) => {
  /**
   * Gets the current state and dispatcher of MountContext.
   * Initial state:
   *   open: true
   */
  const [state, dispatch] = useReducer(reducer, { open: window.innerWidth > mobileBreakpointSidebar });

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  /**
   * Close navbar automatically on smaller screens
   */
  useEffect(() => {
    if (viewport.state.width <= mobileBreakpointSidebar) {
      dispatch({ type: 'close' });
    } else {
      dispatch({ type: 'open' });
    }
  }, [viewport.state.width]);

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the MountContext consumers.
  return (
      <NavbarContext.Provider value={{ state, dispatch }}>
        {children}
      </NavbarContext.Provider>
  );
};
