/**
 * @file Twitter form for new analysis twitter page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from "@material-ui/core/Checkbox";
import Container from '@material-ui/core/Container';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useEffect, useState } from 'react';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { AuthContext } from '../../../hooks/auth';
import { BlockerContext } from '../../../hooks/blocker';
import { MountContext } from '../../../hooks/mount';
import { mobileBreakpointStepper, ViewportContext } from "../../../hooks/viewport";
import { getLocalAnalysis, setLocalAnalysis } from '../../../utils/analysis';
import { getNextPage, listTwitterAccounts, listTwitterCredentials } from '../../../utils/api';
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import InlineTooltip from "../../common/inline-tooltip";
import LoadingButton from '../../common/loading/loading-button';
import LoadingPage from "../../common/loading/loading-page";

/**
 * Loads twitter form for new analysis twitter page.
 *
 * @param {(step: number) => void} updateMaxStep
 */
export default ({ updateMaxStep }: { updateMaxStep: (step: number) => void }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  /**
   * @type {string} twitterAccountId - Twitter account id
   */
  const [twitterAccountId, setTwitterAccountId] = useState('');

  /**
   * @type {any[]} twitterAccountList - array containing metadata of Twitter accounts
   */
  const [twitterAccountList, setTwitterAccountList] = useState([] as any[]);

  /**
   * @type {string} hashtags - text of hashtags separate by ","
   */
  const [hashtags, setHashtags] = useState('');

  /**
   * @type {string[]} twitterCredentialsIds - array containing Twitter credentials ids
   */
  const [twitterCredentialsIds, setTwitterCredentialsIds] = useState(['']);

  /**
   * @type {{}} twitterCredentialsDict - dictionary containing metadata of Twitter credentials
   */
  const [twitterCredentialsDict, setTwitterCredentialsDict] = useState({} as any);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether premium search is enabled
   */
  const [usePremiumSearch, setUsePremiumSearch] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether 30 day search is enabled
   */
  const [use30DaySearch, setUse30DaySearch] = useState(false);

  /**
   * @type {boolean} premiumSelected - indicates whether a premiumAccount is selected
   */
  const [premiumSelected, setPremiumSelected] = useState(false);

  /**
   * Set of credential ids to prevent repeat selections
   * @type {Set<string>}
   */
  const [currentlySelected, setCurrentlySelected] = useState(new Set());

  /**
   * Updates Twitter account id from dropdown menu on change.
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateTwitterAccountId = (e: React.ChangeEvent<HTMLSelectElement>) => setTwitterAccountId(e.target.value);

  /**
   * Updates the hashtags from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateHashtags = (e: React.ChangeEvent<HTMLInputElement>) => setHashtags(e.target.value);

  /**
   * Updates the premium search flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updatePremiumSearch = (e: React.ChangeEvent<HTMLInputElement>) => setUsePremiumSearch(e.target.checked);

  /**
   * Updates the 30 day search flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const update30DaySearch = (e: React.ChangeEvent<HTMLInputElement>) => setUse30DaySearch(e.target.checked);

  /**
   * Updates the Twitter credentials id from dropdown menu on change.
   * @param {number} index - index of the Twitter credentials id
   * @param {React.ChangeEvent<HTMLSelectElement>} e - change event
   */
  const updateTwitterCredentialsIds = (index: number) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTwitterCredentialsIds = [...twitterCredentialsIds];
    newTwitterCredentialsIds[index] = e.target.value;

    setCurrentlySelected(new Set(newTwitterCredentialsIds));
    updatePremiumSelected(newTwitterCredentialsIds);
    setTwitterCredentialsIds(newTwitterCredentialsIds);
  };

  /**
   * Checks if  any premium credentials are selected
   * @param {string[]} newTwitterCredentialsIds
   */
  const updatePremiumSelected = (newTwitterCredentialsIds: string[]) => {
    for (let i = 0; i < newTwitterCredentialsIds.length; i++) {
      if (twitterCredentialsDict[newTwitterCredentialsIds[i]]?.premium) {
        setPremiumSelected(true);
        return;
      }
    }

    setPremiumSelected(false);
    setUse30DaySearch(false);
    setUsePremiumSearch(false);
  };

  // Generates a URL to the new analysis profile page for the back button.
  const moveBackUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 0 });

  // Generates a URL to the new analysis temporal page for the next button.
  const moveNextUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 2 });

  // Generates a URL to team dashboard page for cancel button
  const cancelUrl = makeUrl(pages['team'].url, params);

  /**
   * Adds a new Twitter credentials drop-down list.
   */
  const addTwitterCredentials = () => setTwitterCredentialsIds([...twitterCredentialsIds, '']);

  /**
   * Removes a Twitter credentials drop-down list.
   * @param {number} index - index of the Twitter credentials drop-down list
   */
  const removeTwitterCredentials = (index: number) => () => {
    const newTwitterCredentialsIds = [...twitterCredentialsIds];
    newTwitterCredentialsIds.splice(index, 1);

    setCurrentlySelected(new Set(newTwitterCredentialsIds));
    updatePremiumSelected(newTwitterCredentialsIds);
    setTwitterCredentialsIds(newTwitterCredentialsIds);
  };

  /**
   * Sets up the new analysis twitter form.
   */
  useEffect(() => {
    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    if (analysis.twitterAccountId) {
      setTwitterAccountId(analysis.twitterAccountId);
    }
    if (analysis.hashtags) {
      setHashtags(analysis.hashtags);
    }
    if (analysis.use30DaySearch) {
      setUse30DaySearch(analysis.use30DaySearch);
    }
    if (analysis.usePremiumSearch) {
      setUsePremiumSearch(analysis.usePremiumSearch);
    }

    (async () => {
      const promise1 = listTwitterAccounts(mount.state.signal);
      const promise2 = listTwitterCredentials(params.teamId, mount.state.signal);
      const [result1, result2] = await Promise.all([promise1, promise2]);

      if (result1.statusCode >= 400 && result1.statusCode <= 599) {
        handleHttpError(history, result1.statusCode, auth.dispatch, mount.state.signal);
        return;
      }
      if (result2.statusCode >= 400 && result2.statusCode <= 599) {
        handleHttpError(history, result2.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      let result = result1;
      let data = result.data;
      while (result.next) {
        result = await getNextPage(result.next, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }
        data = data.concat(result.data);
      }
      setTwitterAccountList(data.map((account: any) => ({
        id: account.twitter_id_str,
        name: account.handle
      })));
      if (analysis.twitterAccountId) {
        setTwitterAccountId(analysis.twitterAccountId);
      }

      result = result2;
      data = result.data;
      while (result.next) {
        result = await getNextPage(result.next, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }
        data = data.concat(result.data);
      }

      let newTwitterCredentialsDict = {} as any;
      for (let i = 0; i < data.length; i++) {
        newTwitterCredentialsDict[data[i].id] = data[i]
      }
      setTwitterCredentialsDict(newTwitterCredentialsDict);

      if (analysis.twitterCredentialsIds) {
        // We filter the selected credentials to remove invalid/deleted ids
        let filteredTwitterCredentialsIds: string[] = [];

        for (let i = 0; i < analysis.twitterCredentialsIds.length; i++) {
          if (newTwitterCredentialsDict[analysis.twitterCredentialsIds[i]]?.premium) {
            setPremiumSelected(true);
          }

          // Check if the credential ID is valid
          if (analysis.twitterCredentialsIds[i] === '0' || newTwitterCredentialsDict[analysis.twitterCredentialsIds[i]] !== undefined) {
            filteredTwitterCredentialsIds.push(analysis.twitterCredentialsIds[i]);
          }
        }

        if (filteredTwitterCredentialsIds.length > 0) {
          setTwitterCredentialsIds(filteredTwitterCredentialsIds);
          setCurrentlySelected(new Set(filteredTwitterCredentialsIds));
        }
      }

      setIsLoaded(true);
    })();
  }, [params.teamId, params.analysisId, history, auth.dispatch, mount.state.signal]);

  /**
   * Saves the user input for the form to the browser local storage.
   */
  const saveForm = () => {
    const analysis = getLocalAnalysis(params.analysisId);
    analysis.twitterAccountId = twitterAccountId;
    analysis.twitterAccountName = twitterAccountList.find(account => account.id === twitterAccountId)?.name;
    analysis.hashtags = hashtags;
    analysis.twitterCredentialsIds = twitterCredentialsIds;
    analysis.twitterCredentialsNames = twitterCredentialsIds.map(id => twitterCredentialsDict[id]?.name || 'System');
    analysis.use30DaySearch = use30DaySearch;
    analysis.usePremiumSearch = usePremiumSearch;
    analysis.maxStep = 2;

    updateMaxStep(2);

    setLocalAnalysis(params.analysisId, analysis);
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    setIsSubmitting(true);
    saveForm();
    blocker.dispatch({ url: url });
  };

  const numCredentials = Object.keys(twitterCredentialsDict).length;

  // These breakpoints are used specifically to prevent the credentialsDropdown
  // and the buttons next to them from overflowing.
  const mobileBreakpointXS = 360;
  const mobileBreakpointS = 490;

  /**
   * Generates grid width for credential selector to prevent
   * overflow on mobile.
   *
   * The return values correspond to grid item proportion out of a total of 12.
   *
   * @return {8, 9, 10}
   */
  const credentialGridWidth = () => {
    if (viewport.state.width < mobileBreakpointXS) {
      return 8;
    } else if (mobileBreakpointS < viewport.state.width && viewport.state.width < mobileBreakpointStepper) {
      return 10;
    } else {
      return 9;
    }
  };

  /**
   * The return values are the complement to the values from credentialGridWidth, meaning that
   * the sum of each pair is equal to 12.
   *
   * This function has to explicitly return the values to pass the typecheck.
   * (i.e. doing 12 - credentialGridWidth() would fail the typecheck)
   *
   * @return {2, 3, 4}
   */
  const credentialButtonsGridWidth = () => {
    if (viewport.state.width < mobileBreakpointXS) {
      return 4;
    } else if (mobileBreakpointS < viewport.state.width && viewport.state.width < mobileBreakpointStepper) {
      return 2;
    } else {
      return 3;
    }
  };

  return isLoaded ? (
      <Container maxWidth="xs">
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={redirect(moveNextUrl)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.newAnalysisTwitter.timeLimit}
              </Alert>
            </Grid>
            <Grid item xs={12}>
              {messages.pages.newAnalysisTwitter.twitterAccount}
            </Grid>
            <Grid item xs={12}>
              <SelectValidator variant="outlined" fullWidth label="Twitter account"
                               inputProps={{ 'aria-label': 'Twitter account' }}
                               name="twitter-account" value={twitterAccountId}
                               onChange={updateTwitterAccountId}
                               validators={['required']}
                               errorMessages={['Twitter account is required']}>
                {twitterAccountList.map(account => <MenuItem value={account.id}
                                                             key={`account-${account.id}`}>{account.name}</MenuItem>)}
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              {messages.pages.newAnalysisTwitter.hashtags}
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth label="Hashtags"
                             inputProps={{ 'aria-label': 'Hashtags' }} name="hashtags"
                             value={hashtags} onChange={updateHashtags} multiline rows={3}/>
            </Grid>
            <Grid item xs={12}>
              {messages.pages.newAnalysisTwitter.twitterCredentials}
            </Grid>
            {twitterCredentialsIds.map((twitterCredentialsId, index) => (
                <Grid item container xs={12} key={`credentials-list-${index}`}>
                  <Grid item xs={credentialGridWidth()}>
                    <SelectValidator variant="outlined" fullWidth label="Twitter credentials"
                                     inputProps={{ 'aria-label': 'Twitter credentials' }}
                                     name={`twitter-credentials-${index}`}
                                     value={twitterCredentialsId}
                                     onChange={updateTwitterCredentialsIds(index)}
                                     validators={['required']}
                                     errorMessages={['Twitter credentials are required']}>
                      {
                        /*
                        index === 0 && twitterCredentialsIds.length === 1 && (
                            <MenuItem value="0">System</MenuItem>
                        )*/
                      }
                      {
                        Object.keys(twitterCredentialsDict).map((id_str: string) => (
                                (twitterCredentialsIds[index] === id_str || !currentlySelected.has(id_str)) &&
                                <MenuItem value={twitterCredentialsDict[id_str].id_str}
                                          key={`credentials-${twitterCredentialsDict[id_str].id_str}`}>
                                  {twitterCredentialsDict[id_str].name}
                                </MenuItem>
                            )
                        )
                      }
                    </SelectValidator>
                  </Grid>
                  <Grid item container justify='flex-end' xs={credentialButtonsGridWidth()}>
                    <IconButton edge="end" color="secondary" onClick={addTwitterCredentials}
                                disabled={twitterCredentialsIds.length >= numCredentials || currentlySelected.has("0") || numCredentials === 0}>
                      <AddCircleIcon
                          fontSize={viewport.state.width < mobileBreakpointStepper ? "small" : "large"}/>
                    </IconButton>
                    <IconButton edge="end" color="secondary"
                                onClick={removeTwitterCredentials(index)}
                                disabled={twitterCredentialsIds.length <= 1}>
                      <RemoveCircleIcon
                          fontSize={viewport.state.width < mobileBreakpointStepper ? "small" : "large"}/>
                    </IconButton>
                  </Grid>
                </Grid>
            ))}
            {/*<Grid item xs={12}>
              <Alert severity="warning">
                System credentials cannot be paired with any other credentials
              </Alert>
            </Grid>*/}
            {
              premiumSelected && (
                  <Grid item xs={12}>
                    <FormControlLabel label={
                      <InlineTooltip text={messages.tooltips.fullArchiveSearch}>
                        Full Archive Search
                      </InlineTooltip>
                    } control={<Checkbox color="primary" checked={usePremiumSearch}
                                         onChange={updatePremiumSearch}/>}/>
                    <FormControlLabel label={
                      <InlineTooltip text={messages.tooltips.thirtyDaySearch}>
                        30 Day Search
                      </InlineTooltip>
                    } control={<Checkbox color="primary" checked={use30DaySearch}
                                         onChange={update30DaySearch}/>}/>
                  </Grid>)
            }
            <Grid item xs={12}/>
            <Grid item xs={4}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Next</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  ) : <LoadingPage minHeight="0"/>;
};
