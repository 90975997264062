/**
 * @file Review form for new analysis review page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from "@material-ui/core/Checkbox";
import Container from '@material-ui/core/Container';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert/Alert";
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import messages from '../../../config/messages';
import pages from '../../../config/pages.json';
import { BlockerContext } from '../../../hooks/blocker';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { getLocalAnalysis, removeLocalAnalysis } from '../../../utils/analysis';
import { createAnalysisConfiguration } from '../../../utils/api';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import ProfileTable from './profile-table';
import SemanticTable from './semantic-table';
import SocialTable from './social-table';
import TemporalTable from './temporal-table';
import TwitterTable from './twitter-table';

const useStyles = makeStyles(theme => ({
  '@global': {
    'td:nth-child(2)': {
      width: '50%',
      borderLeftStyle: 'solid',
      borderLeftWidth: 1,
      borderLeftColor: theme.palette.grey[300]
    }
  }
}));

/**
 * Loads review form for new analysis review page.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params: any = useParams();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Page navigation blocker
   */
  const blocker = useContext(BlockerContext);

  // Generates a URL to the new analysis social page for the back button.
  const moveBackUrl = makeUrl(pages['new-analysis'].url, { ...params, stepNumber: 4 });

  // Generates a URL to team dashboard page for cancel button
  const cancelUrl = makeUrl(pages['team'].url, params);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * @type {boolean} isMock - indicates whether the analysis should be mocked (For development purposes)
   */
  const [isMock, setIsMock] = useState(false);

  /**
   * Updates the mock flag from checkbox on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateIsMock = (e: React.ChangeEvent<HTMLInputElement>) => setIsMock(e.target.checked);

  /**
   * Submits the first stage new analysis forms, and redirects to the analysis profile page.
   */
  const submitForm = async () => {
    setIsSubmitting(true);

    // Loads the cached user input for the form from the browser local storage.
    const analysis = getLocalAnalysis(params.analysisId);
    const hashtags = analysis.hashtags.split(/[, ]+/).map((hashtag: string) => hashtag.trim()).filter((hashtag: string) => hashtag.length > 0);
    const twitterCredentialsIds = Array.from(new Set<string>(analysis.twitterCredentialsIds)).filter((id: string) => id !== '0');
    const times = analysis.timeOfDayId === 'free-input' ? [moment(analysis.startTime).format('hh:mm A'), moment(analysis.endTime).format('hh:mm A')] : analysis.timeOfDayId;
    const days = analysis.dayOfWeekId === 'free-input' ? Object.keys(analysis.weekdays).filter(key => analysis.weekdays[key]) : analysis.dayOfWeekId;
    const startDate = moment(analysis.startDate).format('YYYY-MM-DD');
    const endDate = moment(analysis.endDate).format('YYYY-MM-DD');
    const lexicon = Object.keys(analysis.lexiconSelections).reduce((accumulator: any, key) =>
        analysis.lexiconSelections[key] ? {
          ...accumulator,
          [key]: analysis.lexiconEntries[key]
        } : accumulator, {});
    const threshold = analysis.networkId === 'influential-accounts' ? analysis.threshold : undefined;
    const usePremiumSearch = analysis.usePremiumSearch || false;
    const use30DaySearch = analysis.use30DaySearch || false;
    const networkEnabled = analysis.networkEnabled || false;

    const result = await createAnalysisConfiguration(params.teamId, params.analysisId,
        analysis.twitterAccountName, hashtags, twitterCredentialsIds,
        times, days, startDate, endDate, analysis.specialDay,
        lexicon, analysis.insights,
        analysis.networkId, threshold, analysis.communitiesId, usePremiumSearch, use30DaySearch, networkEnabled, isMock,
        mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });
    if (result.success) {
      removeLocalAnalysis(params.analysisId);
      // Generates a URL to the analysis page for the next button.
      const moveNextUrl = makeUrl(pages['analysis'].url, params);
      redirect(moveNextUrl)();
    } else {
      setIsSubmitting(false);
    }
  };

  /**
   * Saves the current form, and redirects to the specific page.
   * @param {string | undefined} url - next page URL
   */
  const redirect = (url?: string) => () => {
    blocker.dispatch({ url: url });
  };

  useStyles();
  return (
      <Container maxWidth="lg">
        <Prompt when={!blocker.state.url} message={messages.navigationBlocker}/>
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.newAnalysisReview.instructions}
              </Alert>
            </Grid>
            {
              process.env.REACT_APP_MOCK_ANALYSIS?.toLowerCase() === "true" && (
                  <Grid item md={12}>
                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h6" gutterBottom>Developer
                        Settings</Typography>
                      <FormControlLabel label="Create Mock Analysis"
                                        aria-label="Create Mock Analysis"
                                        control={<Checkbox checked={isMock} color="primary"
                                                           onChange={updateIsMock}/>}/>
                    </Grid>
                  </Grid>)
            }
            <Grid item md={6} xs={12}>
              <ProfileTable redirect={redirect}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <TwitterTable redirect={redirect}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <TemporalTable redirect={redirect}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <SemanticTable redirect={redirect}/>
            </Grid>
            <Grid item md={6} xs={12}>
              <SocialTable redirect={redirect}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={4}>
              <LoadingButton variant="outlined" color="primary"
                             href={cancelUrl}>Cancel</LoadingButton>
            </Grid>
            <Grid item xs={8}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 onClick={redirect(moveBackUrl)}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Next</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Container>
  );
};
