/**
 * @file Result page for password reset page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import pages from '../../../config/pages.json';
import Layout from '../../common/layout';

/**
 * Loads result page for password reset page.
 */
export default () => (
    <Layout pageId="reset" title="Password has been reset">
      <Typography variant="body1">
        Click <Link component={RouterLink} to={pages['login'].url} variant="body1">here</Link> to
        login your account.
      </Typography>
    </Layout>
);
