/**
 * @file Checkbox validator
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React from "react";
import { ValidatorComponent } from "react-material-ui-form-validator";

/**
 * Class of custom checkbox validator
 * @see {@link https://github.com/NewOldMax/react-material-ui-form-validator}
 */
export default class CheckboxValidator extends ValidatorComponent {
  render() {
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      label,
      ...rest
    } = this.props;

    // Indicates whether the checkbox is currently in a valid state.
    const {isValid} = this.state;
    const color = isValid ? 'primary' : 'error';
    return (
        <>
          <FormControlLabel
              label={<Typography color={color}>{label}</Typography>}
              control={<Checkbox {...rest} icon={<CheckBoxOutlineBlankIcon
                  color={color}/>} checkedIcon={<CheckBoxIcon
                  color={color}/>}/>}/>
          <FormHelperText variant="outlined" error={!isValid}>
            {(!isValid && this.getErrorMessage()) || helperText}
          </FormHelperText>
        </>
    );
  }
}
