/**
 * @file Stepper form for configuring analysis
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import { default as React, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router";
import pages from "../../../config/pages.json";
import { AuthContext } from "../../../hooks/auth";
import { MountContext } from "../../../hooks/mount";
import { getLocalAnalysis } from "../../../utils/analysis";
import { getAnalysis } from "../../../utils/api";
import { handleHttpError, isHTTPStatusCodeError, makeUrl } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";
import StepperForm from '../../common/stepper-form';
import FollowersForm from '../new-analysis-followers/followers-form';
import TermsForm from '../new-analysis-terms/terms-form';
import TweetsForm from '../new-analysis-tweets/tweets-form';

/**
 * Loads stepper form for configuring analysis.
 */
export default () => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();
  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {number} maxStep - Highest step number user can access
   */
  const [maxStep, setMaxStep] = useState(2);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @param {number} newMaxStep
   */
  const updateMaxStep = (newMaxStep: number) => {
    if (newMaxStep > maxStep) {
      setMaxStep(newMaxStep);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <FollowersForm updateMaxStep={updateMaxStep}/>;
      case 1:
        return <TermsForm updateMaxStep={updateMaxStep}/>;
      case 2:
        return <TweetsForm/>;
      default:
        return <p>If you're seeing this... something went wrong.</p>;
    }
  };

  const steps = ['Followers', 'Terms', 'Tweets'];

  /**
   * Update max step and verify analysis is ready for configuration
   */
  useEffect(() => {
    (async () => {
      if (params.analysisId) {
        const analysis = getLocalAnalysis(params.analysisId);

        if (analysis.maxStepConfig) {
          setMaxStep(analysis.maxStepConfig)
        } else {
          setMaxStep(0);
        }

        // Make sure this analysis isn't already submitted
        const result = await getAnalysis(params.teamId, params.analysisId, mount.state.signal);
        if (isHTTPStatusCodeError(result)) {
          handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
          return;
        }

        if (result.data.status !== 'In progress - configuration required') {
          const moveNextUrl = makeUrl(pages['analysis'].url, params);
          history.replace(moveNextUrl);
        } else {
          setIsLoaded(true);
        }
      } else {
        setMaxStep(0);
        setIsLoaded(true);
      }
    })();
  }, [params, history, auth.dispatch, mount.state.signal]);

  return isLoaded
      ? <StepperForm getStepContent={getStepContent} steps={steps} maxStep={maxStep}
                     pageId="configure-analysis"/>
      : <LoadingContent/>;
};
