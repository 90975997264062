/**
 * @file Profile form for user profile page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useContext, useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory } from 'react-router-dom';
import messages from "../../../config/messages";
import pages from "../../../config/pages.json";
import { AuthContext } from '../../../hooks/auth';
import { MessageBarContext } from '../../../hooks/message-bar';
import { MountContext } from '../../../hooks/mount';
import { getUser, updateUser } from '../../../utils/api';
import { saveName } from "../../../utils/auth";
import { handleHttpError, isHTTPStatusCodeError } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';
import LoadingContent from "../../common/loading/loading-content";
import DeletePanel from './delete-panel';
import PreferencesPanel from "./preferences-panel";

/**
 * Loads profile form for user profile page.
 */
export default () => {
  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Message bar state and dispatcher
   */
  const messageBar = useContext(MessageBarContext);

  /**
   * Mount state and dispatcher
   */
  const mount = useContext(MountContext);

  /**
   * Auth state and dispatcher
   */
  const auth = useContext(AuthContext);

  /**
   * @type {string} currentUsername - saved existing username
   */
  const [currentUsername, setCurrentUsername] = useState('');

  /**
   * @type {string} currentFirstName - saved existing first name
   */
  const [currentFirstName, setCurrentFirstName] = useState('');

  /**
   * @type {string} currentLastName - saved existing last name
   */
  const [currentLastName, setCurrentLastName] = useState('');

  /**
   * @type {string} username - username
   */
  const [username, setUsername] = useState('');

  /**
   * @type {string} firstName - user's first name
   */
  const [firstName, setFirstName] = useState('');

  /**
   * @type {string} lastName - user's last name
   */
  const [lastName, setLastName] = useState('');

  /**
   * @type {string} email - user's email
   */
  const [email, setEmail] = useState('');

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * @type {boolean} isSubmitting - indicates whether the loading button animation should be running
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Updates the username from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateUsername = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);

  /**
   * Updates the first name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateFirstName = (e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);

  /**
   * Updates the last name from text field on change.
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateLastName = (e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);

  /**
   * Sets up the user profile form.
   */
  useEffect(() => {
    (async () => {
      const result = await getUser(auth.state.userId, mount.state.signal);
      if (isHTTPStatusCodeError(result)) {
        handleHttpError(history, result.statusCode, auth.dispatch, mount.state.signal);
        return;
      }

      setCurrentUsername(result.data.username);
      setCurrentFirstName(result.data.first_name);
      setCurrentLastName(result.data.last_name);
      setUsername(result.data.username);
      setFirstName(result.data.first_name);
      setLastName(result.data.last_name);
      setEmail(result.data.email);
      setIsLoaded(true);
    })();
  }, [auth.state.userId, history, auth.dispatch, mount.state.signal]);

  /**
   * Submits the user profile form.
   */
  const submitForm = async () => {
    setIsSubmitting(true);

    let newUsername = username.trim().toLowerCase();
    if (newUsername !== currentUsername) {
      setCurrentUsername(newUsername);
    } else {
      newUsername = '';
    }

    let newFirstName = firstName.trim();
    let newLastName = lastName.trim();

    if (newFirstName !== currentFirstName) {
      setCurrentFirstName(newFirstName);
    } else {
      newFirstName = '';
    }

    if (newLastName !== currentLastName) {
      setCurrentLastName(newLastName);
    } else {
      newLastName = '';
    }

    const result = await updateUser(auth.state.userId, newUsername, newFirstName, newLastName, mount.state.signal);
    messageBar.dispatch({ open: true, success: result.success, message: result.message });

    if (result.success) {
      // Update the name that appears on the top bar
      const savedFirstName = result?.data?.first_name || "";
      const savedLastName = result?.data?.last_name || "";

      // Update the local cache
      saveName(savedFirstName, savedLastName);

      // Immediately update the name showed locally
      auth.dispatch({
        type: 'login',
        userId: auth.state.userId,
        userFirstName: savedFirstName,
        userLastName: savedLastName
      });
    }

    setIsSubmitting(false);
  };

  const mainDashboardUrl = pages['main-dashboard'].url;

  return isLoaded ? (
      <Container maxWidth="xs">
        <ValidatorForm onSubmit={submitForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                {messages.pages.userProfile.instructions}
              </Alert>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth label="Username"
                             inputProps={{ 'aria-label': 'Username' }}
                             name="username" value={username} onChange={updateUsername}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant="outlined" fullWidth label="First name"
                             inputProps={{ 'aria-label': 'First name' }}
                             autoComplete="first-name"
                             name="first-name" value={firstName} onChange={updateFirstName}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator variant="outlined" fullWidth label="Last name"
                             inputProps={{ 'aria-label': 'Last name' }} autoComplete="last-name"
                             name="last-name" value={lastName}
                             onChange={updateLastName}/>
            </Grid>
            <Grid item xs={12}>
              <TextValidator variant="outlined" fullWidth label="Email"
                             inputProps={{ 'aria-label': 'Email' }} name="email" value={email}
                             disabled/>
            </Grid>
            <Grid item xs={12}/>

            <Grid item xs={12}>
              <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                  <LoadingButton variant="contained" color="primary"
                                 href={mainDashboardUrl}>Back</LoadingButton>
                </Grid>
                <Grid item>
                  <LoadingButton type="submit" variant="contained" color="secondary"
                                 isSubmitting={isSubmitting}>Update profile</LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
        <Box my={4}>
          <Divider/>
        </Box>
        <PreferencesPanel/>
        <Box my={4}>
          <Divider/>
        </Box>
        <DeletePanel/>
      </Container>
  ) : <LoadingContent/>;
};
