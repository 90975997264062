/**
 * @file Blank table for reports page when no reports are on record
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import analyticsImage from '../../../assets/images/analytics.png';
import pages from '../../../config/pages.json';
import { TeamContext } from '../../../hooks/team';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';

/**
 * Loads blank table for reports page.
 * Prompts user to complete the first analysis.
 */
export default () => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params = useParams();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the analyses page for the complete button.
  const analysesUrl = makeUrl(pages['analyses'].url, params);

  // Only analyst and manager can complete an analysis.
  const isAddable = team.state.role !== 'Viewer';

  return (
      <Box mt={3} mb={2} px={2} py={10} border={1} borderColor="grey.300"
           borderRadius="borderRadius">
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item xs={12}>
            <img src={analyticsImage} alt="Analytics Graphic"/>
          </Grid>
          {isAddable
              ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom>You don't have any reports. Try
                        completing your first analysis.</Typography>
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={12}>
                      <LoadingButton variant="contained" color="secondary" href={analysesUrl}>Complete
                        analysis</LoadingButton>
                    </Grid>
                  </>
              ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1">Your team doesn't have any reports.</Typography>
                  </Grid>
              )}
        </Grid>
      </Box>
  );
};
