/**
 * @file Blank table for analyses page when no analyses are on record
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import analyticsImage from '../../../assets/images/analytics.png';
import pages from '../../../config/pages.json';
import { TeamContext } from '../../../hooks/team';
import { makeUrl } from '../../../utils/url';
import LoadingButton from '../../common/loading/loading-button';

const useStyles = makeStyles(theme => ({
  logoDashboard: {
    maxWidth: '300px'
  },
  button: {
    margin: 2
  }
}));

/**
 * Loads blank table for analyses page.
 * Prompts user to add the first analysis.
 *
 * @param {boolean} dashboard - reduce size of graphic when part of a dashboard
 * @param {boolean} mainDashboard - reduce size of graphic when part of the main dashboard (dashboard must be true if mainDashboard is true)
 * @param {boolean} moreResultsOutsideQuery - whether there are more analyses that can be displayed, but don't match the search criteria
 */
export default ({ dashboard, mainDashboard, moreResultsOutsideQuery }: { dashboard?: boolean, mainDashboard?: boolean, moreResultsOutsideQuery?: boolean }) => {
  /**
   * Parameters from the current URL:
   *   teamId: team id
   */
  const params = useParams();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the new analysis profile page for the add button.
  const addAnalysisUrl = makeUrl(pages['new-analysis-initial'].url, params);

  const allAnalysesUrl = makeUrl(pages['analyses'].url, params);
  const allTeamsUrl = makeUrl(pages['teams'].url, params);

  // If we're in a dashboard but not the main dashboard, it must be the team dashboard
  const teamDashboard = dashboard && !mainDashboard;
  const analysesPage = !teamDashboard && !mainDashboard;

  const isAddable = team.state.role !== 'Viewer';

  const classes = useStyles();
  return (
      <Box mt={3} mb={2} px={2} py={dashboard ? 3 : 10} border={1} borderColor="grey.300"
           borderRadius="borderRadius">
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item xs={12}>
            <img src={analyticsImage} alt="Analytics Graphic"
                 className={dashboard ? classes.logoDashboard : ""}/>
          </Grid>
          {
            mainDashboard && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                      {
                        moreResultsOutsideQuery
                            ? "You don't have any recent analyses."
                            : "You don't have any analyses. Go to your teams to add an analysis."
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton variant="contained" color="secondary" href={allTeamsUrl}
                                   className={classes.button}>
                      View all teams
                    </LoadingButton>
                  </Grid>
                </>
            )
          }
          {
            teamDashboard && (
                isAddable
                    ? moreResultsOutsideQuery
                    ? (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              You don't have any recent analyses.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LoadingButton variant="contained" color="primary"
                                           href={allAnalysesUrl} className={classes.button}>
                              View all analyses
                            </LoadingButton>
                            <LoadingButton variant="contained" color="secondary"
                                           href={addAnalysisUrl} className={classes.button}>
                              Add analysis
                            </LoadingButton>
                          </Grid>
                        </>
                    )
                    : (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              You don't have any analyses. Try adding your first analysis.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LoadingButton variant="contained"
                                           color="secondary"
                                           href={addAnalysisUrl}
                                           className={classes.button}
                            >
                              Add analysis
                            </LoadingButton>
                          </Grid>
                        </>
                    )
                    : moreResultsOutsideQuery
                    ? (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Your team doesn't have any recent analyses.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LoadingButton variant="contained"
                                           color="primary"
                                           href={allAnalysesUrl}
                                           className={classes.button}
                            >
                              View all analyses
                            </LoadingButton>
                          </Grid>
                        </>
                    )
                    : (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Your team doesn't have any analyses.
                            </Typography>
                          </Grid>
                        </>
                    )
            )
          }
          {
            analysesPage && (
                isAddable
                    ? moreResultsOutsideQuery
                    ? (
                        <Grid item xs={12}>
                          <Typography variant="body1" gutterBottom>
                            No analyses found. Try a different search query.
                          </Typography>
                        </Grid>
                    )
                    : (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                              You don't have any analyses. Try adding your first analysis.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <LoadingButton variant="contained" color="secondary"
                                           href={addAnalysisUrl} className={classes.button}>
                              Add analysis
                            </LoadingButton>
                          </Grid>
                        </>
                    )
                    : moreResultsOutsideQuery
                    ? (
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            No analyses found. Try a different search query.
                          </Typography>
                        </Grid>
                    )
                    : (
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Your team doesn't have any analyses.
                          </Typography>
                        </Grid>
                    )
            )
          }
        </Grid>
      </Box>
  );
};
