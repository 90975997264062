/**
 * @file Lexicon table for lexicon detail page
 * @copyright 2020 University of Toronto. All rights reserved.
 */
import Grid from '@material-ui/core/Grid'
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import DialogBox from "../../common/dialog/dialog-box";
import LoadingButton from '../../common/loading/loading-button';


const useStyles = makeStyles(_ => ({
  textField: {
    minWidth: '250px'
  },
  title: {
    flex: '1 1 100%',
  },
  cell: {
    minWidth: '150px'
  },
  paper: {
    width: '100%',
  },
}));

/**
 * Loads lexicon table for lexicon detail page.
 * @param {any} entries - dictionary of lexicon entries
 * @param {React.Dispatch<React.SetStateAction<any>>} setEntries - callback of setting dictionary of lexicon entries
 * @param {boolean} isEditable - indicates whether the lexicon can be edited
 */
export default ({ entries, setEntries, isEditable }: { entries: any, setEntries: React.Dispatch<React.SetStateAction<any>>, isEditable: boolean }) => {
  /**
   * @type {boolean} editDialogOpen
   */
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  /**
   * @type {boolean} deleteDialogOpen
   */
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  /**
   * @type {string} selectedCategory
   */
  const [selectedCategory, setSelectedCategory] = useState("");

  /**
   * @type {string} selectedTerms
   */
  const [selectedTerms, setSelectedTerms] = useState("");

  /**
   * @type {{category: string, terms: string}} entry prior to change
   */
  const [oldData, setOldData] = useState({ category: "", terms: "" });

  /**
   * @type {boolean} terms texfield is blank
   */
  const [termsError, setTermsError] = useState(false);

  /**
   * @type {boolean} category textfield is blank
   */
  const [categoryError, setCategoryError] = useState(false);

  /**
   * @type {string} terms textfield error helpertext
   */
  const [termsErrorText, setTermsErrorText] = useState("");

  /**
   * @type {string} category textfield error helpertext
   */
  const [categoryErrorText, setCategoryErrorText] = useState("");

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateSelectedCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetCategoryErrors();
    setSelectedCategory(e.target.value);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - change event
   */
  const updateSelectedTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetTermsErrors();
    setSelectedTerms(e.target.value);
  };


  // Creates an array of dictionaries from the items in entries where all the terms are joined together as a string.
  let data = Object.keys(entries).map(name => ({
    category: name,
    terms: entries[name].join(', ')
  }));


  const openDialogForm = (entry: { category: string, terms: string }) => () => {
    setOldData(entry);
    setCategoryAndTerms(entry);
    setEditDialogOpen(true);
  };

  const openDeleteDialog = (entry: { category: string, terms: string }) => () => {
    setOldData(entry);
    setDeleteDialogOpen(true);
  };

  const processTerms = (terms: string) => {
    // remove space before and after comma
    terms = terms.replace(/\s*,\s*/g, ",");
    // matches and replaces newline characters with space
    terms = terms.replace(/(\r\n|\n|\r)/gm, " ");
    let termsArray = terms.split(',').map((term: string) => term.trim());
    // remove blank terms for case when input ends with comma
    termsArray = termsArray.filter(term => term !== "");
    return termsArray;
  };

  const saveQuestionChanges = () => {
    const trimmedSelectedCategory = selectedCategory.trim();

    // Category and terms are not blank
    if (trimmedSelectedCategory !== "" && selectedTerms !== "") {
      // Category exists and user is creating a new entry
      if (Object.keys(entries).includes(trimmedSelectedCategory) && oldData.category !== trimmedSelectedCategory) {
        setCategoryError(true);
        setCategoryErrorText("Category already exists");
      }
      // Category exists and user is not creating new entry
      else {
        setEditDialogOpen(false);
        // Check if category was changed or added
        if (oldData.category !== trimmedSelectedCategory) {
          if (entries.hasOwnProperty(oldData.category)) {
            delete entries[oldData.category];
          }
          entries[trimmedSelectedCategory] = processTerms(selectedTerms);
        }
        // If category unchanged, check if terms were updated
        else {
          if (oldData.terms !== selectedTerms) {
            entries[trimmedSelectedCategory] = processTerms(selectedTerms);
          }
        }
      }
    } else {
      if (trimmedSelectedCategory === "") {
        setCategoryErrorText("Category cannot be blank");
        setCategoryError(true);
      }
      if (selectedTerms === "") {
        setTermsErrorText("Terms cannot be blank");
        setTermsError(true);
      }
    }

    setEntries(entries);
  };

  const resetCategoryErrors = () => {
    setCategoryError(false);
    setCategoryErrorText("");
  };

  const resetTermsErrors = () => {
    setTermsError(false);
    setTermsErrorText("");
  };

  const resetAllErrors = () => {
    resetCategoryErrors();
    resetTermsErrors();
  };

  const closeDialog = () => {
    resetAllErrors();
  };

  const deleteCategory = (category: string) => {
    let current_data = { ...entries };
    delete current_data[category];
    setEntries(current_data);
  };

  const setCategoryAndTerms = (entry: { category: string, terms: string }) => {
    setSelectedCategory(entry.category);
    setSelectedTerms(entry.terms);
  };

  const classes = useStyles();

  const AddingBar = () => {
    let entry: { category: string, terms: string } = { category: "", terms: "" };

    return (
        <Toolbar>
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Lexicon table
          </Typography>
          {
            isEditable ? (
                <LoadingButton variant="contained" color="secondary" disableAutoLoad={true}
                               onClick={openDialogForm(entry)}>
                  <AddIcon/>
                </LoadingButton>
            ) : null
          }
        </Toolbar>
    )
  };

  return (
      <Paper className={classes.paper}>
        <AddingBar/>
        <TableContainer component={Paper}>
          <Table aria-label="lexicon terms">
            <TableHead>
              <TableRow>
                {
                  isEditable ? (
                          <>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Category</TableCell>
                            <TableCell align="center">Terms</TableCell>
                          </>
                      )
                      : (
                          <>
                            <TableCell align="center">Category</TableCell>
                            <TableCell align="center">Terms</TableCell>
                          </>
                      )
                }

              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.map(entry => (
                    <TableRow>
                      {
                        isEditable ? (
                            <>
                              <TableCell className={classes.cell} align="center">
                                <LoadingButton disableAutoLoad={true}
                                               onClick={openDialogForm(entry)}>
                                  <EditIcon/>
                                </LoadingButton>
                                <LoadingButton disableAutoLoad={true}
                                               onClick={openDeleteDialog(entry)}>
                                  <DeleteIcon/>
                                </LoadingButton>
                              </TableCell>
                              <TableCell
                                  align="center">{entry.category}</TableCell>
                              <TableCell
                                  align="center">{entry.terms}</TableCell>
                            </>
                        ) : (
                            <>
                              <TableCell
                                  align="center">{entry.category}</TableCell>
                              <TableCell
                                  align="center">{entry.terms}</TableCell>
                            </>
                        )
                      }

                    </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <DialogBox
              disableAutoClose
              open={editDialogOpen}
              setOpen={setEditDialogOpen}
              onConfirm={saveQuestionChanges}
              onDismiss={closeDialog}
              title={`Add terms`}
              confirmText="Save">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                Please enter category:
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" value={selectedCategory}
                           error={categoryError}
                           helperText={categoryErrorText}
                           onChange={updateSelectedCategory}
                           fullWidth label="Category"
                           inputProps={{ 'aria-label': 'Category' }}></TextField>
              </Grid>
              <Grid item xs={12}>
                Please enter terms, seperated by comma:
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" value={selectedTerms}
                           error={termsError}
                           helperText={termsErrorText}
                           onChange={updateSelectedTerms}
                           multiline
                           fullWidth label="Terms"
                           inputProps={{ 'aria-label': 'Terms' }}></TextField>
              </Grid>
            </Grid>
          </DialogBox>
          <DialogBox
              open={deleteDialogOpen}
              setOpen={setDeleteDialogOpen}
              onConfirm={() => deleteCategory(oldData.category)}
              title={`Warning`}
              confirmText="Delete">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                The following category will be deleted: {oldData.category}
              </Grid>
            </Grid>
          </DialogBox>
        </TableContainer>
      </Paper>
  );
};

