/**
 * @file Context for abort controller and signal
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useEffect, useReducer, useState } from 'react';

/**
 * Creates MountContext
 * MountContext contains a global state for abort controller.
 * State:
 *   signal: abort signal to terminate the fetch request
 */
export const MountContext = createContext({} as {
  state: { signal: AbortSignal },
  dispatch: React.Dispatch<{ signal: AbortSignal }>
});

/**
 * MountContext reducer
 * @param {{ signal: AbortSignal }} action - action parameters
 */
const reducer = (_: { signal: AbortSignal }, action: { signal: AbortSignal }) => ({ signal: action.signal });

/**
 * MountContext provider
 * @param {any} children - child components
 */
export const MountProvider = ({ children }: { children: any }) => {
  /**
   * @param {AbortController} - AbortController object
   */
  const [controller] = useState(new AbortController());

  /**
   * Gets the current state and dispatcher of MountContext.
   * Initial state:
   *   signal: controller.signal
   */
  const [state, dispatch] = useReducer(reducer, { signal: controller.signal });

  /**
   * Aborts a fetch request during the page unloading.
   * Prevents a state update for an unmounted component.
   */
  useEffect(() => controller.abort, [controller]);

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the MountContext consumers.
  return (
      <MountContext.Provider value={{ state, dispatch }}>
        {children}
      </MountContext.Provider>
  );
};
