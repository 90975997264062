/**
 * @file Context for user authentication
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import React, { createContext, useReducer } from 'react';

/**
 * Creates AuthContext
 * AuthContext contains a global state for user authentication.
 * State:
 *   isAuthenticated: indicates whether the user is authenticated
 *   userId: user id if the user is authenticated, 0 otherwise
 *   userFirstName: user's first name
 *   userLastName: user's last name
 */
export const AuthContext = createContext({} as {
  state: { isAuthenticated: boolean, userId: string, userFirstName: string, userLastName: string },
  dispatch: React.Dispatch<{ type: string, userId: string, userFirstName: string, userLastName: string }>
});

/**
 * AuthContext reducer
 * Actions:
 *   login: sets isAuthenticated to true and userId to action.userId
 *   logout: sets isAuthenticated to false and userId to 0
 *
 * @param {{ isAuthenticated: boolean, userId: string, userFirstName: string, userLastName: string }} state - current state
 * @param {{ type: string, userId: string, userFirstName: string, userLastName: string }} action - action type and parameters
 */
const reducer = (state: { isAuthenticated: boolean, userId: string, userFirstName: string, userLastName: string }, action: { type: string, userId: string, userFirstName: string, userLastName: string }) => {
  switch (action.type) {
    case 'login':
      return {
        isAuthenticated: true,
        userId: action.userId,
        userFirstName: action.userFirstName,
        userLastName: action.userLastName
      };
    case 'logout':
      return { isAuthenticated: false, userId: '0', userFirstName: "", userLastName: "" };
    default:
      return state;
  }
};

/**
 * AuthContext provider
 * @param {any} children - child components
 */
export const AuthProvider = ({ children }: { children: any }) => {
  /**
   * Gets the current state and dispatcher of AuthContext.
   * Initial state:
   *   isAuthenticated: false
   *   userId: '0'
   *   userFirstName: ''
   *   userLastName: ''
   */
  const [state, dispatch] = useReducer(reducer, {
    isAuthenticated: false,
    userId: '0',
    userFirstName: '',
    userLastName: ''
  });

  // Renders the provider component and its child components.
  // Passes { state, dispatch } props to the AuthContext consumers.
  return (
      <AuthContext.Provider value={{ state, dispatch }}>
        {children}
      </AuthContext.Provider>
  );
};
