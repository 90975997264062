/**
 * @file Generic stepper form template
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from "@material-ui/core/styles";
import { default as React, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from "../../../config/pages.json";
import {
  mobileBreakpointSidebar,
  mobileBreakpointStepper,
  ViewportContext
} from "../../../hooks/viewport";
import { makeUrl } from '../../../utils/url';
import LoadingContent from "../../common/loading/loading-content";

const useStyles = makeStyles(theme => ({
  verticalStepper: {
    padding: 0
  },
  verticalStepContent: {
    [`@media (max-width: ${mobileBreakpointSidebar}px)`]: {
      minWidth: 'calc(100vw - 80px)'
    },
  }
}));

/**
 * Loads stepper form
 *
 * @param { (_: number) => any } getStepContent - return component given step number
 * @param { string[] } steps - array with caption for all steps
 * @param { number } maxStep - Highest step number user is allowed to reach
 * @param { string | undefined } pageId - Id of parent page (Undefined when stepper is externally controlled)
 * @param { number | undefined } activeStepOverride - Allows activeStep to be controlled externally
 */
export default ({ getStepContent, steps, maxStep, pageId, activeStepOverride }: { getStepContent: (_: number) => any, steps: string[], maxStep?: number, pageId?: string, activeStepOverride?: number }) => {

  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Viewport state and dispatcher
   */
  const viewport = useContext(ViewportContext);

  /**
   * @type {number} activeStep
   */
  const [activeStep, setActiveStep] = useState(0);

  /**
   * @type {boolean} isLoaded - indicates whether the page is ready to render
   */
  const [isLoaded, setIsLoaded] = useState(false);

  /**
   * Updates step number
   */
  useEffect(() => {
    if (activeStepOverride !== undefined) {
      setActiveStep(activeStepOverride);
      setIsLoaded(true);
    } else if (params.stepNumber && pageId !== undefined) {
      const maxValidStep = Math.min(steps.length - 1, maxStep !== undefined ? maxStep : steps.length - 1);

      if (0 <= params.stepNumber && params.stepNumber <= maxValidStep) {
        setActiveStep(parseInt(params.stepNumber));
        setIsLoaded(true);
      } else {

        let nextStep = 0;

        if (params.stepNumber > maxValidStep) {
          nextStep = maxValidStep;
        }

        history.replace(makeUrl((pages as any)[pageId].url, { ...params, stepNumber: nextStep }));
      }
    } else {
      setIsLoaded(true);
    }
  }, [params, steps.length, maxStep, history, pageId, activeStepOverride]);

  const classes = useStyles();
  return isLoaded
      ? (<>
        {
          viewport.state.width > mobileBreakpointStepper
              ? (
                  <>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                      ))}
                    </Stepper>

                    <Grid container spacing={2} direction="column" alignItems="center">
                      <Grid item>
                        {getStepContent(activeStep)}
                      </Grid>
                    </Grid>
                  </>
              )
              : (
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <Stepper activeStep={activeStep} orientation="vertical"
                               className={classes.verticalStepper}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                              <StepContent className={classes.verticalStepContent}>
                                {getStepContent(index)}
                              </StepContent>
                            </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
              )
        }
      </>)
      : <LoadingContent/>
};
