/**
 * @file Invite table for invitations page
 * @copyright 2020 University of Toronto. All rights reserved.
 */

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pages from '../../../config/pages.json';
import { TeamContext } from "../../../hooks/team";
import { makeUrl } from '../../../utils/url';
import LoadingButton from "../../common/loading/loading-button";
import LoadingLink from '../../common/loading/loading-link';

const useStyles = makeStyles(_ => ({
  tableRow: {
    cursor: 'pointer'
  }
}));

/**
 * Loads invite table for invitations page.
 * @param {any[]} inviteList - array containing metadata of invitations
 * @param {boolean} hasMore - indicates whether there are more invitations available to load
 * @param {boolean} loadMore - callback to load the next page of invitations
 */
export default ({ inviteList, hasMore, loadMore }: { inviteList: any[], hasMore: boolean, loadMore: () => void }) => {
  /**
   * Parameters from the current URL
   */
  const params: any = useParams();

  /**
   * React Router history object
   */
  const history = useHistory();

  /**
   * Team state and dispatcher
   */
  const team = useContext(TeamContext);

  // Generates a URL to the new team member page for the add button.
  const addMemberUrl = makeUrl(pages['new-team-member'].url, params);

  /**
   * Redirects to a specific invitation page.
   * @param {string} inviteId - invitation id
   */
  const redirect = (inviteId: string) => () => {
    // Generates a URL to a specific invitation page.
    const inviteUrl = makeUrl(pages['team-invites'].url, params) + `/${inviteId}`;
    history.push(inviteUrl);
  };

  // Only manager can add a team member.
  const isAddable = team.state.role === 'Manager';

  const classes = useStyles();
  return (
      <Grid container spacing={2}>
        <Grid container item xs={12} justify='flex-end' spacing={2}>
          {isAddable && (
              <Grid item>
                <LoadingButton variant="contained" color="secondary" href={addMemberUrl}>Add team
                  member</LoadingButton>
              </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={props => <Paper variant="outlined" {...props} />}>
            <Table className="collapse-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Invitation Expires</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inviteList.map((invite: any) => (
                    <TableRow hover key={`invite-${invite.id_str}`} className={classes.tableRow}
                              onClick={redirect(invite.id_str)}>
                      <TableCell compact-title="Email" align="left">
                        <LoadingButton variant="text" color="inherit"
                                       onClick={redirect(invite.id_str)}>
                          {invite.email}
                        </LoadingButton>
                      </TableCell>
                      <TableCell compact-title="Role" align="center">{invite.role}</TableCell>
                      <TableCell compact-title="Invitation Expires"
                                 align="center">{invite.expires && moment(invite.expires).format('YYYY-MM-DD')}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
              {hasMore && (
                  <TableFooter>
                    <TableRow className={classes.tableRow}>
                      <TableCell colSpan={8} align="center">
                        <LoadingLink onClick={loadMore} text="Load more..."/>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}/>
      </Grid>
  );
};
